
import { Helmet } from 'react-helmet';
import Sidebar from '../components/Sidebar';
import React, { useRef, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DraftPage from './scheduler/draft';
import Mylibrary from '../components/common/myLibrary/MylibraryComponant';

import MyDesigns from '../pages/images/myDesigns';
import SchedulePostTrash from './scheduler/SchedulePostTrash';
import Project from '../pages/pageBuilder/projects';
import Projects from './images/projects';
import TrashTickets from '../pages/pixa-support/components/TrashTickets'
import Select from "react-select";

const options = [
    { value: '', label: 'Select Action' },
    { value: 'Delete', label: 'Delete' },
    { value: 'Restore', label: 'Restore' },
]

const options1 = [
    { value: '', label: 'Select Action' },
    { value: 'Delete', label: 'Delete' },
    { value: 'Restore', label: 'Restore' }
]
function Trash({ pageTitle }) {
    const [activeTab, setActiveTab] = useState('Media')
    const [searchKeyword, setSearchKeyword] = useState('');
    const [searchType, setSearchType] = useState(1);
    const [showButtons, setShowButtons] = useState(0);
    const [action, setAction] = useState('');
    const [emptyAll, setEmptyAll] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [customCheckAll, setCustomCheckAll] = useState(false);


    const displayButtons = (val) => {
        if (val == 0) {
            setCheckAll(false);
            setCustomCheckAll(false);
        }
        setShowButtons(val)
    }

    const updateAction = (val) => {

        if (val) {
            setAction(val);
        } else {
            setAction('');
        }
    }

    const emptyStates = () => {
        setCheckAll(false);
        setCustomCheckAll(false);
        setShowButtons(0);
     
    }
  
    const chackAllAction = (val) => {
        if(val){
            setEmptyAll(false)
            setCustomCheckAll(true);
            setCheckAll(true)
        }else
        { 
            setCustomCheckAll(false);
            setCheckAll(false)
        }
    }
    const selectItemAll=(e)=>{
        
        e.target.checked ?  setEmptyAll(false)  :  setEmptyAll(true);
        setCheckAll(e.target.checked);
        setCustomCheckAll(e.target.checked)

    }


    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper">
                <Sidebar />
                <div className='pxl-trashpage-mainWrapper'>
                <div className="pxl_rightBar_assets">
                    {/* Count Card Widgets Section */}
                    <div className="pxl_pageTitle">
                        <h2>{pageTitle}</h2>
                    </div>
                    <div className='fullPage'>
                        <div className="sch-library-header pxl-myLibraryHeader">
                            <h2 className="sch-textfont16">Trash</h2>
                            <div className="pxl_uploadwrapper" >
                                <div className="cun-headerSelect-wrap pxl-mylibrary-selectBox">
                                    <div className="cun-AllChecknoxSelect">
                                        <div className="sch-custom-check-box ">
                                            <div className="form-group">
                                                <input type="checkbox" id="html"
                                                    checked={checkAll && customCheckAll}
                                                    onChange={(e) => selectItemAll(e)} />
                                                <label htmlFor="html">
                                                    Select All 
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cun-headreSelect cuntrashSelect">
                                        <Select options={ (activeTab != 'Post') ? options : options1} isDisabled={showButtons == 0}
                                            value={options.filter(option =>
                                                option.value == action)}
                                            onChange={(e) => setAction(e.value)}
                                        />
                                    </div>
                                </div>
                                {/* <div className={`sch-menuOpen`} >
                                
                                    <button className={`sch-btn ${showButtons == 0 && 'pxl-button-disable'}`} onClick={() => {
                                        (showButtons > 0) && setMenuToggle(!menuToggle)
                                    }}><span className='sch-btn-text'>Actions</span></button>
                                    <div className={`sch-menuBox ${menuToggle ? 'menu_show' : ''}`}>
                                        <a className="" onClick={() => { setAction('Delete'); setMenuToggle(false), "delete cliocks" }} ><span>{svg.app.deleteIcon1}</span>Delete</a>
                                        <a className="" onClick={() => { setAction('Restore'); setMenuToggle(false) }}><span>{svg.app.Restore1}</span>Restore</a>
                                    </div>
                                </div> */}



                                <div className="sch-search-section">
                                    <div className={`sch-search-row sch-search-bg-color`}>
                                        <input placeholder={`Search ${activeTab.toLocaleLowerCase()}`}
                                            type='text'
                                            value={searchKeyword}
                                            onChange={e => setSearchKeyword(e.target.value)}
                                            onKeyUp={async e => {
                                                e.preventDefault();
                                                if (e.key === 'Enter') {
                                                    setSearchType(searchType + 1);
                                                }
                                            }}
                                        />
                                        <button type="button" className="sch-search-btn">
                                            <img src="/assets/images/scheduler/search.svg" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="sch-post-img-wrapper">  
                            <div className='sch-trash-tabs-wrapper'>
                                <Tabs defaultActiveKey={activeTab} onSelect={(k) => { setActiveTab(k); emptyStates() }}>
                                    <Tab eventKey="Media" title="Media" className="pxl-trash-media-data">
                                        {(activeTab == 'Media') && <Mylibrary
                                            isTrash={true}
                                            mediaKeyWord={(activeTab == 'Media') ? searchKeyword : ''}
                                            searchType={(activeTab == 'Media') && searchType}
                                            displayButtons={displayButtons}
                                            action={(activeTab == 'Media') && action}
                                            updateAction={updateAction}
                                            checkAll={checkAll}
                                            chackAllAction={chackAllAction}
                                            checkType={emptyAll}
                                        />}
                                    </Tab>
                                    <Tab eventKey="Draft" title="Draft" className="pxl-trash-draft-data">
                                        {(activeTab == 'Draft') && <DraftPage
                                            isTrash={true}
                                            mediaKeyWord={(activeTab == 'Draft') ? searchKeyword : ''}
                                            searchType={(activeTab == 'Draft') && searchType}
                                            displayButtons={displayButtons}
                                            action={(activeTab == 'Draft') && action}
                                            updateAction={updateAction}
                                            checkAll={checkAll}
                                            chackAllAction={chackAllAction}
                                            checkType={emptyAll}

                                        />}
                                    </Tab>
                                    <Tab eventKey="Project" title="Project" className="pxl-trash-project-data">
                                        {(activeTab == 'Project') &&
                                            <Projects
                                                isTrash={true}
                                                mediaKeyWord={(activeTab == 'Project') ? searchKeyword : ''}
                                                searchType={(activeTab == 'Project') && searchType}
                                                displayButtons={displayButtons}
                                                action={(activeTab == 'Project') && action}
                                                updateAction={updateAction}
                                                checkAll={checkAll}
                                                chackAllAction={chackAllAction}
                                                checkType={emptyAll}


                                            />}
                                    </Tab>
                                    <Tab eventKey="Design" title="Design" className="pxl-trash-design-data">
                                        {(activeTab == 'Design') && <MyDesigns
                                            isTrash={true}
                                            mediaKeyWord={(activeTab == 'Design') ? searchKeyword : ''}
                                            searchType={(activeTab == 'Design') && searchType}
                                            displayButtons={displayButtons}
                                            action={(activeTab == 'Design') && action}
                                            updateAction={updateAction}
                                            checkAll={checkAll}
                                            chackAllAction={chackAllAction}
                                            checkType={emptyAll}


                                            
                                        />}
                                    </Tab>
                                    <Tab eventKey="Post" title="Post" className="pxl-trash-schedulePost-data">
                                        {(activeTab == 'Post') && <SchedulePostTrash
                                            isTrash={true}
                                            mediaKeyWord={(activeTab == 'Post') ? searchKeyword : ''}
                                            searchType={(activeTab == 'Post') && searchType}
                                            displayButtons={displayButtons}
                                            action={(activeTab == 'Post') && action}
                                            updateAction={updateAction}
                                            checkAll={checkAll}
                                            chackAllAction={chackAllAction}
                                            checkType={emptyAll}


                                        />}
                                    </Tab>
                                    {/* <Tab eventKey="builder" title="Builder" className="pxl-trash-builder-data">
                                        {(activeTab == 'builder') && <Project
                                            isTrash={true}
                                            mediaKeyWord={(activeTab == 'builder') ? searchKeyword : ''}
                                            searchType={(activeTab == 'builder') && searchType}
                                            displayButtons={displayButtons}
                                            action={(activeTab == 'builder') && action}
                                            updateAction={updateAction}
                                            checkAll={checkAll}
                                            chackAllAction={chackAllAction}
                                            // checkType={emptyAll}

                                        />}
                                    </Tab> */}
                                    <Tab eventKey="tickets" title="Tickets" className="pxl-trash-ticket-data">
                                        {(activeTab == 'tickets') && <TrashTickets
                                            isTrash={true}
                                            mediaKeyWord={(activeTab == 'tickets') ? searchKeyword : ''}
                                            searchType={(activeTab == 'tickets') && searchType}
                                            displayButtons={displayButtons}
                                            action={(activeTab == 'tickets') && action}
                                            updateAction={updateAction}
                                            checkAll={checkAll}
                                            chackAllAction={chackAllAction}

                                        />}
                                    </Tab>
     
                                </Tabs>
                            </div>

                        </div>
                    </div>
                </div>
                </div>
            </div>




        </>
    )
}

export default Trash