import {  Routes, Route, useLocation } from "react-router-dom";

import Dashboard from "./index";
import Projects from "./projects";
import MyDesigns from "./myDesigns";
import NewDesign from "./new-design";
import Templates from "./templates";
import appConfig from "./../../config/appConfig";
import './../scheduler/css/scheduler.css';
import PrivateRoute from "../PrivateRoute";

const ImageRouter = () => {
    
    if(useLocation().pathname.includes('/images')) {
        require('./../scheduler/css/scheduler.css');
    }
 
    return (
        <>
           
            <Routes>
                <Route path="/" element={<PrivateRoute allowedRoles={['user','admin',"client"]}><Dashboard pageTitle={`${appConfig.appName} | Image Dashboard`} /></PrivateRoute>} />                
                <Route path="/my-projects" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}><Projects pageTitle={`${appConfig.appName} | My Designs`} /></PrivateRoute>} />
                <Route path="/templates" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}><Templates pageTitle={`${appConfig.appName} | Templates`} /></PrivateRoute>} />
                <Route path="/my-designs/projects" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}><MyDesigns pageTitle={`${appConfig.appName} | My Designs`} /></PrivateRoute>} />
                <Route path="/my-designs/projects/:id" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}><MyDesigns pageTitle={`${appConfig.appName} | My Designs`} /></PrivateRoute>} />
                <Route path="/editor/:id" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}  allowedscope={'images'} checkscope={'write'}><NewDesign pageTitle={`${appConfig.appName} | Image Editor`} /></PrivateRoute>} />
             
            </Routes>
        </>
    )
};

export default ImageRouter;