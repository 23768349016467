import { Link,useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect, useRef, useState } from "react";
import { DownloadImagePopUp, NewDesignPopUp, PreviewImages } from "../scheduler/components/SchedulePopUp";
import { NoDataFound, assetsUrlPath, dateFormatter, hitApi, setMyState, skeletonLoader, useOutsideClick, downloadImage, commonToast } from "../../components/commonAction";
import svg from "../../components/svg";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import InfiniteScroll from 'react-infinite-scroll-component';
import ConfirmationPopup from "../../components/common/ConfirmationPopup";
import '../images/css/scheduler.css';
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useStore } from "../../zustand/store";
// import SchedulePopup, { PreviewPost} from "./components/SchedulePopUp";




const MyDesigns = ({ pageTitle, isTrash = false, searchType, mediaKeyWord, displayButtons, action, updateAction, checkAll = false, chackAllAction = false, checkType = false }) => {

    const [state, setState] = useState({
        limit: 15,
        page: 1,
        sort: 'createdAt=-1',
        keyword: '',
        isLoading: false,
        totalCount: 0,
        loadData: false,
        removeMe: [],
        restoreMe: [],
        isLoad: false,
        loadMoreActive: false,
        totalRecord: '',
        newDesignPopUp: false,
        isDataload: false,
        selectedDesign: [],
        projectFolderList: [],
        selectedFolder: '',
        myDesignList: [],
        moveToPopUp: false,
        folderName: 'Uncategorized',
        porjectdata: '',
        showaction: false,
        moveFolder: '',
        btnLoader: '',
        selectedAction: '',
        localCheckAll: '',
        dwnldpopup: false,
        checkLocalType: false,
        fileWidth: '',
        fileHeight: '',
        downloadId: '',
        imgPath: '',
        down_name:'',
    });
    // const [showPreview, setShowPreview] = useState(false);

    let store = useStore(state => state);

    let { role, scope } = store.userData;

    const clearSearch = () => {
        setMyState(setState, { ...state, clearKeyword: !state.clearKeyword, keyword: '' });
    }

    const { id } = useParams();
    let appPath = useLocation().pathname.split('/').filter(Boolean)[0];

    let showDesingtab = useLocation().pathname.replace(`/${appPath}/my-designs/projects/`, '');


    useEffect(() => {
        getMyDesign();
    }, [state.isLoad, searchType, state.selectedFolder, state.clearKeyword])

    useEffect(() => {
        if (id) {
            if (id == 'recent') {
                window.scrollTo(0, 0)
                // setMyState(setState, { selectedFolder: '' });
                // setMyState(setState, { folderName: 'Recent Designs' });
            }
            // setMyState(setState, { selectedFolder: id });
        }
    }, [id])

    useEffect(() => {
        getFolderList();
    }, [])

    useEffect(() => {
        if (isTrash) {
            if (checkAll) {
                setMyState(setState, { selectedDesign: state.myDesignList.map(val => val._id) });
                displayButtons(state.myDesignList.length)
            } else {
                if (checkType) {

                    setMyState(setState, { selectedDesign: [] });
                    displayButtons(0)
                }
            }
        }
    }, [checkAll])
    useEffect(() => {
        if (isTrash) {
            if (state.myDesignList?.length > 0) {
                if (state.myDesignList?.length == state.selectedDesign?.length) {
                    chackAllAction(true)
                } else {
                    chackAllAction(false)
                }
            }
        }
    }, [state.selectedDesign])

    useEffect(() => {

        if (!state.checkLocalType) {
            if (state.myDesignList?.length > 0) {
            
                if (state.myDesignList?.length  == state.selectedDesign?.length) {
                    setMyState(setState, { localCheckAll: true })
                } else {
                    setMyState(setState, { localCheckAll: false })
                }
            }
        }
    }, [state.selectedDesign])


    useEffect(() => {
        if (action == 'Delete') {
            setMyState(setState, { removeMe: state.selectedDesign });
        } else if (action == 'Restore') {
            setMyState(setState, { restoreMe: state.selectedDesign })
        }

    }, [action])

    const getFolderList = () => {
        hitApi({
            url: 'image/folder/get-folders',
            method: "GET",
            data: {
                limit: "all"
            }
        }, (resp, err = null) => {
            if (resp.status) {
                setMyState(setState, { projectFolderList: resp.data.records });

                if (resp.data.records) {

                    let data = resp.data.records.map(val => ({ label: val.title, value: val._id ?? 'uncategorized' }));
                    data.unshift({ label: "Select Project", value: "" });
                    setMyState(setState, { projectFolderList: data });

                    let folder = (resp.data.records).find((item) => item._id == id)
                    if (folder) {
                        setMyState(setState, { folderName: folder.title, porjectdata: folder })
                    }
                }
            }
        });
    }

    const getMyDesign = (type = null) => {
        let { limit, page, sort, keyword } = state,
        
            cPage = (searchType==2 || keyword) ? 1 : type == 'loadMore' ? page + 1 : page;
           
        let reqData = { limit, page: cPage, sort, keyword: searchType ? mediaKeyWord : keyword, };

        if (isTrash) {
            reqData.isTrash = true;
        }
        if (id) {
            if (id == 'recent') {
                // setMyState(setState, { selectedFolder: '' });
                setMyState(setState, { folderName: 'Recent Designs' });
            }
            // setMyState(setState, { selectedFolder: id });
        }
        // if (state.selectedFolder) {
        reqData.folderId = state.selectedFolder ? state.selectedFolder : id == 'recent' ? '' : id
        // }

        setMyState(setState, { isLoading: true, loadMoreActive: type == 'loadMore' ? true : false, page: cPage });
        hitApi({
            url: 'image/my-designs/get-design',
            method: "GET",
            data: reqData
        }, (resp, err = null) => {
            setMyState(setState, { isLoading: false });

            if (resp.status) {
                let newList = type == 'loadMore' ? [...state.myDesignList, ...resp.data.records] : resp.data.records;

                setMyState(setState, { myDesignList: newList, totalRecord: resp.data.totalRecords });
            }
        });
    }


    const handleSelectDesign = (val, design) => {

        if (val) {
            setMyState(setState, { selectedDesign: [...state.selectedDesign, design._id],checkLocalType: false  })
            isTrash && displayButtons([...state.selectedDesign, design].length)
        } else {
            const arrCopy = Array.from(state.selectedDesign);
            const objWithIdIndex = arrCopy.findIndex((obj) => obj === design._id);

            arrCopy.splice(objWithIdIndex, 1);
            setMyState(setState, { selectedDesign: arrCopy,checkLocalType: false })
            isTrash && displayButtons(arrCopy.length);
        }
    }

    const moveTohandle = (e) => {
       
        e.preventDefault();
        if (!state.moveFolder) {
            commonToast('error', `Project is required.`);
            return false
        }
        let data = {
            target: state.selectedDesign,

            // folderId: state.moveFolder
        }
        // if(state.moveFolder!='uncategorized'){

        //     data["folderId"]=state.moveFolder
        // }else
        // {
        // }
        data["folderId"] = state.moveFolder == 'uncategorized' ? null : state.moveFolder;
        setMyState(setState, { btnLoader: 'move' })
        hitApi(
            {
                method: "PATCH",
                url: 'image/my-designs/update-design',
                data: data,
            },
            (resp) => {
                setMyState(setState, { btnLoader: '' })
                if (resp.status) {
                    setMyState(setState, { moveToPopUp: false, selectedDesign: [], moveFolder: '', btnLoader: '', isLoad: !state.isLoad, localCheckAll: false, selectedAction: '' })
                }

            })
    }


    const menuRef = useRef(null);

    useOutsideClick(menuRef, () => setallsubmenu(false));

    // sub menu delet edit setting
    const [allsubmenu, setallsubmenu] = useState(false);
    const toggleSublist = (id) => {
        setallsubmenu(prevState => ({
            //   ...prevState,
            [id]: !prevState[id]
        }));
    };
    // sub menu delet edit setting end

    const closeAllMenu = () => {
        setallsubmenu(false);
        setMyState(setState, { showaction: false })
    }

    const handleSelectedAction = (val) => {
        setMyState(setState, { selectedAction: val });
        closeAllMenu();
        if (val == 'delete') {
            setMyState(setState, { removeMe: state.selectedDesign })
        } else if (val == 'move') {
            setMyState(setState, { moveToPopUp: true })
        }

    }
    const options = [
        { value: '', label: 'Select Action' },
    ];

    const containsWrite = (role === "teamMember") ? scope.images.images.includes("write") : (role === 'client') ? false : true;
    const containsImagesDelete = (role === "teamMember") ? scope?.images?.images?.includes("delete") : (role === 'client') ? false : true;

    if (containsWrite || containsImagesDelete) {
        if (containsWrite) {
            options.push({ value: 'move', label: 'Move to' },);
        }
        if (containsImagesDelete) {
            options.push({ value: 'delete', label: 'Delete' },);
        }
    }
    // select2
    const options1 = [
        { value: 'Templates', label: 'Templates' },
        { value: 'trending', label: 'Trending' },
        { value: 'featured', label: 'Featured' }
    ]
        const noProject = ()=>{
            return 'No project found '
        }



    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            {!isTrash && <div className="sch-banner-wrapper">
                <div className="sch-container-fluid">
                    <div className="sch-banner-box pxl-dashboardBanner-box">
                        <div className="sch-banner-content ">
                            <h2>Discover Your Design Repository</h2>
                            <p>Explore a Wealth of Design Projects and Initiate New Endeavors</p>
                            <div className="sch-search-box sch-template-searchBox">
                                <div className="sch-search-row">
                                    <div className="sch-banner-select-search-box">
                                        {/* <div className="cun-headreSelect"> */}
                                        {/* <Select options={options1} /> */}


                                        {/* <select value={state.selectedFolder ? state.selectedFolder : id == 'recent' ? "" : id}
                                        onChange={(e) => {
                                            setMyState(setState, {
                                                selectedFolder: e.target.value, page: 1
                                            });
                                            let folder = (state.projectFolderList).find((item) => item._id == e.target.value)

                                            if (folder) {
                                                setMyState(setState, { folderName: folder.title })
                                            }
                                            else if (e.target.value == 'recent') {
                                                setMyState(setState, { folderName: 'Recent Designs' })
                                            }
                                            else {
                                                setMyState(setState, { folderName: 'Uncategorized' })
                                                setMyState(setState, {
                                                    selectedFolder: 'uncategorized'
                                                });
                                            }
                                        }}
                                    >
                                        <option value='recent'>All Designs</option>
                                        {
                                            state.projectFolderList.map(project => <option key={project._id} value={project._id}>{(project._id == null) ? 'uncategorized' : project.title}</option>)
                                        }
                                    </select> */}
                                        {/* </div> */}
                                        <div className="sch-banner-select-search-input">
                                            <input type="text" value={state.keyword} placeholder="Search Design" onChange={(e) => setMyState(setState, {
                                                keyword: e.target.value,
                                                loadMoreActive: false
                                            })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                                                        getMyDesign();
                                                    }
                                                }} />
                                        </div>
                                    </div>



                                    <button type="button" className="sch-search-btn">
                                        {!state.keyword ? <span> <img src="/assets/images/scheduler/search.svg" /> </span> : <span className="cun-inputCross" onClick={clearSearch}>{svg.app.crossSvg}</span>}

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {/* Main Wrapper  */}
            <div className="pxl-mydesign-mainWrapper-box">
                <main className="sch-main-wrapper">
                    {/* banner */}

                    {/* Trending Templates Section */}
                    <div className="sch-uncategorized-mainwrapper-box pxl-mydesign-createProjectBox">
                        <div className="sch-container-fluid">
                            {!isTrash && <div className="cun-uncategorizedWrapper pxl-uncategorized-singleWrap">



                                <div className="pxl-uncategorize-heading">
                                    <h2> <Link to={(showDesingtab == 'recent') ? `/images` : `/images/my-projects`}><span><img src="/assets/images/backSvg.svg" alt="" /></span> {` ${state.folderName} (${state.totalRecord ? state.totalRecord : 0})`}</Link></h2>
                                </div>
                                {state.myDesignList.length != 0 && <div className="sch-actionWrap">

                                    <div className="cun-headerSelect-wrap">
                                        <div className="cun-AllChecknoxSelect">
                                            <div className="sch-custom-check-box ">
                                                <div className="form-group">
                                                    <input type="checkbox" id="select-all" disabled={(containsWrite || containsImagesDelete) ? false : true}
                                                        checked={state.localCheckAll}
                                                        onChange={(e) => e.target.checked ? setMyState(setState, { selectedDesign: state.myDesignList.map(val => val._id), localCheckAll: e.target.checked,checkLocalType: true }) : setMyState(setState, { selectedDesign: [], localCheckAll: e.target.checked,checkLocalType: true })}
                                                    />
                                                    <label htmlFor="select-all">Select All</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cun-headreSelect">
                                            <Select options={options}
                                                closeMenuOnSelect={true}
                                                isSearchable={false}
                                                isDisabled={state.selectedDesign.length == 0 ? true : (containsWrite || containsImagesDelete) ? false : true}
                                                value={options.filter(option =>
                                                    option.value == state.selectedAction)}
                                                onChange={(e) => handleSelectedAction(e.value)}
                                            />
                                        </div>

                                    </div>

                                    {/* <button ref={menuRef} className={`sch-btn ${state.selectedDesign.length == 0 && 'pxl-button-disable'}`} disabled={state.selectedDesign.length == 0} onClick={() => setMyState(setState, { showaction: !state.showaction })}><span className='sch-btn-text'>Actions</span></button>
                                <div className={`sch-menuBox ${state.showaction ? 'menu_show' : ''}`}>

                                    <a onClick={() => { closeAllMenu(); setMyState(setState, { removeMe: state.selectedDesign }) }} >
                                        <span>{svg.app.deleteIcon1}</span>Delete

                                    </a>

                                    <a onClick={() => { closeAllMenu(); setMyState(setState, { moveToPopUp: true }) }}>
                                        <span> {svg.app.moveTo}</span>Move to
                                    </a>
                                </div> */}
                                </div>}

                            </div>}

                            <div className="sch-workspace-main-wrapper pxl-workspace-repear-boxSingle">

                                {/* <div className="sch-section-mansory"> */}
                                <InfiniteScroll
                                    dataLength={state.myDesignList?.length}
                                    // next={() => getMyDesign('loadMore')}
                                    style={{ display: 'flex', flexDirection: 'column-reverse', overflow: 'visible' }}
                                    hasMore={state.totalRecord == state.myDesignList?.length ? false : true}
                                    scrollableTarget="scrollableDiv"
                                    scrollThreshold={0.8}
                                >
                                    <div className="sch-workspace-row sch-workspace-row-single">
                                        {/* <div className="sch-loader-flex">
                                    {!state.loadMoreActive && true && [...Array(10)].map((count) => <span key={count}>{skeletonLoader('asset', 1, '100%', 320)}</span>)}
                                </div> */}




                                        {/* <ResponsiveMasonry
                                    columnsCountBreakPoints={{ 350: 1, 394: 2, 750: 3, 900: 5, 1424: 5 }}
                                >
                                    <Masonry gutter={"20px"}> */}
                                        {!isTrash && state.myDesignList.length != 0 && containsWrite  && <div className="sch-workspace-box-fix">
                                            <a href="#" onClick={() => { setMyState(setState, { newDesignPopUp: true }); setallsubmenu(false) }}>
                                                <img src="/assets/images/scheduler/plus-svg.svg" />
                                                <h2 className="sch-plus-icon-button">Create New Design</h2>
                                            </a>
                                        </div>}
                                        {!state.loadMoreActive && state.isLoading && [...Array(10)].map((count, index) => <span key={index}>{skeletonLoader('asset', 1, '100%', 320, index)}</span>)}


                                        {
                                            state.myDesignList.length > 0 && state.myDesignList.map((item, i) => (
                                                <div className={`sch-workspace-repear-single   ${state.selectedDesign.some((obj) => obj === item._id) && 'active_post'}`} key={i}>

                                                    <div className="sch-imagebox">
                                                      
                                                        {item.isProcessing && <div className='pxl-processing-section'><h2>processing...</h2> </div>}
                                                        <img src={item?.files?.original?.thumb ? assetsUrlPath(item.files.original.thumb) : '/assets/images/default-draft-img.jpg'} />
                                                    </div>

                                                    {(containsWrite || containsImagesDelete) && <div className="sch-custom-check-box cun-select sch_hide_checkbox" style={{ opacity: state.selectedDesign.length > 0 && 1 }}>
                                                        <div className="form-group">

                                                            <input type="checkbox" id={i}
                                                                checked={state.selectedDesign.some((obj) => obj === item._id)}
                                                                onChange={(e) => handleSelectDesign(e.target.checked, item)} />
                                                            <label htmlFor={i}></label>
                                                        </div>
                                                    </div>}
                                                    <div className="sch-draft-content">
                                                        <div className="sch-workspace-info pxlsch-workspace-headingText">
                                                            <h4>{item.title}</h4>
                                                            {/* <h2>{state.projectFolderList.map((data)=>{return((item?.folderId==data?.value)?data.label:"")})}</h2> */}
                                                            <h2>{state?.projectFolderList?.map((data)=>{return((item?.folderId==data?.value)?data?.label:(item?.folderId==undefined&&data?.value=='uncategorized')?"Uncategorized":"")})}</h2>

                                                            <h3><img src="/assets/images/scheduler/calendar-svg.svg"></img>{dateFormatter(item.createdAt)}
                                                                {/* <span>
                                                        <img src="/assets/images/scheduler/dot.png"></img>    
                                                    </span> */}
                                                            </h3>

                                                        </div>
                                                        <div className='sch-draft-content'>
                                                            {
                                                                isTrash ?
                                                                    containsImagesDelete && <>

                                                                        <div className={`sch-menuSection  ${allsubmenu[item._id] ? 'sch-menuOpen' : ''}`} >
                                                                            <div className='three_dot_bg' onClick={() => toggleSublist(item._id)} ref={menuRef}><img src="/assets/images/three-dot.svg" alt="" /></div>
                                                                            <div className={`sch-submenubox`}>
                                                                                <ul>
                                                                                    {containsImagesDelete && <li>
                                                                                        <a onClick={() => { closeAllMenu(); setMyState(setState, { restoreMe: [item._id] }) }} >
                                                                                            <span> <img src="/assets/images/restore-svg.svg" alt="" /></span>Restore

                                                                                        </a>
                                                                                    </li>}
                                                                                    {containsImagesDelete && <li>
                                                                                        <a onClick={() => { closeAllMenu(); setMyState(setState, { removeMe: [item._id] }) }}>
                                                                                            <span> <img src="/assets/images/delet-svg.svg" alt="" /></span>Delete
                                                                                        </a>
                                                                                    </li>}
                                                                                </ul>
                                                                            </div>
                                                                        </div>


                                                                    </>

                                                                    :
                                                                    (containsWrite || containsImagesDelete || (item?.files?.original?.path)) && <>

                                                                        <div className={`sch-menuSection   ${allsubmenu[item._id] ? 'sch-menuOpen' : ''}`}>
                                                                            <div className='three_dot_bg' onClick={() => toggleSublist(item._id)} ref={menuRef}>
                                                                                <img src="/assets/images/three-dot.svg" alt="" />
                                                                            </div>
                                                                            <div className={`sch-submenubox sch-submenubox-width140`}>
                                                                                <ul>
                                                                                    {/* {containsWrite && <li>
                                                                                        <a href="#" data-bs-toggle="modal" onClick={() => setShowPreview(item)} data-bs-target="#editAssets" className="pxl-edit-icon">
                                                                                            <span><img src="/assets/images/preview-svg.svg" alt="" /></span>Preview
                                                                                        </a>
                                                                                    </li>} */}
                                                                                    {containsWrite && <li>
                                                                                        <Link to={`/images/editor/${item._id}`}>
                                                                                            <span> <img src="/assets/images/edit-svg.svg" alt="" /></span>Edit
                                                                                        </Link>
                                                                                    </li>}
                                                                                    
                                                                                    {(item?.files?.original?.path) && !item.isProcessing ?
                                                                                        <li>
                                                                                            <a onClick={() => { closeAllMenu(); setMyState(setState, { dwnldpopup: true, fileWidth: item?.files?.original?.width, fileHeight: item?.files?.original?.height, downloadId: item._id,down_name:item.title, imgPath: item?.files?.original?.path }) }}>
                                                                                                <span><img src="/assets/images/downloadSvg.svg" alt=""/> </span>Download
                                                                                            </a>
                                                                                        </li>
                                                                                        : ''}
                                                                                        {containsImagesDelete && <li>
                                                                                        <a onClick={() => { closeAllMenu(); setMyState(setState, { removeMe: [item._id] }) }}>
                                                                                            <span> <img src="/assets/images/delet-svg.svg" alt="" /></span>Delete
                                                                                        </a>
                                                                                    </li>}
                                                                                </ul>
                                                                            </div>
                                                                        </div>


                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        {state.loadMoreActive && state.isLoading ? [...Array(10)].map((i) => <span key={i}  >{skeletonLoader('asset', 1, '100%', 320, i)}</span>) : ''}
                                        {/* </Masonry>
                                </ResponsiveMasonry> */}


                                    </div>
                                    {/* <div className="sch-loader-flex">
                                    {state.loadMoreActive && true ? [...Array(10)].map((i) => <span key={i} className="sch-loader-flex" >{skeletonLoader('asset', 1, 336, 320)}</span>) : ''}
                                </div> */}
                                    {/* </div> */}
                                </InfiniteScroll>
                                {!state.isLoading && state.myDesignList.length == 0 && <NoDataFound title={'No design found.'} type={isTrash || (role === "teamMember") ? '' : !containsWrite ? '' : 'design'} btntext={'Create Design'} showbtn={state.keyword} onCreate={() => setMyState(setState, { newDesignPopUp: true })} bgRemove={true} />}

                                {
                                    state.myDesignList.length < state.totalRecord &&
                                    <div className="loadmorebtn">
                                        <a
                                            className={`sch-loadmoreBtn ${state.myDesignList.length >= state.totalRecord ? 'pxl-button-disable' : ''}`}
                                            onClick={() => {
                                                getMyDesign('loadMore');
                                            }}
                                        >{state.isLoading ? <span>Loading...</span> : <span>Load More</span>}</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            <NewDesignPopUp
                shownPopup={state.newDesignPopUp}
                closePopup={() => setMyState(setState, { newDesignPopUp: false })}
                insideFolder={true}
                currentFolder={id}
                hideporject={[{ value: state.porjectdata?._id || "", label: state.porjectdata?.title || "Select project" }]}
            />
          
            {/* <PreviewImages
                shownPopup={state.previewPopUp}
                closePopup={() => {
                    setShowPreview()
                }}
                asset={showPreview}
            // data={{ title: state.previewData.title, caption: state.previewData.title, mediaUrl: state.previewData.mediaUrl, accounts: state.previewData.postOn, successData: state.previewData?.successData }}
            /> */}

            <Modal
                show={state.moveToPopUp} onHide={() => setMyState(setState, { moveToPopUp: false, selectedDesign: [], localCheckAll: false, selectedAction: '' })} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Move to Project
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={moveTohandle}>
                        <div className="pxl_statusFilter pxl_selectOption form-group">
                            <label>Select project <span className="pxl-star-red">*</span></label>
                            <div className="cun-headreSelect pxl-moveTo-project-select">
                               
                                <Select options={state.projectFolderList.filter(option =>
                                    option.value != showDesingtab)}
                                    closeMenuOnSelect={true}
                                    noOptionsMessage={noProject}
                                    value={state.projectFolderList.filter(option =>
                                        option.value == state.moveFolder)}
                                    onChange={(e) => setMyState(setState, {
                                        moveFolder: (e.value == null) ? 'uncategorized' : e.value
                                    })}
                                />

                            </div>
                            {/* <select className="form-control" value={state.assetStatus}
                                onChange={(e) => {

                                    setMyState(setState, {
                                        moveFolder: e.target.value
                                    });
                                }}
                            >
                                <option value=''>Select project</option>
                                {
                                    state.projectFolderList.map((project,index) => <option key={index} value={project._id}>{project.title}</option>)
                                }
                            </select> */}
                        </div>
                        <button type="submit" disabled={state.btnLoader == 'move'} className="pxl_btn">{state.btnLoader == 'move' ? 'Processing...' : 'Move'}</button>
                    </form>
                </Modal.Body>

            </Modal>

            <ConfirmationPopup
                shownPopup={state.removeMe.length}
                closePopup={() => {
                    setMyState(setState, {
                        removeMe: [],
                        selectedDesign: [],
                        localCheckAll: false,
                        selectedAction: ''
                    })
                    isTrash && updateAction('');
                    isTrash && displayButtons(0);
                }}
                type={"design"}
                title={isTrash ? `Are you sure you want to delete ${state.selectedDesign.length} ${state.selectedDesign.length > 1 ? 'designs' : 'design'}.` : `Do you want to move ${state.removeMe.length} ${state.selectedDesign.length > 1 ? ' designs' : ' design'} to trash?`}
                subTitle={isTrash ? '' : 'You can restore it later from Dashboard > Trash.'}
                yesBtn={isTrash ? "Yes, Delete" : "Yes, Move"}
                btnLoader={state.isDataload}
                removeAction={() => {
                    let data = {}

                    const idArray = state.removeMe;

                    if (isTrash) {
                        data['target'] = idArray.join(',');
                    } else {
                        data['target'] = idArray
                    }

                    setMyState(setState, { isDataload: true })


                    hitApi({
                        url: isTrash ? "image/my-designs/remove-design" : 'image/my-designs/move-to-trash',
                        method: isTrash ? "DELETE" : "PATCH",
                        data: data,
                    }, (resp, err = null) => {
                        if (resp.status) {
                            setMyState(setState, { isLoad: !state.isLoad, page: 1 });
                        }
                        setMyState(setState, {
                            removeMe: [],
                            selectedDesign: [],
                            isDataload: false
                        })
                        isTrash && updateAction('');
                        isTrash && displayButtons(0);
                    })
                }}
            />

            {/* Restore PopUp */}
            <ConfirmationPopup
                shownPopup={state.restoreMe.length}
                closePopup={() => {
                    setMyState(setState, {
                        restoreMe: [],
                        selectedDesign: [],
                        localCheckAll: false,
                        selectedAction: ''
                    })
                    isTrash && updateAction('');
                    isTrash && displayButtons(0);
                }}
                type={"design"}
                title={`Are you sure you want to restore ${state.selectedDesign.length} ${state.restoreMe.length > 1 ? ' designs' : ' design'} ?`}
                subTitle={'This item will be restore immediately.'}
                yesBtn={"Yes, Restore"}
                btnLoader={state.isDataload}

                removeAction={() => {
                    setMyState(setState, { isDataload: true })

                    let data = { 'target': state.restoreMe }

                    hitApi({
                        url: `image/my-designs/recover`,
                        method: "PATCH",
                        data: data,
                    }, (resp, err = null) => {
                        setMyState(setState, {
                            restoreMe: [],
                            selectedDesign: [],
                            isDataload: false
                        })
                        isTrash && updateAction('');
                        isTrash && displayButtons(0);
                        if (resp.status) {
                            setMyState(setState, { isLoad: !state.isLoad, page: 1 });
                        }
                    })
                }}
            />

            {/* Download popup  */}
            <DownloadImagePopUp
                shownPopup={state.dwnldpopup}
                closePopup={() => setMyState(setState, { dwnldpopup: false })}
                width={state.fileWidth}
                height={state.fileHeight}
                target={state.downloadId}
                path={state.imgPath}
                mTitle={state.down_name}
            />



        </>
    )
};

export default MyDesigns;