import { useState, useEffect } from 'react'
import { commonToast, hitApi } from '../../../../components/commonAction';
import ConfirmationPopup from '../../../../components/common/ConfirmationPopup';
import svg from '../svg';

function CallForAction({ p_id, callActions }) {

    const [heading, setHeading] = useState('');
    const [subHeading, setSubHeading] = useState('');
    const [btnTitle, setBtnTitle] = useState('');

    const [callID, setCallID] = useState('');
    const [showPopUp, setShowPopup] = useState(false);
    const [IsData, setIsData] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (callActions) {
            setHeading(callActions.heading)
            setSubHeading(callActions.sub_heading)
            setBtnTitle(callActions.button_title)
            setCallID(callActions._id);
            setIsData(true)
            setIsEdit(false)
        } else {
            setIsEdit(true)
        }
    }, [callActions])


    const addContentFormSubmit = (e) => {
        e.preventDefault();

        if (!heading || !subHeading || !btnTitle) {
            commonToast('error', `Field can not be empty!`);
            return false;
        } else {
            let content = {
                footerpage_id: callID,
                heading: heading,
                sub_heading: subHeading,
                button_title: btnTitle,
                project_id: p_id,
            }
            if (!callID) delete content['footerpage_id'];
            hitApi({
                url: 'pixa-support/api/managefooterPage',
                method: "POST",
                data: content,
                loading: false,
                alert: true,
            }, (resp, err = null) => {
                if (resp.status === 1) {
                    // getCallAction();
                    setHeading(resp.data.heading)
                    setSubHeading(resp.data.sub_heading)
                    setBtnTitle(resp.data.button_title)
                    setCallID(resp.data._id);
                    setIsData(true)
                    setIsEdit(false)
                }
            });

        }
    }


    return (<>
        <form onSubmit={addContentFormSubmit}>

            <h4>Call for Action</h4>
            <div className='mail_back_color_box'>

                {IsData ? <>
                    <div className="land_Action_edit" onClick={() => setIsEdit(true)}>
                        {svg.edit_icon}
                    </div>
                    <div className="land_action_del" onClick={() => setShowPopup(true)} >
                        {svg.delete_red}
                    </div>
                </> : null}

                <div className="pxl_input_feilds mb-3">
                    <label>Heading</label>
                    <input disabled={isEdit ? null : true} type="text" name="" placeholder="Enter Heading" value={heading} onChange={(e => setHeading(e.target.value))} />
                </div>
                <div className="pxl_input_feilds mb-3">
                    <label>Sub Heading</label>
                    <textarea  disabled={isEdit ? null : true}type="text" name="" placeholder="Enter Sub Heading" value={subHeading} onChange={(e) => setSubHeading(e.target.value)} />
                </div>
                <div className="pxl_input_feilds mb-3">
                    <label>Button Title</label>
                    <input disabled={isEdit ? null : true} type="text" name="" placeholder="Enter Button Title" value={btnTitle} onChange={(e) => setBtnTitle(e.target.value)} />
                </div>

                {
                    IsData ? <button type='submit' disabled={isEdit ? null : true} style={{ cursor: isEdit ? 'pointer' : 'not-allowed', gap: '6px' }} className="sch-btn" >Update</button>
                        :
                        <button type='submit' className="pxlCommon-btn" style={{ gap: '6px' }}>
                            
                            Add
                        </button>
                }
            </div>
        </form>

        <ConfirmationPopup
            shownPopup={showPopUp}
            closePopup={() => {
                setShowPopup(false);
                // setFeatureID('')
            }}
            type={"feature"}
            removeAction={() => {
                hitApi({
                    url: 'pixa-support/api/deletePage',
                    method: "POST",
                    data: {
                        footer_id: callID,
                    },
                    loading: false,
                    alert: true,
                }, (resp, err = null) => {
                    if (resp.status === 1) {
                        // getCallAction();
                        setShowPopup(false);
                        setHeading('')
                        setSubHeading('')
                        setBtnTitle('')
                        setCallID('');
                        setIsData(false)
                    }
                });
            }}
        />
    </>
    )
}

export default CallForAction