import { useEffect, useRef, useState } from "react";
import { hitApi, setMyState, assetsUrlPath, skeletonLoader, NoDataFound, useOutsideClick, downloadImage, commonToast } from "../../commonAction";
import ConfirmationPopup from '../ConfirmationPopup';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, Link } from 'react-router-dom';
import svg from "../../svg";
import AudioPlayer from "../../../pages/scheduler/components/AudioPlayer";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import('./mylibrarycssstyle.css');


const options = [
    { value: '', label: 'Select Action' },
    { value: 'delete', label: 'Delete' },
]


const Mylibrary = ({ title = '', mediaType = null, dataPerPage, onClickAct = () => { }, baseClass = '', mediaKeyWord, searchType, isTrash = false, displayButtons, action, updateAction, checkAll = false, chackAllAction=false,checkType = false }) => {
    const [state, setState] = useState({
        keys: '',
        limit: dataPerPage || 10,
        page: 1,
        keyword: '',
        sort: 'createdAt=-1',
        mediaType: '',
        isLoading: false,
        mediaArray: [],
        totalRecord: 0,
        isUploading: false,
        mediaLoading: false,
        selectedPost: [],
        isLoad: false,
        removeMe: [],
        restoreMe: [],
        playMedia: null,
        playAudio: false,
        selectedAction: '',
        localCheckAll: false,
        isDataload:false,
        loadData:false,
        audioElem: new Audio(),
        btnLoader: false,
        editId: '',
        showModal: false,
        imgtitle: ''
    });
    let appPath = useLocation().pathname.split('/').filter(Boolean)[0];
    let showDesingtab = useLocation().pathname.replace(`/${appPath}/my-designs/`, '');

    const handleClose = () => setMyState(setState, {
        showModal: false,
    });

    const openConfi = () => setMyState(setState, {
        showModal: false,
    });

    const updateTitle = (e) => {
        e.preventDefault();
        if (!state.imgtitle.trim()) {
            commonToast('error', `Title is required.`);
            return false
        }

        setMyState(setState, { btnLoader: true });
        let data = {
            target: state.editId,
            title: state.imgtitle
        }
        hitApi({
            url: 'user-assets/update-assets',
            method: "PATCH",
            data: data
        }, (resp, err = null) => {
            setMyState(setState, { btnLoader: false });
            if (resp.status) {
                setMyState(setState, {
                    editId: '',
                    imgtitle: '',
                    showModal: false,
                    isLoad: !state.isLoad
                });
            }
        });
    }

    useEffect(() => {
        filterMedia()
    }, [state.sort, state.isLoad, searchType])

    useEffect(() => {
        if (action == 'Delete') {
            setMyState(setState, { removeMe: state.selectedPost })
        } else if (action == 'Restore') {
            setMyState(setState, { restoreMe: state.selectedPost })
        }
    }, [action])

    useEffect(() => {
        if (isTrash) {
            if (checkAll) {
                setMyState(setState, { selectedPost: state.mediaArray });
                displayButtons(state.mediaArray.length);
            } else {
                if(checkType){
                    setMyState(setState, { selectedPost: [] });
                    displayButtons(0);
                }
            }
        }
    }, [checkAll])

    useEffect(() => {
        if (isTrash) {
            if (state.mediaArray?.length > 0) {
                if (state.mediaArray?.length == state.selectedPost?.length) {
                    chackAllAction(true)
                } else {
                    chackAllAction(false)
                }
            }
        }
    }, [state.selectedPost])

    useEffect(() => {

        if (state.mediaArray?.length > 0) {
            if (state.mediaArray?.length == state.selectedPost?.length) {
                setMyState(setState, { localCheckAll: true })
            } else {
                setMyState(setState, { localCheckAll: false })
            }
        }
    }, [state.selectedPost])


    let uploadMedia = (e) => {
        let file = e?.target?.files[0] || null;

        if (!file) {
            commonToast('error', 'Please choose a file to upload.');
        } else {
            let { type = null } = file;
            if (!process.env.REACT_APP_ALLOW_MEDIA.includes(type)) {
                commonToast('error', 'This file type is not allowed.');
            } else {

                setMyState(setState, { isUploading: true });
                let formData = new FormData();
                formData.append(`files`, e.target.files[0]);
                hitApi({
                    url: 'user-assets/add-assets',
                    method: "POST",
                    data: formData,
                    isFormData: 1
                }, (resp, err = null) => {
                    setMyState(setState, { isUploading: false });
                    if (resp.status) {
                        setMyState(setState, {
                            page: 1,
                            keyword: '',
                            isLoad: !state.isLoad
                        });
                    }
                });
            }

        }
    }
    const filterMedia = (type = null) => {

        if (state.isLoading) {
            return;
        }

        let { limit, page, keyword, sort } = state,
            cPage = type == 'loadMore' ? page + 1 : 1;
        let data = { limit, page: cPage, keyword: searchType ? mediaKeyWord : keyword, sort }
        if (isTrash) {
            data['isTrash'] = true;
        }
        if (mediaType) {
            data.mediaType = mediaType;
        }
        if(showDesingtab.includes("/images/editor/")){
            data.mediaType = 'image';
        }
        setMyState(setState, { isLoading: true, mediaLoading: true });
        hitApi({
            url: 'user-assets/get-assets',
            method: "GET",
            data: data
        }, (resp, err = null) => {
            setMyState(setState, { isLoading: false, mediaLoading: false });
            if (resp.status) {
                let newList = (type == 'loadMore') ? [...state.mediaArray, ...resp.data.records] : resp.data.records;

                setMyState(setState, {
                    mediaArray: newList,
                    totalRecord: resp.data.totalRecords || 0,
                    page: cPage,
                });
            }
        });
    }



    useEffect(() => {
        let t = state.audioElem;
        if (t) {
            if (!state.playAudio) {
                t.pause();
            } else {
                t.src = state.isPlayAudio;
                t.play();
            }
        }
    }, [state.playAudio]);



    const handleSelectPost = (val, post) => {
        if (val) {
            setMyState(setState, { selectedPost: [...state.selectedPost, post] })
            isTrash && displayButtons([...state.selectedPost, post].length)
        } else {
            const arrCopy = Array.from(state.selectedPost);
            const objWithIdIndex = arrCopy.findIndex((obj) => obj._id === post._id);
            arrCopy.splice(objWithIdIndex, 1);
            setMyState(setState, { selectedPost: arrCopy })
            isTrash && displayButtons(arrCopy.length);
        }
    }


    const handleSelectedAction = (val) => {
        setMyState(setState, { selectedAction: val });

        if (val == 'delete') {
            setMyState(setState, { removeMe: state.selectedPost, selectedAction: val });
        }

    }

    // sub menu delet edit setting
    const [allsubmenu, setallsubmenu] = useState(false);
    const toggleSublist = (id) => {
        setallsubmenu(prevState => ({
            //   ...prevState,
            [id]: !prevState[id]
        }));
    };

    const menuRef = useRef();

    useOutsideClick(menuRef, () => setallsubmenu(false));
    const firstInputRef = useRef(null);

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [state.showModal]);
    
    return (
        <>

            <div className={`${baseClass}`}>
                {!isTrash && <div className="sch-library-header pxl-myLibraryHeader">
                    <h2 className="sch-textfont16">{title} {baseClass != 'sortPage' ? `(${state.totalRecord})` : ''}</h2>

                    <div className="pxl_uploadwrapper cun-myLibraryWrap">

                        {baseClass != 'sortPage' && <div className="cun-headerSelect-wrap pxl-mylibrary-selectBox">
                            {state.mediaArray?.length > 0 && <div className="cun-AllChecknoxSelect">
                                <div className="sch-custom-check-box ">
                                    <div className="form-group">
                                        <input ref={firstInputRef}
                                            type="checkbox" id="html"
                                            checked={checkAll || state.localCheckAll}
                                            onChange={(e) => (e.target.checked) ? setMyState(setState, { selectedPost: state.mediaArray, localCheckAll: e.target.checked }) : setMyState(setState, { selectedPost: [], localCheckAll: e.target.checked })}
                                        />
                                        <label htmlFor="html">Select All</label>
                                    </div>
                                </div>
                            </div>}
                            {state.mediaArray?.length > 0 &&
                                <div className="cun-headreSelect">
                                    <Select options={options}
                                        isDisabled={state.selectedPost.length == 0}
                                        value={options.filter(option =>
                                            option.value == state.selectedAction)}
                                        onChange={(e) => handleSelectedAction(e.value)}
                                    />
                                </div>
                            }
                        </div>}


                        <div className="pxl-uploadSearch-myLibrary">
                            <div className={`pxl-custom-file-upload`}>
                                <div className="pxl-custom-file-select">
                                    <div className="pxl-custom-file-select-button" id="fileName">
                                        <img src="/assets/images/newpost/file-upload.png" alt="" />
                                    </div>
                                    <div className="pxl-custom-file-select-name" id="noFile">
                                        {state.isUploading ? 'Uploading...' : <><b>Upload</b> <span className="pxl-upload-file-text">or drop your file here</span></>}
                                    </div>
                                    {!state.isUploading && <input type="file" name="chooseFile" id="chooseFile" accept={process.env.REACT_APP_ALLOW_MEDIA}
                                        onChange={e => {
                                            uploadMedia(e); setMyState(setState, {
                                                selectedPost: [],
                                                selectedAction: ''
                                            })
                                        }}
                                    />}
                                </div>
                            </div>
                            {state.mediaArray?.length >= 0 && <div className="sch-search-section">
                                <div className={`sch-search-row sch-search-bg-color`}>
                                    <input placeholder="Search Media"
                                        type='text'
                                        value={state.keyword}
                                        onChange={e => {
                                            setMyState(setState, { keyword: e.target.value });
                                        }}
                                        onKeyUp={async e => {
                                            e.preventDefault();
                                            if (e.key === 'Enter') {
                                                setMyState(setState, {
                                                    page: 1,
                                                    isLoad: !state.isLoad
                                                });
                                            }
                                        }}
                                    />
                                    <button type="button" className="sch-search-btn">
                                        <img src="/assets/images/scheduler/search.svg" />
                                    </button>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                }

                <div className="sch-post-img-wrapper">

                    {/* <div className="sch-post-images sch-masonry-wrapper"> */}

                    {/* <InfiniteScroll
                        dataLength={state.mediaArray?.length}
                        next={() => filterMedia('loadMore')}
                        style={{ display: 'flex', flexDirection: 'column-reverse', overflow: 'visible' }}
                        hasMore={state.totalRecord == state.mediaArray?.length ? false : true}
                        scrollableTarget="scrollableDiv"
                        scrollThreshold={0.8}
                    > */}

                        <ResponsiveMasonry
                            columnsCountBreakPoints={(baseClass != 'sortPage') ? { 350: 1, 394: 2, 750: 3, 900: 5, 1424: 6 } : { 350: 1, 750: 2, 900: 3 }}
                        >
                            <Masonry gutter={(baseClass != 'sortPage') ? "10px" : '10px'}  >
                                {/* <div>  */}
                              
                                { 
                                state.mediaArray?.length > 0 && state.mediaArray.map((item, index) => {
                                        let thumbObj = {
                                            image: item?.files?.original?.thumb || item?.files?.original?.path,
                                            video: item?.files?.original?.thumb || '/assets/images/newpost/video-newpost.png',
                                            audio: '/assets/images/newpost/audio-newpost.png',
                                        }

                                        return <div className="cun-shortPage-masonry" key={index}

                                            // onMouseEnter={e => {
                                            //     if (item.mediaType != 'image') {
                                            //         setMyState(setState, {
                                            //             playMedia: item._id
                                            //         })
                                            //     }
                                            // }}
                                        >
                                            <div className={`sch-post-image-item ${state.selectedPost.some((obj) => obj._id === item._id) && 'active'}`}>
                                                {/* <div className={state.selectedPost.some((obj) => obj._id === item._id) && 'active'}> */}
                                                <div className="pxl-ImgBox" onClick={e => (item.mediaType != 'audio') && !item.isProcessing && onClickAct({
                                                    id: item._id,
                                                    mediaType: item.mediaType,
                                                    thumb: item?.files?.original?.thumb ? assetsUrlPath(item?.files?.original?.thumb) : '',
                                                    path: assetsUrlPath(item?.files?.original?.path),
                                                    original: item?.files?.original
                                                })}>
                                                    {
                                                        item.isProcessing &&
                                                        <div className='pxl-assets-over pxl-processing'>
                                                            <h2>processing...</h2>
                                                        </div>
                                                    }
                                                    {(baseClass != 'sortPage') && <div className="sch-custom-check-box cun-select sch_hide_checkbox"
                                                        style={{ opacity: state.selectedPost.length > 0 && 1 }}>
                                                        <div className="form-group">

                                                            <input type="checkbox"
                                                                id={item._id}
                                                                checked={state.selectedPost.some((obj) => obj._id === item._id)}
                                                                onChange={(e) => handleSelectPost(e.target.checked, item)}
                                                            />
                                                            <label
                                                                htmlFor={item._id}
                                                            ></label>
                                                        </div>
                                                    </div>}

                                                    {
                                                        state.playMedia == item._id ?
                                                            (
                                                                item.mediaType == 'audio' ?
                                                                    <div className="sch-audioWrpa">
                                                                        <img
                                                                            src={assetsUrlPath(thumbObj[item.mediaType])}
                                                                        />
                                                                        <AudioPlayer media={item} />

                                                                    </div>
                                                                    :

                                                                    <video
                                                                        preload="none"
                                                                        src={assetsUrlPath(item.files.original.path)}
                                                                        autoPlay={true}
                                                                        muted={true}
                                                                        playsInline disablePictureInPicture
                                                                        onMouseLeave={e => {
                                                                            setMyState(setState, {
                                                                                playMedia: null
                                                                            })
                                                                        }}
                                                                        onClick={() => {
                                                                            setMyState(setState, {
                                                                                playMedia: null
                                                                            })
                                                                        }}
                                                                    />

                                                            )
                                                            :
                                                            <>
                                                                <img
                                                                    src={assetsUrlPath(thumbObj[item.mediaType])}
                                                                />
                                                                {(item.mediaType == 'video') && <span className={`sch-audioPlay`}>
                                                                    <a onClick={() => setMyState(setState, {
                                                                        playMedia: item._id
                                                                    })}>
                                                                        {state.playMedia != item._id && svg.app.PlaySvg}
                                                                    </a>
                                                                </span>}
                                                            </>

                                                    }
                                                </div>
                                                {/* {isTrash && <a className="sch-iconRestore" onClick={e => setMyState(setState, {
                                            restoreMe: [item]
                                        })}>{svg.app.Restore}</a>}

                                        <a className="sch-iconSec" onClick={e => {
                                            setMyState(setState, {
                                                removeMe: [item]
                                            })
                                        }}>{svg.app.Removesvg}</a> */}

                                                <div className="sch-imgDescription sch-section-flex">
                                                    <div className="sch-imgTitle">
                                                        <h6>{item.title ? item.title : (item?.files?.original?.path).substring((item?.files?.original?.path).lastIndexOf('/') + 1).replace(/\.(jpg|png|mp4)$/, '')}</h6>
                                                    </div>
                                                    <div className={`sch-menuSection   ${allsubmenu[item?._id] ? 'sch-menuOpen' : ''}`}>
                                                        <div className='three_dot_bg' ref={menuRef} onClick={() => toggleSublist(item?._id)} >{svg.app.threeDot}</div>
                                                        <div className="sch-submenubox">
                                                            <ul>
                                                                {isTrash && <li>
                                                                    <a onClick={e => {
                                                                        setMyState(setState, {
                                                                            restoreMe: [item]
                                                                        });
                                                                        setallsubmenu(false);
                                                                    }}><span><img src="/assets/images/restore-svg.svg" alt="" /> </span>Restore</a>

                                                                </li>}

                                                                {isTrash ? '' : <><li>
                                                                    <a onClick={e => {
                                                                        setMyState(setState, {
                                                                            showModal: true,
                                                                            imgtitle: (item?.title)?item?.title:item?.files?.original.path,
                                                                            editId: item?._id
                                                                        });
                                                                        setallsubmenu(false);
                                                                    }}>
                                                                        <span> <img src="/assets/images/edit-svg.svg" alt="" /></span>Edit
                                                                    </a>
                                                                </li>

                                                                    {(item?.files?.original?.path) && !item.isProcessing ?
                                                                        <li>
                                                                            <a onClick={() => { downloadImage(item?.files?.original?.path, item?.mediaType, item?.title) }}>
                                                                                <span><img src="/assets/images/downloadSvg.svg" alt=""/></span>Download
                                                                            </a>
                                                                        </li>
                                                                        : ''}
                                                                         </>}
                                                                         <li>
                                                                    <a onClick={e => {
                                                                        setMyState(setState, {
                                                                            removeMe: [item]
                                                                        });
                                                                        setallsubmenu(false);
                                                                    }}><span><img src="/assets/images/delet-svg.svg" alt="" /> </span>Delete</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* </div> */}
                                            </div>
                                        </div>
                                    })
                                } 
                                
                                 {state.mediaLoading ?  [...Array(baseClass == 'sortPage' ? 15 : 24)].map((i, index) => <div key={index}>{skeletonLoader('asset', 1, baseClass == 'sortPage' ? '100%' : '100%', baseClass == 'sortPage' ? 102 : 165)}</div>) : null }

                            </Masonry>
                        </ResponsiveMasonry>

                    {/* </InfiniteScroll> */}


                    {/* </div> */}


                    {!state.mediaLoading && state.mediaArray?.length == 0 && <NoDataFound title={state.keyword ? "You don't have media with this name." : "No media found."} bgRemove={true} />}
                </div>

                {
                    state.mediaArray.length < state.totalRecord &&
                    <div className="loadmorebtn">
                        <a
                            className={`sch-loadmoreBtn ${state.mediaArray.length >= state.totalRecord ? 'pxl-button-disable' : ''}`}
                            onClick={() => {
                                filterMedia('loadMore');
                            }}
                        >{state.isLoading ? <span>Loading...</span> : <span>Load More</span>}</a>
                    </div>
                }
            </div>


            {/* Delete Pop Up */}
            <ConfirmationPopup
                shownPopup={state.removeMe.length}
                closePopup={() => {
                    setMyState(setState, {
                        removeMe: [],
                        selectedPost: [],
                        localCheckAll: false,
                        selectedAction: ''
                    })
                    isTrash && updateAction('');
                    isTrash && displayButtons(0);
                }}
                type={"post"}
                title={isTrash ? `Are you sure you want to delete ${state.removeMe.length}  ${state.removeMe.length === 1 ? "media item ?" : "media items ?"} ` : `Do you want to move ${state.removeMe.length} ${state.removeMe.length === 1 ? "media item" : "media items"}  to trash?`}
                subTitle={isTrash ? '' : 'You can restore it later from Dashboard > Trash.'}
                yesBtn={isTrash ? "Yes, Delete" : "Yes, Move"}
                btnLoader={state.isDataload}
                removeAction={() => {
                    let data = {}
                    const idArray = state.removeMe.map((item) => item._id);
                    if (isTrash) {
                        data['target'] = idArray.join(',');
                    } else {
                        data['target'] = idArray
                    }
                    setMyState(setState, { isDataload: true })
                    hitApi({
                        url: isTrash ? 'user-assets/remove-assets' : 'user-assets/move-assets-to-trash',
                        method: isTrash ? "DELETE" : "PATCH",
                        data: data,
                    }, (resp, err = null) => {
                        setMyState(setState, {
                            removeMe: [],
                            selectedPost: [],
                            isDataload: false,
                            selectedAction: ''
                        })
                        isTrash && updateAction('');
                        isTrash && displayButtons(0);
                        if (resp.status) {
                            setMyState(setState, { isLoad: !state.isLoad, page: 1 });
                        }
                    })
                }}
            />

            {/* Restore PopUp */}
            <ConfirmationPopup
                shownPopup={state.restoreMe.length}
                closePopup={() => {
                    setMyState(setState, {
                        restoreMe: [],
                        selectedPost: []
                    })
                    isTrash && updateAction('');
                    isTrash && displayButtons(0);
                }}
                btnLoader={state.isDataload}
                type={" post"}
                title={` Are you sure you want to restore ${state.restoreMe.length}  ${state.restoreMe.length === 1 ? "media item" : "media items"} ?`}
                subTitle={'This item will be restore immediately.'}
                yesBtn={"Yes, Restore"}
                removeAction={() => {
                    let data = {}
                    const idArray = state.restoreMe.map((item) => item._id);

                    data['target'] = idArray
                    setMyState(setState, { isDataload: true })
                    hitApi({
                        url: `user-assets/recover-assets`,
                        method: "PATCH",
                        data: data,
                    }, (resp, err = null) => {
                        setMyState(setState, {
                            restoreMe: [],
                            selectedDesign: [],
                            isDataload: false
                        })
                        isTrash && updateAction('');
                        isTrash && displayButtons(0);
                        if (resp.status) {
                            setMyState(setState, { isLoad: !state.isLoad });
                        }
                    })
                }}
            />


            {/* Edit */}
            <Modal
                show={state.showModal} onHide={handleClose} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {'Update Title'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={updateTitle}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Title <span className="pxl-star-red">*</span></label>
                            <input ref={firstInputRef} type="text" className="form-control" value={(state.imgtitle)} placeholder="Enter title " onChange={(e) => setMyState(setState, { imgtitle: e.target.value })} />
                        </div>
                        <button disabled={state.btnLoader} type="submit" className="pxl_btn">{state.btnLoader ? 'Processing...' : "Update"}</button>
                    </form>
                </Modal.Body>

            </Modal>
        </>


    )
}
export default Mylibrary;