import { useEffect, useRef, useState } from 'react'
import schedulerSvg from './components/schedulerSvg'
import { NoDataFound, hitApi, setMyState, skeletonLoader, useOutsideClick } from '../../components/commonAction';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import ConfirmationPopup from '../../components/common/ConfirmationPopup';
import { useStore } from '../../zustand/store';
import svg from '../../components/svg';


function SchedulePostTrash({ isTrash = false, trashPage = "", mediaKeyWord, searchType, displayButtons, action, updateAction, checkAll = false, chackAllAction = false, checkType = false }) {
  let store = useStore(state => state);
  const [state, setState] = useState({
    limit: 18,
    page: 1,
    sort: 'createdAt=-1',
    keyword: '',
    isLoading: false,
    totalCount: 0,
    loadData: false,
    isDataload: false,
    totalRecord: '',
    mediaType: 'image',
    removeMe: [],
    restoreMe: [],
    isLoad: false,
    selectAllActive: false,
    selectedPost: [],
    draftList: []

  });

  useEffect(() => {
    if (action == 'Delete') {
      setMyState(setState, { removeMe: state.selectedPost })
    } else if (action == 'Restore') {
      setMyState(setState, { restoreMe: state.selectedPost })
    }
  }, [action])

  useEffect(() => {
    if (isTrash) {
      if (checkAll) {
        setMyState(setState, { selectedPost: state.draftList });
        displayButtons(state.draftList.length);
      } else {
        if (checkType) {

          setMyState(setState, { selectedPost: [] });
          displayButtons(0);
        }
      }
    }
  }, [checkAll])

  useEffect(() => {
    if (isTrash) {
      if (state.draftList?.length > 0) {
        if (state.draftList?.length == state.selectedPost?.length) {
          chackAllAction(true)
        } else {
          chackAllAction(false)
        }
      }
    }
  }, [state.selectedPost])

  useEffect(() => {
    getDraftPost();
  }, [state.isLoad, searchType])

  const getDraftPost = (type = null) => {
    let { limit, page, sort, keyword } = state,
      cPage = type == 'loadMore' ? page + 1 : searchType ? 1 : page;
    let reqData = { limit, page: cPage, sort, keyword: searchType ? mediaKeyWord : keyword };

    if (isTrash) {
      reqData.isTrash = true;
    }

    setMyState(setState, { isLoading: true, loadMoreActive: type == 'loadMore' ? true : false });
    hitApi({
      url: 'scheduler/post/get-post',
      method: "GET",
      data: reqData,
    }, (resp, err = null) => {
      setMyState(setState, { isLoading: false });
      if (resp.status) {
        let newList = type == 'loadMore' ? [...state.draftList, ...resp.data.records] : resp.data.records;
        setMyState(setState, { draftList: newList, totalRecord: resp.data.totalRecords, page: cPage });
      }
    });
  }

  const handleSelectPost = (val, post) => {
    if (val) {
      setMyState(setState, { selectedPost: [...state.selectedPost, post] })
      isTrash && displayButtons([...state.selectedPost, post].length)
    } else {
      const arrCopy = Array.from(state.selectedPost);
      const objWithIdIndex = arrCopy.findIndex((obj) => obj._id === post._id);
      arrCopy.splice(objWithIdIndex, 1);
      setMyState(setState, { selectedPost: arrCopy })
      isTrash && displayButtons(arrCopy.length);
    }
  }

  const handleSchedulePost = () => {
    store.updateStore("scheduler", { calendarDate: '', schedulePost: state.selectedPost });
  }


  const menuRef = useRef();
  useOutsideClick(menuRef, () => { setallsubmenu(false) });

  const [allsubmenu, setallsubmenu] = useState(false);
  const toggleSublist = (id) => {
    setallsubmenu(prevState => ({
      //   ...prevState,
      [id]: !prevState[id]
    }));
  };
  // sub menu delet edit setting end


  return (
    <>
      <div className={isTrash ? 'sch-trashDraft' : ''} >
        <div className='sch-container-fluid'>
          <div className='sch-draft-post-wrpper'>

            {/* <div className='sch-draft-box-flex'> */}
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 394: 2, 750: 3, 900: 5, 1424: 6 }}
            >
              <Masonry gutter={"20px"}>
                {!state.loadMoreActive && state.isLoading ? [...Array(12)].map((i) => <div key={i}>{skeletonLoader('asset', 1, '', 300)} </div>) : state.draftList.length > 0 && state.draftList.map((post, i) => (<div key={post._id} className='sch-draft-mansory'>
                  <div className={`sch-draft-box  ${(!post?.mediaUrl || post?.mediaUrl.length == 0) && 'sch-draft-box-imgNone'} ${state.selectedPost.some((obj) => obj._id === post._id) && 'active_post'}`}>
                    <div className={`sch-draft-img-box`}>
                      {post?.mediaUrl && post?.mediaUrl.length > 0 && <img src={post?.mediaUrl[0].thumb || post?.mediaUrl[0].path} alt="" />
                        // : <img src={'/assets/images/default-draft-img.jpg'} alt="" />
                      }
                      <div className="sch-custom-check-box cun-selectAll sch_hide_checkbox" style={{ opacity: state.selectedPost.length > 0 && 1 }}>
                        <div className="form-group">

                          <input type="checkbox" id={i}
                            checked={state.selectedPost.some((obj) => obj._id === post._id)} onChange={(e) => handleSelectPost(e.target.checked, post)} />
                          <label htmlFor={i}></label>
                        </div>
                      </div>
                    </div>
                    <div className='sch-draft-content'>
                      <div className='sch-draft-post-title'>
                        <h2>{post.title}</h2>
                        <p dangerouslySetInnerHTML={{ __html: post.caption }}></p>
                      </div>
                      {

                        isTrash ?
                          <>
                            <div className={`sch-menuSection  ${allsubmenu[post._id] ? 'sch-menuOpen' : ''}`} >
                              <div className='three_dot_bg' id={post._id} onClick={() => toggleSublist(post._id)} ref={menuRef}><img src="/assets/images/three-dot.svg" alt="" /></div>
                              <div className='sch-submenubox'>
                                <ul>
                                  <li>
                                    <a onClick={() => { setallsubmenu(false); setMyState(setState, { restoreMe: [post], selectedPost: [] }) }}>
                                      <span><img src="/assets/images/restore-svg.svg" alt="" /> </span> Restore
                                    </a>
                                  </li>
                                  <li>
                                    <a onClick={() => { setallsubmenu(false); setMyState(setState, { removeMe: [post] }) }}>
                                      <span><img src="/assets/images/delet-svg.svg" alt="" /> </span>  delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>


                          </>

                          :

                          <>
                            <div className={`sch-menuSection  ${allsubmenu[post._id] ? 'sch-menuOpen' : ''}`}>
                              {/* <a id={post._id} onClick={()=> toggleSublist(post._id)} ref={menuRef}><img src="/assets/images/three-dot.svg" alt=""/></a>
                            <div className='sch-submenubox'>
                            <ul>
                              <li>
                              <Link to={`/scheduler/edit-draft/${post._id}`}>
                              <span><img src="/assets/images/edit-svg.svg" alt=""/> </span>  draft
                              </Link>
                              </li>
                              <li>
                              <a href="#" onClick={(e)=>{
 e.preventDefault()
}}>
                              <span><img src="/assets/images/delet-svg.svg" alt=""/> </span>  delete
                              </a>
                              </li>
                            </ul>
                          </div> */}
                            </div>
                            {/* <div className='sch-templateMenu'>
                          <div className='sch-draft-edit'>
                            <Link to={`/scheduler/edit-draft/${post._id}`}>
                              {schedulerSvg.app.EditDraft}
                            </Link>
                          </div>
                          <div className='sch-draft-delet' onClick={() => setMyState(setState, { removeMe: [post] })}>
                            <a href="#" onClick={(e)=>{
 e.preventDefault()
}}>
                              {svg.app.deleteIcon}
                            </a>
                          </div>
                        </div> */}
                          </>
                      }


                      {/* <p>
                  <div dangerouslySetInnerHTML={{ __html: post.caption }}></div>
                </p> */}

                    </div>

                  </div>

                </div>
                ))

                }
                {state.loadMoreActive && state.isLoading ? [...Array(12)].map((i) => <div key={i}>{skeletonLoader('asset', 1, '', 300)} </div>) : ''}
              </Masonry>
            </ResponsiveMasonry>

            {/* </div> */}

            {!state.isLoading && state.draftList.length == 0 && <NoDataFound title={'No scheduled posts found.'} bgRemove={true} />}
            {
              state.draftList.length && state.draftList.length < state.totalRecord &&
              <div className="loadmorebtn">
                <a
                  className={`sch-loadmoreBtn ${state.draftList.length >= state.totalRecord ? 'pxl-button-disable' : ''}`}
                  onClick={() => {
                    getDraftPost('loadMore');
                  }}
                >{state.isLoading ? <span>Loading...</span> : <span>Load More</span>}</a>
              </div>
            }
          </div>
        </div>
      </div>

      {/* Restore PopUp */}
      <ConfirmationPopup
        shownPopup={state.restoreMe.length}
        closePopup={() => {
          setMyState(setState, {
            restoreMe: [],
            selectedPost: []
          })
          isTrash && updateAction('');
          isTrash && displayButtons(0);
        }}
        type={"draft post"}
        title={`Are you sure you want to restore ${state.restoreMe.length} schedule ${state.restoreMe.length > 1 ? "posts" : "post"}  ?`}
        subTitle={'This item will be restored immediately.'}
        yesBtn={"Yes, Restore"}
        btnLoader={state.isDataload}

        removeAction={() => {
          let data = {}
          const idArray = state.restoreMe.map((item) => item._id);

          data['target'] = idArray
          setMyState(setState, { isDataload: true })

          hitApi({
            url: `scheduler/post/recover`,
            method: "PATCH",
            data: data,
          }, (resp, err = null) => {
            setMyState(setState, {
              restoreMe: [],
              selectedDesign: [],
              isDataload: false

            })
            isTrash && updateAction('');
            isTrash && displayButtons(0);
            if (resp.status) {
              setMyState(setState, { isLoad: !state.isLoad, page: 1 });
            }
          })
        }}
      />


      {/* Delete Pop Up */}
      <ConfirmationPopup
        shownPopup={state.removeMe.length}
        closePopup={() => {
          setMyState(setState, {
            removeMe: [],
            selectedPost: []
          })
          isTrash && updateAction('');
          isTrash && displayButtons(0);
        }}
        type={"draft post"}
        title={isTrash ? `Are you sure you want to delete ${state.removeMe.length} schedule ${state.removeMe.length > 1 ? "posts" : "post"} ` : `Do you want to move ${state.removeMe.length} schedule ${state.removeMe.length > 1 ? "posts" : "post"} to trash?`}
        subTitle={isTrash ? '' : 'You can restore it later from Dashboard > Trash.'}
        yesBtn={isTrash ? "Yes, Delete" : "Yes, Move"}
        btnLoader={state.isDataload}

        removeAction={() => {
          let data = {}
          const idArray = state.removeMe.map((item) => item._id);
          if (isTrash) {
            data['target'] = idArray.join(',');
          } else {
            data['target'] = idArray
          }
          setMyState(setState, { isDataload: true })

          hitApi({
            url: `scheduler/post/remove-post`,
            method: "DELETE",
            data: data,
          }, (resp, err = null) => {
            setMyState(setState, {
              removeMe: [],
              selectedDesign: [],
              isDataload: false

            })
            isTrash && updateAction('');
            isTrash && displayButtons(0);
            if (resp.status) {
              setMyState(setState, { isLoad: !state.isLoad, page: 1 });
            }
          })
        }}
      />
    </>
  )
}

export default SchedulePostTrash