import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";

import svg from "../../components/svg";
import Accordion from 'react-bootstrap/Accordion';
import { hitApi, setMyState, assetsUrlPath, skeletonLoader, Pagination, NoDataFound, useOutsideClick, commonToast } from '../../components/commonAction';
import ConfirmationPopup from '../../components/common/ConfirmationPopup';
import { Modal } from 'react-bootstrap';
import AudioPlayer from '../scheduler/components/AudioPlayer'
import Select from "react-select";
import { PreviewImages } from '../scheduler/components/SchedulePopUp';


const Assets = ({ pageTitle }) => {


    const [state, setState] = useState({
        category: [],
        limit: 20,
        page: 1,
        keyword: '',
        sort: 'createdAt=-1,title=-1',
        status: '',
        totalRecords: 0,
        categoryId: '',
        parentId: '',
        modelType: '',
        title: '',
        editId: '',
        clearKeyword: false,
        assetFile: '',
        assetTag: [],
        assetTagInput: '',
        assetTarget: '',
        categoryLoading: false,
        assetLoading: false,
        subCategoryLoading: false,
        activeAssetBtn: false,
        confirmDelete: {},
        deletePopUp: false,
        loadMoreActive: false,
        assetStatus: 2,
        editIndex: '',
        playVideoId: "",
        playAudioId: "",
        btnTitle: 'Stock',
        activeAccordianKey: "all",
        playMedia: '',
        subCategorylist: [],
        showSubCategorylist: false
    });

    const [showPreview, setShowPreview] = useState(false);


    const [totalRecords, setTotalRecords] = useState('0');
    const [assetsList, setAssetsList] = useState([]);
    const [subCategory, setSubCategory] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const firstInputRef = useRef(null);


    const handleClose = () => {
        setShowModal(false);
        setMyState(setState, { ...state, modelType: '', editId: '', title: '' });
    }
    const handleShow = (item) => { setShowModal(true) };

    const [assetsModal, setAssetsModal] = useState(false);
    const handleAssetsClose = () => {
        setAssetsModal(false);
        setMyState(setState, { ...state, assetFile: '', assetTag: [], assetTagInput: '', assetTarget: '' });
    }
    const handleAssetsShow = (item, i) => {
        setallsubmenu(false);
        setAssetsModal(true);
        if (item) {
            setMyState(setState, { ...state, assetFile: '', assetTag: item.tags ? item.tags : [], assetTarget: item._id, assetTagInput: '', status: item.status ? true : false, editIndex: i });
        }
    };
    const [btnLoading, setBtnLoading] = useState(false);



    useEffect(() => {
        getCategory();
    }, [])


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [state.parentId, state.categoryId]);

    useEffect(() => {
        getAssets();
    }, [state.page, state.assetStatus, state.parentId, state.categoryId, state.clearKeyword])

    const clearSearch = () => {
        setMyState(setState, { ...state, clearKeyword: !state.clearKeyword, keyword: '', page: 1, loadMoreActive: false });
    }

    const getCategory = () => {
        let { limit, page, sort } = state;
        setMyState(setState, { categoryLoading: true, });
        hitApi({
            url: 'pixa-stock/get-category',
            method: "GET",
            data: { limit: 'all', page, sort }
        }, (resp, err = null) => {
            if (resp.status) {
                setSubCategory(resp.data.records);
                getSubCategory(resp.data.records);
            }
        });
    }

    const getSubCategory = (categoryData) => {
        let { limit, page, sort } = state;
        hitApi({
            url: 'pixa-stock/get-sub-category',
            method: "GET",
            data: {
                limit: 'all', page, sort,

            }
        }, (resp, err = null) => {

            if (resp.status) {
                const subcategoryMap = {};
                resp.data.records.forEach((sub) => {
                    if (!subcategoryMap[sub.parentId]) {
                        subcategoryMap[sub.parentId] = [];
                    }
                    subcategoryMap[sub.parentId].push(sub);
                });

                const newArray = categoryData.map((cat) => ({
                    ...cat,
                    subCat: subcategoryMap[cat._id] || [],
                }));

                setMyState(setState, {
                    category: newArray,
                    categoryLoading: false,
                });

            }
        });

    }

    const getAssets = () => {
        let maxPage = totalRecords / state.limit;
        if (totalRecords > 0) {
            if (state.page > Math.ceil(maxPage)) {
                return false;
            }
        }

        // let Page = state.keyword ? 1 : state.loadMoreActive ? state.page <= Math.ceil(maxPage) ? state.page  : '' : 1;

        let data = { limit: state.limit, page: state.page, keyword: state.keyword, sort: state.sort };
        // if (state.parentId && state.categoryId) {
        data['category'] = state.parentId;
        data['subCategory'] = state.categoryId;
        // }\


        if (state.assetStatus != 2) {
            data.status = state.assetStatus
        }


        if (!state.categoryId && state.parentId) {
            setAssetsList([]);
            return false;
        }

        setMyState(setState, { assetLoading: true });
        hitApi({
            url: 'pixa-stock/get-media',
            method: "GET",
            data: data
        }, (resp, err = null) => {
            setMyState(setState, { assetLoading: false, });
            if (resp.status) {
                setTotalRecords(resp.data.totalRecords)

                if (state.loadMoreActive) {

                    setAssetsList(prev => [...prev, ...resp.data.records]);
                } else {
                    if (resp.data.records.length == 0) {
                        setAssetsList([]);
                    } else {
                        setAssetsList(resp.data.records);
                    }

                }
            }
        });

    }


    const handleSelect = (eventKey) => {

        if (eventKey == 'all') {
            setMyState(setState, { ...state, parentId: '', categoryId: '', page: 1, activeAssetBtn: false, btnTitle: 'Stock', keyword: '', activeAccordianKey: 'all', assetStatus: 2, loadMoreActive: false });
        } else {
            const foundObject = state.category.find(item => item._id === eventKey);

            if (foundObject?.subCat.length > 0) {
                setMyState(setState, { ...state, parentId: eventKey, page: 1, activeAccordianKey: eventKey, activeAssetBtn: true, categoryId: foundObject.subCat.length > 0 && foundObject.subCat[0]._id, btnTitle: foundObject.title, keyword: '', loadMoreActive: false, assetStatus: 2 });
            }
        }
    };


    const handleCategory = (e) => {
        e.preventDefault();
        if (!state.title.trim()) {
            commonToast('error', `Title is required.`);
            return false
        }
        if (state.modelType == "addCategory") {
            setBtnLoading(true)
            hitApi({
                url: 'pixa-stock/create-category',
                method: "POST",
                data: { title: state.title }
            }, (resp, err = null) => {
                setBtnLoading(false)
                if (resp.status) {
                    let cateList = state.category;
                    let newdata = { ...resp.data, subCat: [] }
                    cateList.unshift(newdata);
                    setMyState(setState, { ...state, modelType: '', title: '', category: cateList, loadMoreActive: false });
                    handleClose();
                }
            });
        } else {
            setBtnLoading(true)
            hitApi({
                url: 'pixa-stock/update-category',
                method: "PATCH",
                data: { title: state.title, target: state.editId, status: state.status }
            }, (resp, err = null) => {
                setBtnLoading(false)
                if (resp.status) {
                    let cateList = state.category;
                    cateList[state.editIndex] = {
                        ...cateList[state.editIndex],
                        title: state.title,
                        status: state.status,
                    }

                    setMyState(setState, {
                        ...state,
                        modelType: '',
                        editId: '',
                        title: '',
                        category: cateList, loadMoreActive: false
                    });
                    handleClose();
                }
            });
        }
    }

    const handleSubCategory = (e) => {
        e.preventDefault();
        if (!state.title.trim()) {
            commonToast('error', `Title is required.`);
            return false
        }
        if (state.modelType == "addSubCategory") {
            setBtnLoading(true)
            hitApi({
                url: 'pixa-stock/create-sub-category',
                method: "POST",
                data: { title: state.title, parentId: state.parentId }
            }, (resp, err = null) => {
                setBtnLoading(false)
                if (resp.status) {
                    let categoryDummy = state.category
                    let match = categoryDummy.some(obj => obj._id === resp.data.parentId);

                    if (match) {
                        let index = categoryDummy.findIndex(obj => obj._id === resp.data.parentId);
                        categoryDummy[index].subCat.push(resp.data);
                    }

                    setSubCategory(prev => [...prev, resp.data])
                    setMyState(setState, { ...state, modelType: '', category: categoryDummy, editId: '', title: '', loadMoreActive: false });
                    handleClose();
                }
            });
        } else {
            setBtnLoading(true)
            hitApi({
                url: 'pixa-stock/update-sub-category',
                method: "PATCH",
                data: { title: state.title, target: state.editId, parentId: state.parentId, status: state.status }
            }, (resp, err = null) => {
                setBtnLoading(false)
                if (resp.status) {
                    let categoryDummy = state.category;

                    let categoryObj = categoryDummy.find(obj => obj._id === state.parentId);
                    let index = categoryDummy.findIndex(obj => obj._id === state.parentId);


                    if (categoryObj) {
                        const updatedState = categoryObj.subCat.map((obj, index) => {
                            if (index == state.editIndex) {
                                return { ...obj, status: state.status, title: state.title };
                            }
                            return obj;
                        });

                        categoryDummy[index].subCat = updatedState;
                    }

                    setMyState(setState, { ...state, modelType: '', editId: '', title: '', status: '', category: categoryDummy, editIndex: '', loadMoreActive: false });
                    handleClose();
                }
            });
        }
    }

    const addAssetTag = (e) => {
        e.preventDefault();
        if (state.assetTagInput) {
            const newList = [...state.assetTag];
            newList.push(state.assetTagInput);
            setMyState(setState, { ...state, assetTag: newList, assetTagInput: '', loadMoreActive: false });
        }
    }

    const removeAssetTag = (index) => {
        const newList = [...state.assetTag];
        newList.splice(index, 1);
        setMyState(setState, { ...state, assetTag: newList });
    }

    const addAsset = () => {
        if (Object.keys(state.assetFile).length > 0) {
            const formData = new FormData();
            Object.values(state.assetFile).forEach((file, index) => {
                formData.append(`files`, file);
            });
            if (state.assetTag.length > 0) {
                state.assetTag.forEach((tag, index) => {
                    formData.append('tags[]', tag);
                })
            } else if (state.assetTagInput) {
                if (state.assetTagInput.includes(",")) {
                    const outputArray = state.assetTagInput.split(",");
                    outputArray.forEach((tag, index) => {
                        formData.append('tags[]', tag);
                    })

                } else {
                    formData.append('tags[]', state.assetTagInput);
                }
            }

            formData.append('category', state.parentId);
            formData.append('subCategory', state.categoryId ? state.categoryId : subCategory.length && subCategory[0]._id);

            setBtnLoading(true);
            hitApi({
                url: 'pixa-stock/add-media',
                method: "POST",
                data: formData,
                isFormData: true,
            }, (resp, err = null) => {
                setBtnLoading(false);
                if (resp.status) {
                    handleAssetsClose(false);
                    setAssetsList(prev => [...prev, ...resp.data])
                    setMyState(setState, {
                        ...state,
                        assetTag: [],
                        assetFile: '',
                        assetTagInput: ''
                    });
                    setTotalRecords(prev => prev + 1);
                }

            });
        } else {
            if (Object.keys(state.assetFile).length == 0) {
                commonToast('error', `Asset file is required.`);
                return false
            }
        }
    }


    const updateAsset = () => {
        if (state.assetTarget) {
            handleAssetsClose(true)
            let data = { target: state.assetTarget, status: state.status };
            if (state.assetTagInput) {
                if (state.assetTagInput.includes(",")) {
                    let outputArray = state.assetTagInput.split(",");
                    if (state.assetTag.length > 0) {
                        outputArray = outputArray.concat(state.assetTag);
                    }
                    data['tags'] = outputArray;
                } else {
                    if (state.assetTag.length > 0) {
                        data['tags'] = [...state.assetTag, state.assetTagInput];
                    } else {
                        data['tags'] = [state.assetTagInput];
                    }

                }
            } else {
                data['tags'] = state.assetTag
            }
            hitApi({
                url: 'pixa-stock/update-media',
                method: "PATCH",
                data: data,
            }, (resp, err = null) => {
                setBtnLoading(false);
                if (resp.status) {

                    handleAssetsClose(false);
                    const updatedState = assetsList.map((obj, index) => {
                        if (index == state.editIndex) {
                            return { ...obj, status: data.status, tags: data.tags };
                        }
                        return obj;
                    });

                    setAssetsList(updatedState);
                    setMyState(setState, { ...state, assetTag: [], assetFile: '' });
                }
            });
        }
    }



    let obj = [{ value: 2, label: 'All' }, { value: 1, label: 'Active' }, { value: 0, label: 'Inactive' }];


    // sub menu delet edit setting
    const [allsubmenu, setallsubmenu] = useState(false);
    const toggleSublist = (id) => {
        setallsubmenu(prevState => ({
            //   ...prevState,
            [id]: !prevState[id]
        }));
    };

    const menuRef = useRef();

    useOutsideClick(menuRef, () => setallsubmenu(false));



    const handleAccordionClick = (category) => {
        setMyState(setState, { subCategorylist: category, showSubCategorylist: true });
        // setMyState(setState, { ...state, parentId: category._id, page: 1, activeAccordianKey: category._id, activeAssetBtn: true, categoryId: category.subCat.length > 0 && category.subCat[0]s._id, btnTitle: category.title, keyword: '', loadMoreActive: false, assetStatus: 2 });

    }

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [showModal,assetsModal]);

    return (

        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper">

                <div className="pxl_rightBar_assets">
                    <div className="pxl_pageTitle cun-pageTitle-res">
                        <h2>{pageTitle}</h2>
                    </div>

                    {/* Admin Assets Section  */}
                    <div className="pxl-template-wrapper">
                        <div className="pxl-template-container">
                            {/* Assets Category */}

                            <div className=''>
                                <div className="pxl_pageTitleInner pxl-pageTitleSpacing">
                                    <h2>Media ({totalRecords})</h2>
                                </div>
                                <div className="pxl-template-categories pxl-assets-filter">
                                    <div className="pxl-template-row">
                                        {!state.showSubCategorylist &&
                                            <>
                                                <div className="pxl-template-filter-header">
                                                    <h4>Stock Type</h4>

                                                    <div className='pxl-assets-plus' onClick={() => {
                                                        handleShow();
                                                        setMyState(setState, {
                                                            modelType: 'addCategory',
                                                        })
                                                    }}>
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault()
                                                        }}>{svg.app.Addsvg}</a>
                                                    </div>
                                                </div>
                                                <div className="pxl-template-filter-body">
                                                    <div className='pxl-accordian-section'>

                                                        <Accordion activeKey={state.activeAccordianKey} defaultActiveKey={state.parentId} flush onSelect={handleSelect}>
                                                            <Accordion.Item eventKey={'all'}  >
                                                                <Accordion.Header >
                                                                    <div className='pxl-accordian-header'>
                                                                        <h2><span></span>All</h2>
                                                                    </div>
                                                                </Accordion.Header>
                                                            </Accordion.Item>
                                                            {!state.categoryLoading ? state?.category.length > 0 ? state?.category.map((category, index) =>
                                                                <Accordion.Item eventKey={category._id} key={index} >
                                                                    <Accordion.Header onClick={() => {
                                                                        if (category?.subCat.length > 0) {
                                                                            handleAccordionClick(category)
                                                                        }
                                                                    }
                                                                    }>
                                                                        <div className='pxl-accordian-header'>
                                                                            <h2>
                                                                                {/* <span>{svg.app.sorting}</span> */}
                                                                                {category.title}
                                                                            </h2>
                                                                        </div>

                                                                    </Accordion.Header>
                                                                    <div className='pxl-accordion-icons-right'>
                                                                        <div className='pxl-icon-tooltip' >
                                                                            <span className='pxl-tooltip-text'>Add</span>
                                                                            <a onClick={() => {
                                                                                handleShow();
                                                                                setMyState(setState, {
                                                                                    modelType: 'addSubCategory',
                                                                                    parentId: category._id,
                                                                                    activeAccordianKey: category._id,

                                                                                })
                                                                            }}>{svg.app.Addsvg}</a>
                                                                        </div>
                                                                        {/* <div className='pxl-icon-tooltip'>
                                                                                <span className='pxl-tooltip-text'>Remove</span>
                                                                                <a href="#" onClick={(e)=>{
                                                                            e.preventDefault()
                                                                            }}>{svg.app.Removesvg}</a>
                                                                            </div> */}

                                                                        <div className='pxl-icon-tooltip'>
                                                                            <span className='pxl-tooltip-text'>Edit</span>
                                                                            <a onClick={() => {
                                                                                handleShow();
                                                                                setMyState(setState, {
                                                                                    modelType: 'editCategory',
                                                                                    title: category.title,
                                                                                    editId: category._id,
                                                                                    status: category.status,
                                                                                    editIndex: index
                                                                                })
                                                                            }}>{svg.app.editIconWhite}</a>
                                                                        </div>
                                                                    </div>

                                                                </Accordion.Item>
                                                            )
                                                                :
                                                                <></>
                                                                // <div>No assets found.</div>
                                                                :
                                                                <div>{skeletonLoader('list', 4)}</div>
                                                            }
                                                        </Accordion>

                                                    </div>

                                                </div> </>
                                        }

                                        {state.showSubCategorylist &&
                                            <>
                                                <div className='cun-backBtn-sec' onClick={(e) => {
                                                    setMyState(setState, { ...state, showSubCategorylist: false, subCategorylist: [], parentId: '', categoryId: '', page: 1, activeAssetBtn: false, btnTitle: 'Stock', keyword: '', activeAccordianKey: 'all', assetStatus: 2, loadMoreActive: false });
                                                }}>
                                                    <span><img src="/assets/images/backSvg.svg" alt="" /></span> Back
                                                </div>
                                                <div className="pxl-template-filter-body">
                                                    <div className='pxl-accordian-section'>
                                                        <Accordion activeKey={state.activeAccordianKey} defaultActiveKey={state.parentId} flush onSelect={handleSelect}>

                                                            <Accordion.Item eventKey={state.subCategorylist._id} key={state.subCategorylist._id} >
                                                                <Accordion.Header>
                                                                    <div className='pxl-accordian-header'>
                                                                        <h2>
                                                                            {/* <span>{svg.app.sorting}</span> */}
                                                                            {state.subCategorylist.title}
                                                                        </h2>
                                                                    </div>

                                                                </Accordion.Header>
                                                                <div className='pxl-accordion-icons-right'>
                                                                    <div className='pxl-icon-tooltip' >
                                                                        <span className='pxl-tooltip-text'>Add</span>
                                                                        <a onClick={() => {
                                                                            handleShow();
                                                                            setMyState(setState, {
                                                                                modelType: 'addSubCategory',
                                                                                parentId: state.subCategorylist._id,
                                                                                activeAccordianKey: state.subCategorylist._id,

                                                                            })
                                                                        }}>{svg.app.Addsvg}</a>
                                                                    </div>
                                                                    {/* <div className='pxl-icon-tooltip'>
                                                                    <span className='pxl-tooltip-text'>Remove</span>
                                                                    <a href="#" onClick={(e)=>{
                                                                        e.preventDefault()
                                                                        }}>{svg.app.Removesvg}</a>
                                                                </div> */}
                                                                    <div className='pxl-icon-tooltip'>
                                                                        <span className='pxl-tooltip-text'>Edit</span>
                                                                        <a onClick={() => {
                                                                            handleShow();
                                                                            setMyState(setState, {
                                                                                modelType: 'editCategory',
                                                                                title: state.subCategorylist.title,
                                                                                editId: state.subCategorylist._id,
                                                                                status: state.subCategorylist.status,
                                                                                // editIndex: i
                                                                            })
                                                                        }}>{svg.app.editIconWhite}</a>
                                                                    </div>
                                                                </div>
                                                                <Accordion.Body>
                                                                    <div className='pxl-accodian-sub-category-body '>
                                                                        {!state.subCategoryLoading ? (state.subCategorylist?.subCat.length > 0) ? state.subCategorylist?.subCat.map((val, i) => (val.parentId == state.subCategorylist._id) && <div className={`pxl-accodian-sub-category ${state.categoryId ? state.categoryId == val._id && 'pxl-sub-categoryActive' : i == 0 && 'pxl-sub-categoryActive'}`} key={val._id} onClick={() => {

                                                                            setMyState(setState, {
                                                                                categoryId: val._id,
                                                                                assetStatus: 2,
                                                                                keyword: '',
                                                                                page: 1,
                                                                                loadMoreActive: false
                                                                            });
                                                                        }}>

                                                                            <h2> {val.title}</h2>
                                                                            <div>
                                                                                {!val.status && <div className='pxl-inactive'>Inactive</div>}

                                                                                <div className='pxl-accordion-icons-right'>
                                                                                    <div className='pxl-icon-tooltip'>
                                                                                        <span className='pxl-tooltip-text'>Edit</span>
                                                                                        <a onClick={() => {
                                                                                            handleShow();
                                                                                            setMyState(setState, {
                                                                                                modelType: 'editSubCategory',
                                                                                                title: val.title,
                                                                                                editId: val._id,
                                                                                                parentId: state.subCategorylist._id,
                                                                                                status: val.status,
                                                                                                editIndex: i
                                                                                            })
                                                                                        }}>{svg.app.editIconWhite}</a>
                                                                                    </div>
                                                                                    {/* <div className='pxl-icon-tooltip'>
                                                                                <span className='pxl-tooltip-text'>remove</span>
                                                                                <a onClick={() => { }} >{svg.app.Removesvg}</a>
                                                                            </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        ) : <div className='pxl-accodian-sub-category' >
                                                                            <h2>No category found.</h2>
                                                                        </div>
                                                                            :
                                                                            <div>{skeletonLoader('list', 2)}</div>
                                                                        }

                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>

                                                        </Accordion>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>


                                </div>
                            </div>
                            {/* Assets Section */} 
                            <div className="pxl-templates-holder">
                                {/* Assets Status Bar */}
                                <div className="pxl-templates-status-bar pxl-filter-wrapper pxl-searchbar-right cun-FlexEnd">
                                    {/* <div className="pxl-all-templates">
                                        <a href="#" onClick={(e)=>{
 e.preventDefault()
}} className="pxl-filter-mobile-btn">
                                            <img src="/assets/images/filter.svg" alt="" />
                                        </a>
                                    </div> */}
                                    <div className="pxl-head-btn-wrap cun-pixaStockHead">
                                        <div className="pxl_filterFeilds ">
                                            {/* <div className="cun-AllChecknoxSelect">
                                                <div className="sch-custom-check-box ">
                                                    <div className="form-group">
                                                        <input type="checkbox" id="select-all"
                                                            //   checked={state.localCheckAll}
                                                            onChange={(e) => (e.target.checked) ? setMyState(setState, { selectedPost: state.mediaArray, localCheckAll: e.target.checked }) : setMyState(setState, { selectedPost: [], localCheckAll: e.target.checked })}
                                                        />
                                                        <label htmlFor="select-all">Select All</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='cun-headreSelect cun-headreSelect-imagetemplate'>
                                                <Select options={options} />
                                            </div> */}

                                            <div className='cun-headreSelect cun-headreSelect-imagetemplate1'>
                                                <Select

                                                    value={obj.filter(option =>
                                                        option.value == state.assetStatus)}
                                                    options={obj}
                                                    onChange={(e) => setMyState(setState, {
                                                        assetStatus: e.value,
                                                        loadMoreActive: false,
                                                        page: 1,

                                                    })}
                                                />
                                            </div>



                                            <div className="sch-search-section">
                                                <div className="sch-search-row sch-search-bg-color-white">
                                                    <input type="text" value={state.keyword} placeholder="Search Stock Media" onChange={(e) => setMyState(setState, {
                                                        keyword: e.target.value,
                                                        loadMoreActive: false
                                                    })}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                                                                getAssets();
                                                                setMyState(setState, { page: 1 })
                                                            }
                                                        }} />
                                                    <button type="button" className="sch-search-btn">
                                                        {!state.keyword ? <img src="/assets/images/scheduler/search.svg" /> : <span className="cun-inputCross" onClick={clearSearch}>{svg.app.crossSvg}</span>}

                                                    </button>
                                                </div>
                                            </div>
                                            <button className={`pxlCommon-btn  ${!state.activeAssetBtn && 'pxl-button-disable'}`} data-bs-toggle="modal" data-bs-target="#addAssets" onClick={() => (state.activeAssetBtn) ? handleAssetsShow() : null}>
                                                 Add New {state.btnTitle}

                                            </button>
                                        </div>



                                    </div>
                                </div>
                                {/* Main Assets */}
                                <div className="pxl-assets-area">
                                    {<div className="pxl-assets-row cun-adminPixastockWrap">

                                        {/* Single Assets */}
                                        {!state.loadMoreActive && state.assetLoading ? [...Array(20)].map((i) => <div key={i}>{skeletonLoader('asset', 1, 200, 200)}</div>) :
                                            assetsList.length > 0 && assetsList.map((asset, i) => {
                                                let thumbObj = {
                                                    image: asset?.files?.original?.thumb || asset?.files?.original?.path,
                                                    video: asset?.files?.original?.thumb || '/assets/images/newpost/video-newpost.png',
                                                    audio: '/assets/images/newpost/audio-newpost.png',
                                                }
                                                return (
                                                    <div className="sch-draft-box" key={asset._id} onMouseEnter={e => {
                                                        if (asset.mediaType != 'image') {
                                                            setMyState(setState, {
                                                                playMedia: asset._id
                                                            })
                                                        }
                                                    }}>


                                                        {/* {asset.isProcessing && <div className='pxl-assets-over pxl-processing'>
                                                            <h2>processing...</h2>
                                                        </div>}    */}


                                                        {!asset.tags && <div className='pxl_emptytag'>{svg.app.Circle}</div>
                                                        }



                                                        {
                                                            state.playMedia == asset._id ?
                                                                (
                                                                    asset.mediaType == 'audio' ? <div className='sch-draft-img-box'>
                                                                        {asset.isProcessing && <div className='pxl-assets-over pxl-processing'>
                                                                            <h2>processing...</h2>
                                                                        </div>}

                                                                        <img
                                                                            src={assetsUrlPath(thumbObj[asset.mediaType])} alt={asset.title}
                                                                        />
                                                                        <AudioPlayer media={asset} />
                                                                    </div>
                                                                        :
                                                                        // <div className='sch-draft-img-box'>
                                                                        <video
                                                                            preload="none"
                                                                            src={assetsUrlPath(asset.files.original.path)}
                                                                            autoPlay={true}
                                                                            muted={true}
                                                                            playsInline disablePictureInPicture
                                                                            onMouseLeave={e => {
                                                                                setMyState(setState, {
                                                                                    playMedia: null
                                                                                })
                                                                            }}
                                                                        />
                                                                    // </div>
                                                                )
                                                                :
                                                                <>
                                                                    <div className='sch-draft-img-box'>
                                                                        {asset.isProcessing && <div className='pxl-assets-over pxl-processing'>
                                                                            <h2>processing...</h2>
                                                                        </div>}

                                                                        <img
                                                                            src={assetsUrlPath(thumbObj[asset.mediaType])} alt={asset.title}
                                                                        />
                                                                        {(asset.mediaType == 'video') && <span className={`sch-audioPlay`}>
                                                                            <a onClick={() => setMyState(setState, {
                                                                                playMedia: asset._id
                                                                            })}>
                                                                                {state.playMedia != asset._id && svg.app.PlaySvg}
                                                                            </a>
                                                                        </span>}
                                                                    </div>
                                                                </>


                                                        }

                                                        <div className='sch-draft-content'>
                                                            <div className="sch-draft-post-title"><h2>{asset?.title}</h2></div>
                                                            <div className={`sch-menuSection  ${allsubmenu[asset._id] ? 'sch-menuOpen' : ''}`}>
                                                                <div className='three_dot_bg' id={asset._id} onClick={() => toggleSublist(asset._id)} ref={menuRef}><img src="/assets/images/three-dot.svg" alt="" /></div>
                                                                <div className={`sch-submenubox`}>
                                                                    <ul >
                                                                        {(asset.mediaType == 'video' || asset.mediaType == 'image') && <li>
                                                                            <a href="#" data-bs-toggle="modal" onClick={() => setShowPreview(asset)} data-bs-target="#editAssets" className="pxl-edit-icon">
                                                                                <span><img src="/assets/images/preview-svg.svg" alt="" /></span>Preview
                                                                            </a>
                                                                        </li>
                                                                        }
                                                                        <li>
                                                                            <a href="#" data-bs-toggle="modal" onClick={() => handleAssetsShow(asset, i)} data-bs-target="#editAssets" className="pxl-edit-icon">
                                                                                <span><img src="/assets/images/edit-svg.svg" alt="" /> </span>Edit
                                                                            </a>
                                                                        </li>

                                                                        <li>
                                                                            <a href="#" onClick={() => {
                                                                                setMyState(setState, {
                                                                                    deletePopUp: true,
                                                                                    confirmDelete: {
                                                                                        title: 'Assets',
                                                                                        url: 'pixa-stock/remove-media',
                                                                                        data: { target: asset._id }
                                                                                    }
                                                                                });
                                                                                setallsubmenu(false);

                                                                            }} data-bs-toggle="modal" data-bs-target="#deleteAssets" className="pxl-delete-icon">
                                                                                <span><img src="/assets/images/delet-svg.svg" alt="" /> </span>Delete
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/* {
                                                    (asset.mediaType == 'video') && <div className='pxl-videoAudio' onMouseOver={() => setTimeout(() => {
                                                        setMyState(setState, { playVideoId: asset._id, playAudioId: '' })
                                                    }, 500)}>
                                                        {(state.playVideoId !== asset._id) && <div className="pxl-video"><div className='pxl-video-svg'>{svg.app.PlaySvg} </div></div>}

                                                        <div className="pxl-assets-img " >
                                                            <img src={(asset.files.original.thumb) ? assetsUrlPath(asset.files.original.thumb) : '/assets/images/newpost/video-newpost.png'} alt="" style={{ display: (state.playVideoId == asset._id) ? 'none' : 'block' }} />

                                                            {(state.playVideoId == asset._id) &&
                                                                <video ref={videoRef} preload="none" src={assetsUrlPath(asset.files.original.path)} alt="" autoPlay={true} muted={true}
                                                                    playsInline disablePictureInPicture onMouseEnter={handleVideoMouseEnter} onMouseLeave={handleVideoMouseLeave}
                                                                    onEnded={() => setMyState(setState, { playVideoId: '' })} onPlay={() => setMyState(setState, { playVideoId: asset._id })} onPause={() => setMyState(setState, { playVideoId: '' })} />
                                                            }
                                                        </div>


                                                    </div>
                                                }
                                                {
                                                    (asset.mediaType == 'audio') && 
                                                    <div onMouseOver={() => setMyState(setState, { playAudioId: asset._id, playVideoId: '' })} onMouseLeave={() => setMyState(setState, { playAudioId: '' })}  >
                                                        {(state.playAudioId !== asset._id) && <div className="pxl-audio"><div className='pxl-audio-svg'>{svg.app.PlaySvg} </div></div>}
                                                        <div className="pxl-assets-img " >
                                                            <img src={asset.files.original.thumb ? assetsUrlPath(asset.files.original.thumb) : '/assets/images/newpost/audio-newpost.png'} alt="" style={{ display: (state.playAudioId == asset._id) ? 'none' : 'block' }} />

                                                            {(state.playAudioId == asset._id) &&
                                                                // <audio ref={audioRef} controls src={assetsUrlPath(asset.files.original.path)} alt="" />
                                                                <div className="pxl-audio"><div className='pxl-audio-svg'>{svg.app.PauseSvg} </div></div>
                                                            }
                                                        </div>
                                                    </div>
                                                } */}

                                                    </div>

                                                )
                                            }
                                            )


                                        }
                                        {state.loadMoreActive && state.assetLoading ? [...Array(20)].map((i) => <div key={i}>{skeletonLoader('asset', 1, 200, 200)}</div>) : ''}

                                    </div>
                                    }


                                    {
                                        !state.loadMoreActive && !state.assetLoading && assetsList.length == 0 && <NoDataFound title="No asset found for this category." bgRemove={true} />
                                    }
                                    {assetsList.length < totalRecords &&
                                            <div className="loadmorebtn">
                                                <a
                                                    className={`sch-loadmoreBtn ${assetsList.length >= totalRecords ? 'pxl-button-disable' : ''}`}
                                                    onClick={()=>{
                                                        let addload = document.getElementById("add_Load_more")
                                                        if(addload){
                                                            addload.click()
                                                        }
                                                    }}
                                                >{state.assetLoading ? <span>Loading...</span> : <span>Load More</span>}</a>
                                            </div>
                                        }

                                    {assetsList.length > 0 && <Pagination
                                        type="Assests"
                                        dataRange={{
                                            start: assetsList.length > 0 ? 1 : 0,
                                            end: assetsList.length,
                                        }}
                                        loadMore={true}
                                        currentPage={state.page}
                                        totalRecords={totalRecords < assetsList.length ? assetsList.length : totalRecords}
                                        perPage={state.limit}
                                        isLoading={state.assetLoading}
                                        onClick={(pageNum) => {
                                            let maxPage = totalRecords / state.limit;
                                            if (pageNum <= Math.ceil(maxPage)) {
                                                setMyState(setState, {
                                                    page: pageNum,
                                                    assetStatus: 2,
                                                    // keyword:'',
                                                    loadMoreActive: true,
                                                });
                                            }
                                        }}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showModal} onHide={handleClose} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {state.modelType == 'addCategory' ? "Add New Stock Type" : state.modelType == 'editCategory' ? "Edit Stock Type" : state.modelType == 'addSubCategory' ? "Add New  Category" : "Edit  Category"}

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Add Category */}
                    {(state.modelType == 'addCategory' || state.modelType == 'editCategory') && <form className="" action=""
                        onSubmit={handleCategory}
                    >
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Title <span className="pxl-star-red">*</span></label>
                            <input ref={firstInputRef} type="text" className="form-control" value={state.title} placeholder="Enter Title" onChange={(e) => setMyState(setState, {
                                title: e.target.value
                            })} />
                        </div>

                        <div className='sch-statusCheckbox sch-relativeClass'>
                            {state.modelType == 'editCategory' && <div className="sch-custom-check-box">
                                <form>
                                    <div className="form-group">
                                        <input type="checkbox" className="form-check-input" checked={state.status} id="exampleCheck1" onClick={(e) => setMyState(setState, {
                                            status: e.target.checked
                                        })} />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Status</label>
                                    </div>
                                </form>
                            </div>}
                        </div>
                        <button type="submit" className="pxl_btn" disabled={btnLoading}>
                            {btnLoading ? "Processing..." : state.modelType == 'addCategory' ? "Add" : 'Update'}
                        </button>
                    </form>}

                    {/* Add Sub Category */}
                    {(state.modelType == 'addSubCategory' || state.modelType == 'editSubCategory') && <form className="" action=""
                        onSubmit={handleSubCategory}
                    >
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Title <span className="pxl-star-red">*</span></label>
                            <input ref={firstInputRef} type="text" className="form-control" value={state.title} placeholder="Enter Title" onChange={(e) => setMyState(setState, {
                                title: e.target.value
                            })} />
                        </div>
                        <div className="sch-statusCheckbox sch-relativeClass">
                            {state.modelType == 'editSubCategory' && <div className="sch-custom-check-box">
                                <form>
                                    <div className='form-group'>
                                        <input type="checkbox" className="form-check-input" checked={state.status} id="exampleCheck1" onClick={(e) => setMyState(setState, {
                                            status: e.target.checked
                                        })} />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Status</label>
                                    </div>
                                </form>
                            </div>}
                        </div>

                        <button type="submit" className="pxl_btn" disabled={btnLoading}>
                            {btnLoading ? "Processing..." : state.modelType == 'addSubCategory' ? "Add" : 'Update'}
                        </button>
                    </form>}

                </Modal.Body>

            </Modal>


            <Modal
                show={assetsModal} onHide={handleAssetsClose} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv pxl-addNewStock-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='pxl-model-header'>
                            <h2 className='modal-title'>{state.assetTarget ? 'Edit Stock' : 'Add New Stock'}</h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='pxl_model_body'>
                        {state.assetTarget ? null : <div className='form-group'>
                            <label >File Upload <span className="pxl-star-red">*</span></label>
                            <div className="pxl-custom-file-upload pxl-pixaStock-file-upload-popup">

                                <div className="pxl-custom-file-select">
                                    <div className="pxl-custom-file-select-button" id="fileName">
                                        <img src="/assets/images/newpost/file-upload.png" alt="" />
                                    </div>
                                    <div className="pxl-custom-file-select-name" id="noFile"><b>Upload</b><span class="pxl-upload-file-text">or drop your file here</span></div>
                                    <input ref={firstInputRef} type="file" name="chooseFile" id="chooseFile" multiple onChange={(e) => setMyState(setState, {
                                        assetFile: e.target.files
                                    })} />

                                </div>
                                {/* <div>{Array.from(state.assetFile).map(file => file.name).join(' , ')}</div> */}
                                <div className='pxl-file-box'>{Array.from(state.assetFile).slice(0, 4).map((file, i) => <div key={i} className='pxl-file-name'>{file.name} </div>)}
                                    {Array.from(state.assetFile).length > 4 && "..." + `+${Array.from(state.assetFile).length - 4} files`}
                                </div>

                            </div>
                        </div>}
                        <div className='pxl-tokenfield-section sch-margin-bottom15'>
                            <label className='pxl-label'>Tags <span className="pxl-star-red">*</span></label>
                            <div className='pxl-tokenfield'>
                                <div className='pxl-tokenfield-inner'>
                                    {state.assetTag && state.assetTag.length > 0 ? state.assetTag.map((val, i) => (
                                        <div className='pxl-tokenfield-child' key={i}>
                                            <p> {val} <span><a onClick={() => removeAssetTag(i)}>{svg.app.crossSvg1}</a></span></p>
                                        </div>
                                    )) : ''}

                                </div>
                                <form onSubmit={(e) => addAssetTag(e)}>
                                    <div className='pxl-tokenfield-input'>
                                        <input ref={firstInputRef} type='text' placeholder='Type and press enter to add tags' value={state.assetTagInput} onChange={(e) => setMyState(setState, {
                                            assetTagInput: e.target.value
                                        })} />
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="sch-statusCheckbox sch-relativeClass">
                            {state.assetTarget && <div className="sch-custom-check-box">
                                <form>
                                    <div className="form-group">
                                        <input type="checkbox" className="form-check-input" checked={state.status} id="exampleCheck1" onClick={(e) => setMyState(setState, {
                                            status: e.target.checked
                                        })} />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Status</label>
                                    </div>
                                </form>
                            </div>}
                        </div>

                        <button type="button" disabled={btnLoading} className="pxl_btn" onClick={state.assetTarget ? updateAsset : addAsset}>
                            {btnLoading ? "Processing..." : state.assetTarget ? "Update" : "Upload"}
                        </button>
                    </div>
                </Modal.Body>

            </Modal>


            <ConfirmationPopup
                shownPopup={state.deletePopUp}
                btnLoader={state.btnLoading}
                closePopup={() => {
                    setMyState(setState, {
                        deletePopUp: false
                    });
                    setallsubmenu(false);
                }}
                type={"stock"}
                removeAction={() => {
                    setMyState(setState, { btnLoading: true });
                    hitApi({
                        url: state.confirmDelete?.url,
                        method: "DELETE",
                        data: state.confirmDelete?.data
                    }, (resp, err = null) => {
                        setMyState(setState, {
                            deletePopUp: false,
                            confirmDelete: {},
                            btnLoading: false
                        })
                        if (resp.status) {
                            setMyState(setState, { loadMoreActive: false });
                            getAssets();
                        }
                    })
                }}
            />
          
            <PreviewImages
                shownPopup={showPreview}
                closePopup={() => setShowPreview(false)}
                asset={showPreview}
            />

        </>
    )
};

export default Assets;