import  { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useStore } from "../../../zustand/store";
import ConfirmationPopup from '../../../components/common/ConfirmationPopup';

import { hitApi, setMyState, Pagination, commonToast, skeletonLoader, NoDataFoundDataTable, getNameInitials, hexToRgbA } from "../../../components/commonAction";
import svg from "./svg";


const TrashTickets = ({ pageTitle, mediaKeyWord, searchType, displayButtons, action, updateAction, checkAll = false ,chackAllAction=false}) => {

    let store = useStore(state => state);
    const { role, scope } = store.userData;

    const [state, setState] = useState({
        data: [],
        limit: 10,
        page: 1,
        keyword: '',
        sort: 'createdAt=-1',
        filterProject: '',
        status: 3,
        totalRecords: '',
        userLoading: false,
        clearKeyword: false,
    });
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        filterProject: '',
        target: '',
        confirmPass: '',
        status: '',
        isEdit: false
    })

    const [btnLoading, setBtnLoading] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [selectedTickets, setSelectedTickets] = useState([]);

    useEffect(() => {
        getTrashTickets();
    }, [state.page, searchType])

    useEffect(() => {
        if (action == 'Delete') {
            setShowPopUp('delete')
        } else if (action == 'Restore') {
            setShowPopUp('restore')
        }
    }, [action])

    useEffect(() => {
            if (checkAll) {
                let data = state.data.map((val)=>val._id);
                setSelectedTickets(data);
                displayButtons(data.length);
            } else {
                setSelectedTickets([]);
                displayButtons(0);
            }
    }, [checkAll])

    
  useEffect(()=>{
   
        if (state.data?.length > 0) {
            if (state.data?.length == selectedTickets?.length) {
                chackAllAction(true)
            } else {
                chackAllAction(false)
            }
        }
    
},[selectedTickets])

    const getTrashTickets = () => {
        setMyState(setState, { ...state, userLoading: true });
        let { limit, page, keyword, status } = state;
        let data = { limit, page: keyword ? 1 : page, searchTerm:searchType ? mediaKeyWord : keyword, last_modified : 1 };


        hitApi({
            url: `pixa-support/api/Trashed_tickets`,
            method: "POST",
            data: data,
            alert: false,
        }, (resp) => {
            setMyState(setState, { ...state, userLoading: false });
            if (resp.status) {

                setMyState(setState, {
                    ...state,
                    data: resp.data,
                    userLoading: false,
                    totalRecords: resp.totalRecords
                });
            }
        });
    }


    const handlePageChange = (pageNumber) => {
        setMyState(setState, (prev) => ({ ...prev, page: pageNumber }));
    };

    const handleSelectTickets = (val, ticket) => {
      
        if (val) {
            setSelectedTickets(curr => [...curr, ticket])
            displayButtons([...selectedTickets, ticket].length); 
        } else {
            const arrCopy = Array.from(selectedTickets);
            const objWithIdIndex = arrCopy.findIndex((obj) => obj === ticket);
            arrCopy.splice(objWithIdIndex, 1);
            setSelectedTickets(arrCopy);
            displayButtons(arrCopy.length);
        }
    }



    let cntStart = ((state.page - 1) * state.limit) + 1, cnt = cntStart;



    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper">
                <div className="sch-container-fluid">


                    <div className="pxl-count-cards">
                        <div className="pxl_container">

                            <div className="pxl_grid">

                                <div className="pxl_table table-responsive support_table">
                                    {

                                        <table className="pxl_table">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Issue Title</th>
                                                    <th>Description</th>
                                                    {/* <th>Project</th> */}
                                                    <th>Status</th>
                                                    <th>Project</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!state.userLoading ?
                                                    state.data.length > 0 ?
                                                        state.data.map((item, index) => {

                                                            return (
                                                                <tr
                                                                    key={index}
                                                                >
                                                                    {/* <td>{state.page == 1 ? (index + 1 ) :   (index + 1 ) + ((state.page - 1) * state.limit)}</td> */}
                                                                    <td>
                                                                        <div className="datatable_flex" style={{ justifyContent: 'flex-start' }}>
                                                                           <div className="cun-AllChecknoxSelect">
                                                                                <div className="sch-custom-check-box ">
                                                                                    <div className="form-group">
                                                                                        <input type="checkbox" id={`ticket${index}`}
                                                                                            // disabled={(containsWrite || containsDelete) ? false : true}
                                                                                            checked={selectedTickets?.some((id) => id == item._id)}
                                                                                            onChange={(e) => handleSelectTickets(e.target.checked, item._id)}
                                                                                        />
                                                                                        <label htmlFor={`ticket${index}`}></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="tab_name_wrap">
                                                                            <div>
                                                                                {
                                                                                    item?.customer?.profile_image ? <img src={item?.customer?.profile_image} /> : <span>{getNameInitials(item?.customer?.name)}</span>
                                                                                }

                                                                            </div>
                                                                            <p>{item?.customer?.name}</p>
                                                                        </div></td>

                                                                    <td>
                                                                        <div>
                                                                            <p>{item?.subject}</p>
                                                                        </div>

                                                                    </td>
                                                                    <td>
                                                                        <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                                                    </td>

                                                                    {/* <td>
                                                        {item?.project?.project_name}
                                                    </td> */}
                                                                    <td>{
                                                                        item?.status?.name ?<div className="pxl_status"
                                                                        style={{ backgroundColor:item?.status?.name ? hexToRgbA(item?.status?.color, 0.1):'', color: item?.status?.color }}
                                                                    >
                                                                        {item?.status?.name}
                                                                    </div>:<div className="ms-4">-</div>
                                                                        }
                                                                        
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <p>{item?.project?.name}</p>
                                                                        </div>

                                                                    </td>

                                                                    <td>
                                                                        <div className="datatable_flex">
                                                                            <div className="PxlRestore-trash-tooltip cun-iconTooltip">
                                                                                <button className={`pxl_tableIcon`}
                                                                                    onClick={() => { setShowPopUp('restore'); setSelectedTickets([item?._id]) }}
                                                                                >{svg.tickets_restore}
                                                                                </button>
                                                                                <div className="cun-show-tooltip">
                                                                                        <p>{'Restore'}</p>
                                                                                    </div>
                                                                            </div>
                                                                            <div className="PxlDelete-trash-tooltip cun-iconTooltip">
                                                                                <button className={`pxl_tableIcon`}
                                                                                    onClick={() => { setShowPopUp('delete'); setSelectedTickets([item?._id]) }}
                                                                                >{svg.deleteWhiteSvg}
                                                                                </button>
                                                                                <div className="cun-show-tooltip">
                                                                                        <p>{'Delete'}</p>
                                                                                    </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })

                                                        :
                                                        (state.keyword) ? <NoDataFoundDataTable column={8} statement="You don't have agent with this name." /> : <NoDataFoundDataTable column={8} statement="No tickets found." />
                                                    :
                                                    <tr ><td colSpan={8}>{skeletonLoader('table')}</td></tr>
                                                }




                                            </tbody>
                                        </table>

                                    }
                                </div>

                                <Pagination
                                    type="User"
                                    dataRange={{
                                        start: state.totalRecords ? cntStart : 0,
                                        end: state.totalRecords ? cntStart + state.data.length - 1 : 0,
                                    }}
                                    currentPage={state.page}
                                    totalRecords={state.totalRecords}
                                    perPage={state.limit}
                                    isLoading={state.userLoading}
                                    onClick={(pageNum) => {
                                        setMyState(setState, {
                                            page: pageNum,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <ConfirmationPopup
                shownPopup={showPopUp === 'delete'}
                closePopup={() => {
                    setShowPopUp(false);
                    setSelectedTickets([]);
                    updateAction('');
                    displayButtons(0);
                }}
                type={"ticket"}
                title={`Are you sure you want to delete ${selectedTickets.length} ${selectedTickets.length > 1 ? "tickets" : "ticket"}?`}

                yesBtn={"Yes, Delete"}
                btnLoader={btnLoading}

                removeAction={() => {
                    let data = {}

                    data['ticket_id'] = selectedTickets
                     setBtnLoading(true);
                    hitApi({
                        url: 'pixa-support/api/delete_ticket',
                        method: "POST",
                        data: data,
                    }, (resp, err = null) => {
                        setShowPopUp(false);
                        setSelectedTickets([]);
                        setBtnLoading(false);
                        if (resp.status) {
                            getTrashTickets();
                        }

                        updateAction('');
                        displayButtons(0);

                    })
                }}
            />


            <ConfirmationPopup
                shownPopup={showPopUp === 'restore'}
                closePopup={() => {
                    setShowPopUp(false);
                    setSelectedTickets([]);
                    updateAction('');
                    displayButtons(0);
                }}
                type={"ticket"}
                title={`Are you sure you want to restore ${selectedTickets.length} ${selectedTickets.length > 1 ? "tickets" : "ticket"} ?`}
                subTitle={'This item will be restore immediately.'}
                yesBtn={"Yes, Restore"}
                btnLoader={btnLoading}

                removeAction={() => {
                    let data = { restore: 1 }
                  
                    data['ticket_id'] = selectedTickets;
                  
                    setBtnLoading(true);
                    hitApi({
                        url: `pixa-support/api/ticket_trash`,
                        method: "POST",
                        data: data,
                    }, (resp, err = null) => {
                        setShowPopUp(false);
                        setSelectedTickets([]);

                        setBtnLoading(false);
                        if (resp.status) {
                            getTrashTickets()
                        }
                        updateAction('');
                        displayButtons(0);

                    })
                }}
            />
        </>
    )
};

export default TrashTickets;