import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import SeoSetting from './SeoSetting';
import DomainSetting from './DomainSetting';
import SupportSetting from './SupportSetting';
import { NoDataFound, commonToast, hitApi, teamMemberRestrictionMess } from "../../components/commonAction";
import { useStore } from "../../zustand/store";
import { useNavigate,Link ,useLocation} from 'react-router-dom';



function BuilderSetting({ pageTitle }) {

    const [activeMenu, setActiveMenu] = useState('domain');
    const searchParams = new URLSearchParams(window?.location?.search);
    const p_id = searchParams.get('id');
    const projName = searchParams.get('name');
    const [isError, setIsError] = useState(false);
    const [resData, setResData] = useState();
    const [seoTitle, setSeoTitle] = useState();
    const [seoDescription, setSeoDescription] = useState();
    const [faviconFile, setFaviconFile] = useState();
    const [logoFile, setLogoFile] = useState();
    const [author, setAuthor] = useState();
    const [keyword, setKeyword] = useState();
    const [domain, setDomain] = useState();
    const [support, setSupport] = useState();
    const [projectList, setProjectList] = useState([]);



    let userData = useStore(state => state)['userData'];
    let { role, scope } = userData;


    useEffect(() => {
        getsupportProjects();
    }, [support])
    useEffect(() => {
        getProjects();
    }, [])


    const getProjects = () => {
        let data = []

        hitApi({
            url: 'page-builder/get-projects',
            method: "GET",
            data: data,
            loading: true,
            alert: false
        }, (resp, err = null) => {
            if (resp.status == true) {
                setResData(resp)
                resp.data.records.map((item) => {
                    if (p_id == item._id) {
                        setSeoTitle(item?.title);
                        setSeoDescription(item?.meta?.description);
                        setFaviconFile(item?.meta?.favicon);
                        setLogoFile(item?.meta?.logo);
                        setAuthor(item?.meta?.author)
                        setKeyword(item?.meta?.keywords)
                        setDomain(item?.domain)
                        setSupport(item?.supportId)


                    }
                })
                setIsError(false)
            } else {
                setIsError(true)
            }
        });


    }


    const getsupportProjects = () => {
        let data = []

        hitApi({
            url: 'pixa-support/api/getProjects',
            method: "POST",
            data: {
                // page: page,
                // listPerPage: listPerPage,
                // searchTerm: search,
                userId: userData.userId,
                // userId:  '6593b8c8867874b7407d4e67'
            },
            loading: true,
            alert: false
        }, (resp, err = null) => {
            if (resp.status == 1) {

                // let data = resp.data.map(item => ((item._id==support)? { value: item._id, label: item.project_name }:""))
                // data.unshift({ value: "", label: "Select" })
                // setProjectList(data)


                let d1 = []
                resp?.data?.map((i) => {
                    if (i._id == support) {
                        let d2 = {
                            value: i._id,
                            label: i.project_name
                        }
                        d1.push(d2)
                    }
                })
                setProjectList(d1)
                setIsError(false)
            } else {
                setIsError(true)
            }
        });


    }


   
    const setActiveTabMenu=(item)=>{
        setActiveMenu(item)
    }


    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper sch-nav-open">
                <div className="sch-container-fluid project_setting_wrap">
                    <div className="support_tabs_wrapper pxlBuilderTabs">

                        <div className='pxlBack-btn'>
                            <Link to={`/builder/projects`} className="pxl-backBtn-round"><span><img src="/assets/images/backArrowBlack.svg" alt="back"/></span>{'Back to Projects '}</Link>
                        </div>
                        <div className="support_tab_inner" >
                            <ul className="menu ">

                                <li className={activeMenu == 'domain' ? "active" : ''}>
                                    <a href="#" onClick={() => setActiveMenu('domain')} >
                                        Domain
                                    </a>
                                </li>
                                <li className={activeMenu == 'project' ? "active" : ''}>
                                    <a href="#" onClick={() => setActiveMenu('project')} >
                                        SEO Settings
                                    </a>
                                </li>
                                <li className={activeMenu == 'Support' ? "active" : ''}>
                                    <a href="#" onClick={() => setActiveMenu('Support')} >
                                        Support
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {
                        activeMenu == 'project' ? <SeoSetting setActiveTabMenu ={setActiveTabMenu}  p_id={p_id} projName={projName} r_Resdata={resData} t_seoTitle={seoTitle} d_seoDescription={seoDescription} f_faviconFile={faviconFile} l_logoFile={logoFile} a_author={author} k_keyword={keyword} /> :
                            activeMenu == 'domain' ? <DomainSetting setActiveTabMenu ={setActiveTabMenu} p_id={p_id} d_domain={domain} /> :
                                activeMenu == 'Support' ? <SupportSetting setActiveTabMenu ={setActiveTabMenu} p_id={p_id} s_support={projectList} /> :
                                    null

                    }
                </div>

            </div>
        </>

    )
}

export default BuilderSetting