import { useStore } from "../zustand/store";
import NoPage from "./404";

const PrivateRoute = ({ children, allowedRoles, apiendpoint, allowedscope, checkscope = false }) => {
  let { scope, role } = useStore(state => state.userData);

  // {images: {…}, support: {…}, scheduler: {…}}
  // images
  // : 
  // {images: Array(3)}
  // scheduler
  // : 
  // {schedule: Array(1)}
  // support
  // : 
  // {add project: Array(1), ticket reply: Array(1)

  // if (allowedRoles?.map(i=>i==role)) {

  if (role == "teamMember" && allowedscope && checkscope) {
    if (scope.hasOwnProperty(allowedscope)) {
      let platform = scope[allowedscope];
      if (allowedscope == 'images') {
        if(platform['images'].includes(checkscope)) return <>{children}</> 
         else return (<NoPage />)
      }
      else if (allowedscope == 'scheduler') {
        if(platform['schedule'].includes(checkscope)) return <>{children}</>
          else return <>(<NoPage />)</>
      } else if (allowedscope == 'support') {
        if (platform['ticket reply'].includes(checkscope)) return <>{children}</> 
         else return (<NoPage />)
      } else {
        return (<NoPage />)
      }
    }
    else return (<NoPage />)
  }
  else if (process.env.REACT_APP_APP_MODE == 'live' && role == 'user' && apiendpoint) {
    return (<NoPage />)
  }
  else if (allowedRoles.includes(role)) {
    return (
      <>{children}</>
    );
  } else {
    // window.location.href='/dashboard' ;
    return (<NoPage />)
  }
};

export default PrivateRoute;

export const TeamMemberScope = ({ children, allowedscope, excludeheader = false }) => {
  let { scope, role } = useStore(state => state.userData);

  if (role != "teamMember") {
    return (
      <>
        {children}
      </>
    );
  }
  else if (scope.hasOwnProperty(allowedscope)) {
    return (
      <>
        {children}
      </>
    );
  } else if (excludeheader) {
    return <></>
  } else {
    return (<NoPage />)
  }
};
