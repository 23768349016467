import { useEffect, useRef, useState } from 'react'

import { SketchPicker, HuePicker, TwitterPicker } from 'react-color';


function ColorPicker({ handleColorTheme, colorVal }) {
  
    const [colorTheme, setColorTheme] = useState(colorVal || '#039df4');
    const materialColors = ['#EF5350', '#EC407A', '#AB47BC', '#7E57C2', '#5C6BC0', '#42A5F5', '#29B6F6', '#26C6DA', '#26A69A', '#66BB6A', '#9CCC65', '#D4E157', '#FFEE58', '#FFCA28', '#FFA726', '#FF7043', '#8D6E63', '#78909C'];
    const pickerRef = useRef(null);
    const containerRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [pickerPosition, setPickerPosition] = useState({});

    useEffect(()=>{
        if(colorVal){
            setColorTheme(colorVal);
        }
    },[colorVal])

    useEffect(() => {
        if (isOpen) {
          const containerRect = containerRef.current.getBoundingClientRect();
          const windowHeight = window.innerHeight;
    
          let top = containerRect.bottom;
          let left = containerRect.left;
          
          if(windowHeight - top > 370){
            top = '100%';
            left = 0;
          }else{
            top = '-395px';
            left = 0;
          }
          setPickerPosition({ top, left });
        }
      }, [isOpen]);

    /* color picker start */

    const isWhiteShade = (rgb) => {
        const threshold = 240; 
        return rgb.r >= threshold && rgb.g >= threshold && rgb.b >= threshold;
    };

  
    const colorPickerChange = (type, color) => { 
        if (color.hex && !isWhiteShade(color.rgb)) {
            var color = color.hex;
            setColorTheme(color);
            handleColorTheme(color);
        }
    }

    const colorPickerComplete = (type, color) => { 
        if (color.hex && !isWhiteShade(color.rgb)) {
            var color = color.hex;
            setColorTheme(color);
            handleColorTheme(color)
        }
    }
    const handleToggle = () => {
        setIsOpen(true);
    };

    const handleClickOutside = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, []);
    /* color picker end */
    return (
        <div className="pu_color_picker_wrapper" ref={containerRef}>
            <div className="pu_color_picker_toggle" onClick={handleToggle} >
                <span className=" pu_color_preview" style={{ backgroundColor: colorTheme }}></span>
                <span className="pu_color_name">{colorTheme}</span>

            </div>
            
            {isOpen && <div className="pu_color_picker_dropdown" ref={pickerRef} style={{ position: 'absolute', top: pickerPosition.top, left: pickerPosition.left }}>
                <SketchPicker
                    color={colorTheme}
                    onChange={(color) => colorPickerChange('color_theme', color)}
                    onChangeComplete={(color) => colorPickerComplete('color_theme', color)}
                />
                <HuePicker
                    color={colorTheme}
                    onChange={(color) => colorPickerChange('color_theme', color)}
                    onChangeComplete={(color) => colorPickerComplete('color_theme', color)}
                    width={276}
                />
                <TwitterPicker
                    color={colorTheme}
                    onChange={(color) => colorPickerChange('color_theme', color)}
                    onChangeComplete={(color) => colorPickerComplete('color_theme', color)}
                    width={276}
                    colors={materialColors}
                />
            </div>}
        </div>
    )
}

export default ColorPicker