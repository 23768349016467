import { Outlet, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import Title from "./components/Title";
import './css/editor.css'
import { useState, useEffect, useRef } from "react";
import { NewDesignPopUp } from "../scheduler/components/SchedulePopUp";
import { NoDataFound, Pagination, assetsUrlPath, setMyState, skeletonLoader, teamMemberRestrictionMess, useOutsideClick } from "../../components/commonAction";
import { hitApi } from "../../components/commonAction";
import svg from "../../components/svg";
import Select from "react-select";

import { useStore } from "../../zustand/store";

const TemplateEvent = [
    {
        id: 1,
        img: '/assets/images/templates/event/template1.jpg',
        text: 'birthday',
    },
    {
        id: 2,
        img: '/assets/images/templates/event/template1.jpg',
        text: 'Event',
    },
    {
        id: 3,
        img: '/assets/images/templates/event/template1.jpg',
        text: 'Hiring',
    },
    {
        id: 4,
        img: '/assets/images/templates/event/template1.jpg',
        text: 'Party',
    },
    {
        id: 5,
        img: '/assets/images/templates/event/template1.jpg',
        text: 'Corporate',
    },
    {
        id: 6,
        img: '/assets/images/templates/event/template1.jpg',
        text: 'Restaurant',
    },
    {
        id: 7,
        img: '/assets/images/templates/event/template1.jpg',
        text: 'Kids',
    },
    {
        id: 8,
        img: '/assets/images/templates/event/template1.jpg',
        text: 'Event',
    }
]

const Dashboard = ({ pageTitle }) => {

    const param = new URLSearchParams(window.location.search);
    const type = param.get('type');

    const [state, setState] = useState({
        limit: 12,
        page: 1,
        keyword: '',
        sort: 'createdAt=-1',
        showNewDesignPopUp: false,
        useTemplateId: '',
        category: [],
        categoryLoading: false,
        categoryId: '',
        subcategoryId: '',
        clearKeyword: false,
        subCategoryLoading: false,
        assetLoading: false,
        templateType: { value: 'all', label: 'Select Templates' },
    })

    const [sublistState, setSublistState] = useState({ 'all': true });
    const [subCategory, setSubCategory] = useState([]);
    const [totalRecords, setTotalRecords] = useState('0');
    const [assetsList, setAssetsList] = useState([]);

    let store = useStore(state => state);
    let { name, role, scope } = store.userData;
    const containsImagesWrite = (role === "teamMember") ? scope?.images?.images?.includes("write") : (role === 'client') ? false : true;


    const toggleSublist = (id) => {

        setSublistState(prevState => ({
            //   ...prevState,
            // [id]: !prevState[id]
            [id]: true
        }));
    };

    const handleSelect = (eventKey) => {

        if (eventKey == 'all') {
            setMyState(setState, { ...state, categoryId: '', subcategoryId: '', page: 1, keyword: '', templateType: { value: 'all', label: 'Select Templates' }, });
        } else {
            const foundObject = state.category?.find(item => item._id === eventKey);

            if (foundObject?.subCat.length > 0) {
                setMyState(setState, { ...state, templateType: { value: 'all', label: 'Select Templates' }, categoryId: eventKey, page: 1, subcategoryId: foundObject.subCat.length > 0 && foundObject.subCat[0]._id, keyword: '' });
            }
            else {
                setMyState(setState, { ...state, templateType: { value: 'all', label: 'Select Templates' }, categoryId: eventKey, page: 1, subcategoryId: '', keyword: '' });
            }
        }
    };

    useEffect(() => {
        getCategory();
    }, []);

    const options = [
        { value: 'all', label: 'Select Templates' },
        { value: 'trending', label: 'Trending' },
        { value: 'featured', label: 'Featured' }
    ]

    useEffect(() => {
        if (type) {
            let selectValueTemplateype ;
            if(type == 'trending')
            {
                selectValueTemplateype = { value: 'trending', label: 'Trending' }
            }else {
                selectValueTemplateype = { value: 'featured', label: 'Featured' }
            }
            setMyState(setState, { templateType: selectValueTemplateype });
        }
    }, [type])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [state.categoryId]);

    useEffect(() => {
        getAssets();
    }, [state.page, state.templateType, state.categoryId, state.subcategoryId, state.clearKeyword])



    const clearSearch = () => {
        setMyState(setState, { ...state, clearKeyword: !state.clearKeyword, keyword: '' });
    }

    const getCategory = () => {
        let { limit, page, sort } = state;
        setMyState(setState, { categoryLoading: true, });
        hitApi({
            url: 'image/template/get-template-category',
            method: "GET",
            data: { limit: 'all', page, sort }
        }, (resp, err = null) => {
            setMyState(setState, { categoryLoading: false, });
            if (resp.status) {
                setSubCategory(resp.data.records);
                getSubCategory(resp.data.records);
            }
        });
    }

    const getSubCategory = (categoryData) => {
        let { limit, page, sort } = state;
        hitApi({
            url: 'image/template/get-template-sub-category',
            method: "GET",
            data: {
                limit: 'all', page, sort,

            }
        }, (resp, err = null) => {

            if (resp.status) {
                const subcategoryMap = {};
                resp.data.records.forEach((sub) => {
                    if (!subcategoryMap[sub.parentId]) {
                        subcategoryMap[sub.parentId] = [];
                    }
                    subcategoryMap[sub.parentId].push(sub);
                });

                const newArray = categoryData.map((cat) => ({
                    ...cat,
                    subCat: subcategoryMap[cat._id] || [],
                }));

                setMyState(setState, {
                    category: newArray,
                    categoryLoading: false,
                });

            }
        });

    }

    const getAssets = (type = null) => {

        let cPage = type == 'loadMore' ? state.page + 1 : state.page;
        let data = { limit: state.limit, page: state.keyword ? 1 : cPage, keyword: state.keyword, sort: state.sort };

        data['category'] = state.categoryId;
        data['subCategory'] = state.subcategoryId;

        if (state.templateType.value != 'all') {
            if (state.templateType?.value == 'trending') {
                data.isTrending = true;
            }
            if (state.templateType.value == 'featured') {
                data.isFeatured = true;
            }
        }

        setMyState(setState, { assetLoading: true });

        hitApi({
            url: 'image/template/get-image-template',
            method: "GET",
            data: data
        }, (resp, err = null) => {
            setMyState(setState, { assetLoading: false, });
            if (resp.status) {
                setTotalRecords(resp.data.totalRecords)

                let newList = type == 'loadMore' ? [...assetsList, ...resp.data.records] : resp.data.records;
                setAssetsList(newList);
            }
        });

    }
    useEffect(() => {
        document.body.classList.add('pxl-body-white');
        return () => {
            document.body.classList.remove('pxl-body-white');
        };
    }, []);
    

    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>

            {/* Main Wrapper  */}
            <main className="sch-main-wrapper">

                {/* <div className="pxl_sidebarMenu">
                    <button onClick={() => logoutMe()}>Logout</button>
                    <div className="pxl_logo">
                        <Link href={()=> false} className="pxl_mainLogo">
                            <img src={process.env.appLogo} alt="Logo"/>
                        </>
                    </div>
                </div> */}


                {/* Banner Start  */}
                <div className="sch-banner-wrapper">
                    <div className="sch-container-fluid">
                        <div className="sch-banner-box pxl-dashboardBanner-box">
                            <div className="sch-banner-content">
                                <h2>Customizable Templates for Every Niche You Desire</h2>
                                <p>Select from  Collection of 12,000 Innovative Templates</p>
                                <div className="sch-search-box sch-template-searchBox">
                                    <div className="sch-search-row">
                                        {/* <select onChange={(e) => setMyState(setState, {
                                            templateType: e.target.value
                                        })}
                                            value={state.templateType}
                                        >
                                            <option value={'all'}>Select Templates</option>
                                            <option value={'trending'}>Trending</option>
                                            <option value={'featured'}>Featured</option>
                                        </select> */}
                                        <div className="sch-banner-select-search-box">
                                            <div className="cun-headreSelect">
                                                <Select isSearchable={false}
                                                // menuIsOpen={true}
                                                value={state.templateType} 
                                                onChange={(options) => {
                                                    setMyState(setState, {
                                                        templateType: options
                                                    })}
                                                }
                                                options={options} 
                                                />
                                            </div>
                                            <div className="sch-banner-select-search-input">
                                                <input type="text" value={state.keyword} placeholder="Search Template" onChange={(e) => setMyState(setState, {
                                                    keyword: e.target.value
                                                })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                                                            getAssets();
                                                        }
                                                    }} />
                                            </div>
                                        </div>
                                        <button type="button" className="sch-search-btn">
                                            {!state.keyword ? <span> <img src="/assets/images/scheduler/search.svg" /></span> : <span className="cun-inputCross" onClick={clearSearch}>{svg.app.crossSvg}</span>}

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Trending Templates Section */}
                <div className="pxl-images-templateMain-wrapper-box">
                    <div className="sch-templates-main-wrapper">
                        <div className="sch-container-fluid">
                            <div className="sch-tempalte-innerwrap">
                                <div className="sch-template-left-category">
                                    <div className="sch-templateHeading">
                                        <h2>Categories</h2>
                                    </div>
                                    <div className="sch-template-category">
                                        <ul>
                                            {!state.categoryLoading &&
                                                <div className="sch-categoryInnerDiv">

                                                    <li id={'all'} onClick={() => { toggleSublist('all'); handleSelect('all') }}>
                                                        <div className={`main ${sublistState['all'] ? 'cun-opensub-category' : ''}`}>
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault()
                                                            }}><p>All Categories</p>  </a>
                                                        </div>
                                                    </li>
                                                </div>}
                                            {!state.categoryLoading ? state?.category.length > 0 ? state?.category.map((category, i) =>
                                                <div className="sch-categoryInnerDiv" key={i}>
                                                    <li id={category._id} onClick={() => { sublistState[category._id] ? toggleSublist('') : toggleSublist(category._id); handleSelect(category._id) }}>

                                                        <div className={`main ${sublistState[category._id] ? 'cun-opensub-category' : ''}`}>

                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault()
                                                            }}><p>{category.title}</p>  {(category?.subCat.length <= 0) ? '' : <span><img src="/assets/images/rightArrow.svg" alt="" /></span>}
                                                            </a>

                                                        </div>
                                                    </li>
                                                    <ul className={`sch-templateSubcategory-ul ${sublistState[category._id] ? 'cun-opensub-category' : ''} `}>
                                                        {!state.subCategoryLoading ? (category?.subCat.length > 0) ? category?.subCat.map((val, i) =>

                                                            <li onClick={() => setMyState(setState, { subcategoryId: val._id })} key={i} className={`schSubmenuActive ${state.subcategoryId == val._id ? "active" : ''}`}>
                                                                <a href="#" onClick={(e) => {
                                                                    e.preventDefault()
                                                                }}><p>{val.title}</p></a>
                                                            </li>

                                                        )
                                                            :
                                                            //    <div>No category found.</div>
                                                            <></>
                                                            :
                                                            <div>{skeletonLoader('list', 2)}</div>
                                                        }
                                                    </ul>
                                                </div>

                                            ) :
                                                <></>
                                                :
                                                <div>{skeletonLoader('list', 4)}</div>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="sch-template-categoryImg">
                                    <div className="sch-templaterow1">
                                        {/*
                                            TemplateEvent.slice(0, 8).map(item => (
                                                <div className="sch-template-box sch-template-eventbox" key={item.id}>
                                                    <div className="sch-template-img">
                                                        <img src={item.img} alt="" />
                                                    </div>
                                                    <a className="sch-edit-temp-btn">{item.text}</a>
                                                </div>
                                            ))
                                        */}
                                    </div>

                                    <div className="sch-section-mansory">
                                        <ResponsiveMasonry
                                            columnsCountBreakPoints={{ 380: 1, 480: 1, 580: 2, 767: 2, 991: 3, 1200: 4, 1424: 5 }}
                                        >
                                            <Masonry gutter={"20px"}>
                                                {state.assetLoading ? [...Array(12)].map((i) => <div key={i}>{skeletonLoader('asset', 1, '100%', 200, i)}</div>) :
                                                    assetsList.length > 0 && assetsList.map((asset, i) => (
                                                        <div className="sch-template-box" key={asset._id}>
                                                            <div className="sch-template-img">
                                                                <img src={asset?.files?.original?.thumb ? assetsUrlPath(asset.files.original.thumb) : '/assets/images/default-draft-img.jpg'} alt="" />
                                                                <div className={`pxl-useTemplate-btn-wrap ${containsImagesWrite ? "" : "pxl-use-template-disable"}`}>
                                                                    <button className={`sch-edit-temp-btn cun-socialIcon`} disabled={!containsImagesWrite} onClick={() => setMyState(setState, { showNewDesignPopUp: true, useTemplateId: asset._id })}>Use Template  {!containsImagesWrite && <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(containsImagesWrite)}</p></div>}</button>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    ))
                                                }
                                            </Masonry>
                                        </ResponsiveMasonry>

                                    </div>

                                    {
                                        assetsList.length >= state.limit && assetsList.length < totalRecords &&
                                        <div className="loadmorebtn">
                                            <a
                                                className={`sch-loadmoreBtn ${assetsList.length >= totalRecords ? 'pxl-button-disable' : ''}`}
                                                onClick={() => {
                                                    getAssets('loadMore');
                                                }}
                                            >{state.isLoading ? <span>Loading...</span> : <span>Load More</span>}</a>
                                        </div>
                                    }
                                    {
                                        !state.assetLoading && assetsList.length == 0 && <NoDataFound title="No template found for this category." />
                                    }



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <NewDesignPopUp
                shownPopup={state.showNewDesignPopUp}
                closePopup={() => setMyState(setState, { showNewDesignPopUp: false })}
                templateId={state.useTemplateId}
            />

        </>
    )
};

export default Dashboard;