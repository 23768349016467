// import svg from "../../helper/svg";

const Alert = () => {
    return (
        // <div className="xs_alert_wrapper">
        //     <p className="xs_alert_inner">
        //         <span className="xs_alert_icon error">{svg.alert_error}</span>
        //         <span className="xs_alert_icon success">{svg.alert_success}</span>
        //         <span className="xs_alert_content">
        //             <span className="xs_alert_title"></span>
        //             <span className="xs_alert_message"></span>
        //         </span>
        //         <span className="xs_alert_close"></span>
        //     </p>

        <div className="pxl-notication-section">
            <div className="pxl-notification-content">
                <img src="/assets/images/grt-success.svg" alt="success" className="pxl-notiSuccess" />
                <img src="/assets/images/noti-error.svg" alt="success" className="pxl-notiError" />
            </div>
            <div className="pxl-success-notification ">
                <h2 className='pxl_notification_title'></h2>
                <p className='pxl_notification_message'></p>
            </div>
            <div className="pxl-error-notification">
                <h2 className='pxl_notification_title'></h2>
                <p className='pxl_notification_message'></p>
            </div>
            <div className="pxl-notification-cross">
                <a href="#" onClick={(e) => {
                    e.preventDefault()
                }}><img src="/assets/images/green-cross-svg.svg" alt="" className="pxl-succesCros" /></a>
                <a href="#" onClick={(e) => {
                    e.preventDefault()
                }}><img src="/assets/images/red-cross-svg.svg" alt="" className="pxl-errorCros" /></a>
            </div>
        </div>


    );
}

export default Alert;