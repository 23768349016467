

import { useEffect, useState, useRef } from "react";

import { useNavigate, Link, useParams } from 'react-router-dom';


import socket from '../../../socket'

import { commonToast, dateFormatter, getNameInitials, hitApi, hexToRgbA, useOutsideClick, assetsUrlPath, teamMemberRestrictionMess } from "../../../components/commonAction";
import svg from "../../../components/svg";

import { useStore } from "../../../zustand/store";
import { Tooltip } from "@mui/material";

import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import makeAnimated from 'react-select/animated';
// import ConfirmationPopup from "../../../components/common/ConfirmationPopup";

import MediaList from './MediaList'

// const options = [
//     { value: 'open', label: 'Open' },
//     { value: 'waiting', label: 'Waiting' },
//     { value: 'resolved', label: 'Resolved' }
// ]
// import axios from "axios";

const TicketsReply = () => {
    const { id, t_id } = useParams();
    const navigate = useNavigate()
    const editorRef = useRef(null);
    const bottomRef = useRef(null);
    let userData = useStore(state => state)['userData'];
    let { role, scope } = userData;
    const colorTheme = '#000';
    const img_path = `usercontent/`;

    const [ticketDetails, setTicketDetails] = useState('');
    const [ticketMessages, setTicketMessages] = useState([]);
    const [description, setDescription] = useState('');

    const [statusList, setStatusList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState();
    const [updateStatus, setUpdateStatus] = useState('')

    const [priorityList, setPriorityList] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState();
    const [updatepriority, setUpdatePriority] = useState('');


    const [btnLoader, setBtnLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(false)


    const [deleteShowPopUp, setDeleteShowPopup] = useState(false);

    const [attachmentFileURL, setAttachmentFileURL] = useState([]);
    const [attachFile, setAttachFile] = useState([]);

    const [recentTicketsList, setRecentTicketsList] = useState([]);
    const [showRecentTickets, setShowRecentTickets] = useState(false);
    const [showNoteSidebar, setShowNoteSidebar] = useState(false);

    // res toggle
    const [myTicketToggle, setMyTicketToggle] = useState(false);


    // Responsive small screen toggle sidebar
    const [showDetailSidebar, setShowDetailSidebar] = useState(false);
    const [editorData, setEditorData] = useState(false);

    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);

    const sidebarRef = useRef();
    let animatedComponents = makeAnimated();

    const containsSupportTicketWrite = (role === "teamMember") ? scope?.support ? scope?.support['ticket reply']?.includes("write") : true : (role === 'client') ? false : true;
    const containsSupportTicketDelete = (role === "teamMember") ? scope?.support ? scope?.support['ticket reply']?.includes("delete") : true : (role === 'client') ? false : true;

    useOutsideClick(sidebarRef, () => {

        setShowDetailSidebar(false);
       
    });
    
    const fetchTicketDetails = async (id) => {
        if (id) {
            setLoading(true)

            hitApi({
                method: 'POST',
                url: 'pixa-support/api/ticket_messages',
                data: {
                    ticket_id: id,
                },
                loading: true,
                alert: false
            }, (resp) => {
                if (resp.status === 1) {
                    setLoading(false);
                    fetchTicketStatus();
                    fetchTicketPriority(resp.ticketdetails?.priority_id);
                    setIsError(false);
                    setTicketMessages(resp.data);
                    setTicketDetails(resp.ticketdetails);
                    // setEditorData(resp.ticketdetails.description);
                    setSelectedStatus([{ value: resp.ticketdetails?.status_id?._id, label: resp.ticketdetails?.status_id?.name }]);

                    setSelectedPriority(resp.ticketdetails?.priority_id);

                    // resp.ticketdetails ? setUpdateStatus(typeof resp.ticketdetails.status_id._id === 'string' ? resp.ticketdetails.status_id._id.split(',') : resp.ticketdetails.status_id._id) : null;
                    // resp.ticketdetails ? setUpdatePriority(typeof resp.ticketdetails.priority_id === 'string' ? resp.ticketdetails.priority_id.split(',') : resp.ticketdetails.priority_id) : null;
                    if (resp.ticketdetails.customer_id) {
                        setShowRecentTickets(true);
                    }

                } else {
                    setIsError(true);
                    setLoading(false);
                }
            });
        }
    };

    useEffect(() => {
        if (showRecentTickets) fetchRecentTickets()
    }, [ticketDetails, id])

    useEffect(() => {
        socket.on('message-response', (data) => {
            if (data) {
                if (data.ticket_id == ticketDetails.ticket_id && data.customer_id == ticketDetails.customer_id._id) {
                    const newData = data
                    setTicketMessages([...ticketMessages, newData]);
                }
            }
        })
    }, [ticketMessages])

    const fetchTicketStatus = () => {
        hitApi({
            url: 'pixa-support/api/getAllTicketStatus',
            method: "POST",
            data: {
                // userId: (userData.role === process.env.roles.agency) ? userData.userId : userData.agency_id,
                userId: userData.userId,
            },
            alert: false
        }, (resp, err = null) => {
            if (resp?.status === 1) {
                let data = resp?.data?.length > 0 ? resp?.data.filter(val => val.status === 1).map(val => ({ value: val._id, label: val.name })) : [];
                setStatusList(data);
            }

        });
    }
    const clearConversation = () => {
        // setBtnLoader(true)
        hitApi({
            url: 'pixa-support/api/delete_ticket_reply',
            method: "POST",
            data: {
                ticket_id: ticketDetails.ticket_id,
            },
            alert: true
        }, (resp, err = null) => {
            // setBtnLoader(false)
            if (resp.status === 1) {
                fetchTicketDetails(t_id);
                setDescription('');
                setAttachmentFileURL([]);
                setAttachFile([]);
                // setDeleteShowPopup(false)
            }
        });
    }


    const fetchTicketPriority = (priority_id) => {
        hitApi({
            url: 'pixa-support/api/getAllTicketPriority',
            method: "POST",
            data: {
                // agency_id: userData.role === process.env.roles.agency ? userData.user_id : userData.agency_id,
                userId: userData.userId,
            },
            alert: false
        }, (resp, err = null) => {
            if (resp.status === 1) {
                let data = resp?.data?.length > 0 ? resp?.data?.map(val => ({ value: val._id, label: val.name })) : [];
                setPriorityList(data);
                setSelectedPriority(data?.map((i) => {
                    if (i.value == priority_id)
                        return i
                }))
            }
        });

    }

    const fetchRecentTickets = () => {
        if (id) {
            hitApi({
                url: 'pixa-support/api/recent_tickets',
                method: "POST",
                // data: {
                //     agency_id: userData.role === process.env.roles.agency ? userData.user_id : userData.agency_id,
                //     project_id: router.query.p_id,
                //     agent_id: ticketDetails.agent_id ? ticketDetails.agent_id._id : '',
                // customer_id: ticketDetails?.customer_id ? ticketDetails?.customer_id._id : '',
                // }
                data: {

                    userId: userData.userId,
                    project_id: id,
                    customer_id: ticketDetails.customer_id ? ticketDetails.customer_id._id : '',
                    //     agent_id: ticketDetails.agent_id ? ticketDetails.agent_id._id : '',
                },
                alert: false
            }, (resp, err = null) => {
                if (resp.status === 1) {
                    setRecentTicketsList(resp.data)
                }
            });
        }

    }

    useEffect(() => {
        // if (!router.isReady) return;
        if (userData.role !== 'admin') fetchTicketDetails(t_id);
    }, [t_id]);

    // }, [router.isReady, router.query.t_id]);


    // Update ticket Start
    const handleUpdateStatus = (option) => {
        hitApi({
            url: 'pixa-support/api/update_ticket_status',
            method: "POST",
            data: {
                ticket_id: ticketDetails._id,
                status_id: option.value
            },
            alert: true
        }, (resp, err = null) => {
            if (resp.status === 1) {
                // setPriorityList(resp.data);
            }
        });

    }

    const handleUpdatePriority = (option) => {


        hitApi({
            url: 'pixa-support/api/update_ticket_priority',
            method: "POST",
            data: {
                ticket_id: ticketDetails._id,
                priority_id: option.value
            },
            alert: true
        }, (resp, err = null) => {

        });


    }
    // Update ticket end 

    const handleReply = () => {
        setIsLoading(true)
        const formData = new FormData();

        formData.append('ticket_id', ticketDetails.ticket_id);
        formData.append('customer_id', ticketDetails.customer_id._id);
        formData.append('subject', ticketDetails.subject);
        formData.append('message', editorData);


        if (userData.role == 'customer') {
            for (let i = 0; i < statusList.length; i++) {
                if (statusList[i].name == 'Open' || statusList[i].name == 'open') {
                    formData.append('status', statusList[i]._id)
                }
            }
        }
        if (attachFile.length >= 11) {
            commonToast("error", "Max limit is 10 files. Please remove some to continue.")
            setIsLoading(false)
            return
        }


        for (let i = 0; i < attachFile.length; i++) {
            formData.append('attachments', attachFile[i]);
        }

        if (editorData || attachFile.length !== 0) {

            hitApi({
                url: 'pixa-support/api/ticket_reply',
                method: "POST",
                data: formData,
                alert: true,
                isFormData: true
            }, (resp, err = null) => {
                if (resp.status === 1) {
                    setIsLoading(false)

                    // let url
                    // if (resp.signedurl.length > 0) {
                    //     for (let i = 0; i < resp.signedurl.length; i++) {
                    //         let blob = new Blob([attachFile[i]], { type: attachFile[i].type });

                    //         let options = {
                    //             headers: {
                    //                 'Content-Type': attachFile[i].type
                    //             }
                    //         }

                    // axios.put(resp.signedurl[i], blob, options)
                    // .then(async response => {
                    // })
                    // .catch(error => {
                    //     console.error('Error uploading file:', error.message);
                    // }); 
                    //     }

                    // }

                    fetchTicketDetails(t_id);
                    // setTimeout(() => {
                    setEditorData('');
                    setDescription('');
                    setAttachmentFileURL([]);
                    setAttachFile([]);
                    // }, 2000);
                }
            });

        } else {
            commonToast("error", "Message field can't be empty")
            setIsLoading(false)

        }

    }


    const handleClickReplyScroll = () => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // const attachmentFile = (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         if (file.type.includes('image/')) {
    //             const url = URL.createObjectURL(file);
    //             setAttachmentFileURL(current => [...current, url]);
    //             setAttachFile(current => [...current, file]);
    //         } else {
    //             commonToast('error', 'Only PNG and JPG are allowed!')

    //         }
    //     }
    // }

    const priorityNoOptionsMessage = () => {
        return "No priority found";
    };
    const customNoOptionsMessage = () => {
        return "No status found";
    };

    const attachmentFile = (event) => {
        const files = Array.from(event.target.files);
        files.forEach(file => {
            if (file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png') {
                const url = URL.createObjectURL(file);
                setAttachmentFileURL(current => [...current, url]);
                setAttachFile(current => [...current, file]);
            } else {
                commonToast("error", 'Only PNG and JPG are allowed!');
            }
        });
    };
    // 
    useEffect(() => {
        document.body.classList.add('pxl-body-white');
        return () => {
            document.body.classList.remove('pxl-body-white');
        };
    }, []);
    // DAte Formator
    function handleTime(time) {
        var now = new Date().getTime();
        var datetime = new Date(time).getTime();
        var days = Math.floor((now - datetime) / 1000 / 60 / (60 * 24));
        if (days === 0) {
            return "Today"
        } else {
            return (days + "  days ago")
        }
    }

    const agentNotesSidebarToggle = (val) => {
        if (val) setShowNoteSidebar(false);
    }

    const messageChange = (value, key) => {
        setDescription(value)
    }


    if (userData.role === -1 || isError) {
        // return <Errorpage />
    } else {
        return (
            <>
                <div className={(showDetailSidebar) ? "in xs_overlay" : ''}></div>

                <div className="xs_page_fixed_position">

                    <div className={"xs_pagetitle_wrapper " + 'pagetitle'}>
                        {/* <PageTitle title={ticketDetails.subject ? "#" + ticketDetails.ticket_id + "  " + ticketDetails.subject : 'Unknown Subject'}>
                            <a onClick={() => router.back()} className="xs_back_btn_round">{svg.back_arrow}</a>
                        </PageTitle> */}

                    </div>

                    <div className={"xs_pagetitle_wrapper " + "xs_head_flex " + 'pageSecondTitle'} >



                        <div className="xs_agent_fliterwraper xs_agent_flex ">


                            {/* priority */}
                            <div className="pxl-ticket-reply-mainWrap pxlTicketGenarate-wrap">
                                <div className="pxl-ticketReplyLetft">
                                    <div className="pxl-ticket-reply-wrap">
                                        <div className="pxl-ticket-reply-left">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault()
                                            }}><span className="pxl-ticket-reply-backIcon" onClick={() => navigate(`/pixa-support/projects/${id}`)}>
                                                    <img src="/assets/images/arrow-left-ticket.svg" alt="" />
                                                </span>
                                                <span className="pxl-ticket-reply-email-txt">{ticketDetails?.subject}</span></a>
                                        </div>
                                        <div className="pxl-ticket-reply-right">
                                            <div className="pxl-ticketRes1">
                                                <div className="pxl-sortedBy-ticket-sec pxl-prioritySelect">
                                                    <span>status</span>

                                                    <Select
                                                     isSearchable={false}
                                                        noOptionsMessage={customNoOptionsMessage}
                                                        options={statusList}
                                                        value={selectedStatus}
                                                        onChange={(option) => {
                                                            setSelectedStatus(option);
                                                            handleUpdateStatus(option)

                                                        }}
                                                        isDisabled={!containsSupportTicketWrite}
                                                    />
                                                </div>
                                                <div className="pxl-sortedBy-ticket-sec pxl-prioritySelect">
                                                    <span>priority</span>
                                                    <Select
                                                     isSearchable={false}
                                                        noOptionsMessage={priorityNoOptionsMessage}
                                                        options={priorityList}
                                                        value={selectedPriority}
                                                        onChange={(option) => {
                                                            setSelectedPriority(option)
                                                            handleUpdatePriority(option)
                                                        }}
                                                        isDisabled={!containsSupportTicketWrite}

                                                    />
                                                </div>
                                            </div>
                                            <div className="pxl-ticket-res2">
                                                <div className="pxl-ticket-replyBtn">
                                                    <button className="sch-btn-iconLeft" onClick={handleClickReplyScroll}>
                                                        <span><img src="/assets/images/white-reply-svg.svg" /></span>
                                                        Reply
                                                    </button>
                                                </div>
                                                {ticketMessages.length > 0 && <div className="pxl-ticket-reply-delete-Btn">
                                                    <Tooltip title={'Clicking this will clear all conversation history'} placement="top" arrow>

                                                        <div className='cun-socialIcon'>
                                                            <button className={`pxl-pink-btn-delete sch-btn-iconLeft ${containsSupportTicketDelete ? '' : "sch-btn-disbale"}`} disabled={!containsSupportTicketDelete} onClick={clearConversation}>
                                                                {/* <span><img src="/assets/images/delete-whitesvg.svg" /></span> */}
                                                                Clear Conversation
                                                            </button>
                                                            {!containsSupportTicketDelete && <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(containsSupportTicketDelete)}</p></div>}

                                                        </div>
                                                    </Tooltip>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pxl-ticketLect-content">
                                        <div className="pxl-ticket-reply-box">
                                            <div className="pxl-ticket-profileBox">
                                                <div className="pxl-ticket-img-text">
                                                    {(ticketDetails.customer_id) && (ticketDetails.customer_id.profile_image) ?
                                                        <img src={assetsUrlPath(ticketDetails.customer_id.profile_image)} alt='' />
                                                        : <h2 className="pxl-ticket-txt">{getNameInitials(ticketDetails?.customer_id ? ticketDetails.customer_id.name : '')}</h2>
                                                    }


                                                    <div className="pxl-ticket-userName">
                                                        <h2>{ticketDetails?.customer_id ? ticketDetails.customer_id?.name : ''}</h2>
                                                        <p>{dateFormatter(ticketDetails?.createdAt)}</p>
                                                    </div>
                                                </div>
                                                {ticketDetails.description  &&<MediaList editorData={ticketDetails.description } />}

                                                {/* {ticketDetails.description ? <div className="pxl-ticketReply" dangerouslySetInnerHTML={{ __html: ticketDetails.description }}></div> : <div></div>} */}


                                                <div className="psl-client-addImg">
                                                    <ul>
                                                        {
                                                            (ticketDetails.files) && ticketDetails.files.map((file, i) => {
                                                                return (

                                                                    <li key={i}>
                                                                        <Link target="_blank" to={assetsUrlPath(file)}>
                                                                            <img src={assetsUrlPath(file)} />
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            }

                                                            )
                                                        }

                                                    </ul>
                                                </div>
                                            </div>

                                        </div>



                                        {
                                            ticketMessages.map((mess, index) => {
                                                return (
                                                    <div key={index} className="pxl-ticket-reply-box">
                                                        <div className="pxl-ticket-profileBox">
                                                            <div className="pxl-ticket-img-text">
                                                                {(mess?.sender == "customer") ? (mess.customer_id?.profile_image) ?
                                                                    <img src={assetsUrlPath(mess?.customer_id?.profile_image)} alt='' /> : <h2 >{getNameInitials(mess.customer_id?.name ? mess.customer_id?.name : ticketDetails.customer_id?.name)}</h2> :
                                                                    (mess.userId?.profile_image) ?
                                                                        <img src={assetsUrlPath(mess.userId?.profile_image)} alt='' /> : <h2 >{getNameInitials(mess.userId ? mess?.userId?.name : '')}</h2>

                                                                }

                                                                <div className="pxl-ticket-userName">
                                                                    <h2>{mess?.userId ? mess?.userId?.name : mess?.customer_id?.name ? mess?.customer_id?.name : mess?.sender == "customer" ? ticketDetails?.customer_id?.name : ''}</h2>
                                                                    <p>{dateFormatter(mess.updatedAt)}</p>
                                                                </div>
                                                            </div>
                                                            {/* {mess.message ? <div className="pxl-ck-editor-list" dangerouslySetInnerHTML={{ __html: mess.message }}></div> : <div></div>} */}
                                                            {mess.message &&<MediaList editorData={mess.message} />}

                                                            <div className="psl-client-addImg">
                                                                <ul>
                                                                    {
                                                                        mess.files.map((file, i) => {
                                                                            return (

                                                                                <li key={i}>
                                                                                    <Link target="_blank" to={assetsUrlPath(file)}>
                                                                                        <img src={assetsUrlPath(file)} />
                                                                                    </Link>
                                                                                </li>
                                                                            )
                                                                        }

                                                                        )
                                                                    }

                                                                </ul>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }

                                        <div className="pxl-create-ticketBox">
                                            <div className="pxl_input_feilds pxl-placeholder-color1 pxl-ticket-editor" ref={bottomRef}>
                                                <label>Message <span className="pxl-star-red">*</span></label>
                                                <CKEditor
                                                    className="pxl-editorArea"
                                                    editor={ClassicEditor}
                                                    data={editorData}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setEditorData(data);
                                                    }}
                                                    config={{
                                                      
                                                        toolbar: [
                                                            'heading', '|',
                                                            'alignment', 'bold', 'italic', 'strikethrough', 'underline', 'code', '|',
                                                            'link', 'bulletedList', 'numberedList', 'todoList', '|',
                                                            'indent', 'outdent', '|',
                                                            // 'imageUpload',
                                                             'blockQuote', 'insertTable',
                                                            //   'mediaEmbed',
                                                               'htmlEmbed', '|',
                                                            'undo', 'redo', '|',
                                                            'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
                                                            'horizontalLine', 'pageBreak', 'removeFormat', 'specialCharacters', 'exportPdf', '|',
                                                            'sourceEditing'
                                                        ],
                                                        // image: {
                                                        //     toolbar: [
                                                        //         'imageTextAlternative', 'imageStyle:full', 'imageStyle:side'
                                                        //     ]
                                                        // },
                                                        table: {
                                                            contentToolbar: [
                                                                'tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'
                                                            ]
                                                        },
                                                        link: {
                                                            defaultProtocol: 'https://'
                                                        }
                                                    }}

                                                />
                                            </div>
                                            <p className="mt-3 mb-3"><span className="pxl-star-red">Note:- You can upload a maximum of 10 files at a time</span></p>
                                            <div className="pxl-custom-file-upload pxl-upload-file-profile pxl-ticket-file-uplod">
                                                <div className="pxl-custom-file-select">
                                                    <div className="pxl-custom-file-select-button" id="fileName">
                                                        <p>+</p>
                                                    </div>
                                                    <div className="pxl-custom-file-select-name" id="noFile"></div>
                                                    <input type="file" name="chooseFile" id="chooseFile" multiple disabled={!containsSupportTicketWrite} onChange={(e) => { attachmentFile(e); e.target.value = ''; }} />

                                                </div>
                                                {attachmentFileURL ?
                                                    attachmentFileURL.map((url) => <div key={url}>
                                                        <label>
                                                            <div >
                                                                <img src={url} alt="" />
                                                            </div>
                                                        </label>

                                                        <Tooltip title="Remove" placement="top" arrow>
                                                            <span className="xs_remove_icon" onClick={() => {
                                                                setAttachmentFileURL(current =>
                                                                    current.filter(data => {
                                                                        return data !== url;
                                                                    }))
                                                                let indexToRemove = attachmentFileURL.indexOf(url);
                                                                setAttachFile(current =>
                                                                    current.filter(data => {
                                                                        return attachFile.indexOf(data) !== indexToRemove;
                                                                    }))

                                                            }}>x</span>
                                                        </Tooltip>

                                                    </div>) : null
                                                }
                                            </div>
                                            <div className={`pxl-replyBtn ${!containsSupportTicketWrite ? "pxl-replyBtn-disable-tooltip" : ''}`}>
                                                <div className='cun-socialIcon'>
                                                    <button onClick={handleReply} disabled={!containsSupportTicketWrite} className={`pxlCommon-btn 
                                                         ${containsSupportTicketWrite ? '' : "sch-btn-disbale"}`}>{isLoading ? 'Processing...' : 'Reply'} </button>
                                                    {!containsSupportTicketWrite && <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(containsSupportTicketWrite)}</p></div>}

                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                                <div className={`pxl-ticket-reply-rightMain-box ${myTicketToggle ? 'pxl-ticketopen ' : 'pxl-ticketclose'}`}>
                                    <div className="pxl-ticketReply-right-res">
                                        <a onClick={() => setMyTicketToggle(!myTicketToggle)}><img src="/assets/images/white-arrow-right.svg" alt="" /></a>
                                    </div>
                                    <div className="pxl-ticketReplyRight">

                                        <div className="pxl-userProfileDeatils">
                                            {(ticketDetails.customer_id) && (ticketDetails.customer_id.profile_image) ?
                                                <img src={`/assets/images/${ticketDetails.customer_id._id}/` + ticketDetails.customer_id.profile_image} alt='' />
                                                : <div className="pxl-userName-letter"><h2 >{getNameInitials(ticketDetails?.customer_id ? ticketDetails.customer_id.name : '')}</h2></div>
                                            }

                                            <div className="pxl-user-profile-mail-info">
                                                <h2>{ticketDetails?.customer_id?.name}</h2>
                                                <h3>{ticketDetails?.customer_id?.email}</h3>
                                                <button>{dateFormatter(ticketDetails?.updatedAt)}</button>
                                            </div>
                                        </div>
                                        <div className="pxl-recent-tickets-wrap">
                                            <h2>Recent tickets ({recentTicketsList.length}) </h2>
                                            {(recentTicketsList.length !== 0) ? recentTicketsList?.map((i, index) => {
                                                return (<div key={index}
                                                    className="pxl-recent-ticket-box"
                                                    style={{ backgroundColor: i?.status_id ? hexToRgbA(i?.status_id?.color, 0.1) : "", border: i?.status_id ? `1px solid ${i?.status_id?.color}` : '' }}
                                                >
                                                    <Link to={`/pixa-support/projects/${id}/tickets/${i.ticket_id}`} >
                                                        <div className="pxl-recent-ticket-row1">
                                                            <h2 style={{ color: i?.status_id ? i?.status_id?.color : '' }}>#{i.ticket_id}</h2>
                                                            <h3 style={{ backgroundColor: i.status_id?.color }}>{i.status_id?.name}</h3>
                                                        </div>
                                                        <div className="pxl-recent-ticket-row2">
                                                            <h2>{i.subject}</h2>
                                                            <h3>Created - {handleTime(i.createdAt)}</h3>
                                                        </div>
                                                    </Link>
                                                </div>)
                                            }) :
                                                <div>There is no other ticket from this user. </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>





                        </div>



                    </div>
                </div>

                <div >


                    {/* <div ref={sidebarRef}
                    //  className={showDetailSidebar ? styles.ticketDetails_sidebar + " " + styles.filter_show : styles.ticketDetails_sidebar}
                    > */}

                    {/* <div >
                            <div >

                                {(ticketDetails.customer_id) && (ticketDetails.customer_id.profile_image) ?
                                    <img src={img_path + `images/${ticketDetails.customer_id._id}/` + ticketDetails.customer_id.profile_image} alt='' /> : <span >{getNameInitials(ticketDetails.customer_id ? ticketDetails.customer_id.name : '')}</span>
                                }
                            </div>

                            <div ><h3> {ticketDetails.customer_id ? ticketDetails.customer_id.name : ''}</h3></div>
                            <div > {ticketDetails.customer_id ? ticketDetails.customer_id.email : ''}</div>
                            <div >Udated - {dateFormatter(ticketDetails.updatedAt)}</div>

                        </div> */}

                    {/* {
                            (userData.role === 'teamMember') ? <div onClick={() => setShowNoteSidebar(true)}>
                                <span className={"xs_btn xs_btn_block " + 'xs_btn'}>Add Notes +</span>
                            </div> : null
                        } */}
                    {/* <div>
                            <div > <span>Recent tickets</span></div>

                            {
                                (recentTicketsList.length !== 0) ? recentTicketsList.map(recent =>
                                    <Link href={`/project/${id}/tickets/${recent.ticket_id}`} onClick={() => setShowDetailSidebar(false)} key={recent._id}>
                                        <div key={recent._id} style={{ borderLeftColor: (userData.role !== 'customer') ? `${recent.status_id.color}` : colorTheme }}>
                                            <div >
                                                <div style={{ color: (userData.role !== 'customer') ? `${recent.status_id.color}` : colorTheme }}>#{recent.ticket_id}</div>
                                                {(userData.role !== 'customer') ? <div style={{ background: `${recent.status_id.color}` }}>{recent.status_id.name
                                                }</div> : null}
                                            </div>
                                            <div >{recent.subject}</div>
                                            <div >Created - {handleTime(recent.createdAt)}</div>
                                        </div>
                                    </Link>
                                ) :
                                    <div>There is no other ticket from this user. </div>
                            }
                        </div> */}

                    {/* </div> */}

                    {/* {
                        (userData.role === process.env.roles.agent) ?
                            <div className={(showNoteSidebar) ? styles.agent_notes_sidebar : styles.agent_notes_sidebar + " " + styles.agent_notes_sidebar_right}>
                                <AgentNote agentNotesSidebarToggle={agentNotesSidebarToggle} />
                            </div> : null
                    } */}


                </div>

                {/* <ConfirmationPopup
                    shownPopup={deleteShowPopUp}
                    closePopup={() => {
                        setDeleteShowPopup(false);
                    }}
                    type={"ticket"}
                    title={`Are you sure you want to clear this conversation?`}
                    subTitle={'You can restore it later from Dashboard > Trash.'}
                    yesBtn={"Yes, Clear"}
                    btnLoader={btnLoader}

                    removeAction={() => {
                        setBtnLoader(true)
                        hitApi({
                            url: 'pixa-support/api/delete_ticket_reply',
                            method: "POST",
                            data: {
                                ticket_id: t_id,
                            },
                            alert: true
                        }, (resp, err = null) => {
                            setBtnLoader(false)
                            if (resp.status === 1) {
                                fetchTicketDetails(t_id);
                                setDescription('');
                                setAttachmentFileURL([]);
                                setAttachFile([]);
                                setDeleteShowPopup(false)
                            }
                        });
                    }}
                /> */}

            </>
        )
    }
}

export default TicketsReply;