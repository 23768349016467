import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import svg from "../../components/svg";
import Accordion from 'react-bootstrap/Accordion';

import { hitApi, setMyState, assetsUrlPath, skeletonLoader, Pagination, NoDataFound, useOutsideClick, commonToast } from '../../components/commonAction';
import ConfirmationPopup from '../../components/common/ConfirmationPopup';
import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";

const Templates = ({ pageTitle }) => {

    let navigate = useNavigate();

    const [state, setState] = useState({
        category: [],
        limit: 15,
        page: 1,
        keyword: '',
        sort: 'createdAt=-1,title=-1',
        status: '',
        totalRecords: 0,
        categoryId: '',
        parentId: '',
        modelType: '',
        title: '',
        editId: '',
        clearKeyword: false,
        assetFile: '',
        assetTag: [],
        assetTagInput: '',
        assetTarget: '',
        categoryLoading: false,
        assetLoading: false,
        subCategoryLoading: false,
        activeAssetBtn: false,
        confirmDelete: {},
        deletePopUp: false,
        loadMoreActive: false,
        assetStatus: 2,
        editIndex: '',
        playVideoId: "",
        playAudioId: "",
        btnTitle: 'Stock',
        activeAccordianKey: "",
        isTrending: true,
        isFeatured: true,
        selectedDesign: [],
        updateAll: false,
        selectAllDesign: [],
        selectedAction: '',
        selectall: false,
        subCategorylist: [],
        showSubCategorylist: false
    });

    const [totalRecords, setTotalRecords] = useState('0');
    const [assetsList, setAssetsList] = useState([]);
    const [subCategory, setSubCategory] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
        setShowModal(false);
        setMyState(setState, { ...state, modelType: '', editId: '', title: '' });
    }
    const handleShow = (item) => { setShowModal(true) };

    const [assetsModal, setAssetsModal] = useState(false);

    const handleAssetsClose = () => {
        setAssetsModal(false);
        setMyState(setState, { ...state, updateAll: false, assetFile: '', assetTag: [], assetTagInput: '', assetTarget: '', title: '', isTrending: true, isFeatured: true, selectall: false, selectedDesign: [] });
    }

    const handleAssetsShow = (item, i) => {
        setAssetsModal(true);
        setMyState(setState, { updateAll: false })
        if (item) {
            setMyState(setState, { ...state, title: item.title, isTrending: item.isTrending, isFeatured: item.isFeatured, assetFile: '', assetTag: item.tags ? item.tags : [], assetTarget: item._id, assetTagInput: '', status: item.status ? true : false, editIndex: i });
        }
    };
    const [btnLoading, setBtnLoading] = useState(false);

    const handleupdateAllpopup = () => {
        setAssetsModal(true);
        setMyState(setState, { ...state, updateAll: true, title: '', isTrending: false, isFeatured: false, assetFile: '', assetTag: [], assetTarget: '', assetTagInput: '', status: false });
    }

    const options = [
        { value: '', label: 'Select Action' },
        { value: 'update', label: 'Update All' },
    ]

    const handleSelectedAction = (val) => {
        setMyState(setState, { selectedAction: val });

        if (val == 'update') {
            // setMyState(setState, { removeMe: state.selectedDesign});
            handleupdateAllpopup();
        }

    }
  

    useEffect(() => {
        getCategory();
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [state.parentId, state.categoryId]);

    useEffect(() => {
        getAssets();
    }, [state.page, state.assetStatus, state.parentId, state.categoryId, state.clearKeyword])

    const clearSearch = () => {
        setMyState(setState, { ...state, clearKeyword: !state.clearKeyword, keyword: '' });
    }

    const getCategory = () => {
        let { limit, page, sort } = state;
        setMyState(setState, { categoryLoading: true, });
        hitApi({
            url: 'image/template/get-category',
            method: "GET",
            data: { limit: 'all', page, sort }
        }, (resp, err = null) => {
            if (resp.status) {
                setSubCategory(resp.data.records);
                getSubCategory(resp.data.records);
            }
        });
    }

    const getSubCategory = (categoryData) => {
        let { limit, page, sort } = state;
        hitApi({
            url: 'image/template/get-sub-category',
            method: "GET",
            data: {
                limit: 'all', page, sort,

            }
        }, (resp, err = null) => {

            if (resp.status) {
                const subcategoryMap = {};
                resp.data.records.forEach((sub) => {
                    if (!subcategoryMap[sub.parentId]) {
                        subcategoryMap[sub.parentId] = [];
                    }
                    subcategoryMap[sub.parentId].push(sub);
                });

                const newArray = categoryData.map((cat) => ({
                    ...cat,
                    subCat: subcategoryMap[cat._id] || [],
                }));

                setMyState(setState, {
                    category: newArray,
                    categoryLoading: false,
                });

            }
        });

    }

    const getAssets = () => {

        let data = { limit: state.limit, page: state.keyword ? 1 : state.page, keyword: state.keyword, sort: state.sort };
        // if (state.parentId && state.categoryId) {
        data['category'] = state.parentId;
        data['subCategory'] = state.categoryId;
        // }

        if (state.assetStatus != 2) {
            data.status = state.assetStatus
        }


        if (!state.categoryId && state.parentId) {
            setAssetsList([]);
            return false;
        }
        setMyState(setState, { assetLoading: true });

        hitApi({
            url: 'image/template/get-template',
            method: "GET",
            data: data
        }, (resp, err = null) => {
            setMyState(setState, { assetLoading: false, });
            if (resp.status) {
                setTotalRecords(resp.data.totalRecords)

                if (state.loadMoreActive) {
                    setAssetsList(prev => [...prev, ...resp.data.records]);
                } else {
                    setAssetsList(resp.data.records);
                }

            }
        });

    }


    const handleSelect = (eventKey) => {

        if (eventKey == 'all') {
            setMyState(setState, { ...state, parentId: '', categoryId: '', page: 1, activeAssetBtn: false, btnTitle: 'Stock', keyword: '', activeAccordianKey: 'all', assetStatus: 2, loadMoreActive: false });
        } else {
            const foundObject = state.category.find(item => item._id === eventKey);

            if (foundObject?.subCat.length > 0) {
                setMyState(setState, { ...state, parentId: eventKey, page: 1, activeAccordianKey: eventKey, activeAssetBtn: true, categoryId: foundObject.subCat.length > 0 && foundObject.subCat[0]._id, btnTitle: foundObject.title, keyword: '', loadMoreActive: false, assetStatus: 2 });
            }
        }
    };


    const handleCategory = (e) => {
        e.preventDefault();
        if (!state.title) {
            commonToast('error', `Title is required.`);
            return false
        }
        if (state.modelType == "addCategory") {
            setBtnLoading(true)
            hitApi({
                url: 'image/template/create-category',
                method: "POST",
                data: { title: state.title }
            }, (resp, err = null) => {
                setBtnLoading(false)
                if (resp.status) {
                    let cateList = state.category;
                    let newdata = { ...resp.data, subCat: [] }
                    cateList.unshift(newdata);
                    setMyState(setState, { ...state, modelType: '', title: '', category: cateList, loadMoreActive: false });
                    handleClose();
                }
            });
        } else {
            setBtnLoading(true)
            hitApi({
                url: 'image/template/update-category',
                method: "PATCH",
                data: { title: state.title, target: state.editId, status: state.status }
            }, (resp, err = null) => {
                setBtnLoading(false)
                if (resp.status) {
                    let cateList = state.category;
                    cateList[state.editIndex] = {
                        ...cateList[state.editIndex],
                        title: state.title,
                        status: state.status,
                    }

                    setMyState(setState, {
                        ...state,
                        modelType: '',
                        editId: '',
                        title: '',
                        category: cateList, loadMoreActive: false
                    });
                    handleClose();
                }
            });
        }
    }

    const handleSubCategory = (e) => {
        e.preventDefault();
        if (!state.title) {
            commonToast('error', `Title is required.`);
            return false
        }
        if (state.modelType == "addSubCategory") {
            setBtnLoading(true)
            hitApi({
                url: 'image/template/create-sub-category',
                method: "POST",
                data: { title: state.title, parentId: state.parentId }
            }, (resp, err = null) => {
                setBtnLoading(false)
                if (resp.status) {
                    let categoryDummy = state.category
                    let match = categoryDummy.some(obj => obj._id === resp.data.parentId);

                    if (match) {
                        let index = categoryDummy.findIndex(obj => obj._id === resp.data.parentId);
                        categoryDummy[index].subCat.push(resp.data);
                    }

                    setSubCategory(prev => [...prev, resp.data])
                    setMyState(setState, { ...state, modelType: '', category: categoryDummy, editId: '', title: '', loadMoreActive: false });
                    handleClose();
                }
            });
        } else {
            setBtnLoading(true)
            hitApi({
                url: 'image/template/update-sub-category',
                method: "PATCH",
                data: { title: state.title, target: state.editId, parentId: state.parentId, status: state.status }
            }, (resp, err = null) => {
                setBtnLoading(false)
                if (resp.status) {
                    let categoryDummy = state.category;

                    let categoryObj = categoryDummy.find(obj => obj._id === state.parentId);
                    let index = categoryDummy.findIndex(obj => obj._id === state.parentId);


                    if (categoryObj) {
                        const updatedState = categoryObj.subCat.map((obj, index) => {
                            if (index == state.editIndex) {
                                return { ...obj, status: state.status, title: state.title };
                            }
                            return obj;
                        });

                        categoryDummy[index].subCat = updatedState;
                    }

                    setMyState(setState, { ...state, modelType: '', editId: '', title: '', status: '', category: categoryDummy, editIndex: '', loadMoreActive: false });
                    handleClose();
                }
            });
        }
    }

    const addAssetTag = (e) => {
        e.preventDefault();
        if (state.assetTagInput) {
            const newList = [...state.assetTag];
            newList.push(state.assetTagInput);
            setMyState(setState, { ...state, assetTag: newList, assetTagInput: '', loadMoreActive: false });
        }
    }

    const removeAssetTag = (index) => {
        const newList = [...state.assetTag];
        newList.splice(index, 1);
        setMyState(setState, { ...state, assetTag: newList });
    }

    const addAsset = () => {
        if (state.title) {
            const formData = { title: state.title, tags: [] };
            if (state.assetTag.length > 0) {
                state.assetTag.forEach((tag, index) => {
                    formData.tags.push(tag);
                })
            } else if (state.assetTagInput) {
                if (state.assetTagInput.includes(",")) {
                    const outputArray = state.assetTagInput.split(",");
                    outputArray.forEach((tag, index) => {
                        formData.tags.push(tag);
                    })

                } else {
                    formData.tags = state.assetTagInput;
                }
            }
            formData['category'] = state.parentId;
            formData['subCategory'] = state.categoryId ? state.categoryId : subCategory.length && subCategory[0]._id;

            setBtnLoading(true);
            hitApi({
                url: 'image/template/add-template',
                method: "POST",
                data: formData,
            }, (resp, err = null) => {
                setBtnLoading(false);
                if (resp.status) {
                    handleAssetsClose(false);
                    setAssetsList(prev => [...prev, { ...resp.data }])
                    setMyState(setState, {
                        ...state,
                        assetTag: [],
                        assetFile: '',
                        title: '',
                        assetTagInput: ''
                    });
                    setTotalRecords(prev => prev + 1);

                    navigate(`/admin/image-template/${resp.data._id}`)
                }
            });
        } else {
            commonToast('error', `Template title is required.`);
            return false

        }
    }


    const updateAsset = () => {



        if (state.updateAll) {
            let data = { target: state.selectedDesign, status: state.status, isTrending: state.isTrending, isFeatured: state.isFeatured };
            if (state.assetTagInput) {
                if (state.assetTagInput.includes(",")) {
                    let outputArray = state.assetTagInput.split(",");
                    if (state.assetTag.length > 0) {
                        outputArray = outputArray.concat(state.assetTag);
                    }
                    data['tags'] = outputArray;
                } else {
                    if (state.assetTag.length > 0) {
                        data['tags'] = [...state.assetTag, state.assetTagInput];
                    } else {
                        data['tags'] = [state.assetTagInput];
                    }

                }
            } else if (state.assetTag) {
                data['tags'] = state.assetTag
            }
            setBtnLoading(true);
            hitApi({
                url: 'image/template/update-template',
                method: "PATCH",
                data: data,
            }, (resp, err = null) => {
                setBtnLoading(false);
                if (resp.status) {
                    handleAssetsClose(false);
                    // const updatedState = assetsList.map((obj, index) => {
                    //     if (index == state.editIndex) {
                    //         return { ...obj, status: data.status, tags: data.tags, isTrending: data.isTrending, isFeatured: data.isFeatured };
                    //     }
                    //     return obj;
                    // });

                    // setAssetsList(updatedState);

                    setMyState(setState, { ...state, assetTag: [], assetFile: '', updateAll: false, selectall: false, selectedDesign: [] });
                }
            });
        }
        else if (state.assetTarget) {
            handleAssetsClose(true)
            let data = { title: state.title, target: state.assetTarget, status: state.status, isTrending: state.isTrending, isFeatured: state.isFeatured };
            if (state.assetTagInput) {
                if (state.assetTagInput.includes(",")) {
                    let outputArray = state.assetTagInput.split(",");
                    if (state.assetTag.length > 0) {
                        outputArray = outputArray.concat(state.assetTag);
                    }
                    data['tags'] = outputArray;
                } else {
                    if (state.assetTag.length > 0) {
                        data['tags'] = [...state.assetTag, state.assetTagInput];
                    } else {
                        data['tags'] = [state.assetTagInput];
                    }

                }
            } else {
                data['tags'] = state.assetTag
            }

            setBtnLoading(true);

            hitApi({
                url: 'image/template/update-template',
                method: "PATCH",
                data: data,
            }, (resp, err = null) => {
                setBtnLoading(false);
                if (resp.status) {

                    handleAssetsClose(false);
                    const updatedState = assetsList.map((obj, index) => {
                        if (index == state.editIndex) {
                            return { ...obj, title: data.title, status: data.status, tags: data.tags, isTrending: data.isTrending, isFeatured: data.isFeatured };
                        }
                        return obj;
                    });
                    setAssetsList(updatedState);
                    setMyState(setState, { ...state, assetTag: [], assetFile: '' });
                }
            });
        }
    }


    // update all functions 

    const handleSelectDesign = (val, design, eve) => {

        if (val) {

            if (eve == 'all') {
                setMyState(setState, { selectall: true });
                const idArray = assetsList.map((item) => item._id);
                setMyState(setState, { selectedDesign: idArray });
            }
            else {
                setMyState(setState, { selectedDesign: [...state.selectedDesign, design._id] })
            }
        } else {

            if (eve == 'all') {
                setMyState(setState, { selectall: false });
                setMyState(setState, { selectedDesign: [] });
            }
            else {
                const arrCopy = (state.selectedDesign).filter(id => id !== design._id);
                setMyState(setState, { selectedDesign: arrCopy })
            }
        }
    }
    let obj = [{ value: 2, label: 'All' }, { value: 1, label: 'Active' }, { value: 0, label: 'Inactive' }];

    // sub menu delet edit setting
    const [allsubmenu, setallsubmenu] = useState(false);
    const toggleSublist = (id) => {
        setallsubmenu(prevState => ({
            //   ...prevState,
            [id]: !prevState[id]
        }));
    };
    // sub menu delet edit setting end
    // let i = 0;
    const menuRef = useRef();
    // useOutsideClick(menuRef, () => {
    //     i++;
    //     if (i > 1) {
    //         setallsubmenu(false);
    //     }
    // });
    useOutsideClick(menuRef, () => setallsubmenu(false));

    const handleAccordionClick = (category) => {
        setMyState(setState, { subCategorylist: category, showSubCategorylist: true, selectall: false, selectedDesign: [] });

    }
    const firstInputRef = useRef(null);

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [assetsModal,showModal]);
    

    return (

        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper pxl-admin-imageTemplate-wrapper">

                <div className="pxl_rightBar_assets">
                    <div className="pxl_pageTitle">
                        <h2>{pageTitle}</h2>
                    </div>

                    {/* Admin Assets Section  */}
                    <div className="pxl-template-wrapper pxlImageTemplate-main-wrapper">
                        <div className="pxl-template-container">
                            {/* Assets Category */}

                            <div className=''>
                                <div className="pxl_pageTitleInner pxl-pageTitleSpacing">
                                    <h2>Templates ({totalRecords})</h2>
                                </div>
                                <div className="pxl-template-categories pxl-assets-filter">
                                    <div className="pxl-template-row">
                                        {!state.showSubCategorylist &&
                                            <>
                                                <div className="pxl-template-filter-header">
                                                    <h4>Template Type</h4>

                                                    <div className='pxl-assets-plus' onClick={() => {
                                                        handleShow();
                                                        setMyState(setState, {
                                                            modelType: 'addCategory',
                                                        })
                                                    }}>
                                                        <a >{svg.app.Addsvg}</ a>
                                                    </div>
                                                </div>
                                                <div className="pxl-template-filter-body">
                                                    <div className='pxl-accordian-section'>
                                                        <Accordion activeKey={state.activeAccordianKey} defaultActiveKey={state.parentId} flush onSelect={handleSelect}>
                                                            <Accordion.Item eventKey={'all'}  >
                                                                <Accordion.Header >
                                                                    <div className='pxl-accordian-header'>
                                                                        <h2><span></span>All Templates</h2>
                                                                    </div>
                                                                </Accordion.Header>
                                                            </Accordion.Item>
                                                            {!state.categoryLoading ? state?.category.length > 0 ? state?.category.map((category, i) =>
                                                                <Accordion.Item eventKey={category._id} key={category._id} >
                                                                    <Accordion.Header onClick={() => {
                                                                        if (category?.subCat.length > 0) {
                                                                            handleAccordionClick(category)
                                                                        }
                                                                    }
                                                                    }>
                                                                        <div className='pxl-accordian-header'>
                                                                            <h2>
                                                                                {/* <span>{svg.app.sorting}</span> */}
                                                                                {category.title}
                                                                            </h2>
                                                                        </div>

                                                                    </Accordion.Header>
                                                                    <div className='pxl-accordion-icons-right'>
                                                                        <div className='pxl-icon-tooltip' >
                                                                            <span className='pxl-tooltip-text'>Add</span>
                                                                            <a onClick={() => {
                                                                                handleShow();
                                                                                setMyState(setState, {
                                                                                    modelType: 'addSubCategory',
                                                                                    parentId: category._id,
                                                                                    activeAccordianKey: category._id,

                                                                                })
                                                                            }}>{svg.app.Addsvg}</ a>
                                                                        </div>
                                                                        {/* <div className='pxl-icon-tooltip'>
                                                                    <span className='pxl-tooltip-text'>Remove</span>
                                                                    <Link href="#">{svg.app.Removesvg}</ Link>
                                                                </div> */}
                                                                        <div className='pxl-icon-tooltip'>
                                                                            <span className='pxl-tooltip-text'>Edit</span>
                                                                            <a onClick={() => {
                                                                                handleShow();
                                                                                setMyState(setState, {
                                                                                    modelType: 'editCategory',
                                                                                    title: category.title,
                                                                                    editId: category._id,
                                                                                    status: category.status,
                                                                                    editIndex: i
                                                                                })
                                                                            }}>{svg.app.editIconWhite}</ a>
                                                                        </div>
                                                                    </div>
                                                                    <Accordion.Body>
                                                                        <div className='pxl-accodian-sub-category-body '>
                                                                            {!state.subCategoryLoading ? (category?.subCat.length > 0) ? category?.subCat.map((val, i) => (val.parentId == category._id) && <div className={`pxl-accodian-sub-category ${state.categoryId ? state.categoryId == val._id && 'pxl-sub-categoryActive' : i == 0 && 'pxl-sub-categoryActive'}`} key={val._id} onClick={() => {

                                                                                setMyState(setState, {
                                                                                    categoryId: val._id,
                                                                                    assetStatus: 2,
                                                                                    keyword: ''
                                                                                });
                                                                            }}>

                                                                                <h2>{val.title}</h2>
                                                                                <div>
                                                                                    {!val.status && <div className='pxl-inactive'>Inactive</div>}
                                                                                    <div className='pxl-accordion-icons-right'>
                                                                                        <div className='pxl-icon-tooltip'>
                                                                                            <span className='pxl-tooltip-text'>Edit</span>
                                                                                            <a onClick={() => {
                                                                                                handleShow();
                                                                                                setMyState(setState, {
                                                                                                    modelType: 'editSubCategory',
                                                                                                    title: val.title,
                                                                                                    editId: val._id,
                                                                                                    parentId: state.subCategorylist._id,
                                                                                                    status: val.status,
                                                                                                    editIndex: i
                                                                                                })
                                                                                            }}>{svg.app.editIconWhite}</ a>
                                                                                        </div>
                                                                                        {/* <div className='pxl-icon-tooltip'>
                                                                                <span className='pxl-tooltip-text'>remove</span>
                                                                                <Link onClick={() => { }} >{svg.app.Removesvg}</ Link>
                                                                            </div> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            ) : <div className='pxl-accodian-sub-category' >
                                                                                <h2>No category found.</h2>
                                                                            </div>
                                                                                :
                                                                                <div>{skeletonLoader('list', 2)}</div>
                                                                            }

                                                                        </div>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            )
                                                                :
                                                                <></>
                                                                // <div>No assets found.</div>
                                                                :
                                                                <div>{skeletonLoader('list', 4)}</div>
                                                            }
                                                        </Accordion>


                                                    </div>

                                                </div>
                                            </>
                                        }

                                        {state.showSubCategorylist &&
                                            <>
                                                <div className='cun-Heading cun-backBtn-sec cunWorkspace-heading' onClick={(e) => {
                                                    setMyState(setState, { ...state, selectall: false, selectedDesign: [], showSubCategorylist: false, subCategorylist: [], parentId: '', categoryId: '', page: 1, activeAssetBtn: false, btnTitle: 'Stock', keyword: '', activeAccordianKey: 'all', assetStatus: 2, loadMoreActive: false });
                                                }}>
                                                    <span><img src="/assets/images/backSvg.svg" alt="" /></span> Back
                                                </div>
                                                <div className="pxl-template-filter-body">
                                                    <div className='pxl-accordian-section'>
                                                        <Accordion activeKey={state.activeAccordianKey} defaultActiveKey={state.parentId} flush onSelect={handleSelect}>

                                                            <Accordion.Item eventKey={state.subCategorylist._id} key={state.subCategorylist._id} >
                                                                <Accordion.Header>
                                                                    <div className='pxl-accordian-header'>
                                                                        <h2>
                                                                            {/* <span>{svg.app.sorting}</span> */}
                                                                            {state.subCategorylist.title}
                                                                        </h2>
                                                                    </div>

                                                                </Accordion.Header>
                                                                <div className='pxl-accordion-icons-right'>
                                                                    <div className='pxl-icon-tooltip' >
                                                                        <span className='pxl-tooltip-text'>Add</span>
                                                                        <a onClick={() => {
                                                                            handleShow();
                                                                            setMyState(setState, {
                                                                                modelType: 'addSubCategory',
                                                                                parentId: state.subCategorylist._id,
                                                                                activeAccordianKey: state.subCategorylist._id,

                                                                            })
                                                                        }}>{svg.app.Addsvg}</ a>
                                                                    </div>
                                                                    {/* <div className='pxl-icon-tooltip'>
                                                                    <span className='pxl-tooltip-text'>Remove</span>
                                                                    <Link href="#">{svg.app.Removesvg}</ Link>
                                                                </div> */}
                                                                    <div className='pxl-icon-tooltip'>
                                                                        <span className='pxl-tooltip-text'>Edit</span>
                                                                        <a onClick={() => {
                                                                            handleShow();
                                                                            setMyState(setState, {
                                                                                modelType: 'editCategory',
                                                                                title: state.subCategorylist.title,
                                                                                editId: state.subCategorylist._id,
                                                                                status: state.subCategorylist.status,
                                                                                // editIndex: i
                                                                            })
                                                                        }}>{svg.app.editIconWhite}</ a>
                                                                    </div>
                                                                </div>
                                                                <Accordion.Body>
                                                                    <div className='pxl-accodian-sub-category-body '>
                                                                        {!state.subCategoryLoading ? (state.subCategorylist?.subCat.length > 0) ? state.subCategorylist?.subCat.map((val, i) => (val.parentId == state.subCategorylist._id) && <div className={`pxl-accodian-sub-category ${state.categoryId ? state.categoryId == val._id && 'pxl-sub-categoryActive' : i == 0 && 'pxl-sub-categoryActive'}`} key={val._id} onClick={() => {

                                                                            setMyState(setState, {
                                                                                categoryId: val._id,
                                                                                assetStatus: 2,
                                                                                keyword: '',
                                                                                page:1,
                                                                                loadMoreActive:false
                                                                            });
                                                                        }}>

                                                                            <h2>{val.title}</h2>
                                                                            <div>
                                                                                {!val.status && <div className='pxl-inactive'>Inactive</div>}
                                                                                <div className='pxl-accordion-icons-right'>
                                                                                    <div className='pxl-icon-tooltip'>
                                                                                        <span className='pxl-tooltip-text'>Edit</span>
                                                                                        <a onClick={() => {
                                                                                            handleShow();
                                                                                            setMyState(setState, {
                                                                                                modelType: 'editSubCategory',
                                                                                                title: val.title,
                                                                                                editId: val._id,
                                                                                                parentId: state.subCategorylist._id,
                                                                                                status: val.status,
                                                                                                editIndex: i
                                                                                            })
                                                                                        }}>{svg.app.editIconWhite}</ a>
                                                                                    </div>
                                                                                    {/* <div className='pxl-icon-tooltip'>
                                                                                <span className='pxl-tooltip-text'>remove</span>
                                                                                <Link onClick={() => { }} >{svg.app.Removesvg}</ Link>
                                                                            </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        ) : <div className='pxl-accodian-sub-category' >
                                                                            <h2>No category found.</h2>
                                                                        </div>
                                                                            :
                                                                            <div>{skeletonLoader('list', 2)}</div>
                                                                        }

                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>

                                                        </Accordion>


                                                    </div>

                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* Assets Section */}
                            <div className="pxl-templates-holder">
                                {/* Assets Status Bar */}
                                <div className="pxl-templates-status-bar pxl-filter-wrapper pxl-searchbar-right cun-FlexEnd">

                                    <div className="pxl-head-btn-wrap">
                                        <div className="pxl_filterFeilds">


                                            <div className="cun-AllChecknoxSelect">
                                                <div className="sch-custom-check-box ">
                                                    <div className="form-group">
                                                        <input type="checkbox" id="select-all" checked={state.selectall} onChange={(e) => handleSelectDesign(e.target.checked, null, 'all')} />
                                                        <label htmlFor="select-all">Select All</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='cun-headreSelect cun-headreSelect-imagetemplate'>

                                                <Select options={options}
                                                    isDisabled={state.selectedDesign.length == 0}
                                                    value={options.filter(option =>
                                                        option.value == state.selectedAction)}
                                                    onChange={(e) => handleSelectedAction(e.value)}
                                                />

                                            </div>

                                            <div className='cun-headreSelect cun-headreSelect-imagetemplate1'>
                                                <Select
                                                    value={obj.filter(option =>
                                                        option.value == state.assetStatus)}
                                                    options={obj}
                                                    onChange={(e) => setMyState(setState, {
                                                        assetStatus: e.value,
                                                        loadMoreActive: false,
                                                        page: 1,

                                                    })}
                                                />
                                            </div>

                                            <div className="sch-search-section">
                                                <div className="sch-search-row sch-search-bg-color-white">
                                                    <input type="text" value={state.keyword} placeholder="Search templates" onChange={(e) => setMyState(setState, {
                                                        keyword: e.target.value,
                                                        loadMoreActive: false
                                                    })}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                                                                getAssets();
                                                            }
                                                        }} />
                                                    <button type="button" className="sch-search-btn">
                                                        {!state.keyword ? <span> {svg.app.searchIcon} </span> : <span className="cun-inputCross" onClick={clearSearch}>{svg.app.crossSvg}</span>}

                                                    </button>
                                                </div>
                                            </div>
                                            {/*  update all templates  */}
                                            {/* <button className={`sch-btn-iconRight ${state.selectedDesign.length == 0 && `pxl-button-disable`}`} disabled={state.selectedDesign.length == 0} onClick={() => handleupdateAllpopup()} data-bs-toggle="modal" data-bs-target="#editAssets">
                                                update all <span> <img src="/assets/images/update-all-img.png" />
                                                </span>
                                            </button> */}
                                            {/*  update all templates  */}
                                            <button className={`pxlCommon-btn ${!state.activeAssetBtn && 'pxl-button-disable'}`} data-bs-toggle="modal" data-bs-target="#addAssets" onClick={() => (state.activeAssetBtn) ? handleAssetsShow() : null}>
                                         
                                                Add New {state.btnTitle}
                                                
                                            </button>

                                        </div>


                                    </div>
                                </div>
                                {/* Main Assets */}
                                <div className="pxl-assets-area pxl-imageTemplateRow-wrap">
                                    {<div className="pxl-assets-row">

                                        {/* Single Assets */}
                                        {!state.loadMoreActive && state.assetLoading ? [...Array(10)].map((i) => <div key={i}>{skeletonLoader('asset', 1, 200, 200)}</div>) :
                                            assetsList.length > 0 && assetsList.map((asset, i) => (
                                                <div className={`sch-draft-box  ${state.selectedDesign.some((obj) => obj === asset._id) && 'active_post'}`} key={asset._id}>

                                                    <div className="sch-draft-img-box">
                                                        {asset.isProcessing && <div className='pxl-assets-over pxl-processing'><h2>processing...</h2> </div>}
                                                        <img src={asset?.files?.original?.thumb ? assetsUrlPath(asset.files.original.thumb) : '/assets/images/default-draft-img.jpg'} alt="" />
                                                        {!asset.tags && <div className='pxl_emptytag'>{svg.app.Circle}</div>
                                                        }
                                                        <div className="sch-custom-check-box cun-selectAll sch_hide_checkbox" style={{ opacity: state.selectedDesign.length > 0 && 1 }}>
                                                            <div className="form-group">
                                                                <input type="checkbox" id={i} checked={state.selectedDesign.some((obj) => obj === asset._id)} onChange={(e) => handleSelectDesign(e.target.checked, asset)} />
                                                                <label htmlFor={i}></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='sch-draft-content'>
                                                        <div className="sch-draft-post-title">
                                                            <h2>{asset.title}</h2>
                                                        </div>
                                                        <div className={`sch-menuSection  ${allsubmenu[asset._id] ? 'sch-menuOpen' : ''}`}>
                                                            <div className='three_dot_bg' id={asset._id} onClick={() => toggleSublist(asset._id)} ref={menuRef}><img src="/assets/images/three-dot.svg" alt="" /></ div>

                                                            <div className={`sch-submenubox`}>
                                                                <ul>
                                                                    <li>
                                                                        <Link to={`/admin/image-template/${asset._id}`} data-bs-toggle="modal"
                                                                            data-bs-target="#editAssets" className="pxl-edit-icon">
                                                                            <span><img src="/assets/images/edit-svg.svg" alt="" /> </span>Edit
                                                                        </Link>
                                                                    </li>

                                                                    <li>
                                                                        <a href="#" onClick={() => {
                                                                            setMyState(setState, {
                                                                                deletePopUp: true,
                                                                                confirmDelete: {
                                                                                    title: 'Template',
                                                                                    url: 'image/template/remove-template',
                                                                                    data: { target: asset._id }
                                                                                }
                                                                            })

                                                                        }} data-bs-toggle="modal" data-bs-target="#deleteAssets" className="pxl-delete-icon">
                                                                            <span><img src="/assets/images/delet-svg.svg" alt="" /> </span>Delete
                                                                        </ a>
                                                                    </li>

                                                                    <li>
                                                                        <a href="#" onClick={() => {
                                                                            handleAssetsShow(asset, i)
                                                                        }} data-bs-toggle="modal" data-bs-target="#editAssets" className="pxl-delete-icon">
                                                                            <span><img src="/assets/images/setting-svg.svg" alt="" /> </span>Setting
                                                                        </ a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            )
                                            )
                                        }
                                        {state.loadMoreActive && state.assetLoading ? [...Array(10)].map((i) => <div key={i}>{skeletonLoader('asset', 1, 200, 200)}</div>) : ''}

                                    </div>
                                    }

                                    {
                                        !state.loadMoreActive && !state.assetLoading && assetsList.length == 0 && <NoDataFound title="No template found for this category." bgRemove={true}/>
                                    }
                                     {assetsList.length < totalRecords &&
                                            <div className="loadmorebtn">
                                                <a
                                                    className={`sch-loadmoreBtn ${assetsList.length >= totalRecords ? 'pxl-button-disable' : ''}`}
                                                    onClick={()=>{
                                                        let addload = document.getElementById("add_Load_more")
                                                        if(addload){
                                                            addload.click()
                                                        }
                                                    }}
                                                >{state.assetLoading ? <span>Loading...</span> : <span>Load More</span>}</a>
                                            </div>
                                        }

                                    {assetsList.length > 0 && <Pagination
                                        type="Assests"
                                        dataRange={{
                                            start: assetsList.length > 0 ? 1 : 0,
                                            end: assetsList.length,
                                        }}
                                        loadMore={true}
                                        currentPage={state.page}
                                        totalRecords={totalRecords < assetsList.length ? assetsList.length : totalRecords}
                                        perPage={state.limit}
                                        isLoading={state.assetLoading}
                                        onClick={(pageNum) => {
                                            setMyState(setState, {
                                                page: pageNum,
                                                loadMoreActive: true,
                                            });
                                        }}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showModal} onHide={handleClose} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {state.modelType == 'addCategory' ? "Add New Template Type" : state.modelType == 'editCategory' ? "Edit Template Type" : state.modelType == 'addSubCategory' ? "Add New  Category" : "Edit  Category"}

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Add Category */}
                    {(state.modelType == 'addCategory' || state.modelType == 'editCategory') && <form className="" action=""
                        onSubmit={handleCategory}
                    >
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Title <span className="pxl-star-red">*</span></label>
                            <input ref={firstInputRef}  type="text" className="form-control" value={state.title} placeholder="Enter Title" onChange={(e) => setMyState(setState, {
                                title: e.target.value
                            })} />
                        </div>

                        <div className='sch-statusCheckbox sch-relativeClass'>
                            {state.modelType == 'editCategory' && <div className="sch-custom-check-box">
                                <form>
                                    <div className="form-group">
                                        <input type="checkbox" className="form-check-input" checked={state.status} id="exampleCheck1" onClick={(e) => setMyState(setState, {
                                            status: e.target.checked
                                        })} />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Status</label>
                                    </div>
                                </form>
                            </div>}
                        </div>
                        <button type="submit" className="pxl_btn" disabled={btnLoading}>
                            {btnLoading ? "Processing..." : state.modelType == 'addCategory' ? "Add" : 'Update'}
                        </button>
                    </form>}

                    {/* Add Sub Category */}
                    {(state.modelType == 'addSubCategory' || state.modelType == 'editSubCategory') && <form className="" action=""
                        onSubmit={handleSubCategory}
                    >
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Title <span className="pxl-star-red">*</span></label>
                            <input ref={firstInputRef} type="text" className="form-control" value={state.title} placeholder="Enter Title" onChange={(e) => setMyState(setState, {
                                title: e.target.value
                            })} />
                        </div>
                        <div className="sch-statusCheckbox sch-relativeClass">
                            {state.modelType == 'editSubCategory' && <div className="sch-custom-check-box">
                                <form>
                                    <div className='form-group'>
                                        <input type="checkbox" className="form-check-input" checked={state.status} id="exampleCheck1" onClick={(e) => setMyState(setState, {
                                            status: e.target.checked
                                        })} />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Status</label>
                                    </div>
                                </form>
                            </div>}
                        </div>

                        <button type="submit" className="pxl_btn" disabled={btnLoading}>
                            {btnLoading ? "Processing..." : state.modelType == 'addSubCategory' ? "Add" : 'Update'}
                        </button>
                    </form>}

                </Modal.Body>

            </Modal>


            <Modal
                show={assetsModal} onHide={handleAssetsClose} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='pxl-model-header'>
                            <h2 className='modal-title'>{(state.updateAll || state.assetTarget) ? 'Template Setting' : 'Add New Template'}</h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='pxl_model_body'>
                        {!state.updateAll && <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Title <span className="pxl-star-red">*</span></label>
                            <input ref={firstInputRef} type="text" className="form-control" value={state.title} placeholder="Enter Title" onChange={(e) => setMyState(setState, {
                                title: e.target.value
                            })} />
                        </div>}
                        <div className='pxl-tokenfield-section sch-margin-bottom15'>
                            <label className='pxl-label'>Tags <span className="pxl-star-red">*</span></label>
                            <div className='pxl-tokenfield'>
                                <div className='pxl-tokenfield-inner'>
                                    {state.assetTag && state.assetTag.length > 0 ? state.assetTag.map((val, i) => (
                                        <div className='pxl-tokenfield-child' key={i}>
                                            <p> {val} <span><a onClick={() => removeAssetTag(i)}>{svg.app.crossSvg1}</ a></span></p>
                                        </div>
                                    )) : ''}

                                </div>
                                <form onSubmit={(e) => addAssetTag(e)}>
                                    <div className='pxl-tokenfield-input'>
                                        <input type='text' placeholder='Type and press enter to add tags' value={state.assetTagInput} onChange={(e) => setMyState(setState, {
                                            assetTagInput: e.target.value
                                        })} />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='sch-toggleButton '>

                            <label className="sch-switch">
                                <input type="checkbox" checked={state.status} id="exampleCheck3" onClick={(e) => setMyState(setState, {
                                    status: e.target.checked
                                })} />
                                <span className="slider"></span>
                            </label>
                            <label className='sch-toggleLabel'>Status</label>
                        </div>

                        <div className="sch-statusCheckbox sch-relativeClass">
                            {(state.updateAll || state.assetTarget) && <div className="sch-custom-check-box">
                                <form>
                                    <div className="form-group">
                                        <input type="checkbox" className="form-check-input" checked={state.isTrending} id="exampleCheck1" onClick={(e) => setMyState(setState, {
                                            isTrending: e.target.checked
                                        })} />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Add in trending templates</label>
                                    </div>
                                </form>
                            </div>}
                        </div>
                        <div className="sch-statusCheckbox sch-relativeClass">
                            {(state.updateAll || state.assetTarget) && <div className="sch-custom-check-box">
                                <form>
                                    <div className="form-group">
                                        <input type="checkbox" className="form-check-input" checked={state.isFeatured} id="exampleCheck2" onClick={(e) => setMyState(setState, {
                                            isFeatured: e.target.checked
                                        })} />
                                        <label className="form-check-label" htmlFor="exampleCheck2">Add in featured templates</label>

                                    </div>

                                </form>
                            </div>}
                        </div>

                        <button type="button" className="pxl_btn" onClick={(state.updateAll || state.assetTarget) ? updateAsset : addAsset} disabled={btnLoading}>
                            {btnLoading ? "Processing..." : (state.updateAll || state.assetTarget) ? "Update" : "Add"}
                        </button>
                    </div>
                </Modal.Body>

            </Modal>


            <ConfirmationPopup
                shownPopup={state.deletePopUp}
                closePopup={() => {
                    setMyState(setState, {
                        deletePopUp: false
                    })
                }}
                btnLoader={state.btnLoading}
                type={"template"}
                removeAction={() => {
                    setMyState(setState, { btnLoading: true });
                    hitApi({
                        url: state.confirmDelete?.url,
                        method: "DELETE",
                        data: state.confirmDelete?.data,
                    }, (resp, err = null) => {
                        setMyState(setState, {
                            deletePopUp: false,
                            confirmDelete: {},
                            btnLoading: false
                        })
                        if (resp.status) {
                            setMyState(setState, { loadMoreActive: false });
                            getAssets();
                        }
                    })
                }}
            />



        </>
    )
};

export default Templates;