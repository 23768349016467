
const TermsConditions= ()=> {
   
    return <div class="pxlnx-privacyPolicy-main-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            
                <div class="pxlnx-privacy-policy-inner-wrap">
                    <div class="pxlnx-privacy-section1">
                        <h3><b>Terms And Condition</b></h3>
                    <h2 class="pxlnx-section-heading">Hours of Operation</h2>
                    <p class="pxlnx-positon-p"><span>*</span> app.pixalab.ai office is open Monday – Friday 9a.m. – 5p.m. EST</p>
                    <p class="pxlnx-positon-p"><span>*</span> We close for all government observed holidays</p>
                    <p>These policies and procedures apply to all products and/or services from: PixaLab (app.pixalab.ai)</p>
                    </div>
                    
                <div  class="pxlnx-privacy-section2">
                    <h2 class="pxlnx-section-heading">Returns</h2>
                <p class="pxlnx-positon-p"><span>*</span> All refunds will be processed within 7 days.</p>
                <p class="pxlnx-positon-p"><span>*</span> 100% money back guarantee covers price of product only.</p>
                <p class="pxlnx-positon-p"><span>*</span> Customers must contact our support department with their order information to request a refund.</p>
                <p class="pxlnx-positon-p"><span>*</span> Once a valid refund request is submitted, the refund process will take no more than 30 days. All refunds will be returned to the original credit card and PayPal orders will be credited back to the PayPal account.</p>
                <p class="pxlnx-positon-p"><span>*</span> You have (30) Days from the date of the original purchase to return the product to receive your refund. Any return received after the (30) DAY time limit will not be processed.</p>
                <p class="pxlnx-positon-p"><span>*</span> If we receive excessive or repeated refund requests from you, we reserve the right to add you to our “blacklist” to prevent future purchases of our products by you and we reserve the right to refuse your business at our sole discretion for any other reason we deem necessary. We also reserve the right to deny future refunds to you if we feel you are taking advantage of our money back guarantee.</p>
                </div>
                
                <div class="pxlnx-privacy-section3">
                    <h2  class="pxlnx-section-heading">Personal Checks</h2>
                <p class="pxlnx-positon-p"><span>*</span> Checks or “e-checks” will not be accepted.</p>
                </div>
                
                <div class="pxlnx-privacy-section4">
                    
                    <h2 class="pxlnx-section-heading">Customer Service</h2>
                <p>If you have questions or comments regarding our products, please contact us here:   <a href=" https://support.pixalab.ai/auth/registration"> https://support.pixalab.ai/auth/registration</a> </p>
                </div>


                </div>
            </div>
        </div>
    </div> 
</div>
}
export default TermsConditions;