import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useStore } from "../../zustand/store";
import svg from "../../components/svg";
import { Modal, Button, Table } from 'react-bootstrap';
import { hitApi, setMyState, Pagination, skeletonLoader, NoDataFoundDataTable, commonToast, validations } from "../../components/commonAction";
import Select from "react-select";
const optionsAdmin = [
    { value: '', label: 'Select Role' },
    { value: 'user', label: 'User' },
]

const Users = ({ pageTitle }) => {

    let store = useStore(state => state);
    const [state, setState] = useState({
        data: [],
        limit: 10,
        page: 1,
        keyword: '',
        sort: 'createdAt=-1',
        role: '',
        status: 3,
        totalRecords: '',
        userLoading: false,
        clearKeyword: false,
    });
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        role: '',
        target: '',
        confirmPass: '',
        status: '',
        isEdit: false
    })
    const [btnLoading, setBtnLoading] = useState(false);


    useEffect(() => {
        getUserList();
    }, [state.page, state.status, state.role, state.clearKeyword])

    const clearSearch = () => {
        setMyState(setState, { ...state, clearKeyword: true, keyword: '' });
    }

    const getUserList = () => {
        setMyState(setState, { ...state, userLoading: true });
        let { limit, page, keyword, sort, role, status } = state;
        let data = { limit, page: keyword ? 1 : page, keyword, sort, role, };
        if (status != 3) {
            data['status'] = status;
        }

        hitApi({
            url: `user/user-list`,
            method: "GET",
            data: data
        }, (resp) => {
            if (resp.status) {
                const response = resp.data.records;

                setMyState(setState, {
                    ...state,
                    // limit: response.length,
                    data: response,
                    userLoading: false,
                    totalRecords: resp.data.totalRecords
                });
            }
        });
    }

    const emptyUserState = () => {
        setMyState(setUser, {
            name: '', email: '', password: '', role: '', target: '', status: '', isEdit: false
        })
    }
    const handlePageChange = (pageNumber) => {
        setMyState(setState, (prev) => ({ ...prev, page: pageNumber }));
    };

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
        setShowModal(false);
        setMyState(setUser, {
            name: '', email: '', password: '', role: '', target: '', isEdit: false, confirmPass: ''
        })
    }
    const handleShow = (item) => {
        setShowModal(true)
        if (item) {
            setMyState(setUser, {
                name: item.name, email: item.email, password: '', role: item.role, target: item._id, isEdit: true, status: item.status
            })
        }
    };

    const createUser = (e) => {
        e.preventDefault();

        if (user.name.trim() && user.email && user.password && user.role) {
            let isValid = validations(user.email, 'email') && validations(user.password, 'password');
            if (isValid) {
                setBtnLoading(true);
                hitApi({
                    url: 'user/add-user',
                    method: "POST",
                    data: { ...user }
                }, (resp) => {
                    setBtnLoading(false);
                    if (resp.status) {
                        handleClose();
                        getUserList();
                        emptyUserState();
                    }
                });
            }
        } else {
            const emptyField = !user.name ? 'Name' : !user.email ? 'Email' : !user.password ? 'Password' : 'Role';
            commonToast('error', `${emptyField} is required.`);
        }
    }

    const updateUser = (e) => {
        e.preventDefault();
        if (!user.name) {
            commonToast('error', `Name is required.`);
            return false;
        }
        if (!user.role) {
            commonToast('error', `Role is required.`);
            return false;
        }
        let isValid = true;
        if (!((user.password && user.confirmPass) || (!user.password && !user.confirmPass))) {
            commonToast('error', `${!user.password ? 'Password' : 'Confirm Password'} is required.`);
            return false;
        }

        let data = {
            name: user.name, target: user.target, role: user.role, status: user.status
        }
        if (user.password && user.confirmPass) {
            if (user.password != user.confirmPass) {
                commonToast('error', `Password and confirm password should be same.`);
                return false;
            }
            isValid = validations(user.password, 'password');
            data['password'] = user.password;
            data['confirmPassword'] = user.confirmPass;
        }
        if (isValid) {
            setBtnLoading(true);
            hitApi({
                url: 'user/update-user',
                method: "PATCH",
                data: data
            }, (resp, err = null) => {
                setBtnLoading(false);
                if (resp.status) {
                    handleClose();
                    getUserList();
                    emptyUserState();
                }
            });

        }

    }

    let cntStart = ((state.page - 1) * state.limit) + 1, cnt = cntStart;

    // let objRole = [{ value: '', label: 'All users' },{ value: 'user', label: 'User' }, { value: 'tmplateCreator', label: 'Template creator' }];


    let objActiveInactive = [{ value: 3, label: 'All Status' }, { value: 1, label: 'Active' }, { value: 0, label: 'Inactive' }];
    const firstInputRef = useRef(null);

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [showModal]);
    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper">
                <div className="pxl_rightBar_assets">
                    {/* Count Card Widgets Section */}
                    <div className="pxl_pageTitle">
                        <h2>{pageTitle}</h2>
                    </div>
                    <div className="pxl-count-cards">
                        <div className="pxl_container">

                            <div className="pxl_grid">
                                <div className="pxl_tableBox_filter">
                                    <div className="pxl_tableData">
                                        <h5>Users ({state.totalRecords})</h5>
                                    </div>
                                    <div className="pxl_filterFeilds cun-users-resheader">
                                        {/* <div className='cun-headreSelect'>
                                            <Select

                                                value={objRole.filter(option =>
                                                    option.value == state.role)}
                                                options={objRole}
                                                onChange={(e) => setMyState(setState, {
                                                    role: e.value,
                                                    page: 1,

                                                })}
                                            />
                                        </div> */}


                                        <div className='cun-headreSelect'>
                                            <Select
                                                value={objActiveInactive.filter(option =>
                                                    option.value == state.status)}
                                                options={objActiveInactive}
                                                onChange={(e) => setMyState(setState, {
                                                    status: e.value,
                                                    page: 1,

                                                })}
                                            />
                                        </div>



                                        {/* <div className="pxl_search_wrapper">
                                            <input type="text" className="form-control pxl_datatableSearch" value={state.keyword} placeholder="Search..." onChange={(e) => setMyState(setState, {
                                                keyword: e.target.value
                                            })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                                                        getUserList()
                                                    }
                                                }} />
                                            {!state.keyword ? <span> {svg.app.searchIcon} </span> : <span onClick={clearSearch} className="search_cross">{svg.app.crossSvg}</span>}
                                        </div> */}
                                        <div className="sch-search-section">
                                            <div className="sch-search-row sch-search-bg-color-white">
                                                <input type="text" value={state.keyword} placeholder="Search Users" onChange={(e) => setMyState(setState, {
                                                    keyword: e.target.value,
                                                    clearKeyword: false
                                                })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                                                            getUserList()
                                                        }
                                                    }} />
                                                <button type="button" className="sch-search-btn">
                                                    {!state.keyword ? <span> <img src="/assets/images/scheduler/search.svg" /> </span> : <span onClick={clearSearch}><img src="/assets/images/scheduler/search.svg" /></span>}

                                                </button>
                                            </div>
                                        </div>
                                        <div className="pxl-add-newUserBtn">
                                            <button class="pxlCommon-btn" onClick={() => handleShow()}>Add New User</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="pxl_table table-responsive support_table">
                                    {

                                        <table className="pxl_table">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Role</th>
                                                    <th>Status</th>
                                                    <th>Plans</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!state.userLoading ?
                                                    state.data.length > 0 ?
                                                        state.data.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td><p>{state.page == 1 ? (index + 1) : (index + 1) + ((state.page - 1) * state.limit)}</p></td>
                                                                    <td><p>{item.name}</p></td>
                                                                    <td><p>{item.email}</p></td>
                                                                    <td><p>
                                                                        {item.role == 'admin' ? 'Admin' : (item.role == 'user' ? 'User' : '-')}</p></td>
                                                                    <td>
                                                                        {/* <label className="pxl_checkBox pxl_toggle">
                                                                <input type="checkbox" className="d-none" name="radio" checked={item.status}/>
                                                                <span className="mr-2">{item.status == 1 ? 'Active' : 'Inactive'}</span> 
                                                            </label> */}
                                                                        <p className="mr-2">{item.status == 1 ? 'Active' : 'Inactive'}</p>

                                                                    </td>
                                                                    <td>
                                                                        <div className="pxl_planHolder_table">
                                                                            <span className="pxl_plans">Plan 1</span>
                                                                            <span className="pxl_plans">Plan 1</span>
                                                                            <span className="pxl_plans">Plan 1</span>
                                                                            <span className="pxl_plans">Plan 1</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="pxl_tableIcon_holder cun-iconTooltip">
                                                                            <a href="#" onClick={(e) => {
                                                                                handleShow(item)
                                                                                e.preventDefault()
                                                                            }} className="pxl_tableIcon pxl_tableIconEdit" >{svg.app.editdefault}

                                                                            </a>
                                                                            <div className="cun-show-tooltip">
                                                                                <p>Edit</p>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })

                                                        :
                                                        (state.keyword) ? <NoDataFoundDataTable column={8} statement="You don't have user with this name." /> : <NoDataFoundDataTable column={8} statement="No user found." />
                                                    :
                                                    <tr ><td colSpan={8}>{skeletonLoader('table')}</td></tr>
                                                }




                                            </tbody>
                                        </table>

                                    }
                                </div>
                                {/* <div className="pxl_tablePagination">
                                    <p>Showing 1 to 10 of 12 entries</p>
                                    <ul>
                                        <li>
                                            <a href="#" onClick={(e)=>{
 e.preventDefault()
}} className="prev"> Preview </a>

                                        </li>
                                        <li><a href="#" onClick={(e)=>{
 e.preventDefault()
}} className="isActive"> 1 </a></li>
                                        <li><a href="#" onClick={(e)=>{
 e.preventDefault()
}}> 2 </a></li>
                                        <li><a href="#" onClick={(e)=>{
 e.preventDefault()
}}> ... </a></li>
                                        <li><a href="#" onClick={(e)=>{
 e.preventDefault()
}}> 10 </a></li>
                                        <li><a href="#" onClick={(e)=>{
 e.preventDefault()
}} className="next"> Next </a></li>
                                    </ul>
                                </div> */}

                                <Pagination
                                    type="User"
                                    dataRange={{
                                        start: state.totalRecords ? cntStart : 0,
                                        end: state.totalRecords ? cntStart + state.data.length - 1 : 0,
                                    }}
                                    showPagination={true}
                                    currentPage={state.page}
                                    totalRecords={state.totalRecords}
                                    perPage={state.limit}
                                    isLoading={state.userLoading}
                                    onClick={(pageNum) => {
                                        setMyState(setState, {
                                            page: pageNum,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Modal
                show={showModal} onHide={handleClose} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {user.isEdit ? "Edit User" : "Add New User"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="" action="" onSubmit={user.isEdit ? updateUser : createUser}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">User Name <span className="pxl-star-red">*</span></label>
                            <input ref={firstInputRef} type="text" className="form-control" value={user.name} placeholder="Enter Name" onChange={(e) => setMyState(setUser, {
                                name: e.target.value
                            })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Email Address <span className="pxl-star-red">*</span></label>
                            <input type="email" className="form-control" value={user.email} disabled={user.isEdit} placeholder="Enter Email" onChange={(e) => setMyState(setUser, {
                                email: e.target.value
                            })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Password {!user.isEdit && <span className="pxl-star-red">*</span>}</label>
                            <input type="password" className="form-control" value={user.password} placeholder="Enter Password" onChange={(e) => setMyState(setUser, {
                                password: e.target.value
                            })} />
                        </div>
                        {user.isEdit && <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Confirm Password</label>
                            <input type="password" className="form-control" value={user.confirmPass} placeholder="Enter Confirm Password" onChange={(e) => setMyState(setUser, {
                                confirmPass: e.target.value
                            })} />
                        </div>}
                        <div className="form-group">
                            <label htmlFor="exampleFormControlSelect2">Role <span className="pxl-star-red">*</span></label>
                            <div className="cun-headreSelect p-0">
                            
                                <Select
                                    options={optionsAdmin}
                                    value={optionsAdmin.filter(option =>
                                        option.value == user.role)}
                                    onChange={(e) => setMyState(setUser, {
                                        role: e.value
                                    })} />
                            </div>

                            {/* <select className="form-control" id="exampleFormControlSelect2" value={user.role} onChange={(e) => setMyState(setUser, {
                                role: e.target.value
                            })}>
                                <option value=''>Select Role</option>
                                <option value="user">User</option>
                            </select> */}
                        </div>


                        {/* <div className="sch-custom-check-box">
                            <form>
                                <div className="form-group">
                                    <input type="checkbox" id="check9" />
                                    <label htmlFor="check9"></label>
                                </div>
                            </form>
                        </div> */}

                        {/* {user.isEdit && <div className="sch-custom-check-box">
                            <input type="checkbox" checked={user.status}  className="form-check-input" id="exampleCheck1" onClick={(e) => setMyState(setUser, {
                                    status: e.target.checked
                                })} />
                            <label className="form-check-label" htmlFor="exampleCheck1">Status</label>

                        </div>} */}

                        <div className="sch-statusCheckbox sch-relativeClass">
                            {user.isEdit && <div className="sch-custom-check-box">
                                <form>
                                    <div className="form-group">
                                        <input type="checkbox" checked={user.status} className="form-check-input" id="exampleCheck1" onClick={(e) => setMyState(setUser, {
                                            status: e.target.checked
                                        })} />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Status</label>
                                    </div>
                                </form>
                            </div>}
                        </div>

                        <button type="submit" className="pxl_btn"> {btnLoading ? "Processing..." : user.isEdit ? "Update" : 'Submit'}</button>
                    </form>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={()=> handleClose()}>Close</Button>
                </Modal.Footer> */}
            </Modal>


        </>
    )
};

export default Users;