import { useState, useEffect } from 'react'
import { commonToast, hitApi } from '../../../../components/commonAction';
import svg from '../svg';
import ConfirmationPopup from '../../../../components/common/ConfirmationPopup';

function HomePage({ p_id, homeData }) {
    const [bannerHeading, setbannerheading] = useState('');
    const [bannerDescription, setBannerDescription] = useState('');
    const [bannerBtn, setBannerBtn] = useState('');
    const [bannerURL, setBannerURL] = useState('');
    const [bannerFile, setbannerFile] = useState('');
    const [home_id, setHome_id] = useState('')
    const img_path = `${process.env.s3URL}usercontent/`;
    const [showPopUp, setShowPopup] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [IsData, setIsData] = useState(false);
    const [isDataload, setIsDataload] = useState(false);
    const [isbtn, setIsbtn] = useState(false);
    
    useEffect(() => {

        if (homeData) {
            setIsData(true);
            setHome_id(homeData._id);
            setbannerheading(homeData.heading)
            setBannerDescription(homeData.description)
            setBannerURL(img_path + `LandingPage/${homeData.agency_id}/` + homeData.home_image);
            setBannerBtn(homeData.button_title);
            setIsEdit(false);
        } else {
            setIsEdit(true);
        }
    }, [homeData])


    const uploadBannerImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type.includes('image/')) {
                const url = URL.createObjectURL(file);
                setBannerURL(url);
                setbannerFile(file);
            } else {
                commonToast('error', `Only PNG and JPG are allowed!`);
            }
        }

    }


    const bannerContentSubmit = (e) => {

        e.preventDefault();
        setIsbtn(true)
        if (!bannerHeading || !bannerDescription || !bannerBtn) {
            setIsbtn(false)
           
            commonToast('error', `Field can not be empty!!`);
            return false;
        } else {
            const formData = new FormData();
            if (p_id) {
                setIsbtn(false)
                formData.append('project_id', p_id)
            }

            formData.append('homepage_id', home_id)
            formData.append('heading', bannerHeading);
            formData.append('description', bannerDescription);
            formData.append('button_title', bannerBtn);
            if (bannerFile) formData.append('home_image', bannerFile);
            hitApi({
                url: 'pixa-support/api/manageHomePage',
                method: "POST",
                data: formData,
                loading: false,
                alert: false,
                isFormData: true
            }, (resp, err = null) => {
                if (resp.status === 1) {
                    setIsbtn(false)
                    setIsData(true);
                    setIsEdit(false);
                    setHome_id(resp.data._id);
                    setbannerheading(resp.data.heading)
                    setBannerDescription(resp.data.description)
                    setBannerURL(img_path + `LandingPage/${resp.data.agency_id}/` + resp.data.home_image);
                    setBannerBtn(resp.data.button_title);

                }
            });
        }
    }


   
   

    return (  <>
        <form onSubmit={bannerContentSubmit } >
            <h4>Home Section</h4>
            <div className='mail_back_color_box'>
            {IsData ? <>
                <div className="land_Action_edit" >
                    {svg.edit_icon}
                </div>
                <div className="land_action_del" onClick={()=>setShowPopup(true)} >
                    {svg.delete_red}
                </div>
                </> : null}
                
                <div className="pxl_input_feilds mb-3">
                    <label>Heading</label>
                    <input type="text" disabled={isEdit ? null : true} name="" placeholder="Enter Heading" value={bannerHeading} onChange={(e) => setbannerheading(e.target.value)} />
                </div>
                <div className="pxl_input_feilds mb-3">
                    <label>Sub Heading</label>
                    <textarea type="text" disabled={isEdit ? null : true} name="" placeholder="Enter Sub Heading" value={bannerDescription} onChange={(e) => setBannerDescription(e.target.value)} />
                </div>
                <div className="pxl_input_feilds mb-3">
                    <label>Button Title</label>
                    <input type="text" name="" disabled={isEdit ? null : true} placeholder="Enter Button Title" value={bannerBtn} onChange={(e) => setBannerBtn(e.target.value)} />
                </div>
                <div className="pxl_input_feilds mb-4">
                    <label>Banner</label>
                    <div className="pxl-custom-file-upload pxl-upload-file-profile">
                        <div className="pxl-custom-file-select">
                            {bannerURL ? <div className='image_url_wrap'> <img src={bannerURL} width={'60px'} />
                                <a className="img_Cross" onClick={() => {
                                    setBannerURL('');
                                    setbannerFile('')
                                }}>  {svg.crossSvg1}</a>
                            </div> : <>
                                <div className="pxl-custom-file-select-button" id="fileName">
                                    <img src="/assets/images/newpost/file-upload1.svg" alt="" />
                                </div>
                                <div className="pxl-custom-file-select-name" id="noFile">
                                    <span> <b>Upload</b> or drop your file here </span>
                                </div>
                                <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => uploadBannerImage(e)} />
                            </>
                            }
                        </div>
                    </div>
                </div>

                {
                    IsData   ?  <button type='submit'  disabled={isEdit ? null : true} style={{cursor: isEdit ?  'pointer':'not-allowed',gap: '6px'}} className="sch-btn" >{isbtn ? "Processing..." : 'Update'}</button>
                :
                <button type='submit' className="pxlCommon-btn" style={{ gap: '6px' }}>
              
                {isbtn ? "Processing..." : 'Add'}
            </button>
                }
                
            </div>
        </form>
         <ConfirmationPopup
         shownPopup={showPopUp}
         closePopup={() => {
            setShowPopup(false);
            //  setFeatureID('')
         }}
         type={"feature"}
         btnLoader={isDataload}
         removeAction={() => {
            setIsDataload(true)
            hitApi({
                url: 'pixa-support/api/deletePage',
                method: "POST",
                data: {
                    homepage_id: home_id,
                },
                loading: true,
            }, (resp, err = null) => {
                if (resp.status === 1) {
                    setIsDataload(false)
                    setShowPopup(false);
                    setHome_id('');
                    setbannerheading('')
                    setBannerDescription('')
                    setBannerURL('');
                    setBannerBtn('');
                    setIsData(false)
                    setIsEdit(false)
                }
            });

         }}
     />
    </>
    )
}

export default HomePage