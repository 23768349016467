import React, { useState, useEffect, useRef } from 'react';
import { hitApi, setMyState, assetsUrlPath,teamMemberRestrictionMess } from "../../commonAction";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import schedulerSvg from '../../../pages/scheduler/components/schedulerSvg';
import { Modal } from 'react-bootstrap';
import Select from "react-select";
let TiktokBtn = ({ selectedAccountsSettings,title = 'Add New Account',  parent = "social",containsSchedularWrite,load,currentAccount,settings,setmodel,handleCheckbox1,handleCheckbox}) => {
    let [state, setstate]=useState({
        privacyLevel : "",
        Comment : false, 
        Duet : false, 
        Stitch : false,
        videoContentDis : false,
        Brand : false,
        BrandContent : false, 
    })
    let [loads,setload]=useState(false)

    console.log(9191,selectedAccountsSettings)
    useEffect(()=>{
        if( loads )
        {
            if(!state.videoContentDis || state.privacyLevel=="SELF_ONLY"){
                setvalue("","",{
                    Brand : false,
                    BrandContent : false, 
                    videoContentDis : false
                })
            }
        }
    },[state.videoContentDis,state.privacyLevel])

    useEffect(()=>{
       
        let d1=selectedAccountsSettings?.[settings?.accountId]
        console.log({d1})
        if(d1)
        {
            setstate(d1)
            setload(true)
        }
        setload(true)
    },[settings])

    const tiktokLogin = () => {
        let data={}
        if(currentAccount)
        {
            data.id=currentAccount?.data?.id
        }
        hitApi({
            url: 'social-account/get-tiktok-auth-link',
            method: "GET",
            data,
        }, (resp, err = null) => {
            if (resp) {
                var openWindow = window.open(resp.data.url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
                var popupTick = setInterval(function() {
                    if (openWindow.closed) {
                        clearInterval(popupTick);
                        setTimeout(() => {
                            load(s1=>!s1)
                        }, 200);
                    }
                }, 500);
            }
        });
    }
    const options=[
        {
            value : "PUBLIC_TO_EVERYONE",
            label :"Everyone",
        },
        {
            value : "MUTUAL_FOLLOW_FRIENDS",
            label :"Friends",
        },
        {
            value : "SELF_ONLY",
            label :"Only me",
        }
    ]

    const selectPrivacy =(value)=>{
        console.log("e.value",value)
        setvalue("privacyLevel",value)
    }

    const setvalue=(key , value,obj=null)=>{
        if (obj) {
          setstate({
            ...state,
            ...obj,
          });
        } else {
          setstate({
            ...state,
            [key]: value,
          });
        }
      
    }
    
    return(
        <>
        	{parent == 'socialAccComponent' ?
				<a  onClick={tiktokLogin}>
					<div className="sch-user-img sch-user-img-add">
						{schedulerSvg.app.addNewSvg}
					</div>
					<div className="sch-user-name">
						<h2>{title}</h2>
					</div>
				</a>
				:
                parent == 'reconnect' ?
                <div className={` pxl-tooltip-button-form pxl-reload-tooltip  ${containsSchedularWrite ? "" : 'pxl-tooltip-btn-diable'}`} >

                <button className={`cun-socialIcon cun-socail-delete ${containsSchedularWrite ? '' : 'sch-btn-disbale'}`} disabled={!containsSchedularWrite} onClick={tiktokLogin}>

                    <img src="/assets/images/social/reconnect.svg" alt="" />

                    <div className="cun-show-tooltip">{containsSchedularWrite ? <p>Reconnect</p> : teamMemberRestrictionMess(containsSchedularWrite)}

                    </div>

                </button>

            </div>
				// <div onClick={tiktokLogin}>reconnect</div>
				:
                <div className='pxl-add-new-accountBtn cun-socialIcon'> 

                <button className={`pxlCommon-btn ${containsSchedularWrite ? "" : 'sch-btn-disbale'}`}  disabled={!containsSchedularWrite}  onClick={tiktokLogin}>{title}</button> 

                {!containsSchedularWrite &&  <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(containsSchedularWrite)}</p></div>}

                </div>
			}
            { settings?.status && 
              <Modal
                show={settings?.status} 
                onHide={()=>{
                    
                    setstate({
                        privacyLevel : "",
                        Comment : false, 
                        Duet : false, 
                        Stitch : false,
                        videoContentDis : false,
                        Brand : false,
                        BrandContent : false, 
                    })

                    handleCheckbox()
                }} 
                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv pxl_ticktok_modal"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className='pxl_model_body'>
                        <div className='pxl_ticktok_modal_wrap'>
                            <h2 className='modal-centered-title'>TickTok</h2>
                            <div className='pxl_input_feilds'>
                                <label>Who can view this</label>
                                <Select options={options} value={options.filter(option =>option.value == state.privacyLevel)} 
                                    onChange={(e) => selectPrivacy(e.value)}
                                />
                            </div>
                            <div className='pxl_input_feilds'>
                                <label>Allow users to</label>
                                <div className='pxl_custom_checkbox_wrap'>
                                    <div className="pxl_custom_checkbox">
                                        <input
                                            type="checkbox"
                                            id={1}
                                            style={{display : "initial"}}
                                            checked={state.Duet}
                                            onChange={(e) => {setvalue("Duet", !state.Duet)}}
                                        />
                                        <label htmlFor={1}>{'Duet'}</label>
                                    </div>
                                    <div className="pxl_custom_checkbox">
                                        <input
                                            type="checkbox"
                                            id={2}
                                            style={{display : "initial"}}
                                            checked={state.Stitch}
                                            onChange={(e) => {setvalue("Stitch", !state.Stitch)}}
                                        />
                                        <label htmlFor={2}>{'Stitch'} </label>
                                    </div>
                                    <div className="pxl_custom_checkbox">
                                        <input
                                            type="checkbox"
                                            id={3}
                                            style={{display : "initial"}}
                                            checked={state.Comment}
                                            onChange={(e) => {setvalue("Comment", !state.Comment)}}
                                        />
                                        <label htmlFor={3}>{'Comment'}</label>
                                    </div>
                                </div>
                            </div>
                            <div className='pxl_input_feilds plx_has_switch'>
                                <label className={"plx_custom_switch"} htmlFor="DiscloseVideo">
                                    <input disabled={state.privacyLevel=="SELF_ONLY"} type="checkbox" id="DiscloseVideo"
                                     checked={state.videoContentDis}
                                     onChange={(e) => {setvalue("videoContentDis", !state.videoContentDis)}}
                                    />
                                    <div className={"plx_slider".concat(state.privacyLevel=="SELF_ONLY" ? " disable" : "")}></div>
                                </label>
                                <label>Disclose video content</label>
                            </div>  
                            {state.videoContentDis && 
                            <div className='pxl_custom_checkbox_wrap'>
                                <div className="pxl_custom_checkbox">
                                    <input
                                        type="checkbox"
                                        id={"Brand"}
                                        style={{display : "initial"}}
                                        checked={state.Brand}
                                        onChange={(e) => {setvalue("Brand", !state.Brand)}}
                                    />
                                    <label htmlFor={"Brand"}>{'Your brand'}</label>
                                </div>

                                <div className="pxl_custom_checkbox">
                                    <input
                                        type="checkbox"
                                        id={4}
                                        style={{display : "initial"}}
                                        checked={state.BrandContent}
                                        onChange={(e) => {setvalue("BrandContent", !state.BrandContent)}}
                                    />
                                    <label htmlFor={4}>{'Branded Content'}</label>
                                </div>

                            </div>
                                }
                       <div class="pxl_input_feilds">By posting, you agree to TikTok's 
                        {(state.Brand || state.BrandContent) &&
                        <>
                         <a href="https://www.tiktok.com/legal/page/global/bc-policy/en" target="_blank"> Branded Content Policy</a> and
                         </>
                        }
                       
                         <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" target="_blank"> Music Usage Confirmation</a>.</div>
                            <div className='pxl_btn_wrap_tiktok'>
                                <button className="pxlCommon-btn" onClick={()=>{
                                    handleCheckbox1({
                                        [settings.accountId] : state
                                    })
                                    setmodel({
                                        status : false,
                                        type : "",
                                        accountId : ""
                                    })
                                    setstate({
                                        privacyLevel : "",
                                        Comment : false, 
                                        Duet : false, 
                                        Stitch : false,
                                        videoContentDis : false,
                                        Brand : false,
                                        BrandContent : false, 
                                    })
                                }}>Save</button>
                                <button className='sch-common-btn-white'>Cancel</button>
                            </div>                
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
            }
        </>
    );
}

export default TiktokBtn;