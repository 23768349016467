import { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { commonToast, hitApi } from '../../../components/commonAction';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

function CreateProjectPopUP({
    openPopUp,
    onClose,
    app
}) {
    const [projectName, setProjectName] = useState('');
    const [isbtn, setIsbtn] = useState(false);
    const navigate = useNavigate();
    const firstInputRef = useRef(null);
    const [agentlist, setAgentList] = useState([]);
    const [agentId, setAgentId] = useState([]);
    let animatedComponents = makeAnimated();

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [openPopUp]);

    useEffect(() => {
        if(app != 'builder')
            {
                const fetchAgent = () => {
                    let data = { role: 'user', limit: 'all' };
            
                    hitApi({
                        url: `team-member/member-list`,
                        method: "GET",
                        data: data,
                        alert: false,
                    }, (resp, err = null) => {
                        if (resp.status) {
                            const activeAgent = resp.data.records.filter(val => val.status == 1);
                            let filterAgent = activeAgent?.map(agent => ({ label: agent.name, value: agent._id }));
                            setAgentList(filterAgent);
                        }
                    });
            
                }
                fetchAgent();
            }
    },[])


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!projectName.trim()) {
            commonToast('error', `Project Name is required!`);
            return false;
        } else {

            let data = {};
            if (app == 'builder') {
                data['title'] = projectName;
            } else {
                data['project_name'] = projectName;
                if(agentId.length)
                {
                    data['agent_id'] = agentId
                }
            }
            setIsbtn(true)

            hitApi({
                url: app == 'builder' ? 'page-builder/add-project' : 'pixa-support/api/create_project',
                method: "POST",
                data: data,
                loading: true,
                alert: true,
            }, (resp, err = null) => {
                setIsbtn(false)
                if (resp.status) {

                    let respData = resp.data;
                    if (respData._id) {
                        onClose();
                        setProjectName('')
                        if (app == 'builder') {
                            window.location.href = `/builder/projects`;
                        } else {
                            navigate(`/pixa-support/create-project?id=${respData._id}&name=${projectName}`);
                        }
                    }
                }

                setAgentId([]);

            });
        }
    }

    return (

        <Modal
            show={openPopUp} onHide={() => {
                setIsbtn(false)
                setAgentId([]);
                onClose();
                setProjectName('');
            }} animation={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="pxl_modalDv pxl-createProject-modal"
        >


            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className='pxl-model-header'>
                        <h2 className='modal-title'>Create Project</h2>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <div className='modal-body'>
                <form action="" className=''
                    onSubmit={handleSubmit}
                >
                    <div className="pxl_input_feilds mb-3">
                        <label>Project Name <span className="pxl-star-red">*</span></label>
                        <input ref={firstInputRef} type="text" name="" placeholder="Enter Project Name" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                    </div>
                    {app == 'builder' ? null :
                         <div className="form-group scope pxl-selectBrand">
                         <label htmlFor="exampleFormControlSelect2">Assign Agents <span className="pxl-star-red">*</span></label>
                         <Select
                             closeMenuOnSelect={false}
                             components={animatedComponents}
                             noOptionsMessage={() => { return 'No agent found'}}
                             value={agentlist.filter(val => agentId.includes(val.value))}
                             isMulti
                             options={agentlist}
                             onChange={(option) => {
                                 let agentIds = option.map(val => val.value);
                                 setAgentId(agentIds)
                             }
                             }
                         />
                     </div>

                    }
                    <button type="submit" className="pxlCommon-btn" disabled={isbtn}>
                        {isbtn ? "Processing..." : 'Add'}
                    </button>
                </form>
            </div>

        </Modal>

    )
}

export default CreateProjectPopUP