import { Outlet, Link, NavLink } from "react-router-dom";
import {Helmet} from "react-helmet";
import { useStore } from "../../zustand/store"; 
import Sidebar from "../../components/Sidebar";
import { useState } from "react";



const Dashboard = ({pageTitle}) => {
    let store = useStore(state => state);
    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper pxl-admin-dashboard-wrap">
                <div className="pxl_rightBar_assets">
                    {/* Count Card Widgets Section */}
                    <div className="pxl_pageTitle">
                        <h2>{pageTitle}</h2>
                    </div>
                    <div className="pxl-count-cards">
                        <div className="pxl_container">
                            <div className="pxl_grid">
                                <div className="pxl_revenueLeftBox">
                                    <div className="px_revenueBox">
                                        <p>Today : <span>$520</span></p>
                                        <p>This week : <span>$520</span></p>
                                        <p>This Month : <span>$520</span></p>
                                        <p>This Year : <span>$520</span></p>
                                        <p>Total: <span>$2080</span></p>
                                    </div>
                                    <div className="pxl_btnright_Dv">
                                        <button className="pxlCommon-btn">View All</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>

        </>
    )
};

export default Dashboard;