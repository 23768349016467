import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import { useStore } from "../../zustand/store";
import svg from "../../components/svg";
import Accordion from 'react-bootstrap/Accordion';
import { hitApi, setMyState, assetsUrlPath, skeletonLoader, Pagination, NoDataFound, commonToast } from '../../components/commonAction';
import ConfirmationPopup from '../../components/common/ConfirmationPopup';
import { Modal } from 'react-bootstrap';


const Assets = ({ pageTitle }) => {


    const [state, setState] = useState({
        category: [],
        limit: 10,
        page: 1,
        keyword: '',
        sort: 'createdAt=-1',
        status: '',
        totalRecords: 0,
        categoryId: '',
        parentId: '',
        modelType: '',
        title: '',
        editId: '',
        clearKeyword: false,
        assetFile: '',
        assetTag: [],
        assetTagInput: '',
        assetTarget: '',
        categoryLoading: false,
        assetLoading: false,
        subCategoryLoading: false,
        activeAssetBtn: false,
        confirmDelete: {},
        deletePopUp: false,
        loadMoreActive: false,
        assetStatus: '',
        editIndex: '',
        playVideoId: "",
        playAudioId: "",
    });
    const videoRef = useRef(null);
    const audioRef = useRef(null);
    const [totalRecords, setTotalRecords] = useState('');
    const [assetsList, setAssetsList] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    let store = useStore(state => state);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
        setShowModal(false);
        setMyState(setState, { ...state, modelType: '', editId: '', title: '' });
    }
    const handleShow = (item) => { setShowModal(true) };

    const [ImageModal, setImageModal] = useState(false);
    const handleImageClose = () => {
        setImageModal(false);
    }
    const handleImageShow = (item) => { setImageModal(true) };

    const [assetsModal, setAssetsModal] = useState(false);
    const handleAssetsClose = () => {
        setAssetsModal(false);
        setMyState(setState, { ...state, assetFile: '', assetTag: [], assetTagInput: '', assetTarget: '' });
    }
    const handleAssetsShow = (item, i) => {
        setAssetsModal(true);
        if (item) {
            setMyState(setState, { ...state, assetFile: '', assetTag: item.tags ? item.tags : [], assetTarget: item._id, assetTagInput: '', status: item.status ? true : false, editIndex: i });
        }
    };
    const [btnLoading, setBtnLoading] = useState(false);



    useEffect(() => {
        getCategory();

    }, [])

    useEffect(() => {
        getAssets();
    }, [state.page, state.assetStatus, state.parentId, state.categoryId, state.clearKeyword])

    const clearSearch = () => {
        setMyState(setState, { ...state, clearKeyword: true, keyword: '' });
    }

    const getCategory = () => {
        let { limit, page, keyword, sort } = state;
        // setMyState(setState, { categoryLoading: true, });
        hitApi({
            url: 'assets/get-category',
            method: "GET",
            data: { limit: 'all', page, keyword, sort }
        }, (resp, err = null) => {
            if (resp) {
                // setMyState(setState, {
                //     ...state,
                //     category: resp.data.records,
                //     categoryLoading: false,                    
                // });
                setSubCategory(resp.data.records);
                getSubCategory(resp.data.records);
                // resp?.data?.records.length && getSubCategory(resp?.data?.records?.[0]._id);
            }
        });
    }
    const getSubCategory = (parId) => {
        let { limit, page, keyword, sort } = state;
        // if (parId) {
        // setMyState(setState, { subCategoryLoading: true, });
        hitApi({
            url: 'assets/get-sub-category',
            method: "GET",
            data: {
                limit: 'all', page, keyword, sort,
                //  parentId: parId
            }
        }, (resp, err = null) => {
            // setMyState(setState, { subCategoryLoading: false, });
            if (resp) {
                const subcategoryMap = {};
                resp.data.records.forEach((sub) => {
                    if (!subcategoryMap[sub.parentId]) {
                        subcategoryMap[sub.parentId] = [];
                    }
                    subcategoryMap[sub.parentId].push(sub);
                });
                // Create the new array with subcategories included
                const newArray = parId.map((cat) => ({
                    ...cat,
                    subCat: subcategoryMap[cat._id] || [], // Default to an empty array if no subcategories
                }));
                setMyState(setState, {
                    // ...state,
                    category: newArray,
                });
                // setSubCategory(resp.data.records);
                // resp?.data?.records.length > 0 && setMyState(setState, { categoryId: resp?.data?.records?.[0]._id });
            }
        });
        // }
    }

    const getAssets = () => {

        let data = { limit: state.limit, page: state.page, keyword: state.keyword, sort: state.sort, status: state.assetStatus };
        // if (state.parentId && state.categoryId) {
        data['categoryId'] = state.parentId;
        data['subCategory'] = state.categoryId;
        // }


        if (!state.categoryId && state.parentId) {
            setAssetsList([]);
            return false;
        }
        setMyState(setState, { assetLoading: true });
        hitApi({
            url: 'assets/get-assets',
            method: "GET",
            data: data
        }, (resp, err = null) => {
            setMyState(setState, { assetLoading: false, });
            if (resp) {
                setTotalRecords(resp.data.totalRecords)

                if (state.loadMoreActive) {
                    setAssetsList(prev => [...prev, ...resp.data.records]);
                } else {
                    setAssetsList(resp.data.records);
                }
            }
        });

    }


    const handleSelect = (eventKey) => {
        const foundObject = state.category.find(item => item._id === eventKey);
        setMyState(setState, { ...state, parentId: eventKey, categoryId: '', activeAssetBtn: true, categoryId: foundObject.subCat.length > 0 && foundObject.subCat[0]._id });
        // getSubCategory(eventKey);
    };


    const handleCategory = (e) => {
        e.preventDefault();
        if (!state.title) {
            commonToast('error', `Title is required.`);
            return false
        }
        if (state.modelType == "addCategory") {
            setBtnLoading(true)
            hitApi({
                url: 'assets/create-category',
                method: "POST",
                data: { title: state.title }
            }, (resp, err = null) => {
                setBtnLoading(false)
                if (resp) {
                    let cateList = state.category;
                    cateList.push(resp.data);
                    setMyState(setState, { ...state, modelType: '', title: '', category: cateList, loadMoreActive: false });
                    handleClose();
                }
            });
        } else {
            setBtnLoading(true)
            hitApi({
                url: 'assets/update-category',
                method: "PATCH",
                data: { title: state.title, target: state.editId, status: state.status }
            }, (resp, err = null) => {
                setBtnLoading(false)
                if (resp) {
                    let cateList = state.category;
                    cateList[state.editIndex] = {
                        ...cateList[state.editIndex],
                        title: state.title,
                        status: state.status,
                    }

                    setMyState(setState, {
                        ...state,
                        modelType: '',
                        editId: '',
                        title: '',
                        category: cateList, loadMoreActive: false
                    });
                    handleClose();
                }
            });
        }
    }

    const handleSubCategory = (e) => {
        e.preventDefault();
        if (!state.title) {
            commonToast('error', `Title is required.`);
            return false
        }
        if (state.modelType == "addSubCategory") {
            setBtnLoading(true)
            hitApi({
                url: 'assets/create-sub-category',
                method: "POST",
                data: { title: state.title, parentId: state.parentId }
            }, (resp, err = null) => {
                setBtnLoading(false)
                if (resp) {
                    setSubCategory(prev => [...prev, resp.data])
                    setMyState(setState, { ...state, modelType: '', editId: '', title: '', loadMoreActive: false });
                    handleClose();
                }
            });
        } else {
            setBtnLoading(true)
            hitApi({
                url: 'assets/update-sub-category',
                method: "PATCH",
                data: { title: state.title, target: state.editId, parentId: state.parentId, status: state.status }
            }, (resp, err = null) => {
                setBtnLoading(false)
                if (resp) {
                    const updatedState = subCategory.map((obj, index) => {
                        if (index == state.editIndex) {
                            return { ...obj, status: state.status, title: state.title };
                        }
                        return obj;
                    });
                    setSubCategory(updatedState);
                    setMyState(setState, { ...state, modelType: '', editId: '', title: '', status: '', editIndex: '', loadMoreActive: false });
                    handleClose();
                }
            });
        }
    }

    const addAssetTag = (e) => {
        e.preventDefault();
        if (state.assetTagInput) {
            const newList = [...state.assetTag];
            newList.push(state.assetTagInput);
            setMyState(setState, { ...state, assetTag: newList, assetTagInput: '' });
        }
    }

    const removeAssetTag = (index) => {
        const newList = [...state.assetTag];
        newList.splice(index, 1);
        setMyState(setState, { ...state, assetTag: newList });
    }

    const addAsset = () => {
        if (Object.keys(state.assetFile).length > 0) {
            const formData = new FormData();
            Object.values(state.assetFile).forEach((file, index) => {
                formData.append(`files`, file);
            });
            if (state.assetTag.length > 0) {
                state.assetTag.forEach((tag, index) => {
                    formData.append('tags[]', tag);
                })
            } else if (state.assetTagInput) {
                if (state.assetTagInput.includes(",")) {
                    const outputArray = state.assetTagInput.split(",");
                    outputArray.forEach((tag, index) => {
                        formData.append('tags[]', tag);
                    })

                } else {
                    formData.append('tags[]', state.assetTagInput);
                }
            }

            formData.append('category', state.parentId);
            formData.append('subCategory', state.categoryId ? state.categoryId : subCategory.length && subCategory[0]._id);

            setBtnLoading(true);
            hitApi({
                url: 'assets/add-assets',
                method: "POST",
                data: formData,
                isFormData: true,
            }, (resp, err = null) => {
                setBtnLoading(false);
                handleAssetsClose(false);
                setAssetsList(prev => [...prev, resp.data[0]])
                setMyState(setState, {
                    ...state,
                    assetTag: [],
                    assetFile: '',
                    assetTagInput: ''
                });

            });
        } else {
            if (Object.keys(state.assetFile).length == 0) {
                commonToast('error', `Asset file is required.`);
                return false
            }
        }
    }


    const updateAsset = () => {
        if (state.assetTarget) {
            handleAssetsClose(true)
            let data = { target: state.assetTarget, status: state.status };
            if (state.assetTag.length > 0) {
                data['tags'] = state.assetTag;
            } else if (state.assetTagInput) {
                if (state.assetTagInput.includes(",")) {
                    const outputArray = state.assetTagInput.split(",");
                    data['tags'] = outputArray;
                } else {
                    data['tags'] = state.assetTagInput;
                }
            }
            hitApi({
                url: 'assets/update-assets',
                method: "PATCH",
                data: data,
            }, (resp, err = null) => {
                setBtnLoading(false);
                if (resp) {

                    handleAssetsClose(false);
                    const updatedState = assetsList.map((obj, index) => {
                        if (index == state.editIndex) {
                            return { ...obj, status: data.status, tags: data.tags };
                        }
                        return obj;
                    });
                    setAssetsList(updatedState);
                    setMyState(setState, { ...state, assetTag: [], assetFile: '' });
                }
            });
        }
    }

    const playVideoIdMouseEnter = async (id) => {
        setMyState(setState, { playVideoId: id });
        setTimeout(() => {
            try {
                if (videoRef.current && videoRef.current.paused) {
                    videoRef.current.play();
                }
            } catch (error) {
                console.error('Error playing video:', error);
            }
        }, 1000);
    }

    const pauseVideoMouseLeave = () => {
        setMyState(setState, { playVideoId: '' });
        if (videoRef.current && !videoRef.current.paused) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    }

    const playAudioIdMouseEnter = async (id) => {
        setMyState(setState, { playAudioId: id });
        setTimeout(() => {
            try {
                if (audioRef.current != null && audioRef.current != undefined && audioRef.current != '') {
                    audioRef.current.play()
                }
            } catch (error) {
                console.error('Error playing audio:', error);
            }
        }, 1000);
    }

    const pauseAudioMouseLeave = async () => {
        setMyState(setState, { playAudioId: '' });
        if (audioRef.current && !audioRef.current.paused) {
            await audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }

    }

    const firstInputRef = useRef(null);

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [assetsModal]);

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [showModal]);
    
    return (

        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper">

                <div className="pxl_rightBar_assets">
                    <div className="pxl_pageTitle">
                        <h2>{pageTitle}</h2>
                    </div>

                    {/* Admin Assets Section  */}
                    <div className="pxl-template-wrapper">
                        <div className="pxl-template-container">
                            {/* Assets Category */}

                            <div className=''>
                                <div className="pxl_pageTitleInner pxl-pageTitleSpacing">
                                    <h2>Assets ({assetsList.length})</h2>
                                </div>
                                <div className="pxl-template-categories pxl-assets-filter">
                                    <div className="pxl-template-row">

                                        <div className="pxl-template-filter-header">
                                            <h4>Assets Type</h4>

                                            <div className='pxl-assets-plus' onClick={() => {
                                                handleShow();
                                                setMyState(setState, {
                                                    modelType: 'addCategory'
                                                })
                                            }}>
                                                    <a href="#" onClick={(e)=>{
                                                        e.preventDefault()
                                                        }}>{svg.app.Addsvg}</a>
                                            </div>
                                        </div>
                                        <div className="pxl-template-filter-body">
                                            <div className='pxl-accordian-section'>
                                                <Accordion defaultActiveKey={state.parentId} flush onSelect={handleSelect}>
                                                    {!state.categoryLoading ? state?.category.length > 0 ? state?.category.map((category, i) =>
                                                        <Accordion.Item eventKey={category._id} key={category._id}>
                                                            <Accordion.Header>
                                                                <div className='pxl-accordian-header'>
                                                                    <h2><span>{svg.app.sorting}</span>{category.title}</h2>
                                                                </div>

                                                            </Accordion.Header>
                                                            <div className='pxl-accordion-icons-right'>
                                                                <div className='pxl-icon-tooltip' >
                                                                    <span className='pxl-tooltip-text'>plus</span>
                                                                    <a onClick={() => {
                                                                        handleShow();
                                                                        setMyState(setState, {
                                                                            modelType: 'addSubCategory',
                                                                            parentId: category._id,

                                                                        })
                                                                    }}>{svg.app.Addsvg}</a>
                                                                </div>
                                                                <div className='pxl-icon-tooltip'>
                                                                    <span className='pxl-tooltip-text'>Remove</span>
                                                                    <a href="#" onClick={(e)=>{
                                                                        e.preventDefault()
                                                                        }}>{svg.app.Removesvg}</a>
                                                                </div>
                                                                <div className='pxl-icon-tooltip'>
                                                                    <span className='pxl-tooltip-text'>edit</span>
                                                                    <a onClick={() => {
                                                                        handleShow();
                                                                        setMyState(setState, {
                                                                            modelType: 'editCategory',
                                                                            title: category.title,
                                                                            editId: category._id,
                                                                            status: category.status,
                                                                            editIndex: i
                                                                        })
                                                                    }}>{svg.app.editIconWhite}</a>
                                                                </div>
                                                            </div>
                                                            <Accordion.Body>
                                                                <div className='pxl-accodian-sub-category-body '>
                                                                    {!state.subCategoryLoading ? (category?.subCat.length > 0) ? category?.subCat.map((val, i) => (val.parentId == category._id) && <div className={`pxl-accodian-sub-category ${state.categoryId ? state.categoryId == val._id && 'pxl-sub-categoryActive' : i == 0 && 'pxl-sub-categoryActive'}`} key={val._id} onClick={() => {
                                                                        setMyState(setState, {
                                                                            categoryId: val._id
                                                                        });
                                                                    }}>

                                                                        <h2>{val.title}</h2>
                                                                        <div className='pxl-accordion-icons-right'>
                                                                            <div className='pxl-icon-tooltip'>
                                                                                <span className='pxl-tooltip-text'>edit</span>
                                                                                <a onClick={() => {
                                                                                    handleShow();
                                                                                    setMyState(setState, {
                                                                                        modelType: 'editSubCategory',
                                                                                        title: val.title,
                                                                                        editId: val._id,
                                                                                        parentId: category._id,
                                                                                        status: val.status,
                                                                                        editIndex: i
                                                                                    })
                                                                                }}>{svg.app.editIconWhite}</a>
                                                                            </div>
                                                                            <div className='pxl-icon-tooltip'>
                                                                                <span className='pxl-tooltip-text'>remove</span>
                                                                                <a onClick={() => {

                                                                                    // setMyState(setState, {
                                                                                    //     deletePopUp: true,
                                                                                    //     confirmDelete: {
                                                                                    //         title: 'Assets',
                                                                                    //         url: 'assets/remove-assets',
                                                                                    //         data: { target: val._id }
                                                                                    //     }
                                                                                    // })

                                                                                }} >{svg.app.Removesvg}</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    ) : <div className='pxl-accodian-sub-category' >
                                                                        <h2>No category found.</h2>
                                                                    </div>
                                                                        :
                                                                        <div>{skeletonLoader('list', 2)}</div>
                                                                    }

                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    )
                                                        :
                                                        <div>No assets found.</div>
                                                        :
                                                        <div>{skeletonLoader('list', 4)}</div>
                                                    }
                                                </Accordion>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Assets Section */}
                            <div className="pxl-templates-holder">
                                {/* Assets Status Bar */}
                                <div className="pxl-templates-status-bar pxl-filter-wrapper pxl-searchbar-right">
                                    <div className="pxl-all-templates">
                                        <a href="#" onClick={(e)=>{
 e.preventDefault()
}} className="pxl-filter-mobile-btn">
                                            <img src="/assets/images/filter.svg" alt="" />
                                        </a>
                                        {/* <h4>Assets (341)</h4> */}
                                    </div>
                                    <div className="pxl-head-btn-wrap">
                                        <div className="pxl_filterFeilds">

                                            <div className="pxl_statusFilter pxl_selectOption">
                                                <select className="form-control"
                                                    onChange={(e) => {
                                                        setMyState(setState, {
                                                            assetStatus: e.target.value
                                                        });
                                                    }}
                                                >
                                                    <option value=''>All</option>
                                                    <option value={1}>Active</option>
                                                    <option value={0}>Inactive</option>
                                                </select>
                                            </div>
                                            <div className="sch-search-section">
                                                <div className="sch-search-row sch-search-bg-color-white">
                                                    <input type="text" value={state.keyword} placeholder="Search Here..." onChange={(e) => setMyState(setState, {
                                                        keyword: e.target.value
                                                    })}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                                                                getAssets();
                                                            }
                                                        }} />
                                                    <button type="button" className="sch-search-btn">
                                                        {!state.keyword ? <span> {svg.app.searchIcon} </span> : <span onClick={clearSearch}>{svg.app.crossSvg}</span>}

                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                        <a href="#" className={`sch-common-btn  ${!state.activeAssetBtn && 'pxl-button-disable'}`} data-bs-toggle="modal" data-bs-target="#addAssets" onClick={() => (state.activeAssetBtn) ? handleAssetsShow() : null}>
                                            + Add New Asset
                                        </a>


                                    </div>
                                </div>
                                {/* Main Assets */}
                                <div className="pxl-assets-area">
                                    {state.assetLoading ? <div>{skeletonLoader('asset', 8)}</div> : <div className="pxl-assets-row">

                                        {/* Single Assets */}
                                        {
                                            assetsList.length > 0 ? assetsList.map((asset, i) => (<div className="pxl-assets-section" key={asset._id}>
                                                {asset.isProcessing && <div className='pxl-assets-over pxl-processing'>
                                                    <h2>processing...</h2>
                                                </div>}

                                                <div className="pxl-assets-inner">

                                                    {!asset.tags && <div className='pxl_emptytag'>{svg.app.Circle}</div>
                                                    }
                                                    {
                                                        (asset.mediaType == 'image') && <div>{(asset.files.original.thumb) ?
                                                            <div className="pxl-assets-img">
                                                                <img src={assetsUrlPath(asset.files.original.thumb)} alt="" />
                                                            </div>
                                                            :
                                                            <div className="pxl-assets-img">
                                                                <img src={assetsUrlPath(asset.files.original.path)} alt="" />
                                                            </div>}
                                                        </div>
                                                    }
                                                    {
                                                        (asset.mediaType == 'video') && <div className='pxl-videoAudio' onMouseOver={() => playVideoIdMouseEnter(asset._id)} onMouseLeave={pauseVideoMouseLeave}>
                                                            {(state.playVideoId !== asset._id) && <div className="pxl-video"><div className='pxl-video-svg'>{svg.app.Videosvg} </div></div>}

                                                            <div className="pxl-assets-img " >
                                                                <img src={(asset.files.original.thumb) ? assetsUrlPath(asset.files.original.thumb) : '/assets/images/newpost/video-newpost.png'} alt="" style={{ display: (state.playVideoId == asset._id) ? 'none' : 'block' }} />

                                                                {(state.playVideoId == asset._id) &&
                                                                    <video ref={videoRef} src={assetsUrlPath(asset.files.original.path)} alt="" autoPlay={true} muted={true}
                                                                        playsInline disablePictureInPicture
                                                                        onEnded={() => setMyState(setState, { playVideoId: '' })} onPlay={() => setMyState(setState, { playVideoId: asset._id })} onPause={() => setMyState(setState, { playVideoId: '' })} />
                                                                }
                                                            </div>


                                                        </div>
                                                    }
                                                    {
                                                        (asset.mediaType == 'audio') &&
                                                        <div onMouseOver={() => playAudioIdMouseEnter(asset._id)} onMouseLeave={pauseAudioMouseLeave}>
                                                            {(state.playAudioId !== asset._id) && <div className="pxl-audio"><div className='pxl-audio-svg'>{svg.app.AudioSvg} </div></div>}
                                                            <div className="pxl-assets-img " >
                                                                <img src={asset.files.original.thumb ? assetsUrlPath(asset.files.original.thumb) : '/assets/images/newpost/audio-newpost.png'} alt="" style={{ display: (state.playAudioId == asset._id) ? 'none' : 'block' }} />

                                                                {(state.playAudioId == asset._id) &&
                                                                    <audio ref={audioRef} controls src={assetsUrlPath(asset.files.original.path)} alt="" autoPlay={true} muted={true}
                                                                        onEnded={() => setMyState(setState, { playAudioId: '' })} onPlay={() => setMyState(setState, { playAudioId: asset._id })} onPause={() => setMyState(setState, { playAudioId: '' })} />
                                                                }
                                                            </div>

                                                        </div>
                                                    }

                                                    {asset.status == 1 &&
                                                        <div className="pxl-item-action">
                                                            <ul>
                                                                <li>
                                                                    <a href="#" data-bs-toggle="modal" onClick={() => handleAssetsShow(asset, i)} data-bs-target="#editAssets" className="pxl-edit-icon">
                                                                        {svg.app.editIcon1}
                                                                    </a>
                                                                </li>

                                                                <li>
                                                                    <a href="#" onClick={() => {
                                                                        setMyState(setState, {
                                                                            deletePopUp: true,
                                                                            confirmDelete: {
                                                                                title: 'Assets',
                                                                                url: 'assets/remove-assets',
                                                                                data: { target: asset._id }
                                                                            }
                                                                        })

                                                                    }} data-bs-toggle="modal" data-bs-target="#deleteAssets" className="pxl-delete-icon">
                                                                        {svg.app.deleteIcon1}
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            )
                                            )
                                                :
                                                <NoDataFound title="No asset found for this category." />
                                        }

                                    </div>
                                    }
                                    {assetsList.length > 0 && <Pagination
                                        type="Assests"
                                        dataRange={{
                                            start: assetsList.length > 0 ? 1 : 0,
                                            end: assetsList.length,
                                        }}
                                        loadMore={true}
                                        currentPage={state.page}
                                        totalRecords={totalRecords < assetsList.length ? assetsList.length : totalRecords}
                                        perPage={state.limit}
                                        isLoading={state.assetLoading}
                                        onClick={(pageNum) => {
                                            setMyState(setState, {
                                                page: pageNum,
                                                loadMoreActive: true,
                                            });
                                        }}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showModal} onHide={handleClose} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {state.modelType == 'addCategory' ? "Add New Asset Type" : state.modelType == 'editCategory' ? "Edit Asset Type" : state.modelType == 'addSubCategory' ? "Add New  Category" : "Edit  Category"}

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Add Category */}
                    {(state.modelType == 'addCategory' || state.modelType == 'editCategory') && <form className="" action=""
                        onSubmit={handleCategory}
                    >
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Title</label>
                            <input ref={firstInputRef} type="text" className="form-control" value={state.title} placeholder="Enter Title" onChange={(e) => setMyState(setState, {
                                title: e.target.value
                            })} />
                        </div>
                        {state.modelType == 'editCategory' && <div className="form-check">
                            <input type="checkbox" className="form-check-input" checked={state.status} id="exampleCheck1" onClick={(e) => setMyState(setState, {
                                status: e.target.checked
                            })} />
                            <label className="form-check-label" htmlFor="exampleCheck1">Status</label>
                        </div>}
                        <button type="submit" className="pxl_btn">
                            {btnLoading ? "Processing..." : state.modelType == 'addCategory' ? "Add" : 'Update'}
                        </button>
                    </form>}

                    {/* Add Sub Category */}
                    {(state.modelType == 'addSubCategory' || state.modelType == 'editSubCategory') && <form className="" action=""
                        onSubmit={handleSubCategory}
                    >
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Title</label>
                            <input ref={firstInputRef} type="text" className="form-control" value={state.title} placeholder="Enter Title" onChange={(e) => setMyState(setState, {
                                title: e.target.value
                            })} />
                        </div>
                        {state.modelType == 'editSubCategory' && <div className="form-check">
                            <input type="checkbox" className="form-check-input" checked={state.status} id="exampleCheck1" onClick={(e) => setMyState(setState, {
                                status: e.target.checked
                            })} />
                            <label className="form-check-label" htmlFor="exampleCheck1">Status</label>
                        </div>}
                        <button type="submit" className="pxl_btn">
                            {btnLoading ? "Processing..." : state.modelType == 'addSubCategory' ? "Add" : 'Update'}
                        </button>
                    </form>}

                </Modal.Body>

            </Modal>


            <Modal
                show={assetsModal} onHide={handleAssetsClose} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='pxl-model-header'>
                            <h2 className='modal-title'>{state.assetTarget ? 'Edit Asset' : 'Add New Asset'}</h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='pxl_model_body'>
                        {state.assetTarget ? null : <div className="pxl-custom-file-upload pxl-bottom-20">
                            <label className='pxl-label'>File Upload</label>
                            <div className="pxl-custom-file-select">
                                <div className="pxl-custom-file-select-button" id="fileName">Choose File</div>
                                <div className="pxl-custom-file-select-name" id="noFile">drag file to upload</div>
                                <input ref={firstInputRef} type="file" name="chooseFile" id="chooseFile" multiple onChange={(e) => setMyState(setState, {
                                    assetFile: e.target.files
                                })} />

                            </div>
                            <div>{Array.from(state.assetFile).map(file => file.name).join(' , ')}</div>
                        </div>}
                        <div className='pxl-tokenfield-section'>
                            <label className='pxl-label'>Tags</label>
                            <div className='pxl-tokenfield'>
                                <div className='pxl-tokenfield-inner'>
                                    {state.assetTag && state.assetTag.length > 0 ? state.assetTag.map((val, i) => (
                                        <div className='pxl-tokenfield-child' key={i}>
                                            <p> {val} <span><a onClick={() => removeAssetTag(i)}>{svg.app.crossSvg1}</a></span></p>
                                        </div>
                                    )) : ''}

                                </div>
                                <form onSubmit={(e) => addAssetTag(e)}>
                                    <div className='pxl-tokenfield-input'>
                                        <input ref={firstInputRef} type='text' placeholder='Type and press enter to add tags' value={state.assetTagInput} onChange={(e) => setMyState(setState, {
                                            assetTagInput: e.target.value
                                        })} />
                                    </div>
                                </form>
                            </div>
                        </div>

                        {state.assetTarget && <div className="form-check mt-3">
                            <input type="checkbox" className="form-check-input" checked={state.status} id="exampleCheck1" onClick={(e) => setMyState(setState, {
                                status: e.target.checked
                            })} />
                            <label className="form-check-label" htmlFor="exampleCheck1">Status</label>
                        </div>}

                        <button type="button" className="pxl_btn" onClick={state.assetTarget ? updateAsset : addAsset}>
                            {btnLoading ? "Processing..." : state.assetTarget ? "Update" : "Upload"}
                        </button>
                    </div>
                </Modal.Body>

            </Modal>

            <Modal
                show={ImageModal} onHide={handleImageClose} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='pxl-model-header'>
                            <h2 className='modal-title'> </h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='pxl_model_body'>
                        <div className='pxl-model-assets-img'>
                            <img src="/assets/images/templates/1.jpg" alt="" />
                        </div>
                    </div>
                </Modal.Body>

            </Modal>


            <ConfirmationPopup
                shownPopup={state.deletePopUp}
                closePopup={() => {
                  
                    setMyState(setState, {
                        deletePopUp: false
                    })
                }}
                btnLoader={state.btnLoading}
                type={"assets"}
                removeAction={() => {
                    setMyState(setState, { btnLoading: true });
                    hitApi({
                        url: state.confirmDelete?.url,
                        method: "DELETE",
                        data: state.confirmDelete?.data,
                    }, (resp, err = null) => {
                        setMyState(setState, {
                            deletePopUp: false,
                            confirmDelete: {},
                            btnLoading: false
                        })
                        if (resp) {
                            setMyState(setState, { loadMoreActive: false });
                            getAssets();
                        }
                    })
                }}
            />



        </>
    )
};

export default Assets;