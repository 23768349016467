import React from 'react';
import Parser from 'html-react-parser';

const MediaList = ({ editorData }) => {
    // Parse the CKEditor data to React elements
    const parsedContent = Parser(editorData, {
        replace: (domNode) => {
            // Check if the node is an oembed tag
            if (domNode.name === 'oembed') {
                const url = domNode.attribs.url;
                // Handle YouTube URL conversion
                if (url.includes('youtube.com/watch?v=')) {
                    const embedUrl = url.replace('watch?v=', 'embed/');
                    return (
                        <iframe
                            width="560"
                            height="315"
                            src={embedUrl}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded Media"
                        ></iframe>
                    );
                }
                // Handle other oembed cases if necessary
                return <p>Unsupported media URL: {url}</p>;
            }
        },
    });

    return <div>{parsedContent}</div>;
};

export default MediaList;