import { useState, useEffect, useRef } from 'react'
import { useStore } from '../../../zustand/store'
import { Link } from 'react-router-dom';
import svg from '../components/svg';
import { NoDataFound, Pagination, dateFormatter, getNameInitials, hexToRgbA, hitApi,commonToast, skeletonLoader, useOutsideClick } from '../../../components/commonAction';
import './projectTicket.css'
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import ConfirmationPopup from '../../../components/common/ConfirmationPopup'
import { Modal } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import { Helmet } from 'react-helmet';
import { DateRangePicker } from 'rsuite';
import socket from '../../../socket'
import MediaList from './MediaList'



const sortedBy = [
    // { value: 'all', label: 'All' },
    { value: 'created', label: 'Created Date' },
    { value: 'last', label: 'Last Modified' },
];

function Ticket() {

    const { id } = useParams();
    const p_id = id;
    
    let userData = useStore(state => state)['userData'];
    let { role, scope } = userData;
    let colorTheme = '#6F10B9';
    const img_path = `${process.env.s3URL}usercontent/`;


    let filterLocalVal = JSON.parse(localStorage.getItem("Ticketfilter"))

    const [btnLoader, setBtnLoader] = useState(false);
    const [statusList, setStatusList] = useState([]);
    // const [selectedStatus, setSelectedStatus] = useState((filterLocalVal) ? (filterLocalVal.status) ? filterLocalVal.status : '' : '');
    const [selectedStatus, setSelectedStatus] = useState("");

    const [typeList, setTypeList] = useState([]);
    // const [selectedType, setSelectedType] = useState((filterLocalVal) ? (filterLocalVal.type) ? filterLocalVal.type : '' : '');
    const [selectedType, setSelectedType] = useState('');

    const [priorityList, setPriorityList] = useState([]);
    // const [selectedPriority, setSelectedPriority] = useState((filterLocalVal) ? (filterLocalVal.priority) ? filterLocalVal.priority : '' : '');
    const [selectedPriority, setSelectedPriority] = useState("");

    const [ticketList, setTicketList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currPage, setCurrPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');

    const [selectedSortedBy, setSelectedSortedBy] = useState("created")
    const [shownoData, setShowNoData] = useState(false);

    const [deleteTicketPopUp, setDeleteTicketPopUp] = useState(false);
    const [assignAgnetPopup, setAssignAgentPopup] = useState(false);
    const [agentName, setAgentName] = useState([]);
    const [agentlist, setAgentList] = useState([]);
    const [ticketId, setTicketId] = useState('');
    const [agentId, setAgentId] = useState([]);

    const [selectedStartDate, setselectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');
    const [selectedDate, setSelectedDate] = useState("");
    // const [selectedDate, setSelectedDate] = useState((filterLocalVal) ? filterLocalVal.date : '');
    // const [SelectedCustomerEmail, setSelectedCustomerEmail] = useState((filterLocalVal) ? filterLocalVal.email : '');
    const [SelectedCustomerEmail, setSelectedCustomerEmail] = useState('');
    const [InputCustomerEmail, setInputCustomerEmail] = useState('');
    const [pasteCustomerEmail, setPasteCustomerEmail] = useState('');
    const [selectedTicket, setSelectedTicket] = useState([]);
    const [deleteTicket, setDeleteTicket] = useState([]);
    
    const [deletePopUp, setDeletePopup] = useState(false);
    const [customerDetail, setCustomerDetail] = useState({});
    const [projectName, setProjectName] = useState('');
    const [updatepriority, setUpdatePriority] = useState('')

    const [filterToggle, setFilterToggle] = useState(false);
    const [error, setError] = useState(false);
    const [menuShow, setMenuShow] = useState('');
    const filterRef = useRef();
    const [totalTickets, setTotaltickets] = useState(0);
    let animatedComponents = makeAnimated();
    const [clearCustomer, setclearCustomer] = useState(false);
    const [getcheck, setgetcheck] = useState(false);
    const containsSupportTicketWrite = (role === "teamMember") ? scope?.support ? scope?.support['ticket reply']?.includes("write") : true : (role === 'client') ? false : true;
    const containsSupportTicketDelete = (role === "teamMember") ? scope?.support ? scope?.support['ticket reply']?.includes("delete") : true : (role === 'client') ? false : true;

    const params = new URLSearchParams(window.location.search);
    const params_name = params.get("status");

    const assignAgentFormSubmit = (e) => {
        e.preventDefault();
        if (agentId.length == 0) {
            commonToast('error', `Agent is required.`);
            return false;
        }
        let data = {
            ticket_id: ticketId,
            agent_id: agentId,
            project_id: p_id
        };
        setBtnLoader(true)
        hitApi({
            url: 'pixa-support/api/assign_agent',
            method: 'POST',
            data: data,
            alert: true,
        }, (resp, err = null) => {
            setBtnLoader(false);
            if (resp.status === 1) {
                fetchTickets();
                closeAgentPopUp();
            }
        });
    }

    const closeAgentPopUp = () => {
        setAssignAgentPopup(false);
        setTicketId('');
        setAgentId([])
    }

    //socket status update 
    useEffect(() => {

        socket.on('ticket_status_update', (data) => {
            if (data.project_id == p_id) {
                const updatedList = [...ticketList];

                for (let i = 0; i < updatedList.length; i++) {
                    if (updatedList[i]._id == data._id) {
                        let updatedData = { ...updatedList[i], status: data.status_id, };
                        updatedList[i] = { ...updatedList[i], ...updatedData };
                        break;
                    }
                }
                setTicketList(updatedList);
            }
        })
    }, [ticketList])

    const checkForMail = () => {
        hitApi({
            url: 'pixa-support/api/checkForMail',
            method: 'POST',
            data: {
                status: 1
            },
            alert: false,
        }, (resp, err = null) => {

        });

    }

    useEffect(() => {
        document.body.classList.add('pxl-body-white');
        return () => {
            document.body.classList.remove('pxl-body-white');
        };
    }, []);

    const handleAssignAgent = (ticket) => {
        setAssignAgentPopup(true);
        setTicketId(ticket._id);
        setAgentId(ticket.agent_id);
        setMenuShow('');
    }

    // useEffect(() => {
    //    
    //     setTimeout(() => {
    //         fetchTickets(0)
    //     }, 0);

    // }, [pasteCustomerEmail])

    const timeoutRef = useRef(null);

    // const handleFilterByCustomerEmail = (e) => {
    //     setSelectedCustomerEmail(e.target.value);
    //     setPasteCustomerEmail(SelectedCustomerEmail)
    //    
    //     if (timeoutRef.current) {
    //         clearTimeout(timeoutRef.current);
    //     }

    //     if (!e.target.value) {
    //         setclearCustomer(!clearCustomer);
    //     } else {
    //         timeoutRef.current = setTimeout(() => {
    //             setPasteCustomerEmail(e.target.value)
    //             fetchTickets(0); 
    //         }, 0);

    //     }

    // };

    // // Clean up the timeout when the component unmounts
    // useEffect(() => {
    //     return () => {
    //         if (timeoutRef.current) {
    //             clearTimeout(timeoutRef.current);
    //         }
    //     };
    // }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
          setSelectedCustomerEmail(InputCustomerEmail)
        }, 1000)
    
        return () => clearTimeout(timer)
      }, [InputCustomerEmail])

    const fetchTickets = async (page = currPage, listPerPage = perPage, search = searchTerm) => {

        if (p_id) {

            setLoading(true);

            if (userData.role !== 'customer') {
                hitApi({
                    url: 'pixa-support/api/getTickets',
                    method: 'POST',
                    data: {
                        page: page,
                        listPerPage: listPerPage,
                        searchTerm: search,
                        status_id: selectedStatus,
                        project_id: p_id,
                        type_id: selectedType ? selectedType : '',
                        priority_id: selectedPriority,
                        date: (selectedSortedBy === "created") ? 1 : '',
                        last_modified: (selectedSortedBy === "last") ? 1 : '',
                        startdate: (selectedStartDate) ? selectedStartDate : '',
                        enddate: (selectedEndDate) ? selectedEndDate : '',
                        customer_email: SelectedCustomerEmail
                    },
                    alert: false,
                }, (resp, err = null) => {
                    if (resp.status === 1) {
                        if (resp.data.length === 0) {
                            setShowNoData(true);
                        } else {
                            setShowNoData(false);
                        }
                        setTicketList(resp.data);
                        setPerPage(resp.perPage);
                        setTotalRows(Math.ceil(resp.totalRecords / resp.perPage));
                        setTotaltickets(resp.totalRecords);
                        setCurrPage(resp.currentPage);
                        setError(false);
                        setLoading(false)
                    }else{
                        setLoading(false)
                        setShowNoData(true);
                    }
                    
                    setTimeout(() => {
                        const url = new URL(window.location.href);
                        url.search = '';
                        window.history.replaceState({}, '', url.toString());
                    }, 3000);
                });
            } else {
                hitApi({
                    url: 'pixa-support/api/ticketrecords',
                    method: 'POST',
                    data: {
                        page: currPage,
                        listPerPage: listPerPage,
                        searchTerm: search,
                        project_id: p_id,
                        type_id: (selectedType) ? selectedType.value : '',
                        priority_id: selectedPriority,
                        startdate: (selectedStartDate) ? selectedStartDate : '',
                        enddate: (selectedDate) ? selectedDate[1] : '',
                    },
                    alert: false,
                }, (resp, err = null) => {
                    if (resp.status === 1) {
                        if (resp.data.length === 0) {
                            setShowNoData(true);
                        } else {
                            setShowNoData(false);
                        }
                        setCustomerDetail(resp.userdata)
                        setTicketList(resp.data);
                        setPerPage(resp.perPage);
                        setTotalRows(resp.totalRecords);
                        setCurrPage(resp.currentPage);
                        setTotaltickets(resp.totalRecords)
                        setError(false)
                       
                    }
                    setLoading(false)
                });
            }
        }
    };

    const fetchProj = () => {
        if (p_id) {
            hitApi({
                url: 'pixa-support/api/project_details',
                method: 'POST',
                data: {
                    project_id: p_id,
                },
                alert: false,
            }, (resp, err = null) => {
                if (resp.status === 1) {
                    setProjectName({ projName: resp.data.project_name, projId: resp.data._id });
                    // fetchTickets(0);
                    fetchTicketPriority();
                    fetchTicketStatus();
                    fetchTicketTypes();
                    setError(false)
                    if (userData.role === 'user') fetchAgent();
                } else {
                    setError(true);
                }
            });

        }

    }

    const fetchTicketStatus = async () => {
        hitApi({
            url: 'pixa-support/api/getAllTicketStatus',
            method: 'POST',
            data: {
                userId: (userData.role === 'user') ? userData.userId : userData.agency_id,
            },
            alert: false,
        }, (resp, err = null) => {
            if (resp.status == 1) {
                let activeStatus = resp.data.length > 0 ? resp.data.filter(val => val.status === 1) : [];
                setStatusList(activeStatus);
                if (params_name == 'open') {
                    setFilterToggle(true)
                    setCurrPage(1)
                    // setGetOpenId(resp.data.length > 0 ? resp.data.filter(val => (val.status === 1 && val.name == 'open') && val._id) : '')
                    let get_openId = (resp.data.length > 0 ? resp.data.filter(val => (val.status === 1 && (val.name=='open' || val.name=='Open') )) : [])
                       setSelectedStatus(get_openId[0]._id);
                }
            }
        });

    };
    // useEffect(() => {
    //     statusList.map(i => {
    //         (i.name == nameStatus) ? return (setSelectedStatus(i._id)): ''
    //     })
    // }, [nameStatus])


    const fetchTicketTypes = async () => {
        hitApi({
            url: 'pixa-support/api/getAllTicketTypes',
            method: 'POST',
            data: {
                userId: (userData.role === 'user') ? userData.userId : userData.agency_id,
            },
            alert: false,
        }, (resp, err = null) => {
            if (resp.status === 1) {
                let data = resp.data.length > 0 ? resp.data.map(val => ({ value: val._id, label: val.name })) : [];
                data.unshift({ label: 'Select Ticket Type', value: '' });
                setTypeList(data);
            }
        });

    };

    const fetchTicketPriority = async () => {
        hitApi({
            url: 'pixa-support/api/getAllTicketPriority',
            method: 'POST',
            data: {
                userId: userData.role === 'user' ? userData.userId : userData.agency_id,
            },
            alert: false,
        }, (resp, err = null) => {
            if (resp.status === 1) {
                setPriorityList(resp.data);
            }
        });
    }

    const fetchAgent = () => {
        let data = { role: 'user', limit: 'all' };

        hitApi({
            url: `team-member/member-list`,
            method: "GET",
            data: data,
            alert: false,
        }, (resp, err = null) => {
            if (resp.status) {
                const activeAgent = resp.data.records.filter(val => val.status == 1);
                let filterAgent = activeAgent?.map(agent => ({ label: agent.name, value: agent._id }));
                setAgentList(filterAgent);
            }
        });

    }

    useEffect(() => {
        if (p_id) fetchProj();
    }, [p_id]);


    useEffect(() => {
        // if (userData.role !== -1)
        //     if (userData.role == 1 || userData.role == 2) {
        //         checkForMail()
        //     }
        // setTimeout(() => {
        //     p_id && fetchTickets(0)
        // }, 500);
       
        if(params_name && selectedStatus)
        {
            fetchTickets(0)
        }
        else if(!params_name) 
        {
            // if(currPage != 1)
            // {
            //     setCurrPage(1);
            // }
            fetchTickets(1)
        }
        

    }, [selectedStatus, selectedType, selectedPriority, selectedSortedBy, selectedDate, SelectedCustomerEmail])

    // useEffect(() => {
    //     fetchTickets()
    // },[currPage])

    // Delete Ticket Start 
    const handleDeleteTicket = (id) => {
        setDeleteTicketPopUp(true);
        if (id) setSelectedTicket([id]);
        setMenuShow('');
    }



    const handleSearchKeyupEvent = async (e) => {
        let t = e.target;
        let searchTerm = t.value;
        setSearchTerm(searchTerm);
        if (e.keyCode === 13) {
            fetchTickets(0, perPage, searchTerm)
        }
    }

    let clearSearch = (e) => {
        setSearchTerm('');
        fetchTickets(0, perPage, '');
    }



    const handlePriorityFilter = (id) => {
        if (selectedPriority === id) {
            setSelectedPriority('');
            // localStorage.setItem('Ticketfilter', JSON.stringify({
            //     ...filterLocalVal, 'priority': ''
            // }));
        } else {
            setSelectedPriority(id);
            // localStorage.setItem('Ticketfilter', JSON.stringify({
            //     ...filterLocalVal, 'priority': id
            // }));
        }
    }

    const handleTypeChange = (e, values) => {
        const {
            target: { value },
        } = e;
        setSelectedType(
            typeof value === 'string' ? value.split(',') : value,
        );
        // localStorage.setItem('Ticketfilter', JSON.stringify({
        //     ...filterLocalVal, 'type': typeof value === 'string' ? value.split(',') : value
        // }));
    };



    const TicketMenuToggle = (id) => {
        if (id == menuShow) {
            setMenuShow('');
        } else {
            setMenuShow(id);
        }
    }

    const menuRef = useRef();


    const handleDateRangeChange = (dates) => {

        if (dates && dates.length > 0) {
            const formattedStartDate = moment(dates[0]).format("YYYY-MM-DD");
            const formattedEndDate = moment(dates[1]).format("YYYY-MM-DD");

            setselectedStartDate(formattedStartDate);
            setSelectedEndDate(formattedEndDate)
            setSelectedDate(`${formattedStartDate} - ${formattedEndDate}`)
        } else {
            setselectedStartDate('');
            setSelectedEndDate('')
            setSelectedDate('');
        }
    };


    //   update ticket Status
    const handleUpdatePriority = (val, id) => {
        hitApi({
            url: 'pixa-support/api/update_ticket_priority',
            method: 'POST',
            data: {
                ticket_id: id,
                priority_id: val
            },
            alert: true,
        }, (resp, err = null) => {
            if (resp.status === 1) {
                fetchTickets()
            }
        });

    }

    // Multiple ticket Delete 
    const handleCheckboxDelete = (id) => {

        if (selectedTicket.includes(id)) {
            setSelectedTicket(selectedTicket.filter((val) => val !== id));
        } else {
            setSelectedTicket([...selectedTicket, id]);
        }

    }

    const [allsubmenu, setallsubmenu] = useState(false);
    const toggleSublist = (id) => {
        setallsubmenu(prevState => ({
            // ...prevState,
            [id]: !prevState[id]
        }));

    };

    const handleSelectTickets = (val, ticket) => {
        if (val) {
            setSelectedTicket(curr => [...curr, ticket?._id])
            //   isTrash && displayButtons([...state.selectedPost, post].length)
        } else {
            const arrCopy = Array.from(selectedTicket);
            const objWithIdIndex = arrCopy.findIndex((id) => ticket._id === id);
            arrCopy.splice(objWithIdIndex, 1);
            setSelectedTicket(arrCopy);

            //   isTrash && displayButtons(arrCopy.length);
        }
    }

    const handleFilterChange = (key, id) => {


        // localStorage.setItem('Ticketfilter', JSON.stringify({
        //     ...filterLocalVal, [key]: id
        // })); 
        if (key == 'status') {
            setSelectedStatus(id);
        } else if (key == 'priority') {
            setSelectedPriority(id);
        } else if (key == 'type') {
            setSelectedType(id)
        }
    }

    let cntStart = ((currPage - 1) * perPage) + 1, cnt = cntStart;

    useOutsideClick(menuRef, () => { setallsubmenu(false) });

    const customNoOptionsMessage = () => {
        return "No priority found";
    };

    //       function getFirstTwoParagraphs(description) {
    //     const div = document.createElement('div');
    //     div.innerHTML = description || '';

    //     const paragraphs = div.querySelectorAll('p');
    //     let result = '';

    //     for (let i = 0; i < paragraphs.length && i < 2; i++) {
    //         result += paragraphs[i].outerHTML;
    //     }

    //     if (paragraphs.length > 2) {
    //         result += '<p>...</p>';
    //     }

    //     return result;
    // }

    const noAgent = () => {
        return 'No agent found';
    };
    const noType = () => {
        return 'No type found';
    }

    return (
        <>
            <div className={(filterToggle) ? "in xs_overlay" : ''}></div>
            <Helmet>
                <title>Pixalab | Tickets</title>
            </Helmet>
            <div className='pxlCreate-ticketWrap-box'>
                <div className="sch-container-fluid">
                    <div className={"xs_page_fixed_position" + (deleteTicketPopUp === true) ? "xs_bg_transparent pxl-support-tickets-wrap pxl-support-flex-header" : ''} >
                        <div className="pxl-support-head1 pxl-head-leftsec pxl-ticketBackArrow-sec">
                            <Link to={'/pixa-support/projects'}>
                                <span className="pxl-ticket-reply-backIcon">
                                    <img src="/assets/images/arrow-left-ticket.svg" alt="" />
                                </span>
                            </Link>
                            <h3>
                                Tickets <span>({totalTickets})</span></h3>
                        </div>
                        <div className={filterToggle ? "xs_pagetitle_wrapper" + " " + 'ticket_right' : "xs_pagetitle_wrapper pxl-head-rightsec"}>
                            <div className="xs_ticket_chat_flex">
                                <div className="xs_pagetitle_right">
                                    <div className='delete_tic_btn_wrap'>
                                        <button className={`pxl-pink-btn-delete sch-btn-iconLeft ${selectedTicket.length == 0 ? "sch-btn-disbale" : ""}`} disabled={selectedTicket.length == 0} onClick={() => setDeletePopup(true)}>
                                            <span>
                                                {svg.deleteWhiteSvg}
                                            </span>
                                            Delete
                                        </button>
                                    </div>
                                    <div className={'ticket_sort pxl-sortedBy-ticket-sec'}>
                                        <span>Sort By :</span>
                                        <Select
                                            isSearchable={false}
                                            options={sortedBy}
                                            value={sortedBy.filter((val) => val.value === selectedSortedBy)}
                                            onChange={(option) => setSelectedSortedBy(option.value)}
                                        />
                                    </div>
                                </div>
                                <div className='pxl-ticketListRight'>
                                    {/* filter ticket btn */}
                                    <div className={!filterToggle ? "xs_back_btn_round pxl-support-filter-ticket-btn" : "xs_back_btn_round filter_btn"} onClick={() => {setFilterToggle(!filterToggle);
                                        // setCurrPage(filterToggle==true&&1)
                                    }}>
                                        <img src="/assets/images/filterSvg.svg" className='pxl-filterImg' />
                                    </div>
                                    {/* search section */}
                                    <div className='pxl-support-searchbar'>
                                        <div className={"xs_search_wrapper "} sx={{ maxWidth: { xs: '150px', md: '200px' } }}>

                                            <input type="text" placeholder="Search Ticket" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onKeyUp={handleSearchKeyupEvent} />
                                            {searchTerm ?
                                                <span className="xs_search_clear" onClick={(e) => clearSearch(e)}>x</span>
                                                : <span className="xs_search_icon">
                                                    <img src="/assets/images/search-svg-pink.svg" alt='search' />
                                                </span>
                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* ticket row */}
                    <div>


                        {loading ? <div className='pxl-ticket-row'>
                            {skeletonLoader('list', 5, '100%', '112px')}
                        </div> :
                            (ticketList.length > 0) ? ticketList.map((ticket, i) => <div className='pxl-ticket-row' key={i}>

                                <ul>

                                    {(containsSupportTicketWrite || containsSupportTicketDelete) &&
                                        <li style={{ width: '20px' }}>
                                            <div className='pxl-ticket-checkbox'>
                                                <div className="sch-custom-check-box ">
                                                    <div className="form-group">
                                                        <input type="checkbox" id={`ticketcheckbox${i}`}
                                                            checked={selectedTicket.includes(ticket?._id)}
                                                            value={selectedTicket.includes(ticket?._id)}
                                                            onChange={(e) => handleSelectTickets(e.target.checked, ticket)} />
                                                        <label htmlFor={`ticketcheckbox${i}`}></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>}

                                    <li><Link to={`/pixa-support/projects/${p_id}/tickets/${ticket.ticket_id}`}>
                                        <div className='pxl-ticket-number pxlticket-color1' style={{ backgroundColor: ticket?.status?.color && hexToRgbA(ticket?.status?.color, 0.1) }}>
                                  
                                            <h2 style={{ color: ticket?.status?.color }}>#{ticket.ticket_id} {ticket?.status?.name == '' || ticket?.status?.name == undefined ? "" : <span style={{ backgroundColor: ticket?.status?.color }}>{ticket?.status?.name}</span>}</h2>
                                        </div>
                                    </Link></li>
                                    <li className='cun-iconTooltip'>
                                        <Link to={`/pixa-support/projects/${p_id}/tickets/${ticket.ticket_id}`}>
                                            <div className='pxl-ticket-content pxlFullpage-ticketbox'>
                                                <h2>{ticket?.subject}</h2>
                                                <div className='pxl-ticketPara'>
                                                    <MediaList editorData={ticket?.description} />
                                                </div>
                                                {/* <div className='pxl-ticketPara ' >
                                                 <div dangerouslySetInnerHTML={{ __html: ticket?.description}}></div>
                                            </div> */}
                                                {/* {ticket?.description?.length >=12 &&<div className='cun-show-tooltip' onClick={(e) => e.stopPropagation()}>
                                            <MediaList editorData={ticket?.description} />
                                                </div>} */}
                                            </div>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={`/pixa-support/projects/${p_id}/tickets/${ticket.ticket_id}`}>
                                            <div className='pxl-ticket-profile'>
                                                <div className='pxl-ticket-profile-name1'>
                                                    {ticket?.customer?.profile_image ? <img src={ticket?.customer?.profile_image} alt="profile-name" /> : <div className="pxl-project-row-letter">{getNameInitials(ticket?.customer?.name)}</div>}
                                                    <p>{ticket.customer ? ticket.customer.name : 'Unknown'}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <div className='pxl-ticket-date'>
                                            <h2>{dateFormatter(ticket?.createdAt)}</h2>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='pxl-ticket-priority'>
                                            <Select
                                                noOptionsMessage={customNoOptionsMessage}
                                                value={{ label: ticket?.priority?.name, value: ticket?.priority?._id }}
                                                options={priorityList.map((val) => ({ label: val.name, value: val._id }))}
                                                onChange={(option) => handleUpdatePriority(option?.value, ticket?._id)}
                                                isDisabled={!containsSupportTicketWrite}
                                            />
                                        </div>
                                    </li>
                                    {(containsSupportTicketWrite || containsSupportTicketDelete) && <li className='pxl-responsiveNone-dot' style={{ width: '20px' }}>
                                        <div className={`sch-menuSection  ${allsubmenu[ticket._id] ? 'sch-menuOpen' : ''}`}>
                                            <div className='pxl-ticket-delet-btn' id={ticket._id} onClick={() => toggleSublist(ticket._id)} ref={menuRef}>

                                                <img src="/assets/images/three-dot.png" alt='menu' />
                                            </div>
                                            <div className='sch-submenubox sch-submenubox-agent'>
                                                <ul>
                                                    {containsSupportTicketWrite && role != 'teamMember' && <li>
                                                        <a onClick={() => handleAssignAgent(ticket)}>
                                                            <span><img src="/assets/images/agentSvg.svg" alt="" /> </span>Assign Agent
                                                        </a>
                                                    </li>}
                                                    {containsSupportTicketDelete && <li>
                                                        <a onClick={() => {
                                                            // setgetcheck(false)
                                                            setDeletePopup(true);
                                                            setDeleteTicket([ticket._id]);
                                                        }} >
                                                            <span><img src="/assets/images/delet-svg.svg" alt="" /> </span>Delete
                                                        </a>
                                                    </li>}
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    }
                                    {/* for responsive li */}
                                    {(containsSupportTicketWrite || containsSupportTicketDelete) && <li className='pxl-responsiveBlock-dot'>
                                        <div className={`sch-menuSection  ${allsubmenu[ticket._id] ? 'sch-menuOpen' : ''}`}>
                                            <div className='pxl-ticket-delet-btn' id={ticket._id} onClick={() => toggleSublist(ticket._id)} ref={menuRef}>

                                                {/* <img src="/assets/images/three-dot.png" alt='menu' /> */}
                                            </div>
                                            <div className='pxl-ticketDelete-agent-res'>
                                                <ul>
                                                    {containsSupportTicketWrite && role != 'teamMember' && <li>
                                                        <a onClick={() => handleAssignAgent(ticket)}>
                                                            <span><img src="/assets/images/agentSvg.svg" alt="" /> </span>Assign Agent
                                                        </a>
                                                    </li>}
                                                    {containsSupportTicketDelete && <li>
                                                        <a onClick={() => {
                                                            setDeletePopup(true);
                                                            setSelectedTicket([ticket._id]);
                                                        }} >
                                                            <span><img src="/assets/images/delet-svg.svg" alt="" /> </span>Delete
                                                        </a>
                                                    </li>}
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    }

                                </ul>
                            </div>
                            )
                                :
                                shownoData ? <NoDataFound title={searchTerm ? "We can not find any Tickets for the chosen action." : "No tickets found."} bgRemove={true} /> : null

                        }



                    </div>
                    {(ticketList.length > 0) && <div className='pxl-ticketTable-pagination'> <Pagination
                        type="Tickets"
                        dataRange={{
                            start: totalTickets ? cntStart : 0,
                            end: totalTickets ? cntStart + ticketList.length - 1 : 0,
                        }}
                        showPagination={true}
                        currentPage={currPage}
                        totalRecords={totalTickets}
                        perPage={perPage}
                        onClick={(pageNum) => {setCurrPage(pageNum); fetchTickets(pageNum)}}
                    /> </div>}

                </div>
            </div>
            <div className={filterToggle ? "xs_pagetitle_wrapper  ticket_right" : "xs_pagetitle_wrapper "}>


                <div className={shownoData ? "" : "w-100"}>

                    <div className={'ticket_list'}>


                        {false && (ticketList.length > 0) ? ticketList.map((ticket, index) =>
                            <div key={ticket._id} className={'ticket_item' + ' ' + (userData.role === 'customer' ? 'ticket_item_customer' : '')} style={{ borderLeftColor: (userData.role === 'customer') ? colorTheme : ticket.status.color }}>

                                {(userData.role !== 'admin') ? <Link className={'ticket_link'} href={'/project/' + p_id + '/tickets/' + ticket.ticket_id}></Link> : null}

                                {(userData.role !== 'customer') ? <div><input type="checkbox" onChange={(e) => handleCheckboxDelete(ticket._id)} /></div> : null}

                                <div className={'ticket_id'} style={{ backgroundColor: (userData.role === 'customer') ? hexToRgbA(colorTheme, .1) : hexToRgbA(ticket.status.color, .1), color: (userData.role === 'customer') ? colorTheme : ticket.status.color }}><div className={'ticket_status_Bg'}>#{ticket.ticket_id} <span className={'ticket_status_stl'}>{ticket?.status?.name}</span> </div></div>
                                <div className={'sub_box'}>
                                    <div className={'ticket_subject'}>{ticket?.subject}</div>
                                    <div className={'ticket_description'}>{ticket?.description?.replace(/<[^>]s*>?/gm, '').slice(0, 100)}</div>
                                </div>

                                {/* Customer image and name */}
                                {(userData.role !== 'customer') ?
                                    <>
                                        <div className={'ticket_author'}>

                                            {
                                                ticket.customer.profile_image ? <img className={'ticket_author_image'} src={img_path + `images/${ticket.customer._id}/` + ticket.customer.profile_image} alt="" /> : <div className={'ticket_author_initials'}>{getNameInitials(ticket.customer ? ticket.customer.name : 'Unknown')}</div>
                                            }
                                            <h3>{ticket.customer ? ticket.customer.name : 'Unknown'}</h3>
                                        </div>
                                    </> : null
                                }

                                <div className={'ticket_right_info'}>
                                    {/* <div className={styles.ticket_date}>{common.dateFormatter(ticket.createdAt)}</div> */}
                                    {/* {(userData.role !=='customer') && <div className={styles.ticket_priority} style={{ backgroundColor: (userData.role ==='customer') ? hexToRgbA(colorTheme, .1) : hexToRgbA(ticket.status.color, .1), color: (userData.role ==='customer') ? colorTheme : ticket.status.color }}>{ticket.priority.name}</div>} */}
                                    <div className={'ticket_prior_box'}>
                                        {(userData.role !== 'customer') ? <div className="xs_mui_select">

                                        </div> : null}
                                    </div>
                                </div>

                                {(userData.role !== 'customer') ? <>
                                    <div className={'ticket_actions'} ref={menuRef} onClick={() => TicketMenuToggle(ticket._id)}>{svg.three_dot}</div>

                                    <div className={(menuShow === ticket._id) ? 'xs_ticket_menu_btns open ' : 'xs_project_menu_btns'}>
                                        {(menuShow === ticket._id) && <>
                                            {(userData.role !== process.env.roles.agent) ?

                                                <div className={'ticket_action_btn'} onClick={() => handleAssignAgent(ticket)}>{svg.menu_icon_agents} Assign Agent</div>
                                                : null}

                                            <div className={'ticket_action_btn'} onClick={() => handleDeleteTicket(ticket._id)}>{svg.icon_delete} Delete</div>

                                        </>}

                                    </div></> : null}
                            </div>
                        ) : null}

                        {/* {loading ? [1, 2, 3].map(x => (
                            <div className={'ticket_item'} key={x}>
                                {skeletonLoader('list')}
                                <Skeleton variant="circular" width={40} height={40} />
                                <div style={{ marginLeft: '10px' }}>
                                    <Skeleton variant="text" sx={{ width: { xl: 500, md: 200, xs: 100 } }} height={10} />
                                </div>
                            </div>
                        )) : null
                        } */}
                    </div>



                </div>


                {/* {shownoData ?
                    <>
                        <NoDataFound title={searchTerm ? "We can not find any Tickets for the chosen action." : "No Results Found."} bgRemove={true} />
                    </>
                    : null
                } */}


            </div>

            <div ref={filterRef} className={filterToggle ? 'filter_sidebar filter_show' : 'filter_sidebar pxl-ticket-sidebar-filter'}>

                <div className={'filter_sidebar_heading'}>
                    <div className="xs_back_btn_round pxl-sidebar-arrow" onClick={() => {setFilterToggle(!filterToggle);
                    // setCurrPage(filterToggle==true&&1)
                    }}> <img src="/assets/images/arrow-left-ticket.png" alt='arrow' />
                    </div>

                    Filters</div>
                {(userData.role !== 'customer') && <div className="xs_input_wrapper">
                    <div className='pxl-status-list-box'>
                        <label>Status</label>
                        <div className={'status_list'}>

                            <div className='pxl-status-radio-btn'>
                                <div className='pxl-waiting-status'>
                                    <input type="radio" checked={selectedStatus == '' ? true : false} id={`statusOpen`} name="openStatus" value="openStatus" onChange={() => { handleFilterChange('status', ""); setSelectedStatus("") }} />
                                    <label htmlFor='statusOpen' >All</label>
                                </div>
                                {
                                    (statusList.length > 0) ? statusList.map((status, i) => {
                                        const styleSheet = document.styleSheets[0];
                                        const rule1 = `
                                .status_color${i} [type="radio"]:checked + label:before, 
                                .status_color${i} [type="radio"]:not(:checked) + label:before {
                                  border: 1px solid ${status?.color};
                                }
                              `;

                                        const rule2 = `
                                .status_color${i} [type="radio"]:checked + label:after, 
                                .status_color${i} [type="radio"]:not(:checked) + label:after {
                                  background: ${status?.color};
                                }
                              `;
                                        try {
                                            styleSheet.insertRule(rule1, styleSheet.cssRules.length);
                                            styleSheet.insertRule(rule2, styleSheet.cssRules.length);
                                        } catch (e) {
                                            console.error("Failed to insert rule:", e);
                                        }
                                        return (
                                            <div className={`pxl-open-status status_color${i}`} key={status?._id}>
                                                {
                                                    (params_name=='open')?
                                                    <>
                                                    <input checked={(params_name=='open'&&selectedStatus==status?._id)&&true} type="radio" id={`status${status?._id}`} name="openStatus" value="openStatus" onChange={() => handleFilterChange('status', status._id)} />
                                                    <label htmlFor={`status${status?._id}`} style={{ color: status?.color }}>{status?.name}</label>
                                                    </>:
                                                    <>
                                                    <input type="radio" id={`status${status?._id}`} name="openStatus" value="openStatus" onChange={() => handleFilterChange('status', status._id)} />
                                                    <label htmlFor={`status${status?._id}`} style={{ color: status?.color }}>{status?.name}</label>
                                                    </>

                                                }
                                            </div>
                                        )
                                    })
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                    {/* priority box */}
                    <div className='pxl-status-list-box pxl-priority-list-box'>
                        <label>priority</label>
                        <div className='pxl-status-radio-btn'>
                            <div className='pxl-waiting-status'>
                                <input type="radio" id={`priorityAll`} name="hight" checked={selectedPriority == '' ? true : false} value="hight" onChange={() => { handleFilterChange('priority', ""); setSelectedPriority("") }} />
                                <label htmlFor='priorityAll' >All</label>
                            </div>

                            {
                                (priorityList.length > 0) ? priorityList.map(priority => <div className="pxl-waiting-status" key={priority._id}>
                                    <input type="radio" id={priority._id} name="hight" value="hight" onChange={() => handleFilterChange('priority', priority._id)} />
                                    <label htmlFor={priority._id}>{priority?.name}</label>
                                </div>) :
                                    ''
                            }

                        </div>
                    </div>
                    <div className='pxl-status-list-box pxl-support-type-box'>
                        <label>type</label>
                        <div className='pxl-supportType'>
                            <Select
                                noOptionsMessage={noType}
                                options={typeList}
                                onChange={(option) => handleFilterChange('type', option.value)}
                                value={typeList.filter(val => val.value === selectedType)}
                            />
                        </div>
                    </div>
                    <div className='pxl-status-list-box pxl-support-date-box'>
                        <label>date</label>
                        <div className="pxl_input_feilds">
                            <div>
                                <DateRangePicker placement="topEnd" appearance="default" placeholder="Select Date Range" onChange={(e) => handleDateRangeChange(e)} showMeridian
                                />
                            </div>
                        </div>
                    </div>
                    <div className='pxl-status-list-box pxl-support-date-box pxl-support-email-box'>
                        <label>email</label>
                        <div className="pxl_input_feilds">
                            <input type="text" name="" placeholder="Email Here" value={InputCustomerEmail} onChange={(e) => setInputCustomerEmail(e.target.value)} />
                            <span><img src="/assets/images/support-emial.png" alt="" /></span>
                        </div>
                    </div>
                </div>}


            </div>

            <ConfirmationPopup
                shownPopup={deletePopUp}
                closePopup={() => {
                    setDeletePopup(false);
                    setSelectedTicket([]);
                    setDeleteTicket([]);
                }}
                type={"ticket"}
                title={`${selectedTicket.length == 0 ? "Do you want to move this ticket to the trash?" : `Do you want to move ${selectedTicket.length} ${selectedTicket.length == 1 ? 'ticket' : "tickets"} to trash?`}`}
                subTitle={'You can restore it later from Dashboard > Trash.'}
                yesBtn={"Yes, Move"}
                btnLoader={btnLoader}

                removeAction={() => {
                    setBtnLoader(true)
                    hitApi({
                        url: 'pixa-support/api/ticket_trash',
                        method: "POST",
                        data: { ticket_id: selectedTicket.length == 0 ? deleteTicket : selectedTicket, trash: 1 },
                    }, (resp, err = null) => {
                        setBtnLoader(false)
                        if (resp.status == 1) {
                            setDeletePopup(false);
                            fetchTickets();
                            setSelectedTicket([]);
                            setDeleteTicket([]);
                        }
                    })
                }}
            />


            <Modal
                show={assignAgnetPopup} onHide={closeAgentPopUp} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Assign Agent
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="" action="" onSubmit={assignAgentFormSubmit}>
                        <div className="form-group scope pxl-selectBrand">
                            <label htmlFor="exampleFormControlSelect2">Select Agent <span className="pxl-star-red">*</span></label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                noOptionsMessage={noAgent}
                                value={agentlist.filter(val => agentId.includes(val.value))}
                                isMulti
                                options={agentlist}
                                onChange={(option) => {
                                    let agentIds = option.map(val => val.value);
                                    setAgentId(agentIds)
                                }
                                }
                            />
                        </div>
                        <button type="submit" className="pxl_btn" >{btnLoader ? 'Processing...' : 'Assign'}</button>
                    </form>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Ticket