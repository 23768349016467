import { useEffect, useRef, useState } from 'react'
import { NoDataFound, hitApi, setMyState, skeletonLoader, useOutsideClick } from '../../components/commonAction';
import { Link, useNavigate } from 'react-router-dom';
import ConfirmationPopup from '../../components/common/ConfirmationPopup';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { useStore } from '../../zustand/store';
import Select from "react-select";
import { Helmet } from 'react-helmet';
import { PreviewPost} from "./components/SchedulePopUp";



function Draft({ isTrash = false, trashPage = "", mediaKeyWord, searchType, displayButtons, action, updateAction, checkAll = false,chackAllAction=false,checkType = false ,pageTitle}) {
  let store = useStore(state => state);
  let { role ,scope} = store.userData;

  const containsWrite = ( role === "teamMember") ?   scope?.scheduler?.schedule.includes("write") : (role === 'client') ? false : true;
  const containsDelete = ( role === "teamMember") ?   scope?.scheduler?.schedule.includes("delete") : (role === 'client') ? false : true;
  let options = [
    { value: '', label: 'Select Action' },
  ];

  if(containsWrite || containsDelete){
   if(containsWrite){ 
    options.push({ value: 'schedule', label: 'Schedule' },)
   }
    if(containsDelete){
    options.push({ value: 'delete', label: 'Delete' },)
   }
  }
  

  const [state, setState] = useState({
    limit: 12,
    page: 1,
    sort: 'createdAt=-1',
    keyword: '',
    isLoading: false,
    totalCount: 0,
    loadData: false,
    isDataload:false,
    totalRecord: '',
    mediaType: 'image',
    removeMe: [],
    restoreMe: [],
    isLoad: false,
    btnLoader:false,
    selectAllActive: false,
    selectedPost: [],
    draftList: [],
    selectedAction: '',
    playMedia:false,
    previewData:{},
    localCheckAll:'',
    previewPopUp:false,
    checkLocalType:false
  });
  const navigate = useNavigate();

  const menuRef = useRef();
 


  useEffect(() => {
    if (action == 'Delete') {

      setMyState(setState, { removeMe: state.selectedPost })
    } else if (action == 'Restore') {
      setMyState(setState, { restoreMe: state.selectedPost })
    }
  }, [action])

  useEffect(() => {
    if (isTrash) {
      if (checkAll) {
        setMyState(setState, { selectedPost: state.draftList });
        displayButtons(state.draftList.length);
      } else {
        if(checkType){

          setMyState(setState, { selectedPost: [] });
          displayButtons(0);
        }
      }
    }
  }, [checkAll])

  useEffect(()=>{
    if(isTrash){
        if (state.draftList?.length > 0) {
            if (state.draftList?.length == state.selectedPost?.length) {
                chackAllAction(true)
            } else {
                chackAllAction(false)
            }
        }
    }
},[state.selectedPost])

useEffect(() => {

  if (!state.checkLocalType) {
      if (state.draftList?.length > 0) {
      
          if (state.draftList?.length  == state.selectedPost?.length) {
              setMyState(setState, { localCheckAll: true })
          } else {
              setMyState(setState, { localCheckAll: false })
          }
      }
  }
}, [state.selectedPost])

  useEffect(() => {
    getDraftPost();
  }, [state.isLoad, searchType])

  const getDraftPost = (type = null) => {
    let { limit, page, sort, keyword } = state,
      cPage = type == 'loadMore' ? page + 1 : page;
    let reqData = { limit, page: cPage, sort, keyword: searchType ? mediaKeyWord : keyword };

    if (isTrash) {
      reqData.isTrash = true;
    }

    setMyState(setState, { isLoading: true, loadMoreActive: type == 'loadMore' ? true : false,page: cPage });
    hitApi({
      url: 'scheduler/draft/get-draft',
      method: "GET",
      data: reqData,
    }, (resp, err = null) => {
      setMyState(setState, { isLoading: false });
      if (resp.status) {
        let newList = type == 'loadMore' ? [...state.draftList, ...resp.data.records] : resp.data.records;
        setMyState(setState, { draftList: newList, totalRecord: resp.data.totalRecords });
      }
    });
  }

  const handleSelectPost = (val, post) => {
    if (val) {
      setMyState(setState, { selectedPost: [...state.selectedPost, post],checkLocalType: false })
      isTrash && displayButtons([...state.selectedPost, post].length)
    } else {
      const arrCopy = Array.from(state.selectedPost);
      const objWithIdIndex = arrCopy.findIndex((obj) => obj._id === post._id);
      arrCopy.splice(objWithIdIndex, 1);
      setMyState(setState, { selectedPost: arrCopy,checkLocalType:false })
      isTrash && displayButtons(arrCopy.length);
    }
  }

  // const handleSchedulePost = () => {
  //   store.updateStore("scheduler", { calendarDate: '', schedulePost: state.selectedPost });
  // }

  // sub menu delet edit setting
  const [allsubmenu, setallsubmenu] = useState(false);
  const toggleSublist = (id) => { 
      setallsubmenu(prevState => ({
        // ...prevState,
      [id]: !prevState[id]
    }));
  
  };

  const handleSelectedAction = (val) => {
    setMyState(setState, { selectedAction: val });

    if (val == 'delete') {
      setMyState(setState, { removeMe: state.selectedPost });
    } else if (val == 'schedule') {
      navigate('/scheduler/schedule-post');
      store.updateStore("scheduler", { calendarDate: '', schedulePost: state.selectedPost });
    }

  }
  useOutsideClick(menuRef, () =>{ setallsubmenu(false)});


  const Icons = {
    "FACEBOOK": "/assets/images/newpost/facebook.svg",
    "INSTAGRAM": "/assets/images/newpost/instagram.svg",
    "LINKEDIN": "/assets/images/newpost/linkedin.svg",
    "PINTEREST": "/assets/images/newpost/pinterest.svg"
}

  return (
    <>
      <Helmet>
                <title>{pageTitle}</title>
      </Helmet>
      <div className={isTrash ? 'sch-trashDraft' : ''} >
      <div className='pxl-draftSingle-mainWrapper-box'>
      <div className='sch-container-fluid'>
          <div className='sch-draft-post-wrpper pxl-draftSingle-pageWrapper'>
            {
              !isTrash &&
              <div className='sch-draft-post-flex'>
                <h2>Draft Posts</h2>
                <div className='sch-drft-post-right-sec'>
                  {state.totalRecord!=0 &&<div className="cun-headerSelect-wrap">
                    <div className='pxl-draft-single-count-select'>
                    <div className="cun-AllChecknoxSelect">
                      <div className="sch-custom-check-box ">
                        <div className="form-group">
                          <input type="checkbox" id="select-all"
                          disabled={(containsWrite || containsDelete) ? false : true}
                            checked={state.localCheckAll}
                            onChange={(e) => e.target.checked ? setMyState(setState, { selectedPost: state.draftList, localCheckAll: e.target.checked,checkLocalType: true }) : setMyState(setState, { selectedPost: [], localCheckAll: e.target.checked ,checkLocalType: true})} />
                          <label htmlFor="select-all">Select All</label>
                        </div>
                      </div>
                    </div>
                    <div className='sch-post-selected'>
                      <p>{state.selectedPost?.length} Post Selected</p>
                    </div>
                    </div>
                    <div className="cun-headreSelect">
                      <Select options={options}
                      isSearchable={false}
                        isDisabled={state.selectedPost.length == 0 ? true : (containsWrite || containsDelete) ? false : true}
                        value={options.filter(option =>
                          option.value == state.selectedAction)}
                        onChange={(e) => handleSelectedAction(e.value)}
                      />
                    </div>

                  </div>}


                  {/* <div className="sch-custom-check-box cun-selectAll">
                    <div className="form-group">
                      <input type="checkbox" id="select-all" onChange={(e) => e.target.checked ? setMyState(setState, { selectedPost: state.draftList }) : setMyState(setState, { selectedPost: [] })} />
                      <label htmlFor="select-all">Select All</label>
                    </div>
                  </div> */}


                  {/* <div className={`sch-draft-schedule-btn`} onClick={handleSchedulePost}>
                    <Link to={state.selectedPost.length == 0 ? '#' : "/scheduler/schedule-post"} className={`sch-btn-iconRight ${state.selectedPost.length == 0 && 'pxl-button-disable'}`}>
                      Select Post To Schedule
                      <span>
                        <img src="/assets/images/white-arrow-right.svg" alt=""/>
                      </span>
                    </Link>
                  </div> */}
                  {/* <a onClick={() => setMyState(setState, { removeMe: state.selectedPost })} className={`sch-btn-iconRight ${state.selectedPost.length == 0 && 'pxl-button-disable'}`}>delete <span>
                                                <img src="/assets/images/delete-whitesvg.svg" /> 
                                            </span></a> */}



                </div>

              </div>
            }
            {/* <div className='sch-draft-box-flex'> */}
            <div>
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 394: 2, 750: 3, 900: 5, 1424: 6 }}
              >
                <Masonry gutter={"10px"}>
             
                  {!state.loadMoreActive && state.isLoading ? [...Array(12)].map((index) =>(<div key={index}> {skeletonLoader('asset', 1, '100%', 300,index)}</div>)) :
                    state.draftList.length > 0 && state.draftList.map((post, i) => (<div className='sch-draft-mansory' key={post._id}>
                      <div className={`sch-draft-box  ${(!post?.mediaUrl || post?.mediaUrl.length == 0) && 'sch-draft-box-imgNone'} ${state.selectedPost.some((obj) => obj._id === post._id) && 'active_post'}`}>
                       

                        <div className={`sch-draft-img-box`}>
                          {post?.mediaUrl && post?.mediaUrl.length > 0 && 
                         
                         
                           <div className='pxl-draftImg'
                           onMouseEnter={e => {
                               if (post?.mediaUrl[0]?.mediaType != 'image') {
                                   setMyState(setState, {
                                       playMedia: post?.mediaUrl[0]?.id
                                   })
                               }
                           }}
                       >
                           {
                               state?.playMedia == post?.mediaUrl[0]?.id ?
                                   <video
                                       preload="none"
                                       src={post?.mediaUrl[0].path}
                                       autoPlay={true}
                                       muted={true}
                                       playsInline disablePictureInPicture
                                       onMouseLeave={e => {
                                           setMyState(setState, {
                                               playMedia: null
                                           })
                                       }}
                                   />
                                   :
                                 <img src={post?.mediaUrl[0]?.mediaType == 'image' ? post?.mediaUrl[0].thumb || post?.mediaUrl[0].path : post?.mediaUrl[0].thumb || '/images/defaultVideoThumb.png'} alt="" /> 
                           }
                           </div> 
                            // <img src={post?.mediaUrl[0]?.mediaType == 'image' ? post?.mediaUrl[0].thumb || post?.mediaUrl[0].path : post?.mediaUrl[0].thumb || '/images/defaultVideoThumb.png'} alt="" /> 
                            // <img src={'/assets/images/default-draft-img.jpg'} alt="" />
                          }
                        {(containsWrite || containsDelete) &&  <div className="sch-custom-check-box cun-selectAll sch_hide_checkbox" style={{ opacity: state.selectedPost.length > 0&& 1 }} >
                            <div className="form-group">

                              <input type="checkbox" id={i} 
                           
                              checked={state.selectedPost.some((obj) => obj._id === post._id)} 
                              onChange={(e) => handleSelectPost(e.target.checked, post)} />
                              <label htmlFor={i}></label>
                            </div>
                          </div>}
                        </div>
                        <div className='sch-draft-content'>
                          <div className='sch-draft-post-title'>
                            <h2>{post.title}</h2>
                            <p dangerouslySetInnerHTML={{ __html: post.caption }}></p>
                          </div>
                          

                          {
                            isTrash ?
                              <>
                                <div className={`sch-menuSection  ${allsubmenu[post._id] ? 'sch-menuOpen' : ''}`} ref={menuRef}>
                                  <div className='three_dot_bg' id={post._id} onClick={() => toggleSublist(post._id)}><img src="/assets/images/three-dot.svg" alt="" /></div>
                                  <div className='sch-submenubox'>
                                    <ul>
                                      <li>
                                        <a onClick={() => { setMyState(setState, { restoreMe: [post], selectedPost: [] }); setallsubmenu(false) }}>
                                          <span><img src="/assets/images/restore-svg.svg" alt="" /> </span>Restore
                                        </a>
                                      </li>
                                      <li>
                                        <a onClick={() => { setMyState(setState, { removeMe: [post] }); setallsubmenu(false) }}>
                                          <span><img src="/assets/images/delet-svg.svg" alt="" /> </span>Delete
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                              </>

                              :

                              (containsWrite || containsDelete) ?
                              <>
                                <div className={`sch-menuSection  ${allsubmenu[post._id] ? 'sch-menuOpen' : ''}`}>
                                  <div className='three_dot_bg' id={post._id} onClick={() => toggleSublist(post._id)} ref={menuRef}><img src="/assets/images/three-dot.svg" alt="" /></div>
                                  <div className='sch-submenubox'>
                                    <ul>
                                    {/* <div className="cun-socialIcon pxl-previewPost-disable">
                                    <a >
                                        <button className={`sch-common-btn-white ${!showPreview && 'pxl-button-disable'}`} onClick={() => { showPreview && setMyState(setState, { previewPopUp: true }) }}><span>
                                            {schedulerSvg.app.eyeSvg}</span>Preview Post</button>
                                        {!showPreview && <div className="cun-show-tooltip">
                                            <p>Fill all details to preview</p>
                                        </div>}
                                    </a>
                                    <div className="cun-show-tooltip"><p>Add either a caption or media to preview the post.</p></div>
                                </div> */}



                                    {containsWrite && <li>
                                      <a >
                                        {/* <Link to={`${post._id}`}> */}
                                        <button onClick={() => {setMyState(setState, { previewPopUp: true, previewData:post }) }} >

                                          <span><img src="/assets/images/viewSvg.svg" alt="" /> </span> Preview
                                        </button>
                                        {/* </Link> */}
                                        </a>
                                      </li>}
                                      {containsWrite && <li>
                                        <Link to={`/scheduler/edit-draft/${post._id}`}>
                                          <span><img src="/assets/images/edit-svg.svg" alt="" /> </span>Edit
                                        </Link>
                                      </li>}
                                    {containsDelete &&  <li>
                                        <a onClick={() => { setMyState(setState, { removeMe: [post] }); setallsubmenu(false) }}>
                                          <span><img src="/assets/images/delet-svg.svg" alt="" /> </span>Delete
                                        </a>
                                      </li>}
                                    </ul>
                                  </div>
                                </div>

                              
                              </> : ''
                          }

                        </div>
                        {/* {post.supportSocialAccount.length>0 && 
                        <div className='sch-draft-social-icons-wrap'>
                             <p>Supported by:</p>
                              <ul>
                              {post.supportSocialAccount?.map((n)=>{
                                return( <li>
                                  <img src={Icons[n]} alt=""/>
                              </li>)
                              })}
                               
                                
                              </ul>
                          </div>
                        } */}
                        </div>
                      

                    </div>
                    ))

                  }
                </Masonry>
              </ResponsiveMasonry>
              {state.loadMoreActive && state.isLoading ? [...Array(12)].map((index) => {<span key={index}>{skeletonLoader('asset', 1, '100%', 300,index)}</span>}) : ''}
            </div>

            {!state.isLoading && state.draftList.length == 0 && <NoDataFound title={'No draft posts found.'} bgRemove={true} />}
            {
              state.draftList.length < state.totalRecord &&
              <div className="loadmorebtn">
                <a
                  className={`sch-loadmoreBtn ${state.draftList.length >= state.totalRecord ? 'pxl-button-disable' : ''}`}
                  onClick={() => {
                    getDraftPost('loadMore');
                  }}
                >{state.isLoading ? <span>Loading...</span> : <span>Load More</span>}</a>
              </div>
            }
          </div>
        </div>
        </div>
      </div>

      {/* Restore PopUp */}
      <ConfirmationPopup
        shownPopup={state.restoreMe.length}
        closePopup={() => {
          setMyState(setState, {
            restoreMe: [],
            selectedPost: [],
            localCheckAll: false,
            selectedAction: '',
            checkLocalType:false
          })
          isTrash && updateAction('');
          isTrash && displayButtons(0);
        }}
        type={"draft post"}
        title={`Are you sure you want to restore ${state.restoreMe.length} draft ${state.restoreMe.length === 1 ? "post" : "posts"} ?`}
        subTitle={'This item will be restore immediately.'}
        yesBtn={"Yes, Restore"}
        btnLoader={state.isDataload}

        removeAction={() => {
          let data = {}
          const idArray = state.restoreMe.map((item) => item._id);

          data['target'] = idArray
          setMyState(setState, { isDataload: true })

          hitApi({
            url: `scheduler/draft/recover`,
            method: "PATCH",
            data: data,
          }, (resp, err = null) => {
            setMyState(setState, {
              restoreMe: [],
              selectedPost: [],
              isDataload: false,
              localCheckAll:false,
              checkLocalType:false

            })
            isTrash && updateAction('');
            isTrash && displayButtons(0);
            if (resp.status) {
              setMyState(setState, { isLoad: !state.isLoad });
            }
          })
        }}
      />


      {/* Delete Pop Up */}
      <ConfirmationPopup
        shownPopup={state.removeMe.length}
        closePopup={() => {
          setMyState(setState, {
            removeMe: [],
            selectedPost: [],
            localCheckAll: false,
            selectedAction: ''
          })
          isTrash && updateAction('');
          isTrash && displayButtons(0);
        }}
        type={"draft post"}
        title={isTrash ? `Are you sure you want to delete ${state.removeMe.length} draft ${state.removeMe.length === 1 ? "post" : "posts"} ?` : `Do you want to move ${state.removeMe.length} draft ${state.removeMe.length === 1 ? "post" : "posts"} to trash?`}
        subTitle={isTrash ? '' : 'You can restore it later from Dashboard > Trash.'}
        yesBtn={isTrash ? "Yes, Delete" : "Yes, Move"}
        btnLoader={state.isDataload}

        removeAction={() => {
          let data = {}
          const idArray = state.removeMe.map((item) => item._id);
          if (isTrash) {
            data['target'] = idArray.join(',');
          } else {
            data['target'] = idArray
          }
          setMyState(setState, { isDataload: true })

          hitApi({
            url: isTrash ? `scheduler/draft/remove-draft` : 'scheduler/draft/move-to-trash',
            method: isTrash ? "DELETE" : "PATCH",
            data: data,
          }, (resp, err = null) => {
            setMyState(setState, {
              removeMe: [],
              selectedPost: [],
              isDataload: false ,
              localCheckAll:false,
              checkLocalType:false
            })
            isTrash && updateAction('');
            isTrash && displayButtons(0);
            if (resp.status) {
              setMyState(setState, { isLoad: !state.isLoad });
            }
          })
        }}
      />
      
           <PreviewPost
                shownPopup={state.previewPopUp}
                closePopup={() => {
                    setMyState(setState, {
                        previewPopUp: false
                    })
                }}
                data={{ title: state.previewData.title, caption: state.previewData.caption, mediaUrl: state.previewData.mediaUrl, accounts: state.previewData.postOn, successData: state.previewData?.successData,supportSocialAccount : state.previewData?.supportSocialAccount }}
            />

    </>
  )
}

export default Draft