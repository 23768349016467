// import { Outlet, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// import React, { useEffect, useState } from "react";
import Sidebar from '../components/Sidebar';
import Facebook from '../components/common/SocialAccount/Facebook';

const Dashboard = ({ pageTitle }) => {
 
    return (

        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>

            <div className="pxl_mainWrapper">
                <Sidebar />
                <div className="pxl_rightBar_assets">
                    <Facebook />
                </div>
            </div>

            

        </>
    )
};

export default Dashboard;