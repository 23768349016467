import React from 'react';
import schedulerSvg from '../../../pages/scheduler/components/schedulerSvg';
import appConfig from '../../../config/appConfig';
import { teamMemberRestrictionMess } from '../../commonAction';

let LinkedinBtn = ({title = 'Add New Account',  parent = "social",containsSchedularWrite,currentAccount=null}) => {


    const linkedinlogin = () => {
        let clientId = (process.env.REACT_APP_LINKEDIN_CLIENT_ID);
        let redirectUri = `${appConfig.appUrl}social`;
        let scope = "openid,profile,w_member_social,email";
			let s1={
				social : "linkedin", 
			}
			if(currentAccount)
			{
				s1.id=currentAccount.accountId
			}
			s1=JSON.stringify(s1)
        const linkedinRedirectUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=`+s1
        window.open(linkedinRedirectUrl, '_blank');
    }
    
    return(
        <>
        	{parent == 'socialAccComponent' ?
				<a  onClick={linkedinlogin}>
					<div className="sch-user-img sch-user-img-add">
						{schedulerSvg.app.addNewSvg}
					</div>
					<div className="sch-user-name">
						<h2>{title}</h2>
					</div>
				</a>
				:
                parent == 'reconnect' ?
                <div className={` pxl-tooltip-button-form pxl-reload-tooltip ${containsSchedularWrite ? "" : 'pxl-tooltip-btn-diable'}`} onClick={linkedinlogin}>
                   <button  disabled={!containsSchedularWrite} className={`cun-socialIcon cun-socail-delete ${containsSchedularWrite ? '' : 'sch-btn-disbale'}`}>
						 <img src="/assets/images/social/reconnect.svg" alt=""/>
						 <div className="cun-show-tooltip">{containsSchedularWrite ? <p>Reconnect</p> : teamMemberRestrictionMess(containsSchedularWrite)}
					</div>
					</button>
					     
                </div>
				// <div onClick={linkedinlogin}>reconnect</div>
				:
				<div className=' pxl-add-new-accountBtn cun-socialIcon'> 
                <button className={`pxlCommon-btn ${containsSchedularWrite ? '' : 'sch-btn-disbale'}`}  disabled={!containsSchedularWrite} onClick={linkedinlogin}>{title}</button> 
				{!containsSchedularWrite &&  <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(containsSchedularWrite)}</p></div>}
				</div>
			}
          
        </>
    );
}

export default LinkedinBtn;