import  { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { commonToast, hitApi, skeletonLoader, AddDataimg, teamMemberRestrictionMess } from '../../../../components/commonAction';
import ConfirmationPopup from '../../../../components/common/ConfirmationPopup';
import svg from '../svg';
import { useStore } from '../../../../zustand/store';


function TicketTypeSettings() {
    let store = useStore(state => state);
    const userData = store.userData;
    let { role } = userData;
    let allowAccess = ( role === 'client') ? false : true;
    const [ticketList, setTicketList] = useState([]);
    const [isError, setIsError] = useState(false);

    const [addTicketBox, setAddTicketBox] = useState(false);
    const [DeleteId, setDeleteId] = useState();
    const [name, setName] = useState('');
    const [editTicketId, setEditTicketId] = useState('');
    const [showPopUp, setShowPopup] = useState(false);
    const [shownoData, setShowNoData] = useState(false);
    const [loading, setLoading] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false);

    const addTicket = (e) => {
        e.preventDefault();
        if (!name) {
            commonToast('error', 'Field can not be empty!');
            return false;
        } else {
            const data = {
                name: name,
                isdefault: 0
            }
            setBtnLoader(true)
            hitApi({
                url: editTicketId ? ('pixa-support/api/manageTicket_type?id=' + editTicketId) : 'pixa-support/api/manageTicket_type',
                method: "POST",
                data: data,
                loading: false,
                alert: true,

            }, (resp, err = null) => {
                setBtnLoader(false)
                if (resp.status === 1) {
                    fetchAllTicket();
                    setName('');
                    setAddTicketBox(false);
                    setEditTicketId('');
                }
            });
        }
    }

    useEffect(() => {
        fetchAllTicket();
    }, [])
    useEffect(()=>{
        let element = document.getElementById("#element");
        if (element) {
          element.scrollTop = element.scrollHeight + 100;
        }
    })

    const fetchAllTicket = () => {
        if (userData.userId) {
            setLoading(true)
            hitApi({
                url: 'pixa-support/api/getAllTicketTypes',
                method: "POST",
                data: {
                    userId: userData.userId
                },
                loading: false,
                alert: false,

            }, (resp, err = null) => {
                if (resp.status === 1) {
                    setLoading(false)
                    setTicketList(resp.data);
                    if (resp.data.length === 0) {
                        setShowNoData(true);
                    } else {
                        setShowNoData(false);
                    }
                    setIsError(false)
                } else {
                    setIsError(true)
                }
            });

        }
    }

    const handleDelete = (id) => {
        setShowPopup(true);
        setDeleteId(id);
    }


    const editTicket = (id) => {
        setEditTicketId(id);
        setAddTicketBox(true);
        setName(ticketList.find(item => item._id === id).name);
    }


    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const pageDragHandle = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            ticketList,
            result.source.index,
            result.destination.index
        );
        setTicketList(items);
        hitApi({
            url: 'pixa-support/api/tickets_ChangeOrder',
            method: "POST",
            data: { list: items, status: "Type" },
            loading: false,
            alert: true,

        }, (resp, err = null) => {
            if (resp.status == 1) {
                fetchAllTicket();
            }
        });


    }
    return (
        <>
            <div className='proj_domai_set_form pxl-ticket-TypeForm' >
                <div id='#element' className={`sup_proj_setting_form pxl-ticketStatusBox ${allowAccess ? "" : "support_type_prio"}`}>
                    <h4 className='proj_main_head'>Ticket Type</h4>
                    <div className='pxl_settingboxMain'>
                        <div className='pxl_settingboxInner'>
                            <DragDropContext onDragEnd={pageDragHandle}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {!loading ? (ticketList.length > 0) ? ticketList.map((status, index) =>
                                                <Draggable key={status._id} draggableId={(status._id).toString()} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            className={`pxl-ticket-status-wrap pxl-ticket-status-color${index + 1 > 5 ? index % 5 + 1 : index + 1} pxl-flex-divide`}
                                                            title={status.name}
                                                        >
                                                            <div className='pxl-ticket-status-name'>
                                                                <h2 className='pxl-font-16-weight-400'> {status.name}</h2>
                                                            </div>
                                                            <div className='pxl_ticket_Set_type_prior'>
                                                                <ul className='pxl-flex-divide'>
                                                                   {status.isdefault ? null :
                                                                   <>
                                                                    <li className={`cun-socialIcon pxl-tooltip-button-form pxl-deleteToltip ${allowAccess ? "" : 'pxl-tooltip-btn-diable long-tooltip-data'}`} >
                                                                        <button onClick={() => editTicket(status._id)} disabled={!allowAccess}>
                                                                            {svg.dt_edit_icon}
                                                                        </button>
                                                                        <div className="cun-show-tooltip"><p>{allowAccess ? "Edit" : teamMemberRestrictionMess()}</p></div>
                                                                    </li>
                                                                    <li className={`cun-socialIcon pxl-tooltip-button-form pxl-deleteToltip ${allowAccess ? "" : 'pxl-tooltip-btn-diable long-tooltip-data'}`}>
                                                                        <button className='pxl-pink-color' onClick={() => {handleDelete(status._id);setAddTicketBox(false)}} disabled={!allowAccess}>
                                                                            {svg.deleteWhiteSvg}
                                                                        </button>
                                                                        <div className="cun-show-tooltip"><p>{allowAccess ? "Delete" : teamMemberRestrictionMess()}</p></div>
                                                                    </li>
                                                                   </> }
                                                                    <li className={`pxl-ticketType-drag cun-socialIcon pxl-tooltip-button-form pxl-deleteToltip ${allowAccess ? "" : 'pxl-tooltip-btn-diable'}`}>
                                                                        {allowAccess ? <a {...provided.dragHandleProps}>
                                                                            {svg.dragDropSvg}
                                                                        </a> :
                                                                            <a href="#" onClick={(e)=>{
                                                                                e.preventDefault()
                                                                               }}>
                                                                                {svg.dragDropSvg}
                                                                            </a>
                                                                        }
                                                                        {allowAccess && <div className="cun-show-tooltip "><p>Drag</p></div>}
                                                                    </li>
                                                                </ul>


                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ) : !addTicketBox ? <AddDataimg title={"No ticket type found."} bgRemove={true} changeDesign={true} />:null : null}
                                            {loading ? <div className='mb-3'>
                                                {skeletonLoader('list', 3)}
                                            </div> : null
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        {addTicketBox ? <form onSubmit={addTicket} >
                            <div className='pxl-add-status-heading' >
                                <h3 className='pxl-font-18-weight-600'>{editTicketId ? 'Edit' : 'Add'} Ticket</h3>
                                <div className="pxl-ticket-status-popup">
                                    <div className="pxl_input_feilds">
                                        <label>Ticket Type <span className="pxl-star-red">*</span></label>
                                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Ticket Type" />
                                    </div>
                                </div>
                                <div className='pxl-setting-button-wrap pxl-addCancelBtn'>
                                    <button type="submit" className="pxlCommon-btn " disabled={btnLoader} >{btnLoader ? "Processing..." : editTicketId ? 'Update' : 'Add'}</button>
                                    <button type="button" className="pxlCommon-btn Pxl-cancel-btn" disabled={btnLoader} onClick={() => {setAddTicketBox(false); setName('');setEditTicketId('')}}>Cancel</button>
                                </div>

                            </div></form>
                            :
                            <div className='pxl-ticket-status-tooltip cun-socialIcon'>
                                <button disabled={!allowAccess} onClick={() => setAddTicketBox(true)} className={`pxlCommon-btn ${!allowAccess ? "sch-btn-disbale addNewtypeDisable" : ""}`}> Add New Type</button>
                                {!allowAccess && <div className="cun-show-tooltip cundisabletooltip"><p>{teamMemberRestrictionMess()}</p></div>}

                            </div>
                        }
                    </div>
                </div>
            </div>
            <ConfirmationPopup
                shownPopup={showPopUp}
                closePopup={() => {
                    setDeleteId('');
                    setShowPopup(false);
                }}
                type={"ticket type"}
                removeAction={() => {

                    hitApi({
                        url: `pixa-support/api/deleteTicketType?id=${DeleteId}`,
                        method: "POST",
                        data: {},
                    }, (resp, err = null) => {
                        setShowPopup(false);
                        setDeleteId('');
                        if (resp.status) {
                            fetchAllTicket();
                        }
                    })
                }}
            />
        </>
    )
}

export default TicketTypeSettings