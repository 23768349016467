import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import svg from "../components/svg";
import { useStore } from "../zustand/store";
import Cookies from "js-cookie";
import { setMyState, getNameInitials, assetsUrlPath, useOutsideClick } from "../components/commonAction";
import HeaderResponsive from './common/responsiveHeader/ResponsiveHeader';
import appConfig from '../config/appConfig';

function Sidebar() {


    const [path, setpath] = useState(useLocation().pathname.replace(/^\//, ''));
    let adminPath = useLocation().pathname.replace("admin/", "");
    const modalRef = useRef();
    let store = useStore(state => state);
    let { name, email, isVerified, role, socialAccountError, profilePic } = store.userData;
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState((path == 'autoresponder'));
    const [openAdminMenu, setOpenAdminMenu] = useState((adminPath == '/image-template'));

    let logoutMe = () => {
        store.updateStore("userData", {});
        Cookies.remove('authToken');
        navigate('/');
    }

    const [state, setState] = useState({
        isActiveMenu: '',
    });

    const handleUserSubMenu = () => {
        setOpenMenu(!openMenu);
        if (path != 'autoresponder') {
            navigate('/autoresponder');
        }
    }

    const handleAdminSubMenu = () => {
        setOpenAdminMenu(!openAdminMenu);

        if (adminPath != '/image-template') {
            setOpenAdminMenu(true);
            navigate('/admin/image-template');
        }
    }
    // let socialError =  [
    //     {
    //         "_id": "663a1c8ce01cc2912b9c9880",
    //         "source": "pinterest",
    //         "reconnectErr": "dasdasd asds ad"
    //     },
    //     {
    //         "_id": "663a1c8ce01cc2912b9c9880",
    //         "source": "facebook",
    //         "reconnectErr": "dasdasd asds ad"
    //     }
    // ]

    const [verifyToggle, setverifyToggle] = useState(false);
    const [loginmenuToggle, SetloginmenuToggle] = useState(false);
    useOutsideClick(modalRef, () => SetloginmenuToggle(false));
    const [accountError, SetaccountError] = useState(false);
    return (
        <>
            <div className="">
                <div className='cun-responsive-toggle'>
                    <div className='sch-responsive-toggle'>
                        <HeaderResponsive />

                    </div>
                </div>
                <div className={`pxl_sidebarMenu ${store.resMenuToggle ? 'closesidebar' : 'opensidebar'}`} ref={modalRef}>
                    {
                        (role === 'user') && !isVerified && <div className={`pxl-verifyMain-wrap ${verifyToggle ? 'pxl-OpenVerifyBox' : 'pxl-CloseVerifyBox'}`}>

                            <div className={`pxl_verification_wrapper pxlVerify-box`} onClick={() => setverifyToggle(!verifyToggle)}>
                                <p>Your account is not verified, please verify to use a hassle-free experience.

                                </p>
                                <Link className='pxl-verifyBtn' to="/account-settings#verification">Verify Now</Link>
                            </div>
                            <div className={`pxl-verify-toggle`}>
                                <a onClick={() => setverifyToggle(!verifyToggle)}>
                                    {/* <img src="/assets/images/arrow-verify.svg" alt=""/> */}
                                    <h2>Verify Now</h2>
                                </a>
                            </div>
                        </div>
                    }
                    {/* {socialAccountError && socialAccountError.length > 0 &&
                        <div className={`pxl_socialError_wrapper ${!isVerified && 'secondary_Alert'} ${accountError ? "pxl-account-errorBoxOpen" : "pxl-account-errorBoxClose"}`} >
                            <div className='pxl-social-error-innerBox'>
                                <h5>Social Account Error</h5>
                                <div className='pxl-socialerror-scroll'>
                                    {
                                        socialAccountError.map((acc, index) => <div className='pxl-socail-error-boxInner'>
                                            <Link to={`/scheduler/social-integration#${acc.source}`}>
                                                <p><span>{acc.source}</span></p>
                                                <p>{acc.reconnectErr}</p></Link>
                                        </div>)
                                    }
                                </div>
                            </div>
                            <div className='pxl-socialErro-box'>
                                <button onClick={() => SetaccountError(!accountError)}>Social account error</button>
                            </div>
                        </div>} */}

                    <div className="pxl_logo cun-sidebarLogo">
                        <Link to='/dashboard' className="pxl_mainLogo">
                            <img src={appConfig.appLogo} alt="Logo" width={'165px'} />
                        </Link>
                    </div>
                    <div className="pxl_mainMenu">
                        {
                            role == 'admin' ?
                                <ul>
                                    <li className={(adminPath == '/dashboard') ? 'isActive' : ''}>
                                        <Link to={'/admin/dashboard'} className={`pxl_navlink`}>
                                            <span className="pxl_menuIcon">{svg.app.dashboardIcon}</span>
                                            <span className='pxl_textMenu'>Dashboard</span>
                                        </Link>
                                    </li>
                                    <li className={(adminPath == '/users') ? 'isActive' : ''}>
                                        <Link to={'/admin/users'} className={`pxl_navlink`}>
                                            <span className="pxl_menuIcon">{svg.app.userSvgWhite}</span>
                                            <span className='pxl_textMenu'>Users </span>
                                        </Link>
                                    </li>
                                    <li className={(adminPath == '/api-documentations') ? 'isActive' : ''}>
                                        <Link to={'/admin/api-documentations'} className={`pxl_navlink`}>
                                            <span className="pxl_menuIcon">{svg.app.api}</span>
                                            <span className='pxl_textMenu'>API </span>
                                        </Link>
                                    </li>
                                    <li className={(adminPath == '/image-template') ? 'isActive' : ''} >
                                        <a className={`pxl_navlink`} onClick={handleAdminSubMenu}>
                                            <span className="pxl_menuIcon">{svg.app.templateIcon}</span>
                                            <span className='pxl_textMenu'>Templates </span>
                                        </a>
                                        <span className='pxl_sidebar-right-arrow'>
                                            {svg.app.downArrow}
                                        </span>
                                        <ul className={`pxl_submenu  ${((adminPath == '/image-template') && openAdminMenu) ? 'sub_menu_open' : ''}`}>
                                            <li className={adminPath == '/image-template' ? 'sub_menu_isActive' : ''}>
                                                <Link to={'/admin/image-template'} className={`pxl_navlink`}>
                                                    <span className="pxl_menuIcon">-</span>
                                                    <span className='pxl_textMenu'>Image Templates</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className={(adminPath == '/pixa-stock') ? 'isActive' : ''}>
                                        <Link to={'/admin/pixa-stock'} className={`pxl_navlink`}>
                                            <span className="pxl_menuIcon">{svg.app.profileIcon}</span>
                                            <span className='pxl_textMenu'>Pixa Stock</span>
                                        </Link>
                                    </li>
                                </ul>
                                : (
                                    role == 'user' ?
                                        <ul>
                                            <li className={path == 'dashboard' ? 'isActive' : ''}>
                                                <Link to={'/dashboard'} className={`pxl_navlink`}>
                                                    <span className="pxl_menuIcon">{svg.app.dashboardIcon}</span>
                                                    <span className='pxl_textMenu'>Dashboard</span>
                                                </Link>
                                            </li>
                                            <li className={path == 'my-library' ? 'isActive' : ''}>
                                                <Link to={'/my-library'} className={`pxl_navlink`}>
                                                    <span className="pxl_menuIcon">{svg.app.MyLibrray}</span>
                                                    <span className='pxl_textMenu'>My Library</span>
                                                </Link>
                                            </li>
                                            <li className={path == 'team-members' ? 'isActive' : ''}>
                                                <Link to={'/team-members'} className={`pxl_navlink`}>
                                                    <span className="pxl_menuIcon">{svg.app.teamMember}</span>
                                                    <span className='pxl_textMenu'>Team Members</span>
                                                </Link>
                                            </li>
                                            <li className={path == 'client' ? 'isActive' : ''}>
                                                <Link to={'/client'} className={`pxl_navlink`}>
                                                    <span className="pxl_menuIcon">{svg.app.userSvgWhite}</span>
                                                    <span className='pxl_textMenu'>Client</span>
                                                </Link>
                                            </li>
                                            <li className={path == 'brand' ? 'isActive' : ''}>
                                                <Link to={'/brand'} className={`pxl_navlink`}>
                                                    <span className="pxl_menuIcon">{svg.app.menuBrand}</span>
                                                    <span className='pxl_textMenu'>Brand</span>
                                                </Link>
                                            </li>
                                            <li className={path == 'trash' ? 'isActive' : ''}>
                                                <Link to={'/trash'} className={`pxl_navlink`}>
                                                    <span className="pxl_menuIcon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 512 512"><g><path fillRule="evenodd" d="M254.645 206.866v269.571c0 4.707-3.851 8.539-8.553 8.539s-8.553-3.832-8.553-8.539V206.866c0-4.707 3.851-8.538 8.553-8.538 4.701 0 8.553 3.831 8.553 8.538zM62.829 69.016l379.83 87.282 5.852-25.423c3.201-14.003-5.552-28.028-19.557-31.249l-82.423-18.935 2.319-9.53c2.551-11.121.6-22.594-5.452-32.33-6.102-9.723-15.605-16.476-26.709-19.041L235.311 1.094C224.207-1.457 212.703.488 203 6.58c-9.753 6.082-16.506 15.56-19.057 26.679l-2.22 9.571-81.831-18.798c-14.005-3.216-28.01 5.559-31.211 19.573zm267.066 7.853-131.682-30.25 2.387-9.528c1.5-6.677 5.602-12.37 11.454-16.041s12.754-4.842 19.407-3.316l81.428 18.706c6.652 1.526 12.354 5.607 16.005 11.465 3.702 5.859 4.852 12.754 3.351 19.434zM161.263 206.46l13.155 269.561c.25 4.707-3.401 8.71-8.103 8.945-.15 0-.3.01-.45.01-4.502 0-8.303-3.554-8.503-8.122l-13.154-269.571c-.2-4.707 3.401-8.71 8.103-8.944 4.7-.248 8.752 3.404 8.952 8.121zm-92.382-35.157 30.46 319.821c1.15 11.9 11.004 20.876 22.958 20.876h247.585c11.954 0 21.807-8.976 22.958-20.876l30.461-319.821zm248.885 304.718L330.92 206.46c.25-4.717 4.251-8.378 8.953-8.122 4.702.234 8.353 4.237 8.103 8.944l-13.155 269.571c-.2 4.568-4.001 8.122-8.502 8.122-.15 0-.3-.01-.45-.01-4.702-.234-8.303-4.238-8.103-8.944z" clipRule="evenodd" fill="#ffffff" dataoriginal="#000000"></path></g></svg>
                                                    </span>
                                                    <span className='pxl_textMenu'>Trash</span>
                                                </Link>
                                            </li>
                                            <li className={(path == 'autoresponder') ? 'isActive' : ''} >
                                                {/* <a onClick={handleUserSubMenu} className={`pxl_navlink cun-dropdownLi`} > */}
                                                <Link to={'/autoresponder'} className={`pxl_navlink`}>
                                                    <span className="pxl_menuIcon">{svg.app.integrationIcon}</span>
                                                    <span className='pxl_textMenu'>Integration </span>
                                                </Link>
                                                {/* </a> */}
                                                {/* <span className='pxl_sidebar-right-arrow' >
                                                    {svg.app.downArrow}
                                                </span> */}

                                                {/* <ul className={`pxl_submenu ${((path == 'social' || path == 'autoresponder') && openMenu) ? 'sub_menu_open' : ''} `}> */}
                                                {/* <li className={path == 'social' ? 'sub_menu_isActive' : ''}>
                                                        <Link to={'/social'} className={`pxl_navlink`}>
                                                            <span className="pxl_menuIcon">-</span>
                                                            <span className='pxl_textMenu'>Social</span>
                                                        </Link>
                                                    </li> */}
                                                {/* <li className={path == 'autoresponder' ? 'sub_menu_isActive' : ''}>
                                                        <Link to={'/autoresponder'} className={`pxl_navlink`}>
                                                            <span className="pxl_menuIcon">-</span>
                                                            <span className='pxl_textMenu'>Autoresponders</span>
                                                        </Link>
                                                    </li>
                                                </ul> */}
                                            </li>

                                            <li>
                                                <Link to={'/notification '} className={`pxl_navlink`}>
                                                    <span className="pxl_menuIcon">{svg.app.settingIcon}</span>
                                                    <span className='pxl_textMenu'>Notification</span>
                                                </Link>
                                            </li>
                                        </ul>

                                        : <>
                                            <ul>
                                                <li className={path == 'dashboard' ? 'isActive' : ''}>
                                                    <Link to={'/dashboard'} className={`pxl_navlink`}>
                                                        <span className="pxl_menuIcon">{svg.app.dashboardIcon}</span>
                                                        <span className='pxl_textMenu'>Dashboard</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </>
                                )
                        }


                        <div className={`pxl_profileDetail pxl-logutSection-wrap ${loginmenuToggle ? "loginMenuOpen" : "loginMenuClose"}`}  >
                            <a href="#" onClick={(e) => {
                                e.preventDefault()
                            }}>
                                <div className={`pxl_profileImg toggle_profile`} onClick={() => SetloginmenuToggle(!loginmenuToggle)}>
                                    <span className='pxl_imgHolder toggle_profile'>
                                        {
                                            profilePic ?
                                                <img src={assetsUrlPath(profilePic)} className='img-responsive img-circle toggle_profile' alt='Profile Image' />
                                                :
                                                getNameInitials(name)
                                        }


                                    </span>
                                    <div className='pxl_profileName toggle_profile'>
                                        <h4 className='toggle_profile'>{name}</h4>
                                        <p className='toggle_profile'>{email}</p>
                                    </div>
                                </div>
                            </a>
                            <ul className={`pxl-account-logout-wrap`}>
                                <li>
                                    <Link to={role == 'admin' ? "/admin/account-settings#profile" : "/account-settings#profile"} className='pxl_navlink'>
                                        <span className="pxl_menuIcon">{svg.app.settingIcon}</span>
                                        <span className='pxl_textMenu'>Account Settings</span>
                                    </Link>
                                </li>
                                <li>
                                    <a className='pxl_navlink' onClick={() => logoutMe()}>
                                        <span className="pxl_menuIcon">{svg.app.logoutIcon}</span>
                                        <span className='pxl_textMenu'>Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Sidebar