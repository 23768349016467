import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { setMyState } from "../../components/commonAction";
import { hitApi } from "../../components/commonAction";
import { skeletonLoader, useOutsideClick } from "../../components/commonAction";
import { useRef } from "react";
import ConfirmationPopup from "../../components/common/ConfirmationPopup";
import { NoDataFound, NewCreatefile } from "../../components/commonAction";
import { useStore } from "../../zustand/store";
import { PreviewPost } from "./components/SchedulePopUp";
import svg from "../../components/svg";

const SchFlexBottom = 'sch-section-bottom-space'


const Dashboard = ({ pageTitle }) => {
    let appPath = useLocation().pathname.split('/').filter(Boolean)[0];
    let currentPath = useLocation().pathname.replace(`/${appPath}`, '');
    const navigate = useNavigate();

    const [state, setState] = useState({

        scheduledCount: 0,
        draftCount: 0,
        designcount: 0,
        isLoading: false,
        isCount: false,
        limit: 7,
        page: 1,
        sort: 'createdAt=-1',
        keyword: '',

        loadData: false,
        mediaType: 'image',
        restoreMe: [],
        isLoad: false,
        selectAllActive: false,
        selectedPost: [],
        draftList: [],
        removeMe: false,
        deleteId: '',
        deleteMe: false,
        postId: '',
        scheduledPosts: [],
        previewData: {},
        previewPopUp: false

    });
    const [allsubmenu, setallsubmenu] = useState(false);

    let store = useStore(state => state);
    let { role } = store.userData;
    let allowAccess = (role === 'client') ? false : true;

    useEffect(() => {
        getDraftPost();
        getAllPost();
    }, [])

    const getDraftPost = (type = null) => {
        let { limit, page, sort } = state;
        let reqData = { limit, page, sort };

        setMyState(setState, { isLoading: true });
        hitApi({
            url: 'scheduler/draft/get-draft',
            method: "GET",
            data: reqData,
        }, (resp, err = null) => {
            setMyState(setState, { isLoading: false });
            if (resp.status) {
                let newList = resp.data.records;
                setMyState(setState, { draftList: newList });
            }
        });
    }


    const getAllPost = () => {

        let { limit, page, sort } = state;
        let reqData = { limit, page, sort };

        setMyState(setState, {
            isLoad: true
        })

        hitApi({
            method: 'GET',
            url: `scheduler/post/get-post`,
            data: reqData
        }, (resp, error) => {

            setMyState(setState, {
                isLoad: false
            })

            if (resp.status) {
                setMyState(setState, {
                    scheduledPosts: (resp.data.records)
                })
            }
        })
    }

    const fetchCounts = () => {

        setMyState(setState, { isCount: true });

        hitApi({
            url: 'scheduler/dashboard/get-count',
            method: "GET",
            data: {}
        }, (resp, err = null) => {
            if (resp.status) {
                setMyState(setState, { draftCount: resp.data.draftcount, designcount: resp.data.designcount, scheduledCount: resp.data.scheduledcount });
            }

            setMyState(setState, { isCount: false });
        });
    }

    useEffect(() => {
        fetchCounts()
    }, [])


    useEffect(() => {
        document.body.classList.add('pxl-body-white');
        return () => {
            document.body.classList.remove('pxl-body-white');
        };
    }, []);
    const menuRef = useRef();

    useOutsideClick(menuRef, () => setallsubmenu(false));


    const toggleSublist = (id) => {
        setallsubmenu(prevState => ({
            //   ...prevState,
            [id]: !prevState[id]
        }));
    };

    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>

            {/* Main Wrapper  */}
            <main className="sch-main-wrapper">
            {state.isLoading?[...Array(3)].map((i, index) => <div className='ms-5 mt-5 me-5' key={index}>{skeletonLoader('asset', 1,'100%', 150)}</div>):<div className='asdf'>

                {/* <div className="pxl_sidebarMenu">
                    <button onClick={() => logoutMe()}>Logout</button>
                    <div className="pxl_logo">
                        <a href={()=> false} className="pxl_mainLogo">
                            <img src={appConfig.appLogo} alt="Logo"/>
                        </a>
                    </div>
                </div> */}


                {/* Banner Start  */}
                <div className="sch-banner-wrapper">
                    <div className="sch-container-fluid">
                        <div className="sch-banner-box pxl-dashboardBanner-box">
                            <div className="sch-banner-content">
                                <h2>Manage Your Social Media Content Effortlessly</h2>
                                <p>Streamline Your Social Media Strategy with Our User-Friendly Dashboards</p>
                                <button className='pxl-create-post-bnr-btn' onClick={() => { !currentPath.includes('/new-post') && navigate('/scheduler/new-post') }}>
                                    <span><img src="/assets/images/create-post-icon.svg" /></span>
                                    Create Post
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {state.draftList.length > 0 || state.scheduledPosts.length > 0 ? <div>
                    {/* Count Card Widgets Section */}
                    <div className="sch-count-cards pxlBanner-countCard-index">
                        <div className="sch-container">
                            <div className="sch-grid pxl-dashboard-count-box">
                                {/* <div className="sch-count-widget">
                                <div className="sch-count-icon">
                                    <span>
                                        <img src="/assets/images/count/icon1.svg" alt="" />
                                    </span>
                                    {state.isCount ? skeletonLoader('asset', 1, 84, 45) : <h3>{state.designcount}</h3>}
                                </div>
                                <p>Total Design Count</p>
                            </div> */}
                                {state.draftList.length > 0 && <div className="pxl-schedular-anchorBox">

                                    {/* <Link to={'/scheduler/draft'}> */}
                                        <div className="sch-count-widget">

                                            <div className="sch-count-icon">
                                                <span>
                                                    {/* <img src="/assets/images/count/icon2.svg" alt="" /> */}
                                                    {svg.TotalDraftPosts}
                                                </span>
                                                {state.isCount ? skeletonLoader('asset', 1, 84, 45) : <h3>{state.draftCount}</h3>}
                                            </div>
                                            {/* <p>Total Draft Posts</p> */}
                                            <p>Total Posts in Draft</p>
                                        </div>
                                    {/* </Link> */}
                                </div>}
                                {state.scheduledPosts.length > 0 && <div className="sch-count-widget">
                                    <div className="sch-count-icon">
                                        <span>
                                            {/* <img src="/assets/images/count/icon4.svg" alt="" /> */}
                                            {svg.TotalScheduledPosts}
                                        </span>
                                        {state.isCount ? skeletonLoader('asset', 1, 84, 45) : <h3>{state.scheduledCount}</h3>}
                                    </div>
                                    <p>Total Scheduled Posts</p>
                                </div>}
                                {/* <div className="sch-count-widget">
                                <div className="sch-count-icon">
                                    <span>
                                        <img src="/assets/images/count/icon3.svg" alt="" />
                                    </span>
                                    <h3>0</h3>
                                </div>
                                <p>Total Published Posts</p>
                            </div> */}

                            </div>
                        </div>
                    </div>


                    {/* draft post Section */}
                    <div className="pxl-schedular-dash-recent-draft pxl-recentDraft-indexBox">
                        <div className="sch-container-fluid">
                            {/* <Title title="Trending Templates" hasTitleLink={true} linkTitle="See All" uri="#" /> */}
                            <div className={`sch-divide-flex sch-divide-flex-res ${SchFlexBottom}`}>
                                <div className="sch-schedular-heading-name">
                                    <h2 className="sch-font-20-weight-600">Recent Draft</h2>
                                </div>
                                {state.draftList.length > 0 && <div className="sch-see-more-link">
                                    <Link to="/scheduler/draft">
                                        See More<span><img src="/assets/images/scheduler/arrow-right.svg" alt="" /></span>
                                    </Link>
                                </div>}
                            </div>
                            <div className="sch-section-row">

                                {state.isLoading ? [...Array(7)].map((i, index) => <div key={index} style={{ marginBottom: '40px' }}>{skeletonLoader('asset', 1, '', 90)}</div>) : state.draftList.length > 0 && state.draftList.map((post, index) => (
                                    <div key={index} className={`sch-template-box pxl-schedular-recentDraft-index schSchedular ${(!post?.mediaUrl || post?.mediaUrl.length == 0) && 'sch-draft-box-imgNone'}`}>
                                        {post?.mediaUrl && post?.mediaUrl.length > 0 && <div className={`sch-draft-img-box`}>
                                            <img src={post?.mediaUrl[0].mediaType === 'image' ? post?.mediaUrl[0].thumb || post?.mediaUrl[0].path : post?.mediaUrl[0].thumb || '/images/defaultVideoThumb.png'} alt="" />

                                        </div>}
                                        <div className='sch-draft-content'>
                                            <div className='sch-draft-post-title'>
                                                <h2>{post.title}</h2>
                                                <p dangerouslySetInnerHTML={{ __html: post.caption }}></p>
                                            </div>

                                            {allowAccess && <div className={`sch-menuSection  ${allsubmenu[post._id] ? 'sch-menuOpen' : ''}`}>
                                                <div className='three_dot_bg' id={post._id} onClick={() => toggleSublist(post._id)} ref={menuRef}><img src="/assets/images/three-dot.svg" alt="" /></div>
                                                <div className='sch-submenubox'>
                                                    <ul>
                                                        <li>
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault()
                                                            }}>
                                                                <button onClick={() => {
                                                                     setMyState(setState, { previewPopUp: true, previewData: post }) 
                                                                     }} >
                                                                    <span><img src="/assets/images/viewSvg.svg" alt="" /> </span> Preview
                                                                </button>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <Link to={`/scheduler/edit-draft/${post._id}`}>
                                                                <span><img src="/assets/images/edit-svg.svg" alt="" /> </span>Edit
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <a onClick={() => setMyState(setState, { removeMe: true, deleteId: post._id })}>
                                                                <span><img src="/assets/images/delet-svg.svg" alt="" /> </span>Delete
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>}

                                        </div>

                                    </div>


                                ))

                                }
                            </div>

                            {!state.isLoad && state.draftList.length == 0 &&
                                <NoDataFound title={'No drafts found.'} changeDesign={true} bgRemove={true} />
                            }
                        </div>
                    </div>



                    {/* get a quick start */}

                    <div className="pxl-schedular-dash-recent-schedular pxl-recent-schdule-indexBox">
                        <div className="sch-container-fluid">
                            {/* <Title title="Trending Templates" hasTitleLink={true} linkTitle="See All" uri="#" /> */}
                            <div className={`sch-divide-flex sch-divide-flex-res ${SchFlexBottom}`}>
                                <div className="sch-schedular-heading-name">
                                    <h2 className="sch-font-20-weight-600">Recent Scheduled</h2>
                                </div>
                                {state.scheduledPosts.length > 0 && <div className="sch-see-more-link">
                                    <Link to="/scheduler/calendar">
                                        See More<span><img src="/assets/images/scheduler/arrow-right.svg" alt="" /></span>
                                    </Link>
                                </div>}
                            </div>
                            <div className="sch-section-row pxlrecent-schedualer-indexbox">

                                {state.isLoad ? [...Array(7)].map((i, index) => <div key={index}>{skeletonLoader('asset', 1, '', 90)}</div>) : state.scheduledPosts.length > 0 && 
                                state.scheduledPosts.map((post, index) => (
                                    <div key={index} className={`sch-template-box schSchedular ${(!post?.mediaUrl || post?.mediaUrl.length == 0) && 'sch-draft-box-imgNone'}`}>
                                        {post?.mediaUrl && post?.mediaUrl.length > 0 && <div className={`sch-draft-img-box`}>
                                            <img src={post?.mediaUrl[0]?.mediaType === 'image' ? post?.mediaUrl[0].thumb || post?.mediaUrl[0].path : post?.mediaUrl[0].thumb || '/images/defaultVideoThumb.png'} alt="" />
                                            {/* : <img src={'/assets/images/default-draft-img.jpg'} alt="" />} */}
                                        </div>}
                                        <div className='sch-draft-content'>
                                            <div className='sch-draft-post-title'>
                                                <h2>{post.title}</h2>
                                                <p dangerouslySetInnerHTML={{ __html: post.caption }}></p>
                                            </div>

                                            {allowAccess && <div className={`sch-menuSection  ${allsubmenu[post._id] ? 'sch-menuOpen' : ''}`}>
                                                <div className='three_dot_bg' id={post._id} onClick={() => toggleSublist(post._id)} ref={menuRef}><img src="/assets/images/three-dot.svg" alt="" /></div>
                                                <div className='sch-submenubox'>
                                                    <ul>
                                                        <li>
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault()
                                                            }}>
                                                                <button onClick={() => { 
                                                                    setMyState(setState, { previewPopUp: true, previewData: post }) 
                                                                    }} >
                                                                    <span><img src="/assets/images/viewSvg.svg" alt="" /> </span> Preview
                                                                </button>
                                                            </a>
                                                        </li>
                                                       { (new Date(post.postDate) > new Date())&& <li>
                                                            <Link to={`/scheduler/edit-schedule-post/${post._id}`}>
                                                                <span><img src="/assets/images/edit-svg.svg" alt="" /> </span>Edit
                                                            </Link>
                                                        </li>}
                                                        <li>
                                                            <a onClick={() => setMyState(setState, { deleteMe: true, postId: post._id })}>
                                                                <span><img src="/assets/images/delet-svg.svg" alt="" /> </span>Delete
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>}

                                        </div>

                                    </div>


                                ))

                                }
                            </div>
                            {!state.isLoad && state.scheduledPosts.length == 0 &&
                                <NoDataFound title={'You have not scheduled any posts yet.'} changeDesign={true} bgRemove={true} />
                            }
                        </div>
                    </div>
                </div> :
                    <div className='sch-container-fluid'>
                        <div className='pxl-schedule-nodata-found pxl-schedule-nodata-found01'>
                            <NewCreatefile title={'You have not scheduled any posts yet.'} changeDesign={true} bgRemove={true} />
                        </div>
                    </div>
                }
</div>
            }
            </main>


            <ConfirmationPopup
                shownPopup={state.removeMe}
                closePopup={() => {
                    setMyState(setState, {
                        removeMe: false,
                        deleteId: ''
                    })
                }}
                type={"design"}
                title={`Do you want to move this draft to trash?`}
                subTitle={'You can restore it later from Dashboard > Trash.'}
                yesBtn={"Yes, Move"}
                btnLoader={state.loadData}
                removeAction={() => {
                    let data = {}
                    data['target'] = state.deleteId
                    setMyState(setState, { loadData: true });
                    hitApi({
                        url: 'scheduler/draft/move-to-trash',
                        method: "PATCH",
                        data: data,
                    }, (resp, err = null) => {
                        if (resp.status) {
                            getDraftPost();
                        }
                        setMyState(setState, {
                            removeMe: false,
                            deleteId: '',
                            loadData: false
                        })
                    })
                }}
            />
            <ConfirmationPopup
                shownPopup={state.deleteMe}
                closePopup={() => {
                    setMyState(setState, {
                        deleteMe: false,
                        postId: ''
                    })
                }}
                type={"design"}
                btnLoader={state.loadData}
                title={`Do you want to move this post to trash?`}
                subTitle={'You can restore it later from Dashboard > Trash.'}
                yesBtn={"Yes, Move"}
                removeAction={() => {
                    let data = {}
                    data['target'] = state.postId
                    setMyState(setState, { loadData: true });
                    hitApi({
                        url: 'scheduler/post/move-to-trash',
                        method: "PATCH",
                        data: data,
                    }, (resp, err = null) => {
                        if (resp.status) {
                            getAllPost();
                        }
                        setMyState(setState, {
                            deleteMe: false,
                            postId: '',
                            loadData: false
                        })
                    })
                }}
            />
            <PreviewPost
                shownPopup={state.previewPopUp}
                closePopup={() => {
                    setMyState(setState, {
                        previewPopUp: false
                    })
                }}
                data={{ title: state.previewData.title, caption: state.previewData.caption, mediaUrl: state.previewData.mediaUrl, accounts: state.previewData.postOn, successData: state.previewData?.successData ,supportSocialAccount : state.previewData?.supportSocialAccount}}
            />
        </>
    )
};

export default Dashboard;