import { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { NoPageFound } from '../components/commonAction';

const NoPage = () => {
    let appPath = useLocation().pathname.split('/').filter(Boolean)[0];
    let currentPath = useLocation().pathname.replace(`/${appPath}`, '');
//    useEffect(()=>{
//     if(!currentPath.includes('builder'))
//     {
//         window.location.href="/dashboard"; 
//     }
//    },[currentPath])
 
    return (
        <>
           <NoPageFound title={'Page Not Found'} btntext={'Back to Dashboard'} />
        </>
    )
};

export default NoPage;