
import { Helmet } from "react-helmet";
import { useStore } from "../../zustand/store";
import React, { useState, useEffect, useRef } from "react";
import { convertObjToQueryString, hitApi, objectToFormData, skeletonLoader, useOutsideClick, commonToast } from "../../components/commonAction";
import svg from "../../components/svg";
import Select from "react-select";
import ExampleResponse from "../api-endpoint/ExampleResponse";
import appConfig from "../../config/appConfig";



const Dashboard = ({ pageTitle }) => {
    let store = useStore(state => state);
    const [apiData, setState] = useState([]);
    const [dataloader, setDataLoader] = useState(false);
    const [addFolder, setAddFolder] = useState(false);
    const [activeApi, setActiveApi] = useState(false);
    const [btnction, setBtnActive] = useState('');
    const [editData, setEditData] = useState('');
    const [activeTab, setActiveTab] = useState('headers');
    const [newAPI, setnewAPI] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);

    let initialState = {
        title: "",
        description: "",
        url: "",
        method: "get",
        query: [
            {
                key: "",
                description: "",
                type: "",
                example: "",
                require: false
            }
        ],
        body: [
            {
                key: "",
                description: "",
                type: "",
                example: "",
                require: false
            }
        ],
        headers: [
            {
                key: "",
                value: "",
                example: "",
                type: ""
            }
        ],
        response: ''
    }
    const [formData, setFormData] = useState(initialState);
    const [tryResponse, setTryResponse] = useState({});
    const [activeFolderTitle, setActiveFodlerName] = useState('');



    let reqMethod = [{ value: 'GET', label: 'GET' }, { value: 'POST', label: 'POST' }, { value: 'PUT', label: 'PUT' }, { value: 'PATCH', label: 'PATCH' }, { value: 'DELETE', label: 'DELETE' }];
    let typeObj = [{ value: 'string', label: 'string' }, { value: 'number', label: 'number' }, { value: 'file', label: 'file' }, { value: 'array', label: 'array' }, { value: 'object', label: 'object' }];
    useEffect(() => {
        getAPIData()
    }, []);

    const [allsubmenu, setallsubmenu] = useState(false);
    const [openFolders, setOpenFolders] = useState([]);
    const toggleSublist = (id) => {
        setallsubmenu(prevState => ({
            //   ...prevState,
            [id]: !prevState[id]
        }));
    };

    const menuRef = useRef();

    useOutsideClick(menuRef, () => setallsubmenu(false));

    const getAPIData = () => {
        setDataLoader(true);
        hitApi({
            url: `api-documentation/get-api-details`,
            method: "GET",
            data: {}
        }, (resp) => {
            setDataLoader(false);
            if (resp.status) {
                const response = resp.data;
                setState(response);
            }
        });
    }

    const handleFolderAction = (e) => {
        if (!editData) {
            commonToast('error', `Folder Name is required.`);
            return false
        }
        let data = {
            title: editData
        }
        let url = '';
        let method = '';
        if (btnction == 'add' || btnction == 'root') {
            if (addFolder) data.parentId = addFolder;
            url = `api-documentation/add-folder`;
            method = 'POST'
        }
        if (btnction == 'edit') {
            data.target = addFolder;
            url = `api-documentation/update-folder`;
            method = 'PATCH'
        }

        hitApi({
            url: url,
            method: method,
            data: data
        }, (resp) => {
            setBtnActive(false);
            setEditData('');
            setAddFolder('');
            if (resp.status) {
                getAPIData()
            }
        });
    }

    const openFoldershandle = (id) => {
        if (openFolders.includes(id)) {
            setOpenFolders(curr => curr.filter(cur => cur != id));
        } else {
            setOpenFolders(curr => [...curr, id]);
        }

    }

    let mapFolder = (folderData) => {
        let { folderId, apis = [], title, folders = [] } = folderData,
            childHtml = [],
            apiHtml = [];
        if (openFolders.includes(folderId)) {
            if (folders.length) {
                for (let childFolderData of folders) {

                    childHtml.push(mapFolder(childFolderData));
                }
            }
        }

        if (openFolders.includes(folderId)) {
            if (apis.length) {
                for (let apiData of apis) {
                    let { title } = apiData;
                    apiHtml.push(<li onClick={e => {
                        setTryResponse({});
                        setActiveFodlerName(false);
                        setActiveApi(apiData);
                        setnewAPI(folderId); 
                        let { title, description, request, response } = apiData;
                        if (!apiData.request.query) {
                            delete request.query;
                            request.query = formData.query;
                        }
                        setFormData({
                            title, description, response, ...request
                        })
                    }}>
                        <a href="#" onClick={(e) => {
                            e.preventDefault()
                        }}>{title}</a>
                    </li>);
                }
            }
        }
        return <li>
            <div className="ap_single_folder_wrap" >
                <a href="#" className="floder_title" onClick={() => { openFoldershandle(folderId); setActiveFodlerName(title) }}>{title}</a>
                <div className={`sch-menuSection  ${allsubmenu[folderId] ? 'sch-menuOpen' : ''}`}>
                    <div className='three_dot_bg'
                        id={folderId} onClick={() => toggleSublist(folderId)} ref={menuRef}
                    ><img src="/assets/images/three-dot.svg" alt="" /></div>
                    <div className={`sch-submenubox sch-submenubox-apiDocument`}>
                        {/* className="pxl-assets-plus" */}

                        <ul >
                            <li>
                                <a href="#" onClick={e => {
                                    setAddFolder(folderId); setBtnActive('add'); setEditData(''); setallsubmenu(false)
                                }}><span><img src="/assets/images/add-foldersvg.svg" alt="" /></span> Add Folder</a>
                            </li>
                            <li>
                                <a href="#" onClick={e => {
                                    setAddFolder(folderId); setBtnActive('edit'); setEditData(title); setallsubmenu(false)
                                }}><span><img src="/assets/images/edit-svg.svg" alt="" /></span>Edit Folder</a>
                            </li>
                            <li>
                                <a href="#" onClick={e => {
                                    setallsubmenu(false)
                                    setActiveApi(false); setTryResponse({}); setAddFolder(false); setActiveFodlerName(false)
                                    setnewAPI(folderId); setBtnActive('api'); setEditData(''); setFormData(initialState);
                                }}><span><img src="/assets/images/new-api-svg.svg" alt="" /></span>New Api </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {
                addFolder == folderId ?
                    <div className="pxl-api-addCancel-section">
                        <input placeholder="Enter folder name " value={editData} onChange={(e) => setEditData(e.target.value)} />
                        <div className="pxl-api-addCancel-btn">
                        <div className="api_action_btn" onClick={e => handleFolderAction()}>{btnction == 'add' ? "Add" : btnction == 'edit' ? "Update" : "Add"}</div>
                        <div className="api_action_btn cancel" onClick={e => setAddFolder('')}>Cancel</div>
                        </div>
                    </div>
                    : <></>
            }


            {childHtml.length ? <ul className="pxl_folder_list" style={{


            }}>{childHtml}</ul> : <></>}


            {apiHtml.length ? <ul className="pxl_api_list" style={{

            }}>{apiHtml}</ul> : <></>}


        </li>;

    };


    const handleInputValue = (para, ind, val) => {
        if (activeTab == 'headers') {
            setFormData(prevState => ({
                ...prevState,
                headers: prevState.headers.map((item, index) =>
                    index === ind ? { ...item, [para]: val } : item
                )
            }));
        } else if (activeTab == 'params') {
            setFormData(prevState => ({
                ...prevState,
                query: prevState.query.map((item, index) =>
                    index === ind ? { ...item, [para]: val } : item
                )
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                body: prevState.body.map((item, index) =>
                    index === ind ? { ...item, [para]: val } : item
                )
            }));
        }

    }

    const addNewRow = () => {
        if (activeTab == 'headers') {

            setFormData(prevState => ({
                ...prevState,
                headers: [
                    ...prevState.headers,
                    {
                        key: "",
                        value: "",
                        type: ""
                    }
                ]
            }))
        } else if (activeTab == 'params') {
            setFormData(prevState => ({
                ...prevState,
                query: [
                    ...prevState.query,
                    {
                        key: "",
                        description: "",
                        type: "",
                        require: false
                    }
                ]
            }))
        } else {
            setFormData(prevState => ({
                ...prevState,
                body: [
                    ...prevState.body,
                    {
                        key: "",
                        description: "",
                        type: "",
                        require: false
                    }
                ]
            }))
        }

    }

    const removeEmptyObjects = (formData) => {
        const updatedFormData = { ...formData };

        updatedFormData.body = updatedFormData.body.filter(item => {
            return Object.keys(item).some(key => {
                if (key !== "require") return item[key] !== "";
            });
        });

        updatedFormData.headers = updatedFormData.headers.filter(item => {
            return Object.values(item).some(value => value !== "");
        });

        updatedFormData.query = updatedFormData.query.filter(item => {
            return Object.keys(item).some(key => {
                if (key !== "require") return item[key] !== "";
            });
        });

        return updatedFormData;
    };

    const SaveAPIData = () => {
        const filteredData = removeEmptyObjects(formData);

        let data = filteredData
        data.folderId = newAPI
        if (activeApi) data.target = activeApi.apiId;
        let url = activeApi ? 'api-documentation/update-api' : `api-documentation/add-api`;
        setBtnLoader(true);
        hitApi({
            url: url,
            method: activeApi ? "PATCH" : "POST",
            data: data
        }, (resp) => {
            setBtnLoader(false);
            if (resp.status) {
                setFormData(initialState);
                setnewAPI('');
                getAPIData(false);
            }
        });
    }



    let tryApi = () => {
        let headers = {};
        let body = {};
        let isFormData = false;

        for (let index = 0; index < formData.headers.length; index++) {
            let key = formData.headers[index].key;
            let value = formData.headers[index].value;
            headers[key] = value;
        }

        for (let index = 0; index < formData.body.length; index++) {
            let key = formData.body[index].key;
            let value = formData.body[index].example;
            body[key] = value;
            if (formData.body[index].type == 'file') {
                isFormData = true;
            }
        }


        let urlRegex = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)?/;
        let url = urlRegex.test(formData.url) ? formData.url : appConfig.apiUrl + formData.url;

        for (let index = 0; index < formData.query.length; index++) {
            if (formData.query[index].key) {
                if (index == 0) {
                    url += `?${formData.query[index].key}=${formData.query[index].example}`;
                } else {
                    url += `&${formData.query[index].key}=${formData.query[index].example}`;
                }
            }
        }
        let { method } = formData;
        method = method.toUpperCase();

        let data = {
            headers: headers,
            method,
            body: isFormData ? objectToFormData(body) : JSON.stringify(body),
        }

        if (['get', "GET"].includes(method)) {
            let qs = convertObjToQueryString(method);
            url = url + `?${qs}`;
        }

        if (method == 'GET' || method == 'DELETE') {
            delete data.body;
        }


        fetch(url, data)
            .then(res => res.json())
            .then(resp => {
                setTryResponse(resp);
            })
    }

    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper pxl-apiDocumentation-mainWrap">
                <div className="pxl_rightBar_assets">
                    {/* Count Card Widgets Section */}
                    <div className="pxl_pageTitle">
                        <h2>{pageTitle}</h2>
                    </div>
                    <div className="pxl-count-cards">
                        <div className="pxl_container api_admin_flex">
                            <div className="pxl_grid api_folder_wrap">
                                <div >
                                    <div className="px_revenueBox">

                                        {
                                            <ul className="api_folder_box">
                                                <li className="ap_single_folder_wrap add_btn" onClick={() => setBtnActive('root')}>Add Folder</li>
                                                <li>
                                                    {
                                                        btnction == 'root' && <div className="api_add_inp_sec">
                                                            <input placeholder="Enter folder name" value={editData} onChange={(e) => setEditData(e.target.value)} />
                                                            <div className="api_action_btn" onClick={e => handleFolderAction()}>Add</div>
                                                            <div className="api_action_btn cancel" onClick={e => { setAddFolder(''); setBtnActive(false) }}>Cancel</div>
                                                        </div>
                                                    }
                                                </li>
                                                {dataloader ? skeletonLoader('list', 4) :
                                                    apiData.length > 0 ? apiData.map(folderData => {
                                                        return mapFolder(folderData)
                                                    }) : <></>
                                                    // <li className="ap_single_folder_wrap add_btn" onClick={() => setBtnActive('add')}>Add Folder</li>
                                                }
                                                {/* <li>
                                                    {
                                                        apiData.length == 0 && btnction == 'add' && <div className="api_add_inp_sec">
                                                            <input value={editData} onChange={(e) => setEditData(e.target.value)} />
                                                            <div className="api_action_btn" onClick={e => handleFolderAction()}>Add</div>
                                                            <div className="api_action_btn cancel" onClick={e => { setAddFolder(''); setBtnActive(false) }}>Cancel</div>
                                                        </div>
                                                    }
                                                </li> */}
                                            </ul>

                                        }


                                    </div>
                                </div>
                            </div>
                            {activeFolderTitle ? <div className="api_folder_text_bold">{activeFolderTitle}</div> : (newAPI || activeApi) ?
                                <div className="api_second_wrap">
                                    <div className="pxl_grid api_form_wrap">

                                        <div className="api_form_box">


                                            <>
                                                <div className="pxl-api-doc-sec1">
                                                    <div className="pxl_input_feilds">
                                                        <label>Title:</label>
                                                        <input type="text" value={formData.title}
                                                            onChange={(e) => setFormData((cur) => {
                                                                return {
                                                                    ...cur, title: e.target.value
                                                                }
                                                            })} />
                                                    </div>
                                                    <div className="pxl_input_feilds">
                                                        <label>Description:</label>
                                                        <input type="text" value={formData.description}
                                                            onChange={(e) => setFormData((cur) => {
                                                                return {
                                                                    ...cur, description: e.target.value
                                                                }
                                                            })} />
                                                    </div>
                                                    <div className="pxl_input_feilds">
                                                        <label>Method :</label>
                                                        <div className="pxl-apiPost-select pxl_input_feilds">

                                                            <input type="text" value={formData.url} placeholder="Enter URL"
                                                                onChange={(e) => setFormData((cur) => {
                                                                    return {
                                                                        ...cur, url: e.target.value
                                                                    }
                                                                })} />
                                                            <div className='cun-headreSelect'>
                                                                <Select
                                                                    value={reqMethod.filter(option =>
                                                                        option.value == formData.method)}
                                                                    options={reqMethod}
                                                                    onChange={(e) =>
                                                                        setFormData((cur) => {
                                                                            return {
                                                                                ...cur, method: e.value
                                                                            }
                                                                        })}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="pxl-api-doc-sec2">
                                                    <div className="api_tabs pxl-apiTab-wrap-box">
                                                        <div className={activeTab == 'headers' ? "active_tab" : null} onClick={() => setActiveTab('headers')}>Headers</div>
                                                        <div className={activeTab == 'body' ? "active_tab" : null} onClick={() => setActiveTab('body')}>Body</div>
                                                        <div className={activeTab == 'params' ? "active_tab" : null} onClick={() => setActiveTab('params')}>Params</div>
                                                    </div>
                                                    <div className="pxl-apiTable-wrap-box">
                                                        <table className="api_table">
                                                            <thead>
                                                                <tr>
                                                                    <th>key</th>
                                                                    <th>{activeTab == 'headers' ? 'value' : 'description'}</th>
                                                                    <th>example</th>
                                                                    <th>type</th>
                                                                    <th style={{ position: 'relative' }} className={activeTab == 'headers' ? "headers" : ''}>{activeTab == 'headers' ? "" : 'isRequired'} <div className="api_add_field"
                                                                        onClick={addNewRow}>{svg.app.Addsvg} </div></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="pxl-api-headerBox">
                                                                {activeTab == 'headers' ?
                                                                    formData.headers.map((head, i) => (
                                                                        <tr>
                                                                            <td><input value={head.key} type="text" name="key" onChange={(e) => handleInputValue('key', i, e.target.value)} /></td>
                                                                            <td><input value={head.value} type="text" name="value" onChange={(e) => handleInputValue(e.target.name, i, e.target.value)} /></td>
                                                                            <td>{(head.type == 'array' || head.type == 'object') ? <textarea value={head.example}
                                                                                onChange={(e) => handleInputValue('example', i, e.target.value)}></textarea> :
                                                                                <input type="text" value={head.example}
                                                                                    onChange={(e) => handleInputValue('example', i, e.target.value)}
                                                                                />
                                                                            } </td>
                                                                            <td>
                                                                                <div className='cun-headreSelect pxl-tableType-select'>
                                                                                    <div className="cun-selectWrap">
                                                                                        <Select
                                                                                            value={typeObj.filter(option =>
                                                                                                option.value == head.type)}
                                                                                            options={typeObj}
                                                                                            onChange={(e) => handleInputValue('type', i, e.value)}
                                                                                            styles={{
                                                                                                menuPortal: (provided) => ({
                                                                                                    ...provided,
                                                                                                    zIndex: 9999,
                                                                                                }),
                                                                                            }}
                                                                                            menuPortalTarget={document.body}

                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className={activeTab == 'headers' ? "headers" : ''}> <span className="api_row_del" onClick={() => setFormData(prevState => ({
                                                                                ...prevState,
                                                                                headers: prevState.headers.filter((item, index) => index !== i)
                                                                            }))}><img src="/assets/images/delet-svg.svg" alt="" /> </span></td>
                                                                        </tr>
                                                                    ))

                                                                    :
                                                                    activeTab == 'body' ?
                                                                        formData.body.map((body, i) => (
                                                                            <tr>
                                                                                <td><input value={body.key} type="text" name="key" onChange={(e) => handleInputValue(e.target.name, i, e.target.value)} /></td>
                                                                                <td><input value={body.description} type="text" name="description" onChange={(e) => handleInputValue(e.target.name, i, e.target.value)} /></td>

                                                                                <td>{(body.type == 'array' || body.type == 'object') ? <textarea
                                                                                    value={body.example}
                                                                                    onChange={(e) => handleInputValue('example', i, e.target.value)}></textarea> :
                                                                                    body.type == 'file' ?
                                                                                        <input type="file"
                                                                                            onChange={(e) => handleInputValue('example', i, e.target.files[0])} />
                                                                                        :
                                                                                        <input type="text"
                                                                                            value={body.example} onChange={(e) => handleInputValue('example', i, e.target.value)} />
                                                                                } </td>

                                                                                <td>
                                                                                    <div className='cun-headreSelect pxl-tableType-select'>
                                                                                        <Select
                                                                                            value={typeObj.filter(option =>
                                                                                                option.value == body.type)}
                                                                                            options={typeObj}
                                                                                            onChange={(e) => handleInputValue('type', i, e.value)}
                                                                                            styles={{
                                                                                                menuPortal: (provided) => ({
                                                                                                    ...provided,
                                                                                                    zIndex: 9999,
                                                                                                }),
                                                                                            }}
                                                                                            menuPortalTarget={document.body}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td >
                                                                                    <div className="d-flex">
                                                                                        <div className="cun-headerSelect-wrap">
                                                                                            <div className="cun-AllChecknoxSelect"><div className="sch-custom-check-box ">
                                                                                                <div className="form-group">
                                                                                                    <input type="checkbox" id={i}
                                                                                                        checked={body.require}
                                                                                                        onChange={(e) => handleInputValue('require', i, e.target.checked)}
                                                                                                    />
                                                                                                    <label htmlFor={i}>Required</label>
                                                                                                </div>
                                                                                            </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <span className="api_row_del" onClick={() => setFormData(prevState => ({
                                                                                            ...prevState,
                                                                                            body: prevState.body.filter((item, index) => index !== i)
                                                                                        }))} ><img src="/assets/images/delet-svg.svg" alt="" /> </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))

                                                                        :
                                                                        formData.query.map((query, i) => (
                                                                            <tr>
                                                                                <td><input value={query.key} type="text" name="key" onChange={(e) => handleInputValue(e.target.name, i, e.target.value)} /></td>
                                                                                <td><input value={query.description} type="text" name="description" onChange={(e) => handleInputValue(e.target.name, i, e.target.value)} /></td>
                                                                                <td>{(query.type == 'array' || query.type == 'object') ? <textarea
                                                                                    value={query.example}
                                                                                    onChange={(e) => handleInputValue('example', i, e.target.value)}></textarea> :
                                                                                    <input type="text"
                                                                                        value={query.example} onChange={(e) => handleInputValue('example', i, e.target.value)} />
                                                                                } </td>
                                                                                <td>
                                                                                    <div className='cun-headreSelect'>
                                                                                        <Select
                                                                                            value={typeObj.filter(option =>
                                                                                                option.value == query.type)}
                                                                                            options={typeObj}
                                                                                            onChange={(e) => handleInputValue('type', i, e.value)}
                                                                                            styles={{
                                                                                                menuPortal: (provided) => ({
                                                                                                    ...provided,
                                                                                                    zIndex: 9999,
                                                                                                }),
                                                                                            }}
                                                                                            menuPortalTarget={document.body}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td >
                                                                                    <div className="d-flex">
                                                                                        <div className="cun-headerSelect-wrap">
                                                                                            <div className="cun-AllChecknoxSelect"><div className="sch-custom-check-box ">
                                                                                                <div className="form-group">
                                                                                                    <input type="checkbox" id={i}
                                                                                                        checked={query.require}
                                                                                                        onChange={(e) => handleInputValue('require', i, e.target.checked)}
                                                                                                    />
                                                                                                    <label htmlFor={i}>Required</label>
                                                                                                </div>
                                                                                            </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <span className="api_row_del" onClick={() => setFormData(prevState => ({
                                                                                            ...prevState,
                                                                                            query: prevState.query.filter((item, index) => index !== i)
                                                                                        }))} ><img src="/assets/images/delet-svg.svg" alt="" /> </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </>
                                        </div>

                                        <div className="pxl-api-doc-sec3">
                                            <div className="pxl-sample-responseBox">
                                                <div className="pxl_input_feilds">
                                                    <label>sample response:</label>
                                                    <textarea type="text" value={formData.response}

                                                        onChange={(e) => setFormData((cur) => {
                                                            return {
                                                                ...cur, response: e.target.value
                                                            }
                                                        })} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="api_btn_sav">
                                            <button className='api-btn api_try_btn pxl-tryItOut-btn' onClick={() => tryApi()}>Try it out</button>
                                            <button className="pxlCommon-btn" disabled={btnLoader} onClick={SaveAPIData}>{btnLoader ? 'Processing...' : activeApi ? "Update" : "Save"}</button>
                                        </div>

                                    </div>
                                    <div className="pxl-api-doc-sec4">
                                        <div className="api_response_box">
                                            <h4>Response</h4>
                                            <ExampleResponse data={tryResponse} />
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};

export default Dashboard;