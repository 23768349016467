import React, { useEffect, useState } from 'react'
import svg from '../svg';
import { commonToast, hitApi } from '../../../../components/commonAction';
import { Modal } from 'react-bootstrap';
import ConfirmationPopup from '../../../../components/common/ConfirmationPopup';

function FAQSection({ p_id, faq }) {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [faqList, setfaqList] = useState([]);

  const [deletePopUp, setDeletePopUp] = useState(false);
  const [FaqID, setFaqId] = useState('');

  useEffect(() => {
    if (faq) setfaqList(faq);
  }, [faq])

  const handleAddFaq = () => {
    setOpenPopUp(true);
    setIsEdit(false);
    setQuestion('');
    setAnswer('');
  }

  const getAllFaq = () => {
    if (p_id) {
      hitApi({
        url: 'pixa-support/getLandingPage',
        method: "POST",
        data: {
          project_id: p_id
        },
        loading: true,
        alert: false,
      }, (resp, err = null) => {
        if (resp.status === 1) {
          if (resp.featurePage) {
            setfaqList(resp.FAQ);
          }
        }
      });

    }
  }

  const handleEditFaq = (faq) => {
    setQuestion(faq.question);
    setAnswer(faq.answer);
    setFaqId(faq._id)
    setOpenPopUp(true);
    setIsEdit(true);
  }

  const addFAQFormSubmit = (e) => {
    e.preventDefault();

    if (!question || !answer) {
      commonToast('error', `Field can not be empty!`);
      return false;
    } else {

      let faq = {
        FAQ_id: FaqID,
        question: question,
        answer: answer,
        project_id: p_id,
      }
      if (!isEdit) delete faq.FAQ_id
      hitApi({
        url: 'pixa-support/api/addFAQ',
        method: "POST",
        data: faq,
        loading: true,
        alert: true,
      }, (resp, err = null) => {
        if (resp.status === 1) {
          setOpenPopUp(false);
          getAllFaq();
        }
      });
    }
  }

  const EditPopupCloseHandler = () => {
    setOpenPopUp(false)
  }

  // Delete Features start 
 
  const handleDelete = (id) => {
    setDeletePopUp(true);
    setFaqId(id);
  }

  
  return (
    <>
      <div className='mail_back_color_box'>
        {
          faqList.length == 0 ?
            <div className='sch-no-data-found-wrapper'>
              <div className='sch-no-data-inner'>
                <img src='/images/support_no_data.png' />
                <h4>No Results Found</h4>
                <p>We can not find any features for the this agency</p>
                <div className='btn_center_add'>
                  <button className="pxlCommon-btn" style={{ gap: '6px' }} onClick={() => setOpenPopUp(true)}>
                    Add Features
                  </button>
                </div>

              </div>

            </div> :
            <>
              {
                faqList.map((val,index) => (<div className="support_single_feature_wrap" key={index}>


                  <div className="single_agent_wrap">
                    <div className="flex_box_img_contet">

                      <div className="feat_detail">
                        <h5>{val.question}</h5>
                        <p>  {val.answer}</p>
                      </div>
                    </div>
                    <div className="land_Action_edit" onClick={() => handleEditFaq(val)}>
                      {svg.edit_icon}
                    </div>
                    <div className="land_action_del" onClick={() => handleDelete(faq._id)}>
                      {svg.delete_red}
                    </div>
                  </div>

                </div>

                ))
              }


              <div className='btn_center_add'>
                <button className="pxlCommon-btn" style={{ gap: '6px' }} onClick={handleAddFaq}>
                
                  Add Features
                </button>
              </div>
            </>
        }

      </div>
      <Modal
        show={openPopUp} onHide={() => setOpenPopUp(false)} animation={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="pxl_modalDv"
      >


        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className='pxl-model-header'>
              <h2 className='modal-title'>Create New Design</h2>
            </div>
          </Modal.Title>
        </Modal.Header>


        <form action="" className='sup_proj_setting_form model'
          onSubmit={addFAQFormSubmit}
        >
          <div className="pxl_input_feilds mb-3">
            <label>Question</label>
            <input type="text" name="" placeholder="Enter Question" value={question} onChange={(e) => setQuestion(e.target.value)} />
          </div>
          <div className="pxl_input_feilds mb-3">
            <label>Answer</label>
            <textarea type="text" name="" placeholder="Enter Answer" value={answer} onChange={(e) => setAnswer(e.target.value)} />
          </div>


          <button type="submit" className="pxl_btn"> Add
            {/* {btnLoading ? "Processing..." : 'Continue'} */}
          </button>
        </form>


      </Modal>

      {/* Delete Pop Up */}
      <ConfirmationPopup
        shownPopup={deletePopUp}
        closePopup={() => {
          setDeletePopUp(false);
          setFaqId('')
        }}
        type={"FAQ"}
        // yesBtn={isTrash ? "Yes, Delete" : "Yes, Move"}
        removeAction={() => {
          
          hitApi({
            url: 'pixa-support/api/deletePage',
            method: "POST",
            data: {
              FAQ_id: FaqID,
            },
            loading: true,
            alert: true,
          }, (resp, err = null) => {
            if (resp.status === 1) {
              getAllFaq();
              setDeletePopUp(false);
              setFaqId('');
            }
          });

        }}
      />
    </>
  )
}

export default FAQSection