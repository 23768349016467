import { Modal } from 'react-bootstrap';
// import { DatePicker, useFormClassNames } from 'rsuite';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import {DesktopDatePicker} from '@mui/lab/DesktopDatePicker';
import { timeZone } from '../config/timeZone'
import "rsuite/dist/rsuite-no-reset.min.css";
import schedulerSvg from '../components/schedulerSvg';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import svg from "../../../components/svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { assetsUrlPath, commonToast, downloadImage, hitApi } from '../../../components/commonAction';
import { useStore } from '../../../zustand/store';
import { useState } from 'react';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import Select from "react-select";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
const options = [
    { value: 'project', label: 'Project' },
    { value: 'project1', label: 'Project1' },
    { value: 'project1', label: 'Project2' }
]


function SchedulePopup({
    shownPopup,
    closePopup,
    title,
    subTitle,
    type,
    handleValue,
    scheduleDate,
    timezone,
    handleSchedule,
    btnLoader,
    isEdit,
    callFrom
}) {


    // const today = new Date();
    const today = dayjs().startOf('day');
    // const today = dayjs().startOf('day');
    // today.setHours(0, 0, 0, 0);

    const shouldDisableDate = (date) => {
        return date.isBefore(today, 'day');
    };
    const handleContinue = () => {
        const selectedDate = dayjs(scheduleDate);
        if (selectedDate.isBefore(today)) {
            commonToast('error', `Schedule date cannot be in the past.`);
            return false;
        }
        if (scheduleDate == 'Invalid date') {
            commonToast('error', `Schedule date is required.`);
            return false
        }
        if (!scheduleDate) {
            commonToast('error', `Schedule date is required.`);
            return false
        }
        if (!timezone) {
            commonToast('error', `Timezone is required.`);
            return false
        }

        handleSchedule();
    }

    const noTimezone= ()=>{
        return 'No timezone found'
    }

    return (
        <>
            <Modal
                show={shownPopup} onHide={closePopup} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv pxl-modelDiv1"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className='pxl_model_body pxl-scdulePost-model'>
                        <div className='pxa_confirmation-delet'>
                            <div className='pxa_textContent'>
                                <h4>{title ? title : `Are you sure you want to delete this ${type ? type : 'item'} ?`}</h4>
                                <p>{subTitle ? subTitle : `This ${type ? type : 'item'} will be deleted immediately. you can't undo this action.`}</p>
                                <div className="sch-modelInner ">
                                    <div className='sch-pupup-flex'>
                                        <div className="pxl_input_feilds">
                                            <label className='sch-text-left'>Date Time</label>
                                            <div className="cun-datePicke pxldatepicker">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                                        <DateTimePicker
                                                            // label="With Time Clock"
                                                            viewRenderers={{
                                                                hours: renderTimeViewClock,
                                                                minutes: renderTimeViewClock,
                                                                seconds: renderTimeViewClock,
                                                            }}

                                                            shouldDisableDate={shouldDisableDate}
                                                            minDate={today}
                                                            

                                                            // disabledDate={date => date > today}
                                                            value={scheduleDate ? dayjs(scheduleDate) : null}
                                                            
                                                            onChange={(date) => { handleValue('date', date.$d); }}
                                                            
                                                        />

                                                    </DemoContainer>
                                                </LocalizationProvider>
                                                {/* <DatePicker

                                                    format="MMMM dd, yyyy hh:mm aa"
                                                    // showMeridian
                                                    // format="MMMM dd, yyyy"
                                                    placeholder="Select Date Time"
                                                    placement="auto" appearance="default"
                                                    disabledDate={date => date < today}
                                                    value={scheduleDate ? new Date(scheduleDate) : null}
                                                    onChange={(date) => { handleValue('date', date); }}

                                                /> */}
                                            </div>
                                        </div>
                                        <div className="pxl_input_feilds sch-select-field">
                                            <label className='sch-text-left'>Timezone</label>
                                            <div className="sch-selectWrap">
                                                <Select
                                                    // menuIsOpen={true}
                                                    noOptionsMessage={noTimezone}
                                                    value={timeZone.filter(option =>
                                                        option.value == timezone || option.utc.includes(timezone))}
                                                    options={timeZone}
                                                    onChange={(e) => handleValue('timezone', e.value, timeZone.filter(option =>
                                                        option.value == e.value)[0].offset)}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {
                                    <div className='sch-schedule-btn-wrap'>
                                        <button disabled={btnLoader} onClick={() => handleContinue()} className='pxl_btn'>{btnLoader ? 'Scheduling...' : isEdit ? 'Update Schedule' : 'Schedule'}</button>
                                    </div>
                                    //       callFrom === "draftSchedule" ? null : <div className='sch-schedule-btn-wrap'>
                                    //       <button disabled={btnLoader} onClick={() => handleContinue()} className='pxl_btn'>{btnLoader ? 'Scheduling...' : isEdit ? 'Update Schedule' : 'Schedule'}</button>
                                    //   </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SchedulePopup;



export const PreviewPost = ({
    shownPopup,
    closePopup,
    data
}) => {
    let supportedAccounts=data?.supportSocialAccount || []
    
    let store = useStore(state => state.userData);
    let activeTab = data.accounts && ['facebook', 'instagram', 'linkedin', 'pinterest', 'youtube'].filter(item => data.accounts.includes(item));
    
    return (
        <Modal
            show={shownPopup} onHide={closePopup}

            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="pxl_modalDv schPreviewPopup pxl-faceBook-postPopup">
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
                <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab ? activeTab[0] + "Tab" : 'facebookTab'}>
                    <div className='sch-model-tab-header'>
                        <Nav variant="pills">
                            {(data.accounts?.length==0)?(data.supportSocialAccount ? data.supportSocialAccount.includes("FACEBOOK") : true) && <Nav.Item>
                                <Nav.Link eventKey="facebookTab" className='sch-fbActive'>
                                    <div className='sch-social-model-wrap'>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <img src="/assets/images/social-icons/fb.svg" alt="" />
                                            <span>Facebook</span>
                                        </a>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>:(data.accounts ? data.accounts.includes("facebook") : true) && <Nav.Item>
                                <Nav.Link eventKey="facebookTab" className='sch-fbActive'>
                                    <div className='sch-social-model-wrap'>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <img src="/assets/images/social-icons/fb.svg" alt="" />
                                            <span>Facebook</span>
                                        </a>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>}
                            {(data.accounts?.length==0)?(data.supportSocialAccount ? data.supportSocialAccount.includes("INSTAGRAM") : true) && <Nav.Item>
                                <Nav.Link eventKey="instagramTab" className='sch-instaActive'>
                                    <div className={`sch-social-model-wrap ${(((data.mediaUrl != undefined) && (data.mediaUrl?.length == 0)) || (data.mediaUrl == undefined)) && 'pxl-button-disable'}`} >
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <img src="/assets/images/social-icons/instagram.svg" alt="" />
                                            <span>Instagram</span>
                                        </a>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>:(data.accounts ? data.accounts.includes("instagram") : true) && <Nav.Item>
                                <Nav.Link eventKey="instagramTab" className='sch-instaActive'>
                                    <div className={`sch-social-model-wrap ${(((data.mediaUrl != undefined) && (data.mediaUrl?.length == 0)) || (data.mediaUrl == undefined)) && 'pxl-button-disable'}`} >
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <img src="/assets/images/social-icons/instagram.svg" alt="" />
                                            <span>Instagram</span>
                                        </a>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>}
                            {(data.accounts?.length==0)?(data.supportSocialAccount ? data.supportSocialAccount.includes("LINKEDIN") : true) && <Nav.Item>
                                <Nav.Link eventKey="linkedinTab" className='sch-linkdinActive'>
                                    <div className='sch-social-model-wrap'>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <img src="/assets/images/social-icons/linkdin.svg" alt="" />
                                            <span>LinkedIn</span>
                                        </a>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>:
                            (data.accounts ? data.accounts.includes("linkedIn") : true) && <Nav.Item>
                            <Nav.Link eventKey="linkedinTab" className='sch-linkdinActive'>
                                <div className='sch-social-model-wrap'>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                    }}>
                                        <img src="/assets/images/social-icons/linkdin.svg" alt="" />
                                        <span>LinkedIn</span>
                                    </a>
                                </div>
                            </Nav.Link>
                        </Nav.Item>}
                            {(data.accounts?.length==0)?(data.supportSocialAccount ? data.supportSocialAccount.includes("PINTEREST") : true) && <Nav.Item>
                                <Nav.Link eventKey="pinterestTab" className='sch-pinterestActive'>
                                    <div className={`sch-social-model-wrap ${(((data.mediaUrl != undefined) && (data.mediaUrl?.length == 0)) || (data.mediaUrl == undefined)) && 'pxl-button-disable'}`}>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <img src="/assets/images/social-icons/pinterest.svg" alt="" />

                                            <span>Pinterest</span>
                                        </a>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>:(data.accounts ? data.accounts.includes("pinterest") : true) && <Nav.Item>
                                <Nav.Link eventKey="pinterestTab" className='sch-pinterestActive'>
                                    <div className={`sch-social-model-wrap ${(((data.mediaUrl != undefined) && (data.mediaUrl?.length == 0)) || (data.mediaUrl == undefined)) && 'pxl-button-disable'}`}>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <img src="/assets/images/social-icons/pinterest.svg" alt="" />

                                            <span>Pinterest</span>
                                        </a>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>}
                            {/* {(data.accounts?.length==0)?(data.supportSocialAccount ? data.supportSocialAccount.includes('youtube') : true) && <Nav.Item>
                                <Nav.Link eventKey="youtubeTab" className='sch-pinterestActive'>
                                    <div className={`sch-social-model-wrap ${(((data.mediaUrl != undefined) && (data.mediaUrl?.length == 0)) || (data.mediaUrl == undefined)) && 'pxl-button-disable'}`}>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <img src="/assets/images/newpost/YT.png" alt="" width={'28px'} height={'28px'} />

                                            <span>Youtube</span>
                                        </a>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>}:
                            {(data.accounts ? data.accounts.includes('youtube') : true) && <Nav.Item>
                                <Nav.Link eventKey="youtubeTab" className='sch-pinterestActive'>
                                    <div className={`sch-social-model-wrap ${(((data.mediaUrl != undefined) && (data.mediaUrl?.length == 0)) || (data.mediaUrl == undefined)) && 'pxl-button-disable'}`}>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <img src="/assets/images/newpost/YT.png" alt="" width={'28px'} height={'28px'} />

                                            <span>Youtube</span>
                                        </a>
                                    </div>
                                </Nav.Link>
                            </Nav.Item>} */}
                        </Nav>
                    </div>
                    <Tab.Content>
                        <Tab.Pane eventKey="facebookTab">
                            <div className='sch-model-social-body'>
                            {supportedAccounts.includes("FACEBOOK") ?
                            <>
                                <div className='sch-model-body-head'>
                                    <div className='sch-model-user-name-area'>
                                        <div className='sch-model-user-img'>
                                            <img src='/assets/images/newpost/socialprofile.png' alt="" />
                                        </div>
                                        <div className='sch-model-user-name'>
                                            <h2>{store.name}</h2>
                                            <p>just now</p>
                                        </div>
                                    </div>

                                    <div className='sch-social-option-box'>
                                        {schedulerSvg.app.threeDot}
                                    </div>
                                </div>
                                <div className='sch-social-img-content'>
                                    <p>{data.caption}</p>
                                    {(data.mediaUrl != undefined) ? (data.mediaUrl?.length < 5) ? <div className={`sch-uploadpost-img${data.mediaUrl?.length}`}>
                                        {data.mediaUrl.map((val) => <>
                                        

                                            {
                                                val?.mediaType == "video" ? <video src={assetsUrlPath(val?.original?.path)}
                                                    preload="none"
                                                    width={'100%'}
                                                    autoPlay={true}
                                                    muted={true}
                                                    playsInline disablePictureInPicture
                                                    controls
                                                /> :
                                                    <img key={val?.id} src={(val?.mediaType == "video" )? assetsUrlPath(val?.original?.thumb) : val?.path || assetsUrlPath(val?.original?.path)} alt="" />
                                            }
                                        </>

)}
                                    </div> :
                                        <div className={`sch-uploadpost-img5 has-image-${data.mediaUrl?.length}`}>
                                            <div className='sch-uploadpost5-img2'>
                                                <img src={data.mediaUrl.mediaType == "video" ? assetsUrlPath(data.mediaUrl[0].original.thumb) : assetsUrlPath(data.mediaUrl[0].original.path)} alt="" />
                                                <img src={data.mediaUrl.mediaType == "video" ? assetsUrlPath(data.mediaUrl[1].original.thumb) : assetsUrlPath(data.mediaUrl[1].original.path)} alt="" />
                                            </div>
                                            <div className='sch-uploadpost5-img3'>
                                                <img src={data.mediaUrl.mediaType == "video" ? assetsUrlPath(data.mediaUrl[2].original.thumb) : assetsUrlPath(data.mediaUrl[2].original.path)} alt="" />
                                                <img src={data.mediaUrl.mediaType == "video" ? assetsUrlPath(data.mediaUrl[3].original.thumb) : assetsUrlPath(data.mediaUrl[3].original.path)} alt="" />
                                                <div className='sch-imgOverlay'>
                                                    <img src={data.mediaUrl.mediaType == "video" ? assetsUrlPath(data.mediaUrl[4].original.thumb) : assetsUrlPath(data.mediaUrl[4].original.path)} alt="" />
                                                    <span>+10</span>
                                                </div>
                                            </div>
                                        </div>

                                        // <div className={`sch-uploadpost-imgWrap has-image-${data.mediaUrl?.length}`}>
                                        //     <div className='item1'>
                                        //         <img src={data.mediaUrl[0].thumb ? data.mediaUrl[0].thumb : data.mediaUrl[0].path} alt="" />

                                        //     </div>
                                        //     <div className='sch-uploadpost-imgDiv'>
                                        //         <img src={data.mediaUrl[1].thumb ? data.mediaUrl[1].thumb : data.mediaUrl[1].path} alt="" />
                                        //     </div>
                                        //     <div className='sch-uploadpost-imgDiv'>
                                        //          <img src={data.mediaUrl[2].thumb ? data.mediaUrl[2].thumb : data.mediaUrl[2].path} alt="" />
                                        //     </div>
                                        //     <div className='sch-uploadpost-imgDiv'>
                                        //             <img src={data.mediaUrl[3].thumb ? data.mediaUrl[3].thumb : data.mediaUrl[3].path} alt="" />
                                        //     </div>


                                        //         <div className='sch-imgOverlay'>
                                        //             <img src={data.mediaUrl[4].thumb ? data.mediaUrl[4].thumb : data.mediaUrl[4].path} alt="" />
                                        //             <span>+10</span>
                                        //         </div>
                                        // </div>
                                        : null
                                    }
                                    {/*=== img2 ===*/}
                                    {/* <div className='sch-uploadpost-img2'>
                                        <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                        <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                    </div> */}

                                    {/*=== img3 ===*/}
                                    {/* <div className='sch-uploadpost-img3'>
                                        <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                       <div className='sch-uploadpost-img2'>
                                            <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                            <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                       </div>
                                    </div> */}

                                    {/*=== img4 ===*/}
                                    {/* <div className='sch-uploadpost-img4'>
                                         <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                        <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                        <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                        <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                    </div> */}

                                    {/*=== img5 ===*/}


                                    <ul className='sch-social-review-sec'>
                                        <li>
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault()
                                            }}>
                                                <img src="/assets/images/social-icons/facebook-icon/fb-like.png" alt="" />
                                                <span>Like</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault()
                                            }}>
                                                <img src="/assets/images/social-icons/facebook-icon/fb-comment.png" alt="" />
                                                <span>comment</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault()
                                            }}>
                                                <img src="/assets/images/social-icons/facebook-icon/fb-share.png" alt="" />
                                                <span>share</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                </>
                                :
                                <div className='preview_note'>Post is not supported for Facebook</div>
                                }
                            </div>

                            <div className='sch_post_view_wrap'>

                                {
                                    data?.successData && data?.successData?.facebook && data?.successData?.facebook.map((acc, i) => <div key={i} className='sch_post_page'>
                                        <span>{acc?.page && acc?.page[0]} - </span>
                                        <a target='_blank' href={`https://www.facebook.com/${acc?.post_id ? acc?.post_id : acc.id}`}> View post on Facebook</a>
                                    </div>
                                    )
                                }
                            </div>

                        </Tab.Pane>
                        {/* instagram */}
                        <Tab.Pane eventKey="instagramTab">
                            {(data.mediaUrl  && data.mediaUrl?.length!=0) ? (((data.mediaUrl?.length > 0) && supportedAccounts.includes("INSTAGRAM")) ? <div className='sch-model-social-body schInstagram-social'>
                                <div className='sch-model-body-head'>
                                    <div className='sch-model-user-name-area'>
                                        <div className='sch-model-user-img'>
                                            <img src='/assets/images/newpost/socialprofile.png' alt="" />
                                        </div>
                                        <div className='sch-model-user-name'>
                                            <h2>{store.name}</h2>

                                        </div>
                                    </div>

                                    <div className='sch-social-option-box'>
                                        {schedulerSvg.app.threeDot1}
                                    </div>
                                </div>
                                <div className='sch-social-img-content'>
                                    <div className='sch-InstaSlider'>
                                        {(data.mediaUrl != undefined) && (data.mediaUrl?.length) && <Swiper
                                            navigation={true}
                                            modules={[Navigation]}
                                            className="mySwiper">
                                            {
                                                (data.mediaUrl != undefined) ? (data.mediaUrl?.length) && data.mediaUrl.map((val) => <SwiperSlide>
                                                    <div className='sch-instagram-sliderBox'>
                                                        {
                                                            val.mediaType == "video" ? <video src={assetsUrlPath(val?.original?.path)}
                                                                preload="none"
                                                                width={'100%'}
                                                                autoPlay={true}
                                                                muted={true}
                                                                playsInline disablePictureInPicture
                                                                controls
                                                            /> : <img src={val.mediaType == "video" ? assetsUrlPath(val?.original?.thumb) :val?.path|| assetsUrlPath(val?.original?.path)} alt="" />
                                                        }

                                                    </div>
                                                </SwiperSlide>) : null
                                            }
                                        </Swiper>}
                                    </div>
                                    <div className='sch-linkdin-post-likeArea'>
                                        <ul className='sch-likeEmoji'>
                                            <li>
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault()
                                                }}>
                                                    <img src="/assets/images/social-icons/instagram-icon/like.png" alt="" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault()
                                                }}>
                                                    <img src="/assets/images/social-icons/instagram-icon/comment.png" alt="" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault()
                                                }}>
                                                    <img src="/assets/images/social-icons/instagram-icon/share.png" alt="" />
                                                </a>
                                            </li>

                                        </ul>
                                        <div className='sch-linkdin-comment-count'>
                                            <img src="/assets/images/social-icons/instagram-icon/save.png" alt="" />
                                        </div>
                                    </div>
                                    <div className='sch-instaBottomArea'>
                                        <h6><span>{data.caption}</span> </h6>
                                        <p>just now</p>
                                    </div>
                                </div>
                            </div> : <div className='preview_note'>Post is not supported for Instagram</div>) : <div className='preview_note'>Media is required for Instagram</div>}
                        </Tab.Pane>
                        {/* linkdin */}
                        <Tab.Pane eventKey="linkedinTab">
                            <div className='sch-model-social-body schLinkdin-social'>
                            {supportedAccounts.includes("LINKEDIN") ?
                            <>
                                <div className='sch-model-body-head'>
                                    <div className='sch-model-user-name-area'>
                                        <div className='sch-model-user-img'>
                                            <img src='/assets/images/newpost/socialprofile.png' alt="" />
                                        </div>
                                        <div className='sch-model-user-name'>
                                            <h2>{store.name}</h2>
                                        </div>
                                    </div>

                                    <div className='sch-social-option-box'>
                                        {schedulerSvg.app.threeDot}
                                    </div>
                                </div>
                                <div className='sch-social-img-content'>
                                    <p>{data.caption}</p>
                                    {/*=== img1 ===*/}
                                    {/* <div className='sch-uploadpost-img1'>
                                        <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                    </div> */}

                                    {/*=== img2 ===*/}
                                    {/* <div className='sch-uploadpost-img2'>
                                        <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                        <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                    </div> */}

                                    {/*=== img3 ===*/}
                                    {/* <div className='sch-uploadpost-img3'>
                                        <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                       <div className='sch-uploadpost-img2'>
                                            <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                            <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                       </div>
                                    </div> */}

                                    {/*=== img4 ===*/}
                                    {/* <div className='sch-uploadpost-img4'>
                                         <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                        <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                        <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                        <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                    </div> */}

                                    {/*=== img5 ===*/}

                                    {/* <div className='sch-uploadpost-img5'>
                                        <div className='sch-uploadpost5-img2'>
                                            <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                            <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                        </div>
                                        <div className='sch-uploadpost5-img3'>
                                            <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                            <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                            <div className='sch-imgOverlay'>
                                                <img src="/assets/images/newpost/social-upload-img.jpg" alt="" />
                                                <span>+10</span>
                                            </div>
                                        </div>
                                    </div> */}

                                    {(data.mediaUrl != undefined) ? (data.mediaUrl?.length < 5) ? <div className={`sch-uploadpost-img${data.mediaUrl?.length}`}>
                                        {data.mediaUrl.map((val) => <>
                                            {val.mediaType == "video" ? <video src={assetsUrlPath(val.original.path)}
                                                preload="none"
                                                width={'100%'}
                                                autoPlay={true}
                                                muted={true}
                                                playsInline disablePictureInPicture
                                                controls
                                            /> : <img key={val.id} src={val.mediaType == "video" ? assetsUrlPath(val?.original?.thumb) : val?.path||assetsUrlPath(val?.original?.path)} alt="" />}
                                        </>)}
                                    </div> :
                                        <div className={`sch-uploadpost-img5 has-image-${data.mediaUrl?.length}`}>
                                            <div className='sch-uploadpost5-img2'>
                                                <img src={data.mediaUrl.mediaType == "video" ? assetsUrlPath(data.mediaUrl[0]?.original?.thumb) : data.mediaUrl[0]?.path||assetsUrlPath(data.mediaUrl[0]?.original?.path)} alt="" />
                                                <img src={data.mediaUrl.mediaType == "video" ? assetsUrlPath(data.mediaUrl[1]?.original?.thumb) : assetsUrlPath(data.mediaUrl[1]?.original?.path)} alt="" />
                                            </div>
                                            <div className='sch-uploadpost5-img3'>
                                                <img src={data.mediaUrl.mediaType == "video" ? assetsUrlPath(data.mediaUrl[2]?.original?.thumb) : assetsUrlPath(data.mediaUrl[2]?.original?.path)} alt="" />
                                                <img src={data.mediaUrl.mediaType == "video" ? assetsUrlPath(data.mediaUrl[3]?.original?.thumb) : assetsUrlPath(data.mediaUrl[3]?.original?.path)} alt="" />
                                                <div className='sch-imgOverlay'>
                                                    <img src={data.mediaUrl.mediaType == "video" ? assetsUrlPath(data.mediaUrl[4]?.original?.thumb) : assetsUrlPath(data.mediaUrl[4]?.original?.path)} alt="" />
                                                    <span>+10</span>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                    }
                                    <ul className='sch-social-review-sec'>
                                        <li>
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault()
                                            }}>
                                                <img src="/assets/images/social-icons/linkding-thumb.png" alt="" />
                                                <span>Like</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault()
                                            }}>
                                                <img src="/assets/images/social-icons/linkding-comment.png" alt="" />
                                                <span>comment</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault()
                                            }}>
                                                <img src="/assets/images/social-icons/linkdin-repost.png" alt="" />
                                                <span>repost</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault()
                                            }}>
                                                <img src="/assets/images/social-icons/linkdin-share.png" alt="" />
                                                <span>share</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                </>
                                :
                                <div className='preview_note'>Post is not supported for Linkedin</div>
                                        }
                            </div>
                        </Tab.Pane>
                        {/* pinterest */}
                        <Tab.Pane eventKey="pinterestTab">
                        {(data.mediaUrl  && data.mediaUrl?.length!=0) ? ((data.mediaUrl?.length > 0) && supportedAccounts.includes("PINTEREST")) ? <div className='sch-model-social-body sch-pinterest-social'>
                                <div className='preview_note'>{data?.caption && (data.caption.length>500 ? data.caption.substring(0,500): data.caption)}</div>
                                <div className='sch-pinterest-img'>
                                    {(data.mediaUrl != undefined) ? (data.mediaUrl?.length) && <>

                                        {data.mediaUrl[0]?.mediaType == "video" ? <video src={assetsUrlPath(data.mediaUrl[0]?.original.path)}
                                            preload="none"
                                            width={'100%'}
                                            autoPlay={true}
                                            muted={true}
                                            playsInline disablePictureInPicture
                                            controls
                                        />
                                            :
                                            <img src={data.mediaUrl[0].mediaType == "video" ? assetsUrlPath(data.mediaUrl[0]?.original?.thumb) : data.mediaUrl[0]?.path||assetsUrlPath(data.mediaUrl[0]?.original?.path)} alt="" />
                                        } </> : null}
                                    <div className='sch-pinterest-copy'>
                                        {schedulerSvg.app.copy}
                                        <span>{data.mediaUrl?.length}</span>
                                    </div>
                                </div>
                            </div> : <div className='preview_note'>Post is not supported for Pinterest</div> : <div className='preview_note'>Media is required for Pinterest</div>}
                        </Tab.Pane>
                        {/* youtube */}
                        <Tab.Pane eventKey="youtubeTab">
                            <div className='youtube_tab_wrapper'>
                                {(data.mediaUrl != undefined) ? data.mediaUrl[0]?.mediaType == "video" && <div className='sch-model-social-body'>

                                    <div className='sch-social-img-content'>

                                        <div className={`sch-uploadpost-img${data.mediaUrl?.length}`}>
                                            {data.mediaUrl.map((val) => <div>
                                                {
                                                    val.mediaType == "video" ? <video src={assetsUrlPath(val.original.path)}
                                                        preload="none"
                                                        width={'100%'}
                                                        autoPlay={true}
                                                        muted={true}
                                                        playsInline disablePictureInPicture
                                                        controls
                                                    /> : <div className='preview_note'>Video is required for YouTube</div>
                                                }

                                            </div>)}
                                        </div>


                                    </div>
                                    <div className='youtube_wrap_lower'>
                                        <div className='sch-model-body-head'>
                                            <div className='sch-model-user-name-area'>
                                                <div className='sch-model-user-img'>
                                                    <img src='/assets/images/newpost/socialprofile.png' alt="" />
                                                </div>
                                                <div className='sch-model-user-name'>
                                                    <h2>{store.name}</h2>

                                                </div>

                                            </div>
                                            <p>{data.caption}</p>

                                        </div>

                                    </div>
                                </div> : <div className='preview_note'>Video is required for YouTube</div>}

                                {(data.mediaUrl != undefined) && data.mediaUrl[0]?.mediaType === "video" && <ul className='sch-social-review-sec pxl-youtube-ul'>
                                    <li>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <div className='social_yt_icon'>
                                                <img src="/assets/images/social-icons/facebook-icon/fb-like.png" alt="" />
                                            </div>
                                            <span>Like</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <div className='social_yt_icon'>
                                                <img src="/assets/images/social-icons/facebook-icon/fb-comment.png" alt="" />
                                            </div>
                                            <span>0</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <div className='social_yt_icon'>
                                                <img src="/assets/images/social-icons/facebook-icon/fb-share.png" alt="" />
                                            </div>
                                            <span>share</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <div className='social_yt_icon'>
                                                {schedulerSvg.app.threeDot}
                                            </div>
                                        </a>
                                    </li>
                                </ul>}

                            </div>
                            {(data.mediaUrl != undefined) && data.mediaUrl[0]?.mediaType !== "video" && <div className='preview_note'>Video is required for YouTube</div>}
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>

            </Modal.Body>
        </Modal>

    )
}


export const GenerateAiContent = ({
    shownPopup,
    closePopup,
    addToPost
}) => {
    const [content, setContent] = useState('');
    const [generatedContent, setGeneratedContent] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const firstInputRef = useRef(null);
    const generateContent = () => {
        if (!content) {
            commonToast('error', `Please describe about caption.`);
            return false;
        }
        setIsProcessing(true);
        hitApi({
            url: 'ai-services/generate-content',
            method: "POST",
            data: { prompt: content },
        }, (resp, err = null) => {
            setIsProcessing(false);
            if (resp.status) {
                setGeneratedContent(resp.data.content);
            }
        });
    }

    const addContent = () => {
        addToPost(generatedContent);
        setContent('');
        setGeneratedContent('');
    }
    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [shownPopup]);

    return (
        <>
            <Modal
                show={shownPopup} onHide={() => { closePopup(); setIsProcessing(false) }} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >


                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='pxl-model-header'>
                            <h2 className='modal-title'>AI Assist</h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='pxl_model_body pxl-aiAssistmodel'>
                        <div className="sch-textareaSection">
                            <textarea placeholder="Describe About Your Caption" cols={2}
                                value={content}
                                ref={firstInputRef}
                                onChange={(e) => setContent(e.target.value)}
                            ></textarea>
                        </div>
                        <div className='sch-genrate-code'>
                            <p>The more precise you are, the better the result will be.</p>
                            <button type='button' className="sch-btn" onClick={generateContent}>{isProcessing ? 'Processing...' : 'Generate'}</button>
                        </div>
                        {generatedContent && <div className="sch-textareaSection">
                            <textarea placeholder="" cols={2}
                                value={generatedContent}
                                readOnly
                            ></textarea>
                            <button type='button' className="sch-btn" onClick={addContent}>Add to Post</button>
                        </div>}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}



export const NewDesignPopUp = ({
    shownPopup,
    closePopup,
    addToPost,
    insideFolder = false,
    currentFolder,
    templateId,
    hideporject = false
}) => {
    const navigate = useNavigate();
    const [btnLoading, setBtnLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [selectedFolder, setselectedFolder] = useState({ value: 'uncategorized', label: 'Uncategorized' });
    const [projectFolderList, setprojectFolderList] = useState([]);
    let appPath = useLocation().pathname.split('/').filter(Boolean)[0];
    let currentPath = useLocation().pathname.replace(`/${appPath}/my-designs/`, '');
    let c_Path = useLocation().pathname.replace(`/${appPath}/my-designs/projects/`, '');
    const isIdPath = useLocation().pathname.includes(`/${appPath}/my-designs/projects/`) ? (c_Path !== "recent" ? c_Path !== "uncategorized" : false) : false;
    const firstInputRef = useRef(null);


    useEffect(() => {
        if (c_Path !== 'recent') {
            if (hideporject) {
                setselectedFolder(hideporject[0])
            }
        }
    }, [hideporject])


    let data = {
        title: title
    }
    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }

        if (shownPopup) {
            getFolderList()
        }

    }, [shownPopup]);

    if (selectedFolder.value != 'uncategorized') {
        if (c_Path == "uncategorized") {

        } else {
            data.folderId = isIdPath ? c_Path : selectedFolder ? selectedFolder.value : c_Path
        }
    } else {
        if (isIdPath) {
            data.folderId = c_Path
        }
    }
    let apiUrl = 'image/my-designs/create-new-design';

    if (templateId) {
        apiUrl = 'image/my-designs/use-template'
        data.templateId = templateId
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!title.trim()) {
            commonToast('error', `Title is required.`);
            return false;
        }
        //    if(templateId){
        if ((currentPath != 'projects/uncategorized') && (currentPath != `projects/${c_Path}`)) {
            if (!selectedFolder?.value) {
                commonToast('error', `Project is required.`);
                return false;
            }
        }
        if (c_Path === 'recent') {
            if (!selectedFolder?.value) {
                commonToast('error', `Project is required.`);
                return false;
            }
        }
        //    }

        setBtnLoading(true);


        hitApi({
            url: apiUrl,
            method: "POST",
            data,
        }, (resp, err = null) => {
            setBtnLoading(false);
            if (resp.status) {
                setTitle('')
                setselectedFolder({ value: 'uncategorized', label: 'Uncategorized' });

                closePopup();
                if (resp.data.target) {
                    navigate(`/images/editor/${resp.data.target}`)
                }
                else {
                    navigate(`/images/editor/${resp.data._id}`)
                }
            }
        });
    }

    const getFolderList = () => {
        hitApi({
            url: 'image/folder/get-folders',
            method: "GET",
            data: {
                "limit": "all"
            }
        }, (resp, err = null) => {
            if (resp.status) {
                if (resp.data.records) {
                    const response = resp.data.records;
                    let d1 = []
                    response.forEach(element => {

                        d1.push(
                            { value: element?._id ? element._id : 'uncategorized', label: element.title }
                        )
                    });
                    d1.unshift({ label: 'Select project ', value: '' });

                    setprojectFolderList(d1);
                }
            }
        });
    }


    const noProject = () => {
        return 'No project found '
    }

    return (
        <>
            <Modal
                show={shownPopup} onHide={() => { closePopup(); setBtnLoading(false); setselectedFolder({ value: 'uncategorized', label: 'Uncategorized' }) }} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >


                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='pxl-model-header'>
                            <h2 className='modal-title'>Create New Design</h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form className="" action=""
                        onSubmit={handleSubmit}
                    >
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Title <span className="pxl-star-red">*</span></label>
                            <input type="text" className="form-control"

                                ref={firstInputRef}
                                value={title}
                                placeholder="Enter Design Title"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>

                        <div className='sch-statusCheckbox sch-relativeClass'>

                        </div>
                        {/* <div className="pxl_statusFilter pxl_selectOption form-group">
                            <label>Select project <span className="pxl-star-red">*</span></label>
                            <div className="cun-headreSelect cun-selectProject-select2">
                                <Select
                                    closeMenuOnSelect={true}
                                    value={selectedFolder}
                                    onChange={(option) => {
                                        setselectedFolder(option)
                                    }}
                                    options={projectFolderList} />
                            </div>

                        </div> */}
                        {/* {&&

                        } */}
                        {
                            c_Path == 'recent' && <div className="pxl_statusFilter pxl_selectOption form-group">
                                <label>Select project <span className="pxl-star-red">*</span></label>
                                <div className="cun-headreSelect cun-selectProject-select2">

                                    <Select
                                        noOptionsMessage={noProject}
                                        closeMenuOnSelect={true}
                                        value={selectedFolder}
                                        onChange={(option) => {
                                            setselectedFolder(option)
                                        }}
                                        options={projectFolderList} />
                                </div>

                            </div>
                        }
                        {(currentPath == 'projects/uncategorized') || (currentPath == `projects/${c_Path}`) ? '' : <div className="pxl_statusFilter pxl_selectOption form-group">
                            <label>Select project <span className="pxl-star-red">*</span></label>
                            <div className="cun-headreSelect cun-selectProject-select2">
                                <Select
                                    closeMenuOnSelect={true}
                                    noOptionsMessage={noProject}
                                    value={selectedFolder}
                                    onChange={(option) => {
                                        setselectedFolder(option)
                                    }}
                                    options={projectFolderList} />
                            </div>

                        </div>}

                        <button type="submit" className="pxl_btn" disabled={btnLoading}>
                            {btnLoading ? "Processing..." : 'Continue'}
                        </button>
                    </form>

                </Modal.Body>
            </Modal>
        </>
    )
}


export const DownloadImagePopUp = ({
    shownPopup,
    closePopup,
    width,
    height,
    target,
    path,
    type,
    setData,
    process,
    mTitle
}) => {

    const [format, setFormat] = useState('jpeg');
    const [dpi, setDpi] = useState(96);
    const [size, setFileSize] = useState('original');
    const [menuOpen, setMenuOpen] = useState(false);
    const firstInputRef = useRef(null);
    localStorage.setItem("mTitle", mTitle)
    const options1 = [
        { value: 'jpeg', label: 'jpeg' },
        { value: 'png', label: 'png' },
        { value: 'webp', label: 'webp' },
    ];
    const options2 = [
        { value: 72, label: '72' },
        { value: 96, label: '96' },
        { value: 300, label: '300' },
    ];

    useEffect(() => {
        if (type == 'editor') {
            if (!process) {
                closePopup();
            }
        }
    }, [process])

    let store = useStore(state => state);

    const getImage = () => {

        if (type == 'editor') {
            setData({ format, dpi, size });
            store.updateStore("save", 2);
        } else {
            if (format == 'jpeg' && dpi == 96 && size == 'original') {
                // localStorage.setItem("mTitle",mTitle)
                downloadImage(path,'image',mTitle);
                closePopup();
            } else {
                hitApi({
                    url: 'image/my-designs/download-design',
                    method: "GET",
                    data: {
                        target: target,
                        format: format,
                        dpi: dpi,
                        size: size
                    }
                }, (resp, err = null) => {
                    if (resp.status) {
                        closePopup();
                        // localStorage.setItem("mTitle",mTitle)
                        downloadImage(resp?.data?.path,'image',mTitle);
                    }
                });
            }
        }

    }
    useEffect(() => {
        
        if (firstInputRef.current) {
            firstInputRef.current.focus();

        }
    }, [shownPopup]);



    return (
        <>
            <Modal
                show={shownPopup} onHide={closePopup} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >


                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Download
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={getImage}>
                        <div className="cun-downloadModel-body">
                            <div className="cun-download-format">
                                <div className="cun-download-format-left">
                                    <h2>format</h2>
                                    <div className="cun-download-select">
                                        <Select options={options1}
                                            ref={firstInputRef}
                                            value={options1.filter(option =>
                                                option.value == format)}
                                            onChange={(e) => setFormat(e.value)}
                                        />
                                    </div>
                                </div>
                                <div className="cun-download-format-left">
                                    <h2>DPI</h2>
                                    <div className="cun-download-select">
                                        <Select options={options2}
                                            value={options2.filter(option =>
                                                option.value == dpi)}
                                            onChange={(e) => setDpi(e.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="cun-download-btn-format">
                                <div className='cun-download-btn-box' onClick={() => setMenuOpen(!menuOpen)}>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                    }}>
                                        <div className='pxl-downloadIcon'>
                                            <span>
                                                <img src="/assets/images/download-img.svg" alt="" /></span>Download
                                        </div>
                                    </a>
                                    <div className='cun-download-dropdown-wrap '>
                                        <div className='down-arrow'><img src="/assets/images/whiteDownArrow.svg" alt="" /></div>

                                    </div>
                                </div>
                                <div className={`cun-downloadMneu ${menuOpen ? 'cun-download-dropedown-open' : ''}`}>
                                    <ul>
                                        <li className={size == 'original' ? 'active' : ''} onClick={() => setFileSize('original')}>
                                            <div class="cun-custom-radioBtn">
                                                <input type="radio" id="original" name="position" value="original" checked={size == 'original'} />
                                                <label for="original">Original <span>({width + ' x ' + height})</span></label>
                                            </div>


                                        </li>
                                        <li className={size == 'medium' ? 'active' : ''} onClick={() => setFileSize('medium')}>
                                            <div class="cun-custom-radioBtn">
                                                <input type="radio" id="medium" name="position" value="medium" checked={size == 'medium'} />
                                                <label for="medium">Medium <span>({width / 2 + ' x ' + height / 2})</span></label>
                                            </div>

                                            {/* <span>Medium</span>({width / 2 + ' x ' + height / 2}) */}

                                        </li>
                                        <li className={size == 'small' ? 'active' : ''} onClick={() => setFileSize('small')}>
                                            <div class="cun-custom-radioBtn">
                                                <input type="radio" id="small" name="position" value="small" checked={size == 'small'} />
                                                <label for="small">Small <span>({width / 4 + ' x ' + height / 4})</span></label>
                                            </div>


                                            {/* <span>Small</span>({width / 4 + ' x ' + height / 4}) */}

                                        </li>
                                        <li>
                                            <div className='cun_btn_box'>
                                                <button type='button' className='pxlCommon-btn' disabled={process} onClick={getImage}>{process ? 'Processsing...' : 'Download'}</button>
                                            </div>
                                        </li>
                                    </ul>
                                    {/* <div className='cun_btn_box'>
                                        <button type='button' className='cun_btn' disabled={process} onClick={getImage}>{process ? 'Processsing...' : 'Download'}</button>
                                    </div> */}
                                </div>
                                {/* <ul>
                                    <li>
                                        <a href="#" onClick={(e)=>{
 e.preventDefault()
}}>
                                            <div className="cun-download-btns">
                                                <span className="cun-download-icon">
                                                    <img src="/assets/images/download-img.png" alt="" />
                                                </span>
                                                <span className="cun-download-btnText">
                                                    small
                                                </span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={(e)=>{
 e.preventDefault()
}}>
                                            <div className="cun-download-btns">
                                                <span className="cun-download-icon">
                                                    <img src="/assets/images/download-img.png" alt="" />
                                                </span>
                                                <span className="cun-download-btnText">
                                                    medium
                                                </span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={(e)=>{
 e.preventDefault()
}}>
                                            <div className="cun-download-btns">
                                                <span className="cun-download-icon">
                                                    <img src="/assets/images/download-img.png" alt="" />
                                                </span>
                                                <span className="cun-download-btnText">
                                                    large
                                                </span>
                                            </div>
                                        </a>
                                    </li>
                                </ul> */}
                            </div>
                            <div className="cun-download-para">
                                <p><b>Note</b> : if your original image size is 1000x1000, Medium size output
                                    will be 500x500 and small size output will be 250x250 </p>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export const PreviewImages = ({
    shownPopup,
    closePopup,
    asset,

}) => {

    let thumbObj = {
        image: asset?.files?.original?.path,
        video: asset?.files?.original?.thumb || '/assets/images/newpost/video-newpost.png',
        // audio: '/assets/images/newpost/audio-newpost.png',
    }
    return (
        <>
            <Modal
                show={shownPopup} onHide={closePopup} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >


                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {asset?.title}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>



                    <div className='img_preview_box'>

                        {
                            asset.mediaType == 'video' ?
                                <video
                                    style={{ width: '100%' }}
                                    // preload="none"
                                    src={assetsUrlPath(asset.files.original.path)}
                                    autoPlay={true}
                                    // muted={true}
                                    // playsInline disablePictureInPicture
                                    controls
                                />

                                :

                                <img
                                    src={assetsUrlPath(thumbObj[asset.mediaType])} alt={asset.title}
                                />


                        }






                    </div>



                </Modal.Body>
            </Modal>
        </>
    )
}