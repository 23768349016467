import  { useState,useEffect  } from 'react'
import { Helmet } from 'react-helmet'
import ProjectSettingTab from './ProjectSettingTab';
import ProjectDomainSetting from './ProjectDomainSetting';
import MailSettings from './MailSettings';
import ProjectLandingPage from './ProjectLandingPage';
import InstallScript from './InstallScript';
import WidgetSetting from './WidgetSetting';
import { useNavigate,Link ,useLocation} from 'react-router-dom';


function ProjectSetting({ pageTitle }) {

    const [activeMenu, setActiveMenu] = useState('project');
    const searchParams = new URLSearchParams(window?.location?.search);
    const p_id = searchParams.get('id');
    const projName = searchParams.get('name');
    let appPath = useLocation().pathname.split('/').filter(Boolean)[0];
    let currentPath = useLocation().pathname.replace(`/${appPath}`, '');
    useEffect(()=>{
        if(currentPath.includes('create-project')){

            setActiveMenu('mail')
        }
    },[currentPath])

    const setActiveTabMenu=(item)=>{
        setActiveMenu(item)
    }

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper sch-nav-open">
                <div className="sch-container-fluid project_setting_wrap pxlPixaSupport-projectTabs">
                    <div className="support_tabs_wrapper">
                        
                    <div className='pxlBack-btn'>
                            <Link to={`/pixa-support/projects`} className="pxl-backBtn-round"><span><img src="/assets/images/backArrowBlack.svg" alt="back" /></span>{'Back to Projects '}</Link>
                        </div>
                        
                        <div className="support_tab_inner" >
                            <ul className="menu ">
                                <li className={activeMenu == 'project' ? "active" : ''}>
                                    <a href="#" onClick={() => setActiveMenu('project')} >
                                        Project Settings
                                    </a>
                                </li>
                                {/* <li className={activeMenu == 'domain' ? "active" : ''}>
                                    <a onClick={() => setActiveMenu('domain')} >
                                        Project Domain Settings
                                    </a>
                                </li> */}
                                <li className={activeMenu == 'mail' ? "active" : ''}>
                                    <a href="#"  onClick={() => setActiveMenu('mail')} >
                                        Mail Settings
                                    </a>
                                </li>
                                {/* <li className={activeMenu == 'landingPage' ? "active" : ''}>
                                    <a onClick={() => setActiveMenu('landingPage')} >
                                        Landing Page Content
                                    </a>
                                </li> */}
                                <li className={activeMenu == 'widget' ? "active" : ''}>
                                    <a href="#" onClick={() => setActiveMenu('widget')} >
                                        Widget Appearance
                                    </a>
                                </li>
                                <li className={activeMenu == 'script' ? "active" : ''}>
                                    <a href="#"  onClick={() => setActiveMenu('script')} >
                                        Install Script
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {
                    activeMenu == 'project' ? <ProjectSettingTab setActiveTabMenu ={setActiveTabMenu} p_id={p_id} projName={projName}/> :
                      activeMenu == 'domain' ? <ProjectDomainSetting setActiveTabMenu ={setActiveTabMenu} p_id={p_id}/> :
                      activeMenu == 'mail' ? <MailSettings setActiveTabMenu ={setActiveTabMenu} p_id={p_id}/> :
                      activeMenu == 'landingPage' ? <ProjectLandingPage setActiveTabMenu ={setActiveTabMenu} p_id={p_id}/> :
                      activeMenu == 'widget' ? <WidgetSetting setActiveTabMenu ={setActiveTabMenu} p_id={p_id}/> :
                      activeMenu == 'script' ? <InstallScript setActiveTabMenu ={setActiveTabMenu} p_id={p_id}/> : null
                }
                </div>
      
            </div>
        </>

    )
}

export default ProjectSetting