
import './App.css';
import './index.css';
import Routes from './pages/MainRoutes'
import 'react-loading-skeleton/dist/skeleton.css'
import { useEffect, useState } from 'react';

const ScriptLoader = ({ scriptUrl, onLoad }) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    useEffect(() => {
        if (window.location.pathname.includes("imagePreview")) {
            return
        }
        if (scriptLoaded) return;

        const pixasupportElement = document.getElementById('pixasupport');
        if (!pixasupportElement) {
            console.error('Element with id "pixasupport" not found');
            return;
        }

        const script = document.createElement('script');
        script.src = scriptUrl;
        script.async = true;

        const initializeScripts = () => {
            if (typeof onLoad === 'function') {
                onLoad();
            }
        };

        script.onload = () => {
            setScriptLoaded(true);
            if (document.readyState === 'complete' || document.readyState === 'interactive') {
                initializeScripts();
            } else {
                document.addEventListener('DOMContentLoaded', initializeScripts);
            }
        };

        pixasupportElement.appendChild(script);

        return () => {
            if (pixasupportElement.contains(script)) {
                pixasupportElement.removeChild(script);
            }
            document.removeEventListener('DOMContentLoaded', initializeScripts);
        };
    }, [scriptUrl, onLoad, scriptLoaded, window.location.pathname]);

    return null;
};
function App(props) {

    const handleScriptLoad = () => {

        if (typeof window.pixasupportProject === 'function') {
            const pixasupportElement = document.getElementById('pixasupport');
            if (pixasupportElement) {
                const pixasupportId = pixasupportElement.getAttribute('class') || pixasupportElement.getAttribute('ref');
                if (pixasupportId) {
                    window.pixasupportProject(pixasupportId);
                }
            }
        }
    };

    // const [widgetElement, setWidgetElement] = useState(null);

    // useEffect(() => {
    //     const observer = new MutationObserver(() => {
    //         // const appendsuccess = document.querySelector(".ptw_widget_wrapper");
    //         const appendsuccess = document.querySelector(".ptw_chat_toggle")
    //         if (appendsuccess) {
    //             setWidgetElement(appendsuccess);
    //         }
    //     });

    //     observer.observe(document.body, {
    //         childList: true,
    //         subtree: true,
    //     });

    //     return () => {
    //         observer.disconnect();
    //     };
    // }, []);

    // useEffect(() => {
    //     if (widgetElement) {
    //         widgetElement.id = 'pxl_support_widget'
    //         dragElement(widgetElement);
    //     }
    // }, [widgetElement]);

    // function dragElement(elmnt) {
    //     var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    //     var bodyRect = document.body.getBoundingClientRect();
    //     var elmntRect = elmnt.getBoundingClientRect();

    //     if (document.getElementById(elmnt.id + "header")) {
    //         document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
    //         document.getElementById(elmnt.id + "header").addEventListener('touchstart', dragMouseDown);
    //     } else {
    //         elmnt.onmousedown = dragMouseDown;
    //     }

    //     function dragMouseDown(e) {

    //         e = e || window.event;
    //         e.preventDefault();
    //         pos3 = e.clientX;
    //         pos4 = e.clientY;
    //         document.onmouseup = closeDragElement;
    //         document.onmousemove = elementDrag;
    //         document.addEventListener('touchend', closeDragElement);
    //         document.addEventListener('touchmove', elementDrag);
    //     }

    //     function elementDrag(e) {
    //         e = e || window.event;
    //         e.preventDefault();
    //         pos1 = pos3 - e.clientX;
    //         pos2 = pos4 - e.clientY;
    //         pos3 = e.clientX;
    //         pos4 = e.clientY;

    //         elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    //         elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    //     }

    //     function closeDragElement() {
    //         document.onmouseup = null;
    //         document.onmousemove = null;
    //         document.removeEventListener('touchend', closeDragElement);
    //         document.removeEventListener('touchmove', elementDrag);
    //     }
    // }

    return (
        <div className="App">

            <Routes />
            <div id="pixasupport" className="f31bd9b73cf5d1454ba8743fc809bb4b9b63a005cb7064e42aa5ec63f4c14ebe"></div>
            <ScriptLoader scriptUrl="https://api.pixasupport.com/project_widget.js" onLoad={handleScriptLoad} />

        </div>
    );
}

export default App;
