import appConfig from './../config/appConfig';
const PrivacyPolicy = () => {
    
    return (
        <>
            <div className="pxlnx-privacyPolicy-main-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="pxlnx-privacy-policy-banner">
                                <div className="pxlnx-privacy-policy-logo">
                                <span><img src={appConfig.appLogo} alt="" /></span>

                                </div>
                                <div className="pxlnx-loginBtn">
                                    <a href="https://www.app.pixalab.ai/">Login</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="pxlnx-privacy-policy-inner-wrap">
                                <div className="pxlnx-privacy-section1">
                                    <h3><b>Privacy And Policy</b></h3>
                                    {/* <p className="pink-color">Privacy Policy – app.pixalab.ai</p> */}
                                    <p className="pink-color">(Last Updated: June 10th, 2023)</p>
                                </div>

                                <div className="pxlnx-privacy-section2 ">
                                    <p>app.pixalab.ai is serious about protecting your online privacy. This Privacy Statement
                                        explains our views and practices concerning privacy, and how they may pertain to you as
                                        a user of our website.</p>

                                    <p>“You” or “Your” means you as a participant in or as a user of the app.pixalab.ai website.
                                        “We” or “Our” or “Us” means app.pixalab.ai. “Our site” means app.pixalab.ai.</p>

                                    <p>All information transmitted, printed or otherwise submitted to app.pixalab.ai via this
                                        website shall be deemed to be the property of app.pixalab.ai and app.pixalab.ai shall be free to
                                        use such information for any lawful purpose as detailed herein.</p>

                                    <p>This site contains links to other sites and we are not responsible for the privacy
                                        practices or the content of such sites. We reserve the right to release such information
                                        to law enforcement or other governmental officials as we, in our sole and absolute
                                        discretion, deem necessary to comply with the law.</p>
                                </div>
                                <div className="pxlnx-privacy-section3 top30">
                                    <h2 className="pxlnx-section-heading">Section 1: Collected Information</h2>
                                    <p>We automatically collect and/or track the following:</p>
                                    <p className="pxlnx-positon-p"><span>1.</span> Web page http headers (home server domain names, IP
                                        address, type of client computer, and type of Web browser);</p>
                                    <p className="pxlnx-positon-p"><span>2.</span> Information knowingly provided by you through
                                        on-line forms, registration forms, surveys, and/or other entries, such as email
                                        addresses, personal, financial or demographic information;</p>
                                    <p className="pxlnx-positon-p"><span>3.</span>Information, user specific or aggregate, on what
                                        pages our visitors access; and</p>
                                    <p className="pxlnx-positon-p"><span>4.</span> E-mail addresses of visitors that communicate with
                                        app.pixalab.ai via e-mail.</p>
                                </div>
                                <div className="pxlnx-privacy-section4 top30">
                                    <h2 className="">Section 2: Personal Information Needed by Us and How It Is Used</h2>
                                    <p className="pxlnx-positon-p1"><span>Introduction - </span> We have different parameters through
                                        which we collect visitors' information. It could be information that our visitors
                                        provide by directly entering our site or through various other websites that our website
                                        is networked with. Information of visitors can be collected through our various domains
                                        of services available on the internet.</p>
                                    <p className="pxlnx-positon-p1"><span>Registration - </span> The various personal details that we
                                        ask from our visitors while they sign up to our site includes name, address, telephone
                                        number, billing information (for eg. Credit Card Number), and the model or type of
                                        personal computer being used to access the Services. The Information collected from
                                        different visitors during the registration is used for creating and managing a separate
                                        account for each member (for eg. Billing purposes or Email Marketing).</p>
                                    
                                  
                                    <p className="pxlnx-positon-p1"><span>Responses to Email Inquiries- </span> When Visitors or
                                        Members send email inquiries to our company, the return email address is used to answer
                                        the email inquiry.</p>
                                 
                                    <p className="pxlnx-positon-p1"><span>Special Cases- </span> It is our company's policy to use or
                                        share the personal information of our Visitors or Members in the ways described herein
                                        without additional notice or means to opt-out or otherwise prohibit such unrelated uses.
                                    </p>
                                    <p className="pxlnx-positon-p1">Also, we may disclose personal information about our Visitors,
                                        Members, or information regarding their use of the Services or Web sites accessible
                                        through our Services, for any reason, if, in our sole discretion, we believe is
                                        reasonable for sharing, including credit agencies, collection agencies, merchant
                                        database agencies, law enforcement, or abide by laws.</p>
                                    <p className="pxlnx-positon-p1"><span>You Must Be Eighteen (18) Years or Older to Access This Web
                                        Site. If You Are Under Eighteen Years of Age, You Are Not Permitted to Access This
                                        Web Site for Any Reason. Due to the Age Restrictions for Use of This Web Site, No
                                        Information Obtained by This Web Site, Is Suitable For Children.</span></p>
                                    <p className="pxlnx-positon-p1"><span>Public Forums- </span>Please remember that any information
                                        you may disclose in any Member Directory, or other public areas of our Web sites or the
                                        Internet, becomes public information. You should exercise caution when deciding to
                                        disclose personal information in these public areas.</p>
                                    <p className="pxlnx-positon-p1">Be cautioned that any information you may provide to any Member
                                        Directory, or other public areas of our Web sites or the Internet, becomes public
                                        information. You should be extremely careful while sharing your personal details to such
                                        websites that disclose information publicly.</p>
                                    <p className="pxlnx-positon-p1"><span>Our Company's Commitment to Data Security- </span>Services
                                        and Web sites we sponsor possess security measures to protect the loss, misuse, and
                                        alteration of the information under our control. While we make every effort to ensure
                                        the integrity and security of our network and systems, we cannot guarantee complete
                                        protection from third-party "hackers" that may obtain the information illegally.</p>
                                    <p className="pxlnx-positon-p1"><span>Where to ask Questions About Our Privacy Policy- </span>If you
                                        have any questions about this Privacy Policy or the processes and practices described
                                        herein, you may reach out to us through our contact information provided on this Web
                                        site.</p>
                                    <p className="pxlnx-positon-p1"><span>Revisions to This Policy- </span>Our company reserves the right to revise,
                                        amend, or modify this policy, our Terms Of Service agreement, and our other policies and
                                        agreements at any time and in any manner, by updating this posting. After the changes or
                                        modifications are implemented to our privacy policy and the terms of service agreement,
                                        we will constitute your acknowledgement and acceptance of these changes as you continue
                                        using our site.</p>
                                </div>
                                <div className="pxlnx-privacy-section4 top30">
                                   
                                        <h2 className="">Section 3: Pixalab complies with the <a href="https://developers.google.com/terms/api-services-user-data-policy"> Google API Services User Data Policy</a>, including the Limited Use requirements.</h2>
                                    

                                    <p className="pxlnx-positon-p1">
                                        We have different parameters through which we collect visitors' information. It could be information that our visitors provide by directly entering our site or through various other websites that our website is networked with. Information of visitors can be collected through our various domains of services available on the internet.
                                    </p>
                                </div>

                                <div className="pxlnx-privacy-section6 top30">
                                    <h2 className="">Section 4: Your Opt-Out Rights</h2>
                                    <p>You may opt-out of receiving communications from us and/or our partners by not submitting
                                        your information. We also allow you to remove your information from our marketing lists.
                                        If you remove your information from our marketing lists it will no longer be used by us
                                        to send promotional correspondence to you. You can remove your information from our
                                        marketing lists by clicking on the “Unsubscribe” link at the bottom of every email we
                                        send you, or by sending your request, in writing, via email to: support@pixalab.ai</p>
                                </div>
                                <div className="pxlnx-privacy-section8 top30">
                                    <h2 className="">Section 5: Our Right to Contact You</h2>
                                    <p>We reserve the right to contact you regarding your account status and changes to
                                        subscriber agreements, privacy policy, or any other policies or agreements relevant to
                                        you.</p>
                                    <p></p>
                                </div>
                                <div className="pxlnx-privacy-section9 top30">
                                    <h2 className="">Section 6: Our Right to Change</h2>
                                    <p>We reserve the right to change this policy at any time by notifying you of the existence
                                        and location of the new or revised privacy policy or by posting the changes online at
                                        our site.</p>
                                    <p></p>
                                    <p></p>
                                </div>
                                <div className="pxlnx-privacy-section10 top30">
                                    <h2>Section 7: Our Contact Information</h2>
                                    <p>If you have any questions about this privacy statement, the practices of this site, or
                                        your dealings with this website, you can contact us via email sent to:
                                        support@pixalab.ai.</p>
                                </div>
                                <div className="pxlnx-privacy-section11 top30">
                                    <h2>Section 8: Anti Spam Policy. Can-Spam Act Compliance</h2>
                                    <p>app.pixalab.ai is dedicated to ensuring compliance with the ‘Can-Spam Act’, which took effect
                                        January 1, 2004. You may receive email from app.pixalab.ai in the following circumstances:
                                    </p>
                                    <p className="pxlnx-positon-p"><span>1.</span> Acknowledging your application has been received
                                        and requesting additional action</p>
                                    <p className="pxlnx-positon-p"><span>2.</span> Requests for additional information to support your
                                        current application</p>
                                    <p className="pxlnx-positon-p"><span>3.</span> Response to your inquiries regarding the status of
                                        your transactional requests</p>
                                    <p className="pxlnx-positon-p"><span>4.</span> Thanking you for your valued business</p>
                                    <p className="pxlnx-positon-p"><span>5.</span> Advertisements for our products, services, changes
                                        in services, new product availability</p>
                                    <p className="pxlnx-positon-p"><span>6.</span> Advertisements for third party products and
                                        services where we have determined that such product or service may be of interest to our
                                        customers</p>
                                    <p className="pxlnx-positon-p">To ensure compliance with the Can-Spam Act, app.pixalab.ai has
                                        implemented the following guidelines for email delivery:</p>
                                    <p className="pxlnx-positon-p"><span>1.</span> All emails sent to you by app.pixalab.ai will clearly
                                        identify app.pixalab.ai as the sender. FROM / SENDER: support@pixalab.ai<br />
                                    </p>
                                    <p><span><b>(NOTE: The actual sender will alter from department to department, but all from
                                        addresses will end with @app.pixalab.ai).</b></span></p>
                                    <p className="pxlnx-positon-p"><span>2.</span> The Subject Line of any email you receive will
                                        always accurately describe the subject matter of the email.</p>
                                    <p className="pxlnx-positon-p"><span>3.</span> Any email from app.pixalab.ai will include the ability
                                        to unsubscribe from future email messages.</p>
                                    <p className="pxlnx-positon-p"><span>4.</span> Unsubscribing will ensure the customer is removed
                                        from ALL lists at app.pixalab.ai; this excludes customer service emails regarding the
                                        processing and status of a current product.</p>
                                    <p className="pxlnx-positon-p"><span>5.</span> All emails sent to you directly by app.pixalab.ai
                                        include valid postal address information in the footer.</p>
                                    <p className="pxlnx-positon-p"><span>6.</span> All third party marketing partners are required to
                                        comply with the Can-Spam Act.</p>
                                    <p className="pxlnx-positon-p"><span>7.</span> Emails sent to you directly by app.pixalab.ai will
                                        include an email address and physical address where you can send suggestions, complaints
                                        or other correspondence.</p>
                                </div>

                                <div className="pxlnx-privacy-section12 top30">
                                    <h2>Section 9: Information for ISPs</h2>
                                    {/* <p>Web site: app.pixalab.ai</p> */}
                                    <p>Support: <a href=" https://support.pixalab.ai/auth/registration" target="_blank">
                                        https://support.pixalab.ai/auth/registration</a></p>
                                </div>

                                <div className="pxlnx-privacy-section13">
                                    <p>app.pixalab.ai understands consumers’ concerns over the use of their personal information. We
                                        hope this information will relieve any concerns your company may have regarding our
                                        email policies. app.pixalab.ai and its affiliated sites use only an “opt-in or opt out”
                                        method of obtaining customer information, and it is not our policy or desire to send
                                        unsolicited email. We obtain email addresses and personal information from third parties
                                        that follow the same set of policies. app.pixalab.ai email messages sent always include
                                        information about the origin of the emails and instructions on how recipients can
                                        unsubscribe from receiving future email messages.</p>
                                    <p>From time to time, app.pixalab.ai will enter into an arrangement with a third party website
                                        to allow individuals to opt into our marketing program on those third party websites. In
                                        each case the third party websites have represented and warranted to us, among other
                                        things,</p>
                                    <p className="pxlnx-positon-p"><span>1.</span> that the data was collected voluntarily from
                                        individuals on website registrations and co-registrations,</p>
                                    <p className="pxlnx-positon-p"><span>2.</span> that the sellers have a right, under any applicable
                                        privacy statements, to transfer the data to us, and</p>
                                    <p className="pxlnx-positon-p"><span>3.</span> that app.pixalab.ai has the right to send marketing
                                        offers to the individuals.</p>
                                    <p>We hope this information satisfies any questions or concerns you may have regarding the
                                        email practices of app.pixalab.ai.</p>
                                </div>
                                <div className="pxlnx-privacy-section13 top30">
                                <p className=""><span style={{fontWeight:500, color : "black",fontSize:"16px"}}>User data received from Google/Facebook OR any third party app will not be used for any purposes beyond providing or improving the functionality of Pixalab services.</span></p>
                                </div>
                                <div className="pxlnx-privacy-section12 top30">
                                    <h2>Section 10: Data Deletion
                                    </h2>
                                </div>

                                <div className="pxlnx-privacy-section13">
                                    <p>We provide an option in Account Settings for users to delete their data. Users can select 'Delete Account' if they wish to permanently delete their account and all associated data. Alternatively, users may contact our support team to request account deletion. Upon receiving the request, we will delete the account and all associated data within 48 hours.</p>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PrivacyPolicy;