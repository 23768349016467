import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
// import ProjectDomainSetting from '../ProjectDomainSetting';
// import AgencySettings from './AgencySettings';
import TicketStatusSettings from './TicketStatusSettings';
import TicketTypeSettings from './TicketTypeSettings';
import TicketPrioritySettings from './TicketPrioritySettings';



function PixaSupportSettings({ pageTitle }) {

    const [activeMenu, setActiveMenu] = useState('status');
    const searchParams = new URLSearchParams(window?.location?.search);
    const p_id = searchParams.get('id');
    const projName = searchParams.get('name');
  

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper sch-nav-open pxl-pixaSupport-settingTabs ">
                <div className="sch-container-fluid project_setting_wrap">
                    <div className="support_tabs_wrapper">
                        <div className="support_tab_inner" >
                            <ul className="menu pxl-responsiveTabs">
                        
                                {/* <li className={activeMenu == 'domain' ? "active" : ''}>
                                    <a href="#" onClick={() => setActiveMenu('domain')} >
                                         Domain Settings
                                    </a>
                                </li>
                                <li className={activeMenu == 'agency' ? "active" : ''}>
                                    <a onClick={() => setActiveMenu('agency')} >
                                        Agency Settings
                                    </a>
                                </li> */}
                                <li className={activeMenu == 'status' ? "active" : ''}>
                                    <a href="#" onClick={() => setActiveMenu('status')} >
                                       Ticket Status
                                    </a>
                                </li>
                                <li className={activeMenu == 'type' ? "active" : ''}>
                                    <a href="#" onClick={() => setActiveMenu('type')} >
                                    Ticket Type
                                    </a>
                                </li>
                                <li className={activeMenu == 'priority' ? "active" : ''}>
                                    <a href="#" onClick={() => setActiveMenu('priority')} >
                                    Ticket Priority
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {
                  
                    //   activeMenu == 'domain' ? <ProjectDomainSetting p_id={p_id}/> :
                    //   activeMenu == 'agency' ? <AgencySettings p_id={p_id}/> :
                      activeMenu == 'status' ? <TicketStatusSettings p_id={p_id}/> :
                      activeMenu == 'type' ? <TicketTypeSettings p_id={p_id}/> :
                      activeMenu == 'priority' ? <TicketPrioritySettings p_id={p_id}/> : null

                }
                </div>
      
            </div>
        </>

    )
}

export default PixaSupportSettings