import React, { useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { Modal } from 'react-bootstrap';
import { useEffect } from "react";
import { getNameInitials, hitApi, setMyState, skeletonLoader } from "../../../components/commonAction";
import AddFbBtn from "../../../components/common/SocialAccount/Facebook"
import schedulerSvg from "./schedulerSvg";
import LinkedinBtn from "../../../components/common/SocialAccount/linkedin";
import PinterestBtn from "../../../components/common/SocialAccount/pinterest";
import GoogleBtn from "../../../components/common/SocialAccount/Google";
import Tiktok from "../../../components/common/SocialAccount/Tiktok";
import svg from "../../../components/svg";

const SocialAccount = ({selectedAccountsSettings, supportedAccounts, selectedAccounts, postOn, handleCheckbox,handleCheckbox1, myLibraryToggle, toggleHandle }) => {

    const [ss, setss] = useState(false);
    console.log({selectedAccountsSettings},9090)
    const [accountToggle, setaccountToggle] = useState(false);
    const [loadaccounts, setloadaccounts]=useState(false)
    const [state, setState] = useState({
        isLoading: false,
        activeSubAccIndex: 0,
        accountsList: [],
        facebooklist: [],
        instalist: [],
        linkedinlist: [],
        pinterestlist: [],
        acoountsListObj: {},
    });
    const [model ,setmodel]=useState({
        status : false,
        type : "",
        accountId : ""
    })
    //hide youtube
    const socialMediaTypes = [
        { type: 'facebook', icon: '/assets/images/newpost/facebook.svg' },
        { type: 'instagram', icon: '/assets/images/newpost/instagram.svg' },
        { type: 'linkedin', icon: '/assets/images/newpost/linkedin.svg' },
        { type: 'pinterest', icon: '/assets/images/newpost/pinterest.svg' },
        { type: 'youtube', icon: '/assets/images/newpost/YT.png' },
        { type: 'tiktok', icon: '/assets/images/newpost/tiktok.svg' },

    ];

    useEffect(() => {
        if (myLibraryToggle) {
            setaccountToggle(false);
        }
    }, [myLibraryToggle])

    let filterAccounts = () => {
        setMyState(setState, { isLoading: true });
        hitApi({
            url: 'social-account/get-accounts',
            method: "GET",
            data: {
                limit: 'all',
                page: 1,
            }
        }, (resp, err = null) => {
            setMyState(setState, { isLoading: false });
            if (!err) {
                const organizedData = {};

                resp.data.records.forEach((item) => {
                    const { source } = item;
                    if (!organizedData[source]) {
                        organizedData[source] = [];
                    }
                    organizedData[source].push(item);
                });

                setMyState(setState, {
                    acoountsListObj: organizedData,
                    accountsList: resp.data.records,
                    facebooklist: resp.data.records.filter(d => d.source == 'facebook'),
                    instalist: resp.data.records.filter(d => d.source == 'instagram'),
                    pinterestlist: resp.data.records.filter(d => d.source == 'pinterest'),
                    linkedinlist: resp.data.records.filter(d => d.source == 'linkedin'),

                });
            }
        });
    }
    const handleuncheck=()=>{
        if(model.save)
        {
            setmodel({
                status : false,
                type : "",
                accountId : ""
            })
            return
        }
        const updatedlist = { ...selectedAccounts };
        const sourceUpdate = postOn.filter(source =>  source.toLowerCase() != model.type.toLowerCase());
        if(updatedlist[model.accountId])
        {
            delete updatedlist[model.accountId]
        }

        handleCheckbox(sourceUpdate, updatedlist);
        setmodel({
            status : false,
            type : "",
            accountId : ""
        })
        
        
    }



    useEffect(() => {
        filterAccounts();
    }, [loadaccounts]);


    useEffect(() => {
        if(supportedAccounts)
        {
            const updatedlist = { ...selectedAccounts };
            const sourceUpdate = postOn.filter(source => supportedAccounts.some(acc => acc.toLowerCase() === source.toLowerCase()));
    
            state.accountsList.forEach(account => {
                if (updatedlist.hasOwnProperty(account._id)) {
                    if (!supportedAccounts.some(acc => acc.toLowerCase() === account.source.toLowerCase())) {
                        delete updatedlist[account._id];
                    }
                }
            });
    
            handleCheckbox(sourceUpdate, updatedlist);
        }

    }, [supportedAccounts])


    const handleCheckboxValue = (dbId, accountId, source) => {
        const updatedlist = { ...selectedAccounts };
        const sourceUpdate = [...postOn];
        console.log("dbId, accountId, source",dbId, accountId, source,{updatedlist})
        if ( !supportedAccounts || !supportedAccounts.length || supportedAccounts.some(acc => acc.toLowerCase() == source)) {
            
            if (updatedlist.hasOwnProperty(dbId)) {

                if (updatedlist[dbId].includes(accountId)) {
                    updatedlist[dbId] = updatedlist[dbId].filter(id => id !== accountId);
                    if (updatedlist[dbId].length == 0) {
                        delete updatedlist[dbId];
                        if (sourceUpdate.includes(source)) {
                            const index = sourceUpdate.indexOf(source);
                            if (index > -1) {
                                sourceUpdate.splice(index, 1);
                            }
                        }
                    }
                } else {
                    if (accountId) {
                        updatedlist[dbId] = [...updatedlist[dbId], accountId];
                    } else {
                        delete updatedlist[dbId];
                        if (sourceUpdate.includes(source)) {
                            const index = sourceUpdate.indexOf(source);
                            if (index > -1) {
                                sourceUpdate.splice(index, 1);
                            }
                        }
                    }

                }
            } else {
                if (accountId) {
                    updatedlist[dbId] = [accountId];
                } else {
                    updatedlist[dbId] = [];
                }
            }


            if (updatedlist.hasOwnProperty(dbId)) {
                if (!(sourceUpdate.includes(source))) {
                    sourceUpdate.push(source);
                }
            }
            console.log("sourceUpdate, updatedlist",sourceUpdate, updatedlist)
            handleCheckbox(sourceUpdate, updatedlist);
            // if (updatedlist.hasOwnProperty(dbId)) {
            //     handleCheckbox(source, updatedlist);
            // } else {
            //     handleCheckbox('', updatedlist);
            // }
        }

    };



    let SingleAccordianComponent = (socialMedia, acc) => {
        console.log("socialMedia, acc",socialMedia, acc)
        return(
        <div className="sch-accodian-user" key={acc._id}>
            <div className="sch-custom-check-box">
            {socialMedia.type=="tiktok" && selectedAccounts !== undefined && Object.keys(selectedAccounts).includes(acc._id) && 
               <span className="ticktok-settings" onClick={()=>{
                setmodel({
                    status : true, 
                    accountId : acc._id,
                    type : "tiktok",
                    save : true,
                })
            }}>
                <img src="/assets/images/settings-ticktok.svg" />
            </span>
                }

                

                <div className="form-group">
                    <input
                        type="checkbox"
                        id={acc._id}
                        checked={selectedAccounts !== undefined && Object.keys(selectedAccounts).includes(acc._id)}
                        onChange={(e) => { 
                            handleCheckboxValue(acc._id, null, acc?.source)
                            if(selectedAccounts !== undefined && Object.keys(selectedAccounts).includes(acc._id)){
                                handleCheckbox1("",acc._id)
                            }else{
                                setmodel({
                                    status : true, 
                                    accountId : acc._id,
                                    type : "tiktok"
                                })
                            }
                             
                        }}
                    />
                    <label htmlFor={acc._id} className="cun-checkboxLabel">

                        <div className="sch-user-info">
                            <div className="sch-user-img">
                                <img src={acc?.data?.profile_image || acc?.data?.picture || `/assets/images/default-img/${socialMedia.type}-default-img.png`} alt="" />
                            </div>
                            <div className="sch-user-name">
                                <h2>{acc.data?.name ? acc?.data?.name : 'account name'}</h2>
                                <p>{acc.type ? acc.type : 'Profile'}</p>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    )};

    return (
        <>
            <div className={`sch-post-left ${accountToggle ? 'classAdd' : 'classRemove'}`}>
                <div className={`sch-postInnerSec `}>
                    <div className="sch-socialAccount-toggle">
                        <a href="#" className={`sch-accountSocial-arrow`} onClick={() => { setaccountToggle(!accountToggle); myLibraryToggle && toggleHandle(!accountToggle) }}>
                            {schedulerSvg.app.Arrow}
                        </a>
                    </div>
                    <div className="sch-post-left-count">
                        <div className="sch-postAccount-heading">
                            <h2>Social Accounts ({state.accountsList?.length > 0 ? state.accountsList?.length : '0'})</h2>
                        </div>
                        <div className="pxlReload-information-svg">
                            <div className="pxl-informationSec sch-tooltipWrap">
                                {/* <a href="#"><img src="/assets/images/information.svg" alt="" /></a> */}
                                <a className={state.isLoading ? " moveIcon" : ""} href="#" onClick={() => filterAccounts()}>
                                    <img src="/assets/images/roloadSvg.svg" alt="" />
                                </a>
                                <div className="sch-tooltipShow">
                                    <p>Click on the Reload button to fetch your connected accounts here.</p>
                                </div>
                            </div>
                            {/* <div className={"sch-tooltipWrap pxlRoted-svg"}>
                                <a className={state.isLoading ? " moveIcon" : ""} href="#" onClick={() => filterAccounts()}>
                                    <img src="/assets/images/roloadSvg.svg" alt="" />
                                </a>
                                <p onClick={() => filterAccounts()}><img src="/assets/images/roloadSvg.svg" alt=""/></p>
                            </div> */}

                        </div>
                    </div>


                    <div className={`sch-account-accordian`}>

                        <Accordion defaultActiveKey="0" flush>
                            {socialMediaTypes.map((socialMedia, index) => 
                            {
                                console.log({socialMedia})
                            return(
                                <Accordion.Item key={index} eventKey={index.toString()} className="sch-arrow-first">
                                    <Accordion.Header className={`pxl-accodian-color${index + 1}`}>
                                        <div className="pxl-accordian-header pxl-accordian-header1">
                                            <h2 className={`sch-${socialMedia.type}-color`}>
                                                <span><img src={socialMedia.icon} alt="" /></span>
                                                {socialMedia.type}
                                            </h2>
                                        </div>
                                    </Accordion.Header>
                                    {/* {supportedAccounts.forEach((account) => {console.log(account.toLowerCase(),"opop", socialMedia.type.toLowerCase())})}
                                   {!supportedAccounts.length ||  supportedAccounts.includes(account => account.toLowerCase() == socialMedia.type.toLowerCase())? */}
                                    <Accordion.Body className="accodianBodyBottomSpace">
                                        <div className="sch-accordian-body pxl-accordianInner-height">
                                            {

                                                !state.isLoading ? (Object.keys(state.acoountsListObj)).includes(socialMedia.type) ? Object.keys(state.acoountsListObj).map((account, i) => {
                                                    let accountArray = state.acoountsListObj[account];
                                                    console.log({supportedAccounts})
                                                    if ((account === socialMedia.type) && ['instagram', 'linkedin', "youtube","tiktok"].includes(account)) {
                                                        return <div className={`sch-accodian-body1 ${(!supportedAccounts || !supportedAccounts.length || supportedAccounts.some(acc => acc.toLowerCase() == socialMedia.type)) ? '' : 'pxl-accordian-disable-socialIcons'}`} key={i}>

                                                            {accountArray.map((acc) => SingleAccordianComponent(socialMedia, acc)
                                                            )}
                                                        </div>
                                                    } else if ((account === socialMedia.type) && ['facebook', 'pinterest'].includes(account)) {

                                                        return (
                                                            <Accordion defaultActiveKey={state.activeSubAccIndex} flush className={"pxl-accordianInner-height"}>
                                                                {
                                                                    accountArray.map((data) => {
                                                                        let isSingle = account == 'pinterest' ? data.data.boards : data.data.pages;

                                                                        if (!isSingle) {
                                                                            return (
                                                                                <div className={`sch-accodian-body1  ${(!supportedAccounts || !supportedAccounts.length || supportedAccounts.some(acc => acc.toLowerCase() == socialMedia.type)) ? '' : 'pxl-accordian-disable-socialIcons'}`}>{SingleAccordianComponent(socialMedia, data)}
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return (

                                                                                <Accordion.Item eventKey={data.accountId} className="sch-accordion-itemInner" onSelect={(event) => setMyState(setState, { activeSubAccIndex: event })} key={data.accountId}>
                                                                                    <Accordion.Header>


                                                                                        <div className="sch-accodian-user">
                                                                                            <div className="sch-user-info">
                                                                                                <div className="sch-user-img">
                                                                                                    {data?.data?.profile_image ?
                                                                                                        <img src={data?.data?.profile_image} alt="" />
                                                                                                        : <img src={`/assets/images/default-img/${socialMedia.type}-default-img.png`} alt="" />
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="sch-user-name">
                                                                                                    <h2>{data.data.name}</h2>
                                                                                                    <p></p>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </Accordion.Header>
                                                                                    <Accordion.Body >
                                                                                        <div className={`sch-accodian-body1  ${(!supportedAccounts || !supportedAccounts.length || supportedAccounts.some(acc => acc.toLowerCase() == socialMedia.type)) ? '' : 'pxl-accordian-disable-socialIcons'}`}>
                                                                                            {

                                                                                                Object.keys(account == 'pinterest' ? data.data.boards : data.data.pages).map((acc) => {
                                                                                                    let obj = account == 'pinterest' ? { name: data?.data?.boards[acc] } : data.data.pages[acc];
                                                                                                    return (
                                                                                                        <div className="sch-accodian-user" key={acc}>

                                                                                                            <div className="sch-custom-check-box sch-checkbox-post">
                                                                                                                <form>
                                                                                                                    <div className="form-group">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            id={acc}
                                                                                                                            checked={selectedAccounts !== undefined && Object.keys(selectedAccounts).includes(data._id) && selectedAccounts[data._id].includes(acc)}
                                                                                                                            onChange={() => handleCheckboxValue(data._id, acc, data.source)}
                                                                                                                        />
                                                                                                                        <label htmlFor={acc} className="cun-checkboxLabel">
                                                                                                                            <div className="sch-user-info">
                                                                                                                                <div className="sch-user-img">
                                                                                                                                    {
                                                                                                                                        obj.profile ?
                                                                                                                                            <img src={obj.profile} alt="" />
                                                                                                                                            :
                                                                                                                                            <span className="social_user_img_icon">
                                                                                                                                                {getNameInitials(obj.name)}
                                                                                                                                            </span>

                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                                <div className="sch-user-name">
                                                                                                                                    <h2>{obj.name}</h2>
                                                                                                                                    <p>{account == 'pinterest' ? 'Board' : 'Page'}</p>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                </form>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                            )
                                                                        }
                                                                    }


                                                                    )
                                                                }

                                                            </Accordion>
                                                        )
                                                    }
                                                })
                                                    :
                                                    // <div className="sch-accodian-body1">
                                                    <div className="sch-no-account-found">No account to select </div>
                                                    // </div>
                                                    :
                                                    skeletonLoader('list', 2)
                                            }

                                        </div>
                                        <div className="sch-add-account">
                                            <div className="sch-accodian-user">
                                                {socialMedia.type == 'facebook' ?
                                                    <AddFbBtn title="Add New Account"
                                                        source="facebook"
                                                        currentList={state.facebooklist}
                                                        onUpdate={data => {
                                                            setState((prevstate) => {
                                                                return {
                                                                    ...prevstate,
                                                                    facebooklist: data,
                                                                    acoountsListObj: {
                                                                        ...prevstate.acoountsListObj,
                                                                        ['facebook']: data
                                                                    }
                                                                }
                                                            })
                                                            // setMyState(setState, {
                                                            //     facebooklist: data,
                                                            //     acoountsListObj : {
                                                            //         ...state.acoountsListObj,
                                                            //         'facebook' : data
                                                            //     }
                                                            // });
                                                        }}
                                                        parent={'socialAccComponent'}
                                                    />
                                                    :
                                                    socialMedia.type == 'instagram' ?
                                                        <AddFbBtn title="Add New Account" source="instagram"
                                                            currentList={state.instalist}
                                                            onUpdate={data => {
                                                                setState((prevstate) => {
                                                                    return {
                                                                        ...prevstate,
                                                                        instalist: data,
                                                                        acoountsListObj: {
                                                                            ...prevstate.acoountsListObj,
                                                                            ['instagram']: data
                                                                        }
                                                                    }
                                                                })
                                                            }}
                                                            parent={'socialAccComponent'} />
                                                        :

                                                        socialMedia.type == 'linkedin' ?
                                                            <LinkedinBtn title="Add New Account" parent={'socialAccComponent'} />
                                                            :
                                                            socialMedia.type == 'pinterest' ?
                                                                <PinterestBtn title="Add New Account" parent={'socialAccComponent'} />
                                                                : 
                                                                socialMedia.type == 'youtube' ?
                                                                <GoogleBtn title="Add New Account" load={setloadaccounts} parent={'socialAccComponent'} />
                                                                :
                                                                <>
                                                                {console.log("selectedAccountsSettings",selectedAccountsSettings)}
                                                                <Tiktok selectedAccountsSettings={selectedAccountsSettings} title="Add New Account" load={setloadaccounts} parent={'socialAccComponent'} settings={model} setmodel={setmodel}  handleCheckbox1={handleCheckbox1} handleCheckbox={handleuncheck}/>
                                                                </>
                                                
                                                }
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                    {/* : ''} */}
                                </Accordion.Item>
                            )
                             }
                            )}
                        </Accordion>
                    </div>
                </div>
            </div>

        </>

    )
}
export default SocialAccount;