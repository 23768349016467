import React, { useState, useEffect, useRef } from 'react';
import { hitApi, setMyState, assetsUrlPath, teamMemberRestrictionMess, commonToast } from "../../commonAction";
import { Modal } from 'react-bootstrap';
import schedulerSvg from '../../../pages/scheduler/components/schedulerSvg';
import { useStore } from '../../../zustand/store';
import svg from '../../svg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let FacebookBtn = ({ title = 'Add New Account', source,index, currentList, accountsList, parent = "social", onUpdate = null, allaccountlistupdate = null, containsSchedularWrite = true }) => {
	let store = useStore(state => state);
	const [tok,settok]=useState("")
	// useEffect(() => {
	// 	// const initFacebookSDK = () => {
	// 	//   window.FB.init({
	// 	// 	appId: process.env.REACT_APP_FACEBOOK_APP_ID,
	// 	// 	cookie: true,
	// 	// 	xfbml: true,
	// 	// 	version: "v17.0",
	// 	//   });
	// 	//   window.FB.getLoginStatus(function (response) {
	// 	// 	console.log('FB Login Status:', response);
	// 	//   });
	// 	// };

	// 	// window.fbAsyncInit = initFacebookSDK;

	// 	// const loadFacebookSDK = () => {
	// 	//   const scriptId = 'facebook-jssdk';
	// 	//   if (document.getElementById(scriptId)) {
	// 	// 	initFacebookSDK();
	// 	// 	return;
	// 	//   }

	// 	//   const js = document.createElement('script');
	// 	//   js.id = scriptId;
	// 	//   js.src = "https://connect.facebook.net/en_US/sdk.js";
	// 	//   js.onload = () => {

	// 	// 	if (window.FB) {
	// 	// 	  initFacebookSDK();
	// 	// 	} else {
	// 	// 	  console.error("Facebook SDK script failed to load.");
	// 	// 	}
	// 	//   };

	// 	//   const fjs = document.getElementsByTagName('script')[0];
	// 	//   fjs.parentNode.insertBefore(js, fjs);
	// 	// };

	// 	// loadFacebookSDK();

	// 	window.fbAsyncInit = function () { console.log("fv inittt");
	// 		window.FB.init({
	// 			appId: process.env.REACT_APP_FACEBOOK_APP_ID,
	// 			cookie: true,
	// 			xfbml: true,
	// 			version: "v17.0",
	// 		})
	// 		window.FB.getLoginStatus(function (response) {

	// 		});
	// 	};

	// 	(function (d, s, id) {
	// 		var js,
	// 			fjs = d.getElementsByTagName(s)[0];
	// 		if (d.getElementById(id)) {
	// 			return;
	// 		}
	// 		js = d.createElement(s);
	// 		js.id = id;
	// 		js.src = "//connect.facebook.net/en_US/sdk.js";
	// 		fjs.parentNode.insertBefore(js, fjs);

	// 	})(document, "script", "facebook-jssdk");

	// }, []);
	useEffect(() => {
		const initFacebookSDK = () => {
			if (window.FB) {
				window.FB.init({
					appId: process.env.REACT_APP_FACEBOOK_APP_ID,
					cookie: true,
					xfbml: true,
					version: "v17.0",
				});
				window.FB.getLoginStatus(function (response) {
					
					if(response?.authResponse?.accessToken)
					{
						settok(response?.authResponse?.accessToken)
					}
				});
			} else {
				console.error("Facebook SDK not yet available.");
			}
		};

		const loadFacebookSDK = () => {
			const scriptId = 'facebook-jssdk';
			if (document.getElementById(scriptId)) {
				initFacebookSDK();
				return;
			}

			const js = document.createElement('script');
			js.id = scriptId;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			js.onload = initFacebookSDK;

			const fjs = document.getElementsByTagName('script')[0];
			fjs.parentNode.insertBefore(js, fjs);
		};

		const checkFBInit = () => {
			if (window.FB) {
				initFacebookSDK();
			} else {
				setTimeout(checkFBInit, 100);
			}
		};

		window.fbAsyncInit = checkFBInit;

		loadFacebookSDK();
	}, []);

	const [popupVisible, setPopupVisible] = useState(false);
	const [fbPages, setFbPages] = useState([]);
	const [filteredPages, setfilteredPages] = useState([]);
	const [accessToken, setaccessToken] = useState('');
	const [keyword, setKeyword] = useState('');

	const closePopup = () => {
		setPopupVisible(false);
	};

	const handleSearch = (e) => {

		if (!keyword) {
			setfilteredPages(fbPages);
			return;
		}
		let filteredPages = fbPages.filter(page => page.name.toLowerCase().includes(keyword.toLowerCase()));
		setfilteredPages(filteredPages);
	};


	function getFacebookUserDetails(accessToken) {
		return new Promise((resolve, reject) => {
			window.FB.api(
				"/me?fields=id,name,email",
				"GET",
				{ access_token: accessToken },
				function (response) {
					resolve(response);
				}
			);
		});
	}


	function getProfileImage(accessToken, id) {
		return new Promise((resolve, reject) => {
			window.FB.api(
				`/${id}/picture?redirect=0`, "GET", { access_token: accessToken },
				function (response) {
					if (response && !response.error) {
						resolve(response);
					}
				}
			);
		})
	}

	function getFacebookPages(accessToken) {
		return new Promise((resolve, reject) => {
			window.FB.api(
				"/me/accounts",
				"GET",
				{
					fields: "id,name,access_token,connected_instagram_account,pages_show_list,profile_pic ",
					limit: "1000",
					access_token: accessToken,
				},
				function (response) {
					resolve(response);
				}
			);
		});
	}

	function getInstagramAccDetail(accessToken, id) {
		return new Promise((resolve, reject) => {
			window.FB.api(
				`/${id}`,
				"GET",
				{
					fields: "profile_picture_url,name,username",
					access_token: accessToken,
				},
				function (response) {
					resolve(response);
				}
			);
		});
	}

	const handleFbPagesRetrieved = (pages,type,token) => {
		setFbPages(pages);
		setfilteredPages(pages);
		if(type=="reconnect")
		{
			reeConnectAllPages(pages,token)
			
		}else{
			setPopupVisible(true);
		}
		store.updateStore("pageLoading", false);
	};

	const getInstaPages = async (fbpages) => {
		const instagramPages = [];
		for (const p of fbpages) {
			if (p.connected_instagram_account) {
				const instaAcc = await getInstagramAccDetail(accessToken, p.connected_instagram_account.id);
				if (instaAcc) {
					const instaPage = {
						pageId: p.id,
						id: p.connected_instagram_account.id,
						accessToken: p.access_token,
						name: instaAcc.name,
						profile_image: instaAcc.profile_image,
					};
					instagramPages.push(instaPage);
				}
			}
		}
		return instagramPages;
	};

	const addPage = async (pageData) => {
		let facebookUserDetails = await getFacebookUserDetails(
			accessToken
		);
		let profiledata = await getProfileImage(accessToken, facebookUserDetails.id);
		if (facebookUserDetails) {
			let newdata;
			if (source == 'facebook') {
				let dbDocument = currentList.find(item => item.accountId === facebookUserDetails.id);
				newdata = dbDocument?.data;
				if (dbDocument && newdata) {
					newdata.pages = {
						...newdata.pages,
						[pageData.id]: {
							name: pageData.name,
							pageAccessToken: pageData.access_token,
						}
					};
				} else {
					newdata = {
						name: facebookUserDetails.name,
						email: facebookUserDetails.email,
						accessToken: accessToken,
						profile_image: profiledata.data.is_silhouette == false ? profiledata.data.url : "",
						pages: {
							[pageData.id]: {
								name: pageData.name,
								pageAccessToken: pageData.access_token,
							}
						}
					}

				}


			}
			//for instagram
			else {
				newdata = {
					facebookId: facebookUserDetails.id,
					name: pageData.name,
					profile_image: pageData.profile_image ? pageData.profile_image : '',
					accessToken: accessToken,
				}
			}

			let upbData = {
				source: source == 'facebook' ? 'facebook' : 'instagram',
				type: 'page',
				accountId: source == 'facebook' ? facebookUserDetails.id : pageData.id,
				data: newdata
			};

			hitApi({
				url: 'social-account/manage-account',
				method: "POST",
				data: upbData
			}, (resp, err = null) => {
				if (resp) {

					if (onUpdate) {
						let cList = [...currentList];

						if (cList.length) {
							upbData._id = resp.data.target;

							let isnewAcc = true;
							cList.map((d, i) => {
								if (d.accountId === facebookUserDetails.id && source == 'facebook') {
									isnewAcc = false;
									cList[i] = upbData;
								} else if (d.accountId === pageData.id && source == 'instagram') {
									isnewAcc = false;
									cList[i] = upbData;
								}
							});

							if (isnewAcc) {
								cList.push(upbData);
							}

						} else {
							upbData._id = resp.data.target;
							cList = [upbData];
						}
						console.log({cList})
						onUpdate(cList);

						if (allaccountlistupdate) {
							let allaccountlist = accountsList;
							if (allaccountlist.length) {
								let isnewAcc = true;
								allaccountlist.map((d, i) => {
									if (d.accountId === facebookUserDetails.id && source == 'facebook') {
										isnewAcc = false;
										allaccountlist[i] = upbData;
									} else if (d.accountId === pageData.id && source == 'instagram') {
										isnewAcc = false;
										allaccountlist[i] = upbData;
									}
								});

								if (isnewAcc) {
									allaccountlist.push(upbData);
								}

							} else {
								allaccountlist = cList;
							}

							allaccountlistupdate(allaccountlist)
						}

					}
				}
			});
		}
	}


	const reeConnectAllPages = async (allpageData,token) => {

		let facebookUserDetails = await getFacebookUserDetails(
			token
		);
		
		let profiledata = await getProfileImage(token, facebookUserDetails.id);

		if (facebookUserDetails) {
			let checkreconnectdata=currentList[index]

				if(source == 'facebook') 
				{
					if(checkreconnectdata.accountId !=facebookUserDetails.id)
					{
						commonToast('error', "Please connect proper account.")
						return
					}
					
				}else{
					let check=allpageData.some((acc=>acc.id==checkreconnectdata.accountId))
					if(!check)
					{
						commonToast('error', "Please connect proper account.")
						return
					}
				}
			let newdata;
			let dbDocument = currentList.find(item => item.accountId === facebookUserDetails.id);

			if (source == 'facebook') {
				newdata = dbDocument?.data;
				if (dbDocument && newdata) {
					newdata.accessToken=token
					allpageData.forEach((element,index) => {
						if(newdata.pages[element.id]){
							newdata.pages[element.id]={
							name: element.name,
							pageAccessToken: element.access_token,
							}
						}
					});	
				}
			}else {
				newdata = {
					facebookId: facebookUserDetails.id,
					name: checkreconnectdata.name,
					profile_image: checkreconnectdata.profile_image ? checkreconnectdata.profile_image : '',
					accessToken: token,
				}
			}
			let upbData = {
				source: source == 'facebook' ? 'facebook' : 'instagram',
				type: 'reconnect_page',
				accountId:   facebookUserDetails.id ,
				data: newdata,
			};
			console.log({checkreconnectdata},"upbDataupbDataupbData")
			hitApi({
				url: 'social-account/manage-account',
				method: "POST",
				data: upbData
			}, (resp, err = null) => {
				if (resp) {

					if (onUpdate) {
						let cList = [...currentList];

						if (cList.length) {
							upbData._id = resp.data.target;

							let isnewAcc = true;
							console.log({cList})
							cList.map((d, i) => {
								if (d.accountId === facebookUserDetails.id && source == 'facebook') {
									isnewAcc = false;
									cList[i] = upbData;
								} else if (d.data.facebookId === facebookUserDetails.id && source == 'instagram') {
									isnewAcc = false;
									cList[i].data.accessToken = token;
								}
							});

						

						} else {
							cList = [upbData];
						}

						onUpdate(cList);

						if (allaccountlistupdate) {
							let allaccountlist = accountsList;
							if (allaccountlist.length) {
								let isnewAcc = true;
								allaccountlist.map((d, i) => {
									if (d.accountId === facebookUserDetails.id && source == 'facebook') {
										isnewAcc = false;
										allaccountlist[i] = upbData;
									} else if (d.accountId === allpageData.id && source == 'instagram') {
										isnewAcc = false;
										allaccountlist[i] = upbData;
									}
								});

								if (isnewAcc) {
									allaccountlist.push(upbData);
								}

							} else {
								allaccountlist = cList;
							}

							allaccountlistupdate(allaccountlist)
						}

					}
				}
			});
		}
	}

	// window.FB.getLoginStatus(function (response) {
	// 				console.log('FB Login Status:', response);
	// 			});

	let fb_login = (type="") => {
		console.log({type})
		window.FB.login(function (response) {

			setPopupVisible(false);
		
			if (response.authResponse) {
				if (response.status == "connected") {
			
					let authresponse = response.authResponse;
					let path = "oauth/access_token";
					let method = "GET";
					let params = {
						grant_type: "fb_exchange_token",
						client_id: process.env.REACT_APP_FACEBOOK_APP_ID,
						client_secret: process.env.REACT_APP_FACEBOOK_SECRET_KEY,
						fb_exchange_token: authresponse.accessToken,
					};
					let callback = async (result) => {
						let accessToken = result.access_token;
						
						store.updateStore("pageLoading", true);
						let fbpages = await getFacebookPages(accessToken);

						if (source == 'facebook') {
							handleFbPagesRetrieved(fbpages.data ,type,accessToken);
							setaccessToken(accessToken);
						} else {
							if (fbpages) {
								let instagramPages = await getInstaPages(fbpages.data);
								handleFbPagesRetrieved(instagramPages ,type,accessToken);
								setaccessToken(accessToken);
							}
						}

					};
					window.window.FB.api(path, method, params, callback);
				}
			}
			else {
				console.log('close window')
			}

		}, {
 			// auth_type: 'reauthenticate',
			scope: "pages_manage_posts,pages_show_list,pages_read_engagement,business_management" + (source == "instagram" ? ",instagram_basic,instagram_content_publish" : ""),
		});
	}

	return (
		<>

			{parent == 'socialAccComponent' ?
				<a onClick={fb_login}>
					<div className="sch-user-img sch-user-img-add">
						{schedulerSvg.app.addNewSvg}
					</div>
					<div className="sch-user-name">
						<h2>{title}</h2>
					</div>
				</a>
				:
				parent == 'reconnect' ?


					<div className={` pxl-tooltip-button-form pxl-reload-tooltip  ${containsSchedularWrite ? "" : 'pxl-tooltip-btn-diable'}`} >
						<button className={`cun-socialIcon cun-socail-delete ${containsSchedularWrite ? '' : 'sch-btn-disbale'}`} disabled={!containsSchedularWrite} onClick={()=>fb_login("reconnect")}>
							<img src="/assets/images/social/reconnect.svg" alt="" />
							<div className="cun-show-tooltip">{containsSchedularWrite ? <p>Reconnect</p> : teamMemberRestrictionMess(containsSchedularWrite)}
							</div>
						</button>

					</div>
					:
					<div className='pxl-add-new-accountBtn cun-socialIcon'>
						<button className={`pxlCommon-btn ${containsSchedularWrite ? '' : 'sch-btn-disbale'}`} onClick={fb_login} disabled={!containsSchedularWrite}>{title}</button>
						{!containsSchedularWrite && <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(containsSchedularWrite)}</p></div>}
					</div>
			}

			<Modal className='pxlInstagram-account-connection-popup' show={popupVisible} onHide={closePopup} animation={true} size="lg" centered>
				<Modal.Header closeButton>
					<Modal.Title>{source == 'facebook' ? 'Facebook Pages' : 'Instagram Account'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='sch-socialAccount-wrapper'>
						<div className="sch-search-section">
							<div className="sch-search-row sch-search-bg-color-white">
								<input type="text" placeholder="Search Pages"
									value={keyword}
									onChange={(e) => setKeyword(e.target.value)}
									onKeyDown={(e) => {
										if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
											handleSearch();
										}
									}}
								/>
								{/* <button type="button" className="sch-search-btn">
									{!keyword ? <span> {svg.app.searchIcon} </span> : <span>{svg.app.crossSvg}</span>}
								</button> */}
							</div>
						</div>
						<div className='sch-social-account-wrapper'>
							{(filteredPages.length > 0) ? filteredPages.map((page, index) => (
								<div className='sch-social-account-flex' key={index}>
									<div className='sch-socail-pageName'>
										{
											page?.profile_image ?
												<img src={page?.profile_image} alt="" />
												:
												<div className='sch-socialDefault-img'>
													<img src={`/assets/images/default-img/${source}-default-img.png`} alt="" />
												</div>
										}
										<h2>{page?.name}</h2>
									</div>
									{
										(source == 'facebook' ?
											(currentList.find((item) => item.data.pages && item?.data?.pages?.hasOwnProperty(page.id))) :
											(currentList.find((item) => item.accountId == page.id)))
											? (
												<div className='sch-connectedAccount'>Connected</div>
											) : (

												<div><a className='pxl_btn' onClick={() => addPage(page)}>Connect</a></div>
											)}
								</div>
							)) : <div>No matching page found.</div>}
						</div>
						<div className='sch-continue-social-wrap'>
							{/* <p>If you need to add another {source} account, simply log out of or switch {source} accounts first.</p> */}
							<div className='sch-continueBtn pxl-account-btn-left'>

								<a onClick={() => closePopup()} className='pxl_btn'>Continue</a>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default FacebookBtn;