// import { toast } from "react-toastify";
import moment from "moment";
import Cookies from "js-cookie";
import Skeleton from 'react-loading-skeleton';
import { useEffect } from "react";
import appConfig from "../config/appConfig";

export let hitApi = (params, cb) => {
   
    // return new Promise((reslve, reject) => {
    try {
        if (!params.url) {
            commonToast('error', 'Trying to call API without URL.')

            // toast.error('Trying to call API without URL.', { toastId: 'toast' });
            // reject();
            return;
        }

        let nav = navigator || null;
        if (nav && !nav.onLine) {
            commonToast('error', 'Please check your internet connection or try again later.')

            // toast.error('Please check your internet connection or try again later.', { toastId: 'toast' });
            // reject();
            return;
        }

        var detail = {
            method: params.method,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                mode: "no-cors", // no-cors

            },
        };

        params.data.clientTime = +Date.now();

        if (params.isFormData) {
            // delete detail.headers;
            detail.headers = {};
            detail["body"] = params.data;
        } else if (['POST', 'PATCH', 'PUT'].includes(params.method.toUpperCase())) {
            detail["headers"] = {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
            };
            detail["body"] = JSON.stringify(params.data);
        } else {
            if (Object.keys(params.data).length) {

                var str = [];
                for (var p in params.data) {
                    
                    if (params.data.hasOwnProperty(p)) {
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params.data[p]));
                    }
                }

                if (params.url.indexOf("?") !== -1) {
                    params.url += "&" + str.join("&");
                } else {
                    params.url += "?" + str.join("&");
                }

            }
        }

        let urlRegex = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)?/;
        params.url = urlRegex.test(params.url) ? params.url : appConfig.apiUrl + params.url;

        // common.manageLoader(params, true); // apply loader show

        detail.headers.authorization = `Bearer ${Cookies.get('authToken')}`;
        fetch(params.url, detail).then((res2) => res2.json()).then((resp) => {
            // common.manageLoader(params, false);
            if (resp.status) {
                if (resp.message && resp.message != "") {
                    
                    if (params.alert == false) {
                        // console.log("nothing");
                    } else {
                        commonToast('success', resp.message)
                    }


                    // toast.success(resp.message, { toastId: 'toast' });
                }
                // reslve(resp);
                cb(resp);
                return;
            } else {

                if (resp?.message == "jwt malformed") { //
                    window.location = '/';
                }

                if (resp.message && resp.message != "") {
                    commonToast('error', resp.message)

                    // toast.error(resp.message, { toastId: 'toast' });
                }
                cb({}, resp.message);
                // reject(resp);
                // return;
            }
        }).catch(err => {
            cb({}, err.message);
        });
    } catch (error) {

        cb({}, error.message);
        // reject(error.message);
        // return;
    }
    // });
};
export let dateFormatter = (date, formate = "D MMM YYYY h:mm A") => {
    return moment(date).format(formate);
};



export let copyData = (data) => {
    // copy(data.target);
    commonToast('success', data.type + " copied successfully.")

    // toast.success(data.type + " copied successfully.", { toastId: 'toast' });
};
export let getRandomChar = () => {
    return Math.random().toString(36).slice(-8);
};

let convertToCSV = (objArray) => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
            if (line != "") line += ",";
            line += '"' + array[i][index] + '"';
        }
        str += line + "\r\n";
    }
    return str;
};
export let exportCSVFile = (headers, items, fileTitle) => {
    // if (headers) {
    //     items.unshift(headers);
    // }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);
    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + ".csv" || "export.csv";
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
export let convertQueryStringToObj = (path) => {
    let querySt = {};
    let qString = path.split("?") || [];
    if (qString.length > 1) {
        let p = qString[1].split("&");
        p.map((d, i) => {
            let t = d.split("=");
            querySt[t[0]] = t[1];
        });
    }

    return querySt;
};
export let convertObjToQueryString = (obj = {}) => {
    let resObj = [];
    if (Object.keys(obj).length > 0) {
        for (let d in obj) {
            resObj.push(`${d}=${obj[d]}`)
        }
    }
    return resObj.join('&');
};
export let setMyState = (setQuery, params) => {
    return new Promise((res, rej) => {
        for (const key in params) {
            setQuery((prevState) => ({
                ...prevState,
                [key]: params[key],
            }));
        }
        res();
    });
};
export function objectToFormData(obj) {
    const formData = new FormData();
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            formData.append(key, obj[key]);
        }
    }
    return formData;
}

export function getNameInitials(name) {
    const [firstName, lastName] = name ? name.split(" ").map(name => name.charAt(0)) : [];
    const nameinitials = lastName ? `${firstName}${lastName}` : firstName;
    return nameinitials?.toUpperCase();
}


export function Pagination(props) {
   
    let prePostCnt = 2;
    let { totalRecords, perPage, isLoading, currentPage, type, dataRange, onClick, loadMore,showPagination } = props;
    let paginationButton = []
    let pageCnt = 1;
    for (let i = 1; i <= parseInt(totalRecords); i = i + parseInt(perPage)) {
        paginationButton.push(pageCnt++);
    }

    return (
        <>
            {
                !isLoading ?
                    <div  style={{display :(showPagination!=true)&& "none"}} className="pxl_tablePagination">

                        <p>{'Showing ' + dataRange.start + ' to ' + dataRange.end + ' of ' + totalRecords}</p>
                        {
                            (loadMore) ?
                                <button id={showPagination!=true&&'add_Load_more'} type="button" disabled={totalRecords == dataRange.end} className={`pxl_btn ${totalRecords == dataRange.end && 'pxl-button-disable'}`} onClick={(e) => {
                                    onClick(currentPage + 1);
                                }}> {!totalRecords ? "Processing..." : "Load More"}</button>
                                :
                                <ul className="pxa_pageBox">
                                    {
                                        paginationButton.length ?
                                            <li key="0"><a onClick={(e) => {
                                                onClick(1);
                                            }} className={'prev ' + (currentPage == 1 ? 'disabled' : '')} disabled>First</a> </li> : ''

                                    }


                                    {
                                        paginationButton.map((pageNum, index) => {

                                            if (pageNum > parseInt(currentPage) + prePostCnt || parseInt(pageNum) + prePostCnt < parseInt(currentPage)) {
                                                if (pageNum == 1 || pageNum == paginationButton.length) {
                                                    return <span key={index} className={''}>...</span>;
                                                } else {
                                                    return;
                                                }
                                            }
                                            return (
                                                <li key={index}><a className={currentPage == pageNum ? 'isActive' : ''} onClick={(e) => {
                                                    onClick(pageNum);
                                                }}>{pageNum}</a></li>
                                            )
                                        })
                                    }

                                    {
                                        paginationButton.length ?
                                            <li> <a key={paginationButton.length + 1} onClick={(e) => {
                                                onClick(paginationButton.length);
                                            }} className={'next ' + (currentPage == paginationButton.length ? 'disabled' : '')}>Last</a> </li>
                                            : <></>
                                    }

                                </ul>
                        }



                    </div>
                    : <></>
            }


        </>
    )
}


export function skeletonLoader(type, count, width, height, index) {

    if (type == 'table') {
        return <>
            <Skeleton key={index} count={2} width={'100%'} height={60} />

        </>
    }

    if (type == 'list') {
        return <Skeleton key={index} count={count ? count : 2} width={width ? width : '100%'} height={height ? height : 50} />
    }

    if (type == 'asset') {
        return <div><Skeleton key={index} count={1} width={width} inline height={height} /></div>
    }

    if (type == 'pixastock') {
        return <div className="sch-post-img-main">
            <div className="sch-post-heading-flex" >
                <Skeleton count={1} width={105} inline height={20} />
            </div>
            <div className="sch-post-images">
                {[...Array(count ? count : 3)].map((i, index) => <span key={index} style={{ width: `calc(33.3% - 7px)` }}><Skeleton count={1} width={'100%'} inline height={108} /> </span>)}
            </div>
        </div>
    }

}

export function validations(value, type) {
    if (type == 'email') {
        let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
        let pass =/^[A-Za-z0-9!@#$%^&*()_+\-={}\[\]\\|:;"'<>,.?/]+$/
        if (!regex.test(value)) {
            commonToast('error', 'Email is not valid.')
            // toast.error(`Email is not valid.`, { toastId: 'toast' });
            return false
        }
        return true

    } else if (type == 'password') {
        // if(pass)

        if (value.length < 6 || /\s/.test(value)) {
            commonToast('error', 'Password must be at least 6 characters long and contain no spaces.')

            // toast.error('Password must be at least 6 characters long and contain no spaces.', { toastId: 'toast' });
            return false
        }
        
        return true

    }
}
export function fieldsValidations(value,label) {
    const alphanumericPattern =  /^[A-Za-z0-9 _-]+$/;
;
    const specialCharPattern = /[~`!@#$%^&*()+={}\[\]|\\:;"'<>,.?/]/;
    if (value.length < 3) {
        commonToast('error', `${label} must be at least 3 character long.`);
        return false
    }

    if (value.length > 40) {
        commonToast('error', `${label} must be no longer than 40 characters.`);
        return false
    }

    if (!alphanumericPattern.test(value) ) {
        commonToast('error', `${label} should not contain special characters.`);
        return false
    }
  

    // if (specialCharPattern.test(value) && value.length < 1) {
    //     commonToast('error', `${label} is too short and contains special characters.`);
    //     return false
    // }

    // if (specialCharPattern.test(value)) {
    //     commonToast('error', `${label} should not contain special characters.`);
    //     return false
    // }
    return true;
}


export function assetsUrlPath(img) {
   
    if (img && img.startsWith("public")) {
        let newPath = img.slice("public".length + 1);
        let path = appConfig.apiUrl
        return path + newPath
    } else if (img && img?.includes('/assets/images/')) { 
        return img;
    } else if (img?.includes('medias/')) {
        return appConfig.cdnPath + img
    }
    else { 
       
        // return process.env.REACT_APP_APP_MODE === 'live' ?  console.log(img,"true ",process.env.REACT_APP_APP_MODE, appConfig?.cdnPath+img) :

        return process.env.REACT_APP_APP_MODE === 'dev' ?    appConfig?.cdnPath + img :appConfig?.cdnPath + `medias/${img}`
    }
}

export function NoDataFound({ title, type, btntext, onCreate, changeDesign = false, showbtn, bgRemove = false }) {
    // if(type == 'design'){
    return <div className={`pxl-nodesign-wrap1 ${changeDesign ? `remove` : ``} ${bgRemove ? '' : 'cun-no-design-wrapper'}`}>
    <div className="cun-noDesign-inner">
        <img src="/assets/images/no-design-img.png" alt="" />
        <h2>{title}</h2>
        {!showbtn &&<div className="scun-noImg-btn">
            {type == 'design' && <a className="pxlCommon-btn" onClick={onCreate}>
                {btntext}
            </a>}
        </div>}
    </div>
</div>
    // }else{
    //     return (
    // <div className="sch-no-data-found-wrapper">
    //     <div className="sch-no-data-inner">
    //         <div className="sch-nodata-img">
    //             <img src="/assets/images/newpost/empty-data.png" alt="" />
    //         </div>
    //         <div className="sch-nodata-text">
    //             <h2>{title}</h2>
    //         </div>
    //     </div>
    // </div>
    //     )
    // }

}
export function NoPageFound({ title, btntext, onCreate, changeDesign = false,  bgRemove = false }) {
    // if(type == 'design'){
    return <div className={`pxl-page-not-found-wrap ${changeDesign ? `remove` : ``} ${bgRemove ? '' : 'cun-no-design-wrapper'}`}>
    <div className="cun-noDesign-inner">
        <img src="/assets/images/page-not-found.svg" alt="" />
        <h2><span>Oop's...</span>{title}</h2>
        <p>
        Don't worry, you can return to your dashboard and continue from there.
        </p>
        <div className="scun-noImg-btn">
            <a className="pxlCommon-btn" href="/dashboard">
                {btntext}
            </a>
        </div>
    </div>
</div>
}

export function AddDataimg({ title, type, btntext, onCreate, changeDesign = false, bgRemove = false }) {
  
    return <div className={`pxl-nodesign-wrap1 ${changeDesign ? `remove` : ``} ${bgRemove ? '' : 'cun-no-design-wrapper'}`}>
                <div className="cun-noDesign-inner">
                    <img src="/assets/images/Create_File.png" alt="" />
                    <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
                    <div className="scun-noImg-btn">
                        {type == 'design' && <a className="pxlCommon-btn" onClick={onCreate}>
                            {btntext}
                        </a>}
                    </div>
                </div>
            </div>
  

}
export function NewCreatefile({ title, type, btntext, onCreate, changeDesign = false, bgRemove = false }) {
      return <div className={`pxl-nodesign-wrap1 ${changeDesign ? `remove` : ``} ${bgRemove ? '' : 'cun-no-design-wrapper'}`}>
      <div className="cun-noDesign-inner">
          <img src="/assets/images/newCreatefile.png" alt="" />
          <h2>{title}</h2>
          <div className="scun-noImg-btn">
              {type == 'design' && <a className="pxlCommon-btn" onClick={onCreate}>
                  {btntext}
              </a>}
          </div>
      </div>
  </div>
    
  
  }




export function NoDataFoundDataTable({ column, statement }) {
    return (
        <tr><td className="text-center" colSpan={column}>{statement}</td></tr>
    )
}


export function getExtensionFromUrl(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}


export function createScheduleDate(date, offset) {
    return moment(date).utcOffset(offset).utc().format()
}

export function Loader() {
    return (
        <div className="pxl_loader">
            <img src="/loader.gif" />
        </div>
    )
}


// export const useOutsideClick = (ref, callback) => { 
//     const handleClick = e => {

//       if (ref.current && !ref.current.contains(e.target)) {
//         callback();
//       }
//     };

//     useEffect(() => {
//       document.addEventListener("click", handleClick);

//       return () => {
//         document.removeEventListener("click", handleClick);
//       };
//     });
//   };
export function useOutsideClick(ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {

                if (!ref.current || ref.current.contains(event.target)) {
                    return false;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },

        [ref, handler]
    );
}
// export function useOutsideClick(ref, handler, exceptions = []) {
//     useEffect(() => {
//         const listener = (event) => {
//             if (!ref.current || ref.current.contains(event.target) || exceptions.some(exception => exception.current && exception.current.contains(event.target))) {
//                 return;
//             }
//             handler(event);
//         };

//         document.addEventListener("mousedown", listener);
//         document.addEventListener("touchstart", listener);

//         return () => {
//             document.removeEventListener("mousedown", listener);
//             document.removeEventListener("touchstart", listener);
//         };
//     }, [ref, handler, exceptions]);
// }


//download image 
export const downloadImage = (path, mediaType,title) => {
    const pattern = new RegExp('^(https?:\\/\\/)');
    // const mediaTitle = localStorage.getItem('mTitle')
    const imageUrl = path.includes("https") ? path : assetsUrlPath(path);
    // fetch(imageUrl).then((response) => response.blob()).then((blob) => {
    //     const objectURL = URL.createObjectURL(blob);
    //     const a = document.createElement("a");
    //     a.href = objectURL;
    //     a.download = (mediaType == 'video' || mediaType == 'audio') ? "video.mp4" : "image.jpg";
    //     a.style.display = "none";
    //     a.click();
    // });
    
    fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
            const blobUrl = URL.createObjectURL(blob);
           
            const mimeType = blob.type; 
            let ext = mimeType.split('/')[1]

            if(ext.split('+'))
            {
                ext = ext.split('+')[0]
            }

            const a = document.createElement("a");
            a.href = blobUrl;
            // a.download = ((mediaType === 'video' || mediaType === 'audio') ? `${title}.${ext}` : `${title}.${ext}`);
            a.download = (`${title}.${ext}`);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            localStorage.clear('mTitle');
        });
}

export const commonToast = (type, message) => {
    // toast.dismiss();
    if (type == 'error') {
        AlertMsg('error',"Oh no! Error",message);
    }
    if (type == 'success') {
        AlertMsg('success',"Great Success!",message);
    }

}
export function AlertMsg(type, title, message){
   
    const wrapperEl = document.querySelector('.pxl-notication-section');

    const titleEl = document.querySelector('.pxl_notification_title');
    const messageEl = document.querySelector('.pxl_notification_message');
    const closeEl = document.querySelector('.pxl-notification-cross');
    wrapperEl.classList.remove('success');
    wrapperEl.classList.remove('error');
    wrapperEl.classList.add(type);
    titleEl.innerHTML = title;
    messageEl.innerHTML = message;
    setTimeout(() => {
        wrapperEl.classList.add('open_alert');
    }, 100);

    if(!wrapperEl.classList.contains('open_alert')){
        setTimeout(() => {
            wrapperEl.classList.remove('open_alert');
            wrapperEl.classList.remove('success');
            wrapperEl.classList.remove('error');
        }, 5000);
    }

    closeEl.addEventListener('click', function(el){
        wrapperEl.classList.remove('success');
        wrapperEl.classList.remove('open_alert');
        wrapperEl.classList.remove('error');
    });
}

export const hexToRgbA = (hex, alpha) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    throw new Error('Bad Hex');
}

export const teamMemberRestrictionMess = (val) => {
    return 'You have read-only access.'
}