
import { Helmet } from "react-helmet";

import svg from "../components/svg";
import Sidebar from "../components/Sidebar";
import { useEffect, useState, useRef } from "react";
import { NoDataFoundDataTable, AddDataimg, Pagination, commonToast, hitApi, setMyState, skeletonLoader, validations, NoDataFound } from "../components/commonAction";
import { Modal } from 'react-bootstrap';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Tooltip } from "@mui/material";


const Notification = ({ pageTitle }) => {

    const [state, setState] = useState({
        data: [],
        keys: '',
        limit: 10,
        page: 1,
        keyword: '',
        sort: 'createdAt=-1',
        totalRecords: '',
        isTrash: '',
        userLoading: true,
        clearKeyword: false,
    });
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        confirmPass: '',
        target: '',
        status: '',
        scope: { "brandId": [] },
        isEdit: false
    })
    const [btnLoading, setBtnLoading] = useState(false);
    const [selectedScope, setSelectedScope] = useState([]);
    const [selectedId, setSelectedId] = useState([]);

    const [objScope, setObjScope] = useState([])
    const [notificationData, setNotificationData] = useState([])

    useEffect(() => {
        getBrandList();
        getNotification();

    }, [])
    // useEffect(() => {
    //     getUserList();

    // }, [state.page])

    const clearSearch = () => {
        setMyState(setState, { ...state, clearKeyword: true, keyword: '' });
    }

    const getBrandList = () => {
        hitApi({
            url: `brand/brand-list`,
            method: "GET",
            data: {
                limit: 'all'
            }
        }, (resp) => {
            if (resp.status) {
                const response = resp.data.records;
                let d1 = []
                response.forEach(element => {
                    d1.push(
                        { value: element._id, label: element.title }
                    )
                });
                setObjScope(d1)
            }
        });
    }


    // const emptyUserState = () => {
    //     setMyState(setUser, {
    //         name: '', email: '', password: '', target: '', status: '', isEdit: false
    //     })
    // }




    const getNotification = () => {
        setBtnLoading(true);
       
        setBtnLoading(false);


      
        setBtnLoading(true);
        hitApi({
            url: 'scheduler/dashboard/get-notification',
            method: "GET",
            data: {}
        }, (resp, err = null) => {
            setBtnLoading(false);
            if (resp.status) {
                setNotificationData(resp.data)
            }
        });
    }

    let animatedComponents = makeAnimated();
    let cntStart = ((state.page - 1) * state.limit) + 1, cnt = cntStart;

    const handleShow = (item) => {
        if (item) {
            setMyState(setUser, {
                name: item.name, email: item.email, password: '', target: item._id, isEdit: true, status: item.status
            });
            let resultArray = [];
            item?.scope?.brandId.map((i) => {
                let matchingObj = objScope.find(obj => obj.value === i);
                if (matchingObj) {
                    resultArray.push(matchingObj);
                }
            });
            setSelectedScope(resultArray);

        }
    };
    // const firstInputRef = useRef(null);

    // useEffect(() => {
    //     if (firstInputRef.current) {
    //         firstInputRef.current.focus();
    //     }
    // }, [showModal]);

    // useEffect(() => {

    //     if(state.keyword == '' && !state.userLoading)
    //     {
    //         setMyState(setState,{page : 1})
    //         getUserList()
    //     }
    // },[state.keyword])

    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper">
                <Sidebar />
                <div className="pxl_rightBar_assets pxl-bg-back-color-wrap pxl-clientMain-wrapper-box">
                    <div className="pxl_pageTitle">
                        <h2>{pageTitle}</h2>
                    </div>
                    <div className="pxl-count-cards">
                        <div className="pxl_container">
                            <div className="pxl_grid">
                                <div className="pxl_tableBox_filter">
                                    {notificationData.length > 0 || state.keyword ? <div className="pxl_tableData">
                                        <h5>Notification ({notificationData.length})</h5>
                                    </div> : ""}
                                    <div className="pxl_filterFeilds cun-users-resheader">
                                        <div className="sch-search-section sch-searchButton-wrap">
                                            {/* {notificationData.length > 0 || state.keyword ? <div className="sch-search-row sch-search-bg-color-white">
                                                <input type="text" value={state.keyword} placeholder="Search Client"
                                                    onChange={(e) => {
                                                        setMyState(setState, {
                                                            keyword: e.target.value,
                                                            clearKeyword: false
                                                        })
                                                    }
                                                    }
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                                                            if (state.page != 1) {
                                                                setMyState(setState, { page: 1 })
                                                            } else {
                                                                // getUserList()
                                                            }
                                                        }
                                                    }}
                                                />
                                                <button type="button" className="sch-search-btn">
                                                    {!state.keyword ? <span> <img src="/assets/images/scheduler/search.svg" /> </span> : <span onClick={clearSearch}><img src="/assets/images/scheduler/search.svg" /></span>}
                                                </button>
                                            </div> : ""} */}
                                            {/* <div className="pxl-addNew-client-btn">
                                                <button className="pxlCommon-btn" onClick={() => handleShow()}>
                                                    Add New Client
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="pxl_table table-responsive support_table">
                                    <table className="pxl_table">
                                        {notificationData?.length > 0 ? <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Brand Name</th>
                                                {/* <th>Brand Logo</th> */}
                                                <th>Social Platform</th>
                                                <th>Social Account</th>
                                                <th>Issues</th>
                                            </tr>
                                        </thead> : ""}
                                        <tbody>
                                            {(notificationData) ?
                                                notificationData?.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="cun-socialIcon"><p>{state.page == 1 ? (index + 1) : (index + 1) + ((state.page - 1) * state.limit)}</p></td>
                                                            <td className="cun-socialIcon">{item.brand[0].title}</td>
                                                            {/* <td className="cun-socialIcon"><img src={`https://api.pixalab.ai/${item.brand[0].icon.original.path}`} /></td> */}
                                                            <td className="cun-socialIcon"><p>{item.source}</p></td>
                                                            <td className="cun-socialIcon"><p>{item.data?.name}</p></td>
                                                            <td className="cun-socialIcon"><p>{item.reconnectErr}</p></td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr ><td colSpan={8}>{skeletonLoader('table')}</td></tr>
                                            }
                                            {(!state.userLoading && state.data.length === 0) && !state.keyword ?
                                                <AddDataimg title={`You haven't added any client yet.<br/> Feel free to add a new client by clicking the "Add New Client" button`} bgRemove={true} />
                                                :
                                                (!state.userLoading && state.data.length === 0) && state.keyword ? <NoDataFound title={'No matching record found.'} /> : null
                                            }
                                        </tbody>
                                    </table>
                                </div>


                                {/* {state.data.length > 0 && <Pagination
                                    type="User"
                                    dataRange={{
                                        start: state.totalRecords ? cntStart : 0,
                                        end: state.totalRecords ? cntStart + state.data.length - 1 : 0,
                                    }}
                                    showPagination={true}
                                    currentPage={state.page}
                                    totalRecords={state.totalRecords}
                                    perPage={state.limit}
                                    isLoading={state.userLoading}
                                    onClick={(pageNum) => {
                                        setMyState(setState, {
                                            page: pageNum,
                                        });
                                    }}
                                />} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Notification;