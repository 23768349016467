import { Routes, Route, useLocation } from "react-router-dom";


import Dashboard from "./index";
import NewPost from "./newPost";
import Calendar from "./calendar";
import Draft from "./draft";
import SchedulePost from "./schedule-post";
import appConfig from "./../../config/appConfig";
import Social from '../../pages/Social'
import './css/scheduler.css';
import PrivateRoute from "../PrivateRoute";

const SchedulerRouter = () => {
    
    if(useLocation().pathname.includes('/scheduler')) {
        require('./css/scheduler.css');
    }
 
    return (
        <>
           
            <Routes>
                <Route path="/" element={<PrivateRoute allowedRoles={['user','admin',"client"]}> <Dashboard pageTitle={`${appConfig.appName} | Dashboard`} /> </PrivateRoute> } />
                <Route path="/new-post" element={<PrivateRoute allowedRoles={['user','admin','teamMember']} allowedscope={'scheduler'} checkscope={'write'}> <NewPost pageTitle={`${appConfig.appName} | Create New Post`} /> </PrivateRoute> } />
                <Route path="/edit-draft/:id" element={<PrivateRoute allowedRoles={['user','admin','teamMember']} allowedscope={'scheduler'} checkscope={'write'}><NewPost pageTitle={`${appConfig.appName} | Draft`} /></PrivateRoute>} />
                <Route path="/edit-schedule-post/:id" element={<PrivateRoute allowedRoles={['user','admin','teamMember']} allowedscope={'scheduler'} checkscope={'write'}><NewPost pageTitle={`${appConfig.appName} | Schedule Post`} /></PrivateRoute>} />
                <Route path="/calendar" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}><Calendar pageTitle={`${appConfig.appName} | Calendar`} /></PrivateRoute>} />
                <Route path="/draft" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}><Draft pageTitle={`${appConfig.appName} | Draft`} /></PrivateRoute>} />
                <Route path="/schedule-post" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]} allowedscope={'scheduler'} checkscope={'write'}><SchedulePost pageTitle={`${appConfig.appName} | Schedule Post`} /></PrivateRoute>} />
                <Route path="/social-integration" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}><Social pageTitle={`${appConfig.appName} | Social Accounts`}/></PrivateRoute>}/>

            </Routes>
        </>
    )
};

export default SchedulerRouter;