import React, { useState, useEffect, useRef } from 'react';
import { hitApi, setMyState, assetsUrlPath, teamMemberRestrictionMess } from "../../commonAction";
import schedulerSvg from '../../../pages/scheduler/components/schedulerSvg';
import appConfig from '../../../config/appConfig';

let PinterestBtn = ({title = 'Add New Account',parent = "social",containsSchedularWrite,currentAccount=null}) => {
    console.log({currentAccount})
    const piLogin = () => {
        let clientId = process.env.REACT_APP_PINTEREST_APP_ID;
        let redirectUri = `${appConfig.appUrl}social`;
        let scope = `boards:write,boards:write_secret,boards:read,boards:read_secret,pins:read,pins:read_secret,pins:write,pins:write_secret,user_accounts:read`;
        let s1={
            social : "pinterest", 
        }
        if(currentAccount)
        {
            s1.id=currentAccount.accountId
        }
        s1=JSON.stringify(s1)
        let pinterestAuthUrl = `https://www.pinterest.com/oauth/?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=`+s1;
        window.open(pinterestAuthUrl, '_blank');
    }

    return(
        <>
        {parent == 'socialAccComponent' ?
				<a  onClick={piLogin}>
					<div className="sch-user-img sch-user-img-add">
						{schedulerSvg.app.addNewSvg}
					</div>
					<div className="sch-user-name">
						<h2>{title}</h2>
					</div>
				</a>
				:
                parent == 'reconnect' ?

                <div className={` pxl-tooltip-button-form pxl-reload-tooltip ${containsSchedularWrite ? "" : 'pxl-tooltip-btn-diable'}`}>
                    <button className={`cun-socialIcon cun-socail-delete ${containsSchedularWrite ?  "" : 'sch-btn-disbale'}`} disabled={!containsSchedularWrite}  onClick={piLogin}>
						 <img src="/assets/images/social/reconnect.svg" alt=""/>
                         <div className="cun-show-tooltip">{containsSchedularWrite? <p>Reconnect</p> : teamMemberRestrictionMess(containsSchedularWrite) }
					</div>
					</button>
					
                    
                </div>
				// <div onClick={piLogin}>reconnect</div>
				:
                <div className=' pxl-add-new-accountBtn cun-socialIcon'> 
                <button className={`pxlCommon-btn ${containsSchedularWrite ?  "" : 'sch-btn-disbale'}`} disabled={!containsSchedularWrite} onClick={piLogin}>{title}</button>
                {!containsSchedularWrite &&  <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(containsSchedularWrite)}</p></div>}
                </div>
			}
            
        </>
    );
}

export default PinterestBtn;