import { useEffect,useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import { commonToast, hitApi, setMyState } from "../components/commonAction";
import { useStore } from "../zustand/store";
import ConfirmationPopup from "../components/common/ConfirmationPopup";
import { Helmet } from "react-helmet";

const Autoresponder = ({ pageTitle }) => {

    const [state, setState] = useState({
        activeAR: 'sendx',
        disconnectPopUp: false,
        connectedAR: [],
        fieldsValue: {},
        btnLoader: false
    });

    let store = useStore(state => state.userData);
    const firstInputRef = useRef(null);
    useEffect(() => {
      if (firstInputRef.current) {
          firstInputRef.current.focus();
      }
  }, [state.activeAR]);
    const ARfield = {
        'sendx': [{ name: 'teamId', label: 'Team ID', value: '' }, { name: 'apiKey', label: 'API Key', value: '' }],
        'mailchimp': [{ name: 'apiKey', label: 'API Key', value: '' }],
        'mailvio': [{ name: 'apiKey', label: 'API Key', value: '' }],
        'sendlane': [{ name: 'token', label: 'Token', value: '' }],
        'sendiio': [{ name: 'secret', label: 'Secret  Key', value: '' }, { name: 'token', label: 'Token', value: '' }],
    }

    useEffect(() => {
        getConnectedAR();
    }, []);

    const getConnectedAR = () => {
        hitApi({
            url: 'autoresponder/getAR',
            method: "GET",
            data: { userId: store.userId }
        }, (resp, err = null) => {
            if (resp.status) {
                const newState = { ...state.fieldsValue };
                resp.data.forEach(item => {
                    const { name, data } = item;
                    newState[name] = { ...data };
                });
                let connectARList = resp.data.map((obj) => obj.name);

                setMyState(setState, { connectedAR: [...new Set(connectARList)], fieldsValue: newState });
            }
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const newState = { ...state.fieldsValue };
        newState[state.activeAR] = { ...newState[state.activeAR], [name]: value };
        setMyState(setState, { fieldsValue: newState });
    };

    const connetAR = (e) => {
        e.preventDefault();
        const activeARFields = ARfield[state.activeAR];
      
        const missingFields = activeARFields.filter((field) => !state.fieldsValue[state.activeAR]?.[field.name]?.trim());
            
        if (missingFields.length > 0) {
            commonToast('error', `${missingFields[0].label} is required.`);
        } else {
            let data = { ...state.fieldsValue[state.activeAR], arName: state.activeAR };
            
            setMyState(setState, { btnLoader: true });
            hitApi({
                url: 'autoresponder/connectAR',
                method: "POST",
                data: data
            }, (resp, err = null) => {
                setMyState(setState, { btnLoader: false });
                if (resp.status) {
                    setMyState(setState, { connectedAR: [...state.connectedAR, state.activeAR] });
                }
            });
        }
    };

    const disconnectAR = (e) => {
        e.preventDefault();
        setMyState(setState, { disconnectPopUp: true });
    };

    return (
        <>
            <div className="pxl_mainWrapper">
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>
                <Sidebar />
                <div className="pxl_rightBar_assets">
                    <div className="pxl_autoresponder_wrapper">
                        <div className="pxl_autoresponder_container">
                            <h4 className="pxl_heading">
                                Connect Your Autoresponder
                            </h4>
                            <div className="pxl_autoresponder_list">
                                <ul>
                                    {['sendx', 'mailchimp', 'mailvio', 'sendlane', 'sendiio'].map((ar) => (
                                        <li key={ar} onClick={() => setMyState(setState, { activeAR: ar })}>
                                            <a href="#" className={
                                                state.connectedAR.includes(ar) ? "sch-autoresponder-active" : (state.activeAR === ar ? "pxl_autoresponder_selected" : undefined)
                                            }>
                                                <span className="pxl_autoresponder_icon">
                                                    <img src={`assets/images/autoresponder/${ar}.png`} alt="" />
                                                </span>
                                                <span className="pxl-autoresponder-name">{ar.charAt(0).toUpperCase() + ar.slice(1)}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="pxl_autoresponder_content">
                                <h4 className="pxl_from_title">
                                    {state.connectedAR.includes(state.activeAR) ? 'Disconnect' : 'Connect'} {state.activeAR}
                                </h4>
                                <form onSubmit={state.connectedAR.includes(state.activeAR) ? disconnectAR : connetAR}>
                                    <div className="pxl_autoresponder_frm">
                                        {
                                            ARfield[state.activeAR].map((fields, index) => (
                                                <div className="pxl_input_feilds" key={index}>
                                                 
                                                    <label>
                                                        {fields.label}
                                                    </label>
                                                    <input type="text" ref={index==0?firstInputRef:null} name={fields.name} placeholder={`Enter ${fields.label}`} readOnly={state.connectedAR.includes(state.activeAR)}
                                                        onChange={handleChange}
                                                        value={state.fieldsValue[state.activeAR] && state.fieldsValue[state.activeAR][fields.name] || ''} />
                                                </div>
                                            ))
                                        }

                                        <div className="pxl_btn_wrap pxl-autoresponder-btn">
                                            <button type="submit" disabled={state.btnLoader} className="pxlCommon-btn">
                                           
                                                {state.connectedAR.includes(state.activeAR) ? 'Disconnect' : state.btnLoader ? 'Processing...' : 'Connect'}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationPopup
                shownPopup={state.disconnectPopUp}
                closePopup={() => {
                    setMyState(setState, {
                        disconnectPopUp: false
                    })
                }}
                headTitle={'Disconnect Autoresponder'}
                btnLoader={state.btnLoader}
                type={"disconnect"}
                title={`Do you want to disconnect ${state.activeAR} autoresponder?`}
                subTitle={`This autoresponder will be disconnected immediately. you can't undo this action.`}
                yesBtn="Yes, Disconnect"
                removeAction={() => {
                    setMyState(setState, {
                        btnLoader: true,
                    })
                    hitApi({
                        url: 'autoresponder/disconnectAR',
                        method: "DELETE",
                        data: {
                            arName: state.activeAR
                        },
                    }, (resp, err = null) => {
                        setMyState(setState, {
                            disconnectPopUp: false, btnLoader: false,
                        })
                        if (resp.status) {
                            const newState = { ...state.fieldsValue };
                            delete newState[state.activeAR];
                            setMyState(setState, { connetAR: state.connectedAR.splice(state.connectedAR.indexOf(state.activeAR), 1), fieldsValue: newState });
                        }
                    })
                }}
            />
        </>
    )
};
export default Autoresponder;




// import { useEffect, useState } from "react";
// import Sidebar from "../components/Sidebar";
// import { commonToast, hitApi, setMyState } from "../components/commonAction";

// import { useStore } from "../zustand/store";
// import ConfirmationPopup from "../components/common/ConfirmationPopup";
// import {Helmet} from "react-helmet";


// const Autoresponder = ({ pageTitle }) => {

//     const [state, setState] = useState({
//         activeAR: 'sendx',
//         disconnectPopUp: false,
//         connectedAR: [],
//         fieldsValue: {},
//         btnLoader:false
//     })

//     let store = useStore(state => state.userData);

//     const ARfield = {
//         'sendx': [{ name: 'teamId', label: 'Team Id', value: '' }, { name: 'apiKey', label: 'API Key', value: '' }],
//         'mailchimp': [{ name: 'apiKey', label: 'API Key', value: '' }],
//         'mailvio': [{ name: 'apiKey', label: 'API Key', value: '' }],
//         'sendlane': [{ name: 'token', label: 'Token', value: '' }],
//         'sendiio': [{ name: 'secret', label: 'Secret  Key', value: '' }, { name: 'token', label: 'Token', value: '' }],
//     }

//     useEffect(() => {
//         getConnectedAR();
//     }, [])

//     const getConnectedAR = () => {
//         hitApi({
//             url: 'autoresponder/getAR',
//             method: "GET",
//             data: { userId: store.userId }
//         }, (resp, err = null) => {
//             if (resp.status) {
//                 const newState = { ...state.fieldsValue };
//                 resp.data.forEach(item => {
//                     const { name, data } = item;
//                     newState[name] = { ...data };
//                 });
//                 let connectARList = resp.data.map((obj) => obj.name);

//                 setMyState(setState, { connectedAR: [...new Set(connectARList)], fieldsValue: newState })
//             }
//         });
//     }

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         const newState = { ...state.fieldsValue };
//         newState[state.activeAR] = { ...newState[state.activeAR], [name]: value };
//         setMyState(setState, { fieldsValue: newState })
//     };

//     const connetAR = (e) => {
//         e.preventDefault();
//         const activeARFields = ARfield[state.activeAR];
      
//         const missingFields = activeARFields.filter((field) => !state.fieldsValue[state.activeAR]?.[field.name]?.trim());
            
//         if (missingFields.length > 0) {
//             commonToast('error' ,`${missingFields[0].label} is required.`);
//         } else {
//             let data = { ...state.fieldsValue[state.activeAR], arName: state.activeAR };
            
//             setMyState(setState, { btnLoader:true });
//             hitApi({
//                 url: 'autoresponder/connectAR',
//                 method: "POST",
//                 data: data
//             }, (resp, err = null) => {
//                 setMyState(setState, { btnLoader:false })
//                 if (resp.status) {
//                     setMyState(setState, { connectedAR: [...state.connectedAR, state.activeAR] })
//                 }
//             });
//         }
//     }

//     const disconnectAR = (e) => {
//         e.preventDefault();
//         setMyState(setState, { disconnectPopUp: true })
//     }

//     return (
//         <>
//             <div className="pxl_mainWrapper">
//                 <Helmet >
//                     <title>{pageTitle}</title>
//                 </Helmet>
//                 <Sidebar />
//                 <div className="pxl_rightBar_assets">
//                     <div className="pxl_autoresponder_wrapper">
//                         <div className="pxl_autoresponder_container">
//                             <h4 className="pxl_heading">
//                                 Connect Your Autoresponder
//                             </h4>
//                             <div className="pxl_autoresponder_list">
//                                 <ul>
//                                     <li onClick={() => setMyState(setState, { activeAR: 'sendx' })}>
//                                         <a href="#" className={state.connectedAR.includes('sendx') ? "sch-autoresponder-active" : state.activeAR == 'sendx' && "pxl_autoresponder_selected"}>
//                                             <span className="pxl_autoresponder_icon">
//                                                 <img src="assets/images/autoresponder/SendX.png" alt="" />
//                                             </span>
//                                             <span className="pxl-autoresponder-name">SendX</span>
//                                         </a>
//                                     </li>
//                                     <li onClick={() => setMyState(setState, { activeAR: 'mailchimp' })}>
//                                         <a href="#" className={state.connectedAR.includes('mailchimp') ? "sch-autoresponder-active" : state.activeAR == 'mailchimp' && "pxl_autoresponder_selected"}>
//                                             <span className="pxl_autoresponder_icon">
//                                                 <img src="assets/images/autoresponder/mailchimp.png" alt="" />
//                                             </span>
//                                             <span className="pxl-autoresponder-name">Mailchimp</span>
                                            
//                                         </a>
//                                     </li>
//                                     <li onClick={() => setMyState(setState, { activeAR: 'mailvio' })}>
//                                         <a href="#" className={state.connectedAR.includes('mailvio') ? "sch-autoresponder-active" : state.activeAR == 'mailvio' && "pxl_autoresponder_selected"}>
//                                             <span className="pxl_autoresponder_icon">
//                                                 <img src="assets/images/autoresponder/Mailvio.png" alt="" />
//                                             </span>
//                                             <span className="pxl-autoresponder-name"> Mailvio</span>
                                           
//                                         </a>
//                                     </li>
//                                     <li onClick={() => setMyState(setState, { activeAR: 'sendlane' })}>
//                                         <a href="#" className={state.connectedAR.includes('sendlane') ? "sch-autoresponder-active" : state.activeAR == 'sendlane' && "pxl_autoresponder_selected"}>
//                                             <span className="pxl_autoresponder_icon">
//                                                 <img src="assets/images/autoresponder/sandlane.png" alt="" />
//                                             </span>
//                                             <span className="pxl-autoresponder-name">Sendlane</span>
                                            
//                                         </a>
//                                     </li>
//                                     <li onClick={() => setMyState(setState, { activeAR: 'sendiio' })}>
//                                         <a href="#" className={state.connectedAR.includes('sendiio') ? "sch-autoresponder-active" : state.activeAR == 'sendiio' && "pxl_autoresponder_selected"}>
//                                             <span className="pxl_autoresponder_icon">
//                                                 <img src="assets/images/autoresponder/Sendiio.png" alt="" />
//                                             </span>
//                                             <span className="pxl-autoresponder-name">Sendio</span>
                                            
//                                         </a>
//                                     </li>
//                                 </ul>
//                             </div>
//                             <div className="pxl_autoresponder_content">
//                                 <h4 className="pxl_from_title">
//                                     {state.connectedAR.includes(state.activeAR) ? 'Disconnect' : 'Connect'} {state.activeAR}
//                                 </h4>
//                                 <form onSubmit={state.connectedAR.includes(state.activeAR) ? disconnectAR : connetAR}>
//                                     <div className="pxl_autoresponder_frm">
//                                         {
//                                             ARfield[state.activeAR].map((fields,index) => (
//                                                 <div className="pxl_input_feilds" key={index}>
//                                                     <label>
//                                                         {fields.label}
//                                                     </label>
//                                                     <input type="text" name={fields.name} placeholder={`Enter ${fields.label.toLocaleLowerCase()}`} readOnly={state.connectedAR.includes(state.activeAR)}
//                                                         onChange={handleChange}
//                                                         value={state.fieldsValue[state.activeAR] && state.fieldsValue[state.activeAR][fields.name] || ''} />
//                                                 </div>
//                                             ))
//                                         }

//                                         <div className="pxl_btn_wrap">
//                                             <button type="submit" disabled={state.btnLoader} className="pxl_btn" >{state.connectedAR.includes(state.activeAR) ? 'Disconnect' : state.btnLoader ?   'Processing...'   : 'Connect'}</button>
//                                         </div>

//                                     </div>
//                                 </form>
//                             </div>

//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <ConfirmationPopup
//                 shownPopup={state.disconnectPopUp}
//                 closePopup={() => {
//                     setMyState(setState, {
//                         disconnectPopUp: false
//                     })
//                 }}
//                 headTitle={'Disconnect Autoresponder'}
//                 btnLoader={state.btnLoader}
//                 type={"disconnect"}
//                 title={`Do you want to disconnect ${state.activeAR} autoresponder?`}
//                 subTitle={`This autoresponder will be disconnected immediately. you can't undo this action.`}
//                 yesBtn="Yes, Disconnect"
//                 removeAction={() => {
//                     setMyState(setState, {
//                         btnLoader: true,
//                     })
//                     hitApi({
//                         url: 'autoresponder/disconnectAR',
//                         method: "DELETE",
//                         data: {
//                             arName: state.activeAR
//                         },
//                     }, (resp, err = null) => {
//                         setMyState(setState, {
//                             disconnectPopUp: false,btnLoader: false,
//                         })
//                         if (resp.status) {
//                             const newState = { ...state.fieldsValue };
//                             delete newState[state.activeAR];
//                             setMyState(setState, { connetAR: state.connectedAR.splice(state.connectedAR.indexOf(state.activeAR), 1), fieldsValue: newState })
//                         }
//                     })
//                 }}
//             />
//         </>
//     )
// };
// export default Autoresponder;