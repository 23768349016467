import { useEffect, useState } from 'react'
import SocialAccount from './components/SocialAccountComponant'
import { useStore } from '../../zustand/store';
import { Loader, NoDataFound, commonToast, createScheduleDate, dateFormatter, hitApi, setMyState } from '../../components/commonAction';
import schedulerSvg from './components/schedulerSvg';
import SchedulePopup, { PreviewPost } from './components/SchedulePopUp';
import { useNavigate } from "react-router-dom";
import moment from 'moment';


function SchedulePost() {
  let store = useStore(state => state);

  const [state, setState] = useState({
    schedulePopUp: false,
    postDate: "",
    scheduleDate: '',
    timeZone: '',
    timeZoneOffset: '',
    PreviewPopUp: false,
    previewData: {},
    selectedPostList: [],
    checkedPost: [],
    selectedAccounts: {},
    postOn: [],
    supportedAccounts : []
  });

  const navigate = useNavigate();

  useEffect(() => {
    setMyState(setState, { selectedPostList: store.scheduler?.schedulePost, checkedPost: store.scheduler?.schedulePost.map(val => val._id) });

  }, [])

  useEffect(() => {

    let checkedPost = state.selectedPostList.filter((val) => state.checkedPost.some(id => val._id == id));

    const commonSupportedAccounts = checkedPost.reduce((acc, post) => {
      if (acc.length === 0) {
        return post.supportSocialAccount;
      } else {
        return acc.filter(account => post.supportSocialAccount.includes(account));
      }
    }, []);

    setMyState(setState,{supportedAccounts : commonSupportedAccounts});
    
  },[state.checkedPost])

  useEffect(() => {
    document.body.classList.add('pxl-body-white');
    return () => {
      document.body.classList.remove('pxl-body-white');
    };
  }, []);

  // useEffect(() => {
  //   if (!state.timeZone) {
  //     const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //     setMyState(setState, { timeZone: timeZone });
  //   }
  // }, [state.schedulePopUp])



  const scheduleDateHandle = (key, value, offset) => {
    let copyArray = state.selectedPostList;
    if (key == 'date') {
      const date = new Date(value);
      const formattedDate = dateFormatter(date);

      setMyState(setState, { postDate: date, scheduleDate: value });


      // const updatedObject = { ...copyArray.find(item => item._id === state.schedulePopUp), postDate: formattedDate, scheduleDate: value };
      // const indexToUpdate = copyArray.findIndex(item => item._id === state.schedulePopUp);
      // copyArray[indexToUpdate] = updatedObject;
      // setMyState(setState, { selectedPostList: copyArray, scheduleDate: formattedDate });

    } else if (key == 'timezone') {
      setMyState(setState, { timeZone: value, timeZoneOffset: offset });


      // const updatedObject = { ...copyArray.find(item => item._id === state.schedulePopUp), timeZone: value, timeZoneOffset: offset };
      // const indexToUpdate = copyArray.findIndex(item => item._id === state.schedulePopUp);
      // copyArray[indexToUpdate] = updatedObject;

      // setMyState(setState, { selectedPostList: copyArray, timeZone: value, timeZoneOffset: offset });

    }
  }


  const handleCheckbox = (sourceUpdate, updatedlist) => {

    setMyState(setState, { selectedAccounts: updatedlist, postOn: sourceUpdate });
  };



  const handleSelectPost = (val, post) => {

    if (val) {
      setMyState(setState, { checkedPost: [...state.checkedPost, post._id] })
    } else {
      const arrCopy = Array.from(state.checkedPost);
      const objWithIdIndex = arrCopy.findIndex((obj) => obj === post._id);
      arrCopy.splice(objWithIdIndex, 1);
      setMyState(setState, { checkedPost: arrCopy })
    }

  }

  function handleScheduleDraftPost() {
    if (state.checkedPost.length == 0) {
      return false
    }
    let checkedPost = state.selectedPostList.filter((val) => state.checkedPost.some(id => val._id == id));

    // const hasRequiredFields = checkedPost.every(obj => {
    //   return obj.hasOwnProperty("timeZone") && obj.hasOwnProperty("scheduleDate")
    // });

    // if (!hasRequiredFields) {
    //   commonToast('error', `Post missing timezone or schedule date.`);
    //   return false;
    // }
    if (state.postOn.length == 0) {
      commonToast('error', `Select atleast one social account.`);
      return false;
    }

    const hasError = checkedPost.some(post => {
      const supportedAccountsLower = post.supportSocialAccount.map(account => account.toLowerCase());
      const hasOnlySupported = state.postOn.every(account => supportedAccountsLower.includes(account.toLowerCase()));
      
      if (!hasOnlySupported) {
        return true;
      }
      return false; 
    });
    
    if(hasError)
    {
      commonToast('error', `Only supported accounts are allowed for each post.`);
      return false;
    }
    

    let dates = {}

    let d1 = new Date(state.postDate)
    let offset = d1.getTimezoneOffset()
    if (state.timeZoneOffset > 0) {
      d1.setMinutes(d1.getMinutes() + offset);
      d1.setMinutes(d1.getMinutes() + (state.timeZoneOffset * 60))
    }
    else {
      d1.setMinutes(d1.getMinutes() - (state.timeZoneOffset * 60))
      d1.setMinutes(d1.getMinutes() - offset)
    }
   
    dates.postDate = d1
    // dates.scheduleDate = new Date()
    dates.scheduleDate = moment(state.postDate).utcOffset(state.timeZoneOffset).utc().format()

    const modifiedData = checkedPost.map((obj) => {
      // let data = {
      //   title: obj.title,
      //   caption: obj.caption,
      //   postType: "shchedule",
      //   draftId: obj._id,
      //   scheduleDate: createScheduleDate(obj.scheduleDate, obj.timeZoneOffset),
      //   postDate: obj.postDate,
      //   timeZone: obj.timeZone,
      //   postOn: state.postOn
      // }

      let data = {
        brandId: obj.brandId,
        title: obj.title,
        caption: obj.caption,
        postType: "shchedule",
        draftId: obj._id,
        // scheduleDate: createScheduleDate(state.scheduleDate, state.timeZoneOffset),
        // postDate: state.postDate,
        supportedAccounts:obj.supportSocialAccount,
        timeZone: state.timeZone,
        postOn: state.postOn
      }
      if (obj.mediaUrl) {
        data['mediaUrl'] = obj.mediaUrl;
      }
      return data;
    });

    hitApi({
      url: 'scheduler/post/multiple-schedule-post',
      method: "POST",
      data: {
        "accounts": state.selectedAccounts,
        "postList": modifiedData,
        dates,
      },
    }, (resp, err = null) => {
      setMyState(setState, { schedulePopUp: false, scheduleDate: '', timeZone: '' })
      if (resp.status) {
        let updatedList = state.selectedPostList.filter(post => !modifiedData.some(check => check.draftId == post._id));
        setMyState(setState, { selectedPostList: updatedList, checkedPost: [], selectedAccounts: [] });
        store.updateStore("scheduler", { calendarDate: '', schedulePost: updatedList });
        navigate('/scheduler/calendar', { replace: true });
      }
    });
  }

  const SocialIcons = {
    "FACEBOOK": "/assets/images/social-icons/fb.svg",
    "LINKEDIN": "/assets/images/social-icons/linkdin.svg",
    "INSTAGRAM": "/assets/images/social-icons/instagram.svg",
    "PINTEREST": "/assets/images/social-icons/pinterest.svg",
    "YOUTUBE": "/assets/images/newpost/YT.png"
  }
  return (
    <>

      {store.pageLoading && <Loader />}

      <div className='pxlDraft-postMainBOx'>
        <div className='sch-container-fluid'>
          <div className='sch-draft-post-wrpper'>
            <div className='sch-draft-post-flex sch-schedule-desktop'>
              <h2>Schedule Multiple Posts</h2>
              <div className='sch-scheduleBtn'>
                <button disabled={state.checkedPost.length == 0} onClick={() => setMyState(setState, { schedulePopUp: true })} className={`pxlCommon-btn ${state.checkedPost.length == 0 && 'pxl-button-disable'}`}>
                  Schedule
                </button>
              </div>
            </div>
            <div className='sch-schedule-postWrap'>
              <div className='sch-socialAccount'>
                <SocialAccount supportedAccounts = {state.supportedAccounts} selectedAccounts={state.selectedAccounts} postOn={state.postOn} handleCheckbox={handleCheckbox} />
              </div>

              <div className='sch-schedule-main pxlSchedule-singlepost-data'>
                <div className='sch-schedule-mainRes'>
                  <div className='sch-draft-post-flex'>
                    <h2>Schedule Multiple Posts</h2>
                    <div className='sch-scheduleBtn'>
                      <a className={`pxlCommon-btn ${state.checkedPost.length == 0 && 'pxl-button-disable'}`} onClick={handleScheduleDraftPost}>
                        Schedule

                      </a>
                    </div>
                  </div>
                </div>
                {
                  state.selectedPostList.length > 0 ? state.selectedPostList.map((post, i) => (
                    <div className='sch-schedule-post-row' key={post._id}>
                      <div className='sch-scheduleSec sch-scheduleSecFullPage'>
                        <div className="sch-custom-check-box cun-selectAll" style={{ cursor: 'not-allowed!important' }}>
                          <div className="form-group" >
                            <input type="checkbox" id={i}
                              // disabled={!(post.scheduleDate && post.timeZone)}
                              checked={state.checkedPost.includes(post._id)}
                              onChange={(e) => handleSelectPost(e.target.checked, post)} />
                            <label htmlFor={i} ></label>
                          </div>
                        </div>
                        {/* <div className='sch-scheduleImage'>
                          {post.mediaUrl && post.mediaUrl.length > 0 && <img src={post.mediaUrl[0]?.thumb || post.mediaUrl[0]?.path} alt="" />}
                        </div> */}
                        <div className='sch-schedulePost-heading'>
                          <h2>{[post.title]}</h2>
                        </div>
                      </div>
                      <div className='pxl-scheduleContent'>
                        <p>{[post.caption]}</p>
                      </div>
                      <div className='sch-scheduleSecRight'>
                        <div className='sch-scheduleCalender' >
                          {
                            post?.supportSocialAccount && post?.supportSocialAccount?.length > 0 &&
                            <div className='pxl-schdule-post-socialIcons-sec'>
                              {post?.supportSocialAccount?.map((s2) => {
                                return (<>
                                  <img src={SocialIcons[s2]} alt="" />
                                </>)
                              })}

                            </div>

                          }


                        </div>
                        <div className='sch-schedulePreview' onClick={() => setMyState(setState, { PreviewPopUp: true, previewData: post })}>
                          <a href="#" onClick={(e) => {
                            e.preventDefault()
                          }}>
                            <span className='sch-eyeSvg'>
                              {schedulerSvg.app.EyeWhiteSvg}
                            </span>
                            <span className='schPreview'>
                              Preview
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  )) :
                    <NoDataFound title={'There is no post to schedule.'} bgRemove={true} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <SchedulePopup
        shownPopup={state.schedulePopUp}
        closePopup={() => {
          setMyState(setState, {
            schedulePopUp: false,
            // scheduleDate: '',
            // timeZone: ''
          })
        }}
        type={"Schedule Post"}
        title={`Schedule Post`}
        handleValue={scheduleDateHandle}
        scheduleDate={state.scheduleDate}
        handleSchedule={handleScheduleDraftPost}
        timezone={state.timeZone}
        subTitle={'All scheduled posts will be posted on selected social media platforms'}
        callFrom="draftSchedule"
      />

      <PreviewPost
        shownPopup={state.PreviewPopUp}
        closePopup={() => {
          setMyState(setState, {
            PreviewPopUp: false
          })
        }}
        data={state.previewData}
      />

      {/* social popup start */}

    </>
  )
}

export default SchedulePost