import { Helmet } from "react-helmet";
import { useState, useEffect,useRef } from "react";
import { setMyState,NoDataFound } from "../../components/commonAction";
import { hitApi, hexToRgbA } from "../../components/commonAction";
import { skeletonLoader, useOutsideClick } from "../../components/commonAction";
import svg from "./components/svg";
import { useStore } from "../../../src/zustand/store";


// let data = [ 
//     {id:1,count:262,type:'Resolved'},
//     {id:2,count:262,type:'Closed'},
//     {id:3,count:262,type:'Waiting'},
//     {id:4,count:262,type:'Pending'},
//     {id:5,count:262,type:'Open'},
// ]

const Dashboard = ({ pageTitle }) => {
    const [data, setData] = useState([])
    const [state, setState] = useState({

        scheduledCount: 0,
        draftCount: 0,
        designcount: 0,
        isLoading: false,
        isCount: false,
    });
    const [allsubmenu, setallsubmenu] = useState(false);
    let store = useStore(state => state);

    let { userId } = store.userData;

    const fetchCounts = () => {
        setMyState(setState, { isCount: true, });

        hitApi({
            url: 'pixa-support/api/getAgent_analytics',
            method: "POST",
            data: { userId: userId },
            alert: false
        }, (resp, err = null) => {
            if (resp.status) {
                setData(resp.data)
                setMyState(setState, {isLoading:resp.data.length, draftCount: resp.data.draftcount, designcount: resp.data.designcount, scheduledCount: resp.data.scheduledcount });
            }

            setMyState(setState, { isCount: false });
        });
    }

    useEffect(() => {
        fetchCounts()
    }, [])


    const menuRef = useRef();
    useOutsideClick(menuRef, () => setallsubmenu(false));
    useEffect(() => {
        document.body.classList.add('pxl-body-white');
        return () => {
          document.body.classList.remove('pxl-body-white');
        };
      }, []);
    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>


            <main className="sch-container-fluid pxl-pixasupport-dashboard">
                {
                        state.isCount && [...Array(8)].map((i, index) => <div key={index}>{skeletonLoader('asset', 1,'100%', 165)}</div>)
                    }
            <div className="dash_head">
                    <h3>Dashboard  </h3>
                </div>
                <div className="pxl-pixasupport-tickets-status-wrap">
                   {<div className="pxl-pixasupport-ticket-statusBox pxl-ticketBoxColor1">
                        <h2>{data.totle_project}</h2>
                        <p>Total Project</p>
                    </div>}
                    {/* {
                        data.tickets?.map((val, i) => {
                        return(<div className="single_dash_box" key={i}>
                            <div className={`bodercolor`}
                                style={{ backgroundColor: hexToRgbA(val.color,1) }}
                                ></div>
                           
                            <div className={`pxl-pixasupport-ticket-statusBox`} 
                                style={{ color: val.color, borderColor: val.color, backgroundColor: hexToRgbA(val.color,0.1) }}
                                >
                                <h2 style={{ color: val.color, borderColor: val.color }}>{val.ticketCount}</h2>
                                <p>{val.name}</p>
                            </div>
                        </div>)})
                    } */}
                     {
                        data.tickets?.map((val, i) => {
                        return(
                            <div className="pxl-pixasupport-ticket-statusBox" style={{backgroundColor: hexToRgbA(val.color,0.1)}}>
                                <div className={`bodercolor`}
                                style={{ backgroundColor: hexToRgbA(val.color,1) }}
                                ></div>
                            <h2 style={{color: hexToRgbA(val.color,1)}}>{val.ticketCount}</h2>
                            <p >{val.name}</p>
                        </div>
                        )})
                    }
                    {<div className="pxl-pixasupport-ticket-statusBox pxl-ticketBoxColor5">
                        <h2>{data.Unassignedticket}</h2>
                        <p>Unassigned</p>
                    </div>}
                </div>
               
                <div className="dash_grid">
                   

                </div>
                    {state.isLoading ==0 && !state.isCount&&
                        <NoDataFound title={'No data found.'}  changeDesign={true} bgRemove={true}   />
                    }


            </main>
        </>
    )
};

export default Dashboard;