
import { Helmet } from "react-helmet";
import { useStore } from "../zustand/store";
import { useNavigate } from 'react-router-dom';
import Sidebar from "../components/Sidebar";
import svg from "../components/svg";

const Dashboard = ({ pageTitle }) => {
    let store = useStore(state => state);

    let { role, scope = {} } = store.userData;
    const navigate = useNavigate();

    let AppsDataOption = [
        { navigatePath: "/builder", teamMemNavUrl: "/builder/projects", urlPath: `app.pixalab.ai/builder`, app: 'Builder', value: 'builder', icon: 'builderApp',toolTip:'' },
        { navigatePath: "/scheduler", teamMemNavUrl: "/scheduler/calendar", urlPath: `app.pixalab.ai`, app: 'Scheduler', value: 'scheduler', icon: 'schedularApp',toolTip:'Effortlessly create, plan and automate your content across multiple platforms, ensuring timely and consistent updates' },
        { navigatePath: "/images", teamMemNavUrl: "/images/templates", urlPath: `app.pixalab.ai/images`, app: 'Images', value: 'images', icon: 'imagesApp',toolTip:'Create stunning visuals with ease—design your own, explore our extensive library, or leverage AI for effortless enhancements' },
        { navigatePath: "/pixa-support", teamMemNavUrl: "/pixa-support/projects", urlPath: `app.pixalab.ai/pixa-support`, app: 'Pixa Support', value: 'support', icon: 'supportApp',toolTip:"Streamlines your website's customer service by integrating efficient ticketing solutions, ensuring seamless support management and enhanced user experience" }
    ]

    const AppsData = (role === "teamMember") ? AppsDataOption.filter(item => scope.hasOwnProperty(item.value)) : AppsDataOption;


    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper">
                <Sidebar />
                <div className="pxl-admin-dashboard-wrapper">
                    <div className="pxl_rightBar_assets">
                        {/* Count Card Widgets Section */}
                        <div className="pxl-flex-divide">
                            <div className="pxl-headingTop">
                                <h2>{pageTitle}</h2>
                            </div>
                            {/* <div className="sch-search-section pxl-dashboardSearch1">
                            <div className="sch-search-row sch-search-bg-color">
                                <input placeholder="Search Here..." type="text" />
                                <button type="button" className="sch-search-btn">
                                    <img src="/assets/images/scheduler/search.svg"/>
                                    </button>
                            </div>
                        </div> */}
                        </div>
                        {/* Trending Templates Section */}
                        <div className="pxl-templates-wrapper pxl-dashboard-appmain-wrap">
                            <div className="pxl-container-fluid">
                                <div className="pxl-apps-heading">
                                    <h2 className="pxl-font-20-weight-700">Apps</h2>
                                </div>
                                <div className="pxl-dashboard-appWrap">

                                    {
                                        AppsData.map((val, index) => (<>
                                            {val.app != "Builder" &&
                                                <div className="" key={index} onClick={() => {
                                                    {
                                                        val.app != "Builder" && navigate(role === "teamMember" ? val.teamMemNavUrl : val.navigatePath);
                                                    }
                                                }}>
                                                    <div className={`pxl-dashboard-appbox cun-iconTooltip pxl-dashboard-app-color-box${index}`}>
                                                        <div className="pxl-dashboard-box-left">
                                                            <div className="pxl-dashboard-app-img">
                                                                {svg.app[val.icon]}
                                                                {/* <img src="/assets/images/app-schedular-svg.svg"/> */}
                                                            </div>
                                                            <div className="pxl-dashboard-app-content">
                                                                <p>{val.app}</p>
                                                            </div>
                                                        </div>
                                                        <div className="pxl-dashboard-box-right">
                                                            {svg.app.dashboardAppSchedularSvg}
                                                        </div>
                                                        <div className="cun-show-tooltip" onClick={(e) => e.stopPropagation()}>
                                                            <p>{val.toolTip}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                        </>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                        {(role!='teamMember') && <div className="pxl-templates-wrapper pxl-dashboard-appmain-wrap pxl-upComing-box ">
                            <div className="pxl-container-fluid">
                                <div className="pxl-apps-heading">
                                    <h2 className="pxl-font-20-weight-700"><span className="mt-5"> Upcoming</span></h2>
                                </div>
                                <div className="pxl-dashboard-appWrap">
                                    {
                                        // AppsData.map((val, index) => (
                                            <div className="" key={"index"} 
                                            // onClick={() => {
                                            //     {
                                            //         val.app != "Builder" && navigate(role === "teamMember" ? val.teamMemNavUrl : val.navigatePath);
                                            //     }
                                            // }}
                                            >
                                                {/* {val.app == "Builder" ? */}
                                                    <div className={`pxl-dashboard-appbox pxl-dashboard-app-color-box${0}`}>
                                                        <div className="pxl-dashboard-box-left">
                                                            <div className="pxl-dashboard-app-img">
                                                                {svg.app["builderApp"]}
                                                                </div>
                                                            <div className="pxl-dashboard-app-content">
                                                                <p>Builder</p>
                                                            </div>
                                                        </div>
                                                        <div className="pxl-dashboard-box-right">
                                                            {svg.app.dashboardAppSchedularSvg}
                                                        </div>
                                                    </div>
                                                    {/* :'' */}
                                                {/* } */}

                                            </div>
                                        // ))
                                    }

                                </div>
                            </div>
                        </div>}


                    </div>
                </div>
            </div>






        </>
    )
};

export default Dashboard;